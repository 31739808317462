import React, {useState, useEffect, useCallback} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import FormControlLabel  from '@mui/material/FormControlLabel';
import Popover from '@mui/material/Popover';
import { HeaderBar, MessageBox, AdjuntoToggle, Attributes, PrintPreview, ExportButton } from '../../../components';
import FileList from '../../Attachments/FileList';
import POrderEdit from './POrderEdit';
import {POrderDetail} from './POrderDetail';
import POrderBottomMenu from './POrderBottomMenu';
import POrderLine from './POrderLine';
import POrderPrint from './POrderPrint';
import POrderImportDialog from './POrderImportDialog';
import POrderRecepcionDialog from './POrderRecepcionDialog';
import ActivityOpenClose  from '../../Activities/ActivityOpenClose';
import { postAPI, getAPI, deleteAPI, useTabs, checkUserRoles, activityLog } from "../../../utils";
import {red, green, blue, options_moneda, EXCEL_LIMIT} from '../../../appConfig';

import moment from 'moment'

const AttributesStyled = styled(Attributes)(({ theme }) => ({
    '.MuiAttributes-label': {
      borderBottom: '1px solid'
    },
  }) 
)

const ArchivosPedidos = ({ doc, disabled, anchorEl, adjunto, handleAdjunto, handleUpload, handleUploadDelete, smallScreen}) => {
  return (
      <Box sx={{height:'100%', width: '100%', overflow: 'hidden'}}>
        <AdjuntoToggle 
            editable={!disabled} 
            edit={adjunto[0]} 
            onClick={handleAdjunto(0)}
        >
            Archivo Pedido
        </AdjuntoToggle>                          
        <Popover
          // id={id}
          open={adjunto[0]}
          anchorEl={anchorEl}
          onClose={handleAdjunto(0)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <FileList 
            editable={true}
            folder="/compras"
            docs={doc.archivo_pedido} 
            onUploaded={handleUpload('archivo_pedido')}
            onDelete={handleUploadDelete('archivo_pedido')}
          />
        </Popover>
        <FileList 
            style={{
              overflow: 'auto', height: !smallScreen ? "calc(100% - 340px)" : '100%'
            }}
            editable={false}
            folder="/compras"
            docs={doc.archivo_pedido} 
          />
    </Box>
  )}
  const ArchivosAdjuntos = ({ doc, disabled, anchorEl, adjunto, handleAdjunto, handleUpload, handleUploadDelete}) => {
    return (
    <Box sx={{height:'100%', width: '100%', overflow: 'hidden'}}>
      <AdjuntoToggle 
          editable={!disabled} 
          edit={adjunto[1]} 
          onClick={handleAdjunto(1)}
      >
          Archivo Detalle
      </AdjuntoToggle>
      <Popover
        // id={id}
        open={adjunto[1]}
        anchorEl={anchorEl}
        onClose={handleAdjunto(1)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <FileList 
          editable={true}
          folder="/compras"
          docs={doc.archivo_detalle} 
          onUploaded={handleUpload('archivo_detalle')}
          onDelete={handleUploadDelete('archivo_detalle')}
        />
      </Popover>
      <FileList 
        folder="/compras"
        docs={doc.archivo_detalle} 
      />
    </Box>
  )
}
const Adjuntos = ({...other}) => (
  <>
    <Grid item xs={12} md={3}>
      <ArchivosPedidos {...other}/>
    </Grid>
    <Grid item xs={12} md={3}>
      <ArchivosAdjuntos {...other} />
    </Grid>
  </>
)

const StatusChange = ({theme, status, onStatusChange, puedeAprobar, ...other}) => {
  const sx = {marginLeft: '20px', borderColor: 'white', color:'white'}
  const newButton = (col, status, dateFld) => (
    <Button
      size='small'
      variant='outlined' 
      sx={{
        ...sx, 
        backgroundColor: col,     
        // color: theme.palette.getContrastText(col),
        // '&:hover': {
        //     backgroundColor: col[700],
        // },        
      }} 
      onClick={onStatusChange(status, dateFld)} 
      {...other}
    >
      {status}
    </Button>
  )
  const aprobadoBtn    = puedeAprobar && newButton(green, 'APROBADO', 'fecha_aprobado')
  const desaprobadoBtn = puedeAprobar && newButton(red,   'DESAPROBADO', 'fecha_aprobado')
  const pedidoBtn      = newButton(green, 'PEDIDO', 'fecha_pedido')
  const confirmadoBtn  = newButton(green, 'CONFIRMADO', 'fecha_confirmado')
  const paraPedirBtn   = newButton(blue, 'PARA PEDIR')
  
  switch (status) {
    case 'PARA PEDIR':
      return [aprobadoBtn, desaprobadoBtn]
    case 'APROBADO':
      return [pedidoBtn, paraPedirBtn]
    case 'DESAPROBADO':
      return [paraPedirBtn]
    case 'PEDIDO':
      return [confirmadoBtn, paraPedirBtn]
    case 'CONFIRMADO':
      return [ paraPedirBtn ]
    default:
      return ""    
  }
}

export default function POrderShowEdit({docId, onReload}) {
    const { enqueueSnackbar } = useSnackbar();
    const [ edit, setEdit] = useState(docId === 'new');
    const [ doc, setDoc ] = useState({
      _id: docId,
      np: '',
      status: "PARA PEDIR", 
      tipo: 'MATERIALES',
      tratamiento: '-1',
      moneda: 'USD',
      lugar_entrega: 'WELTTECHNIK',
      provee: {},
      detail: [],
      fecha_entrega: moment(),
      fecha_aprobado: '',
      fecha_pedido: '',
      fecha_confirmado: '',
      fecha_recibido: ''
    });
    const [line, setLine] = useState(false);
    const [recepcion, setRecepcion] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [pendiente, setPendiente] = useState(false);
    const [adjunto, setAdjunto] = useState({0:false, 1:false});
    const [importDialog, setImportDialog] = useState(false);
    const [reload, setReload] = useState(false);
    const [skus, setSkus] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const currentUser = useSelector(state => state.currentUser);
    const {appendTab, updateCurrentTab, removeCurrentTab} = useTabs({
      onReload: reload => { 
        debugger; 
        if (onReload) onReload(reload);
        setReload(true); 
      }
    })

    const disabled = !checkUserRoles(currentUser, ['local-admin', 'gerencia', 'compras-edit']);
    const puedeAprobar = checkUserRoles(currentUser, ['local-admin', 'gerencia']);
    const puedeBorrar = checkUserRoles(currentUser, ['local-admin', 'gerencia']) && doc.status === 'PARA PEDIR';
    const puedeRecibir = ['PARA PEDIR','PEDIDO', 'APROBADO'].indexOf(doc.status) === -1;
    const puedePedir = ['PARA PEDIR'].indexOf(doc.status) > -1 && doc.pl_totArticles > 0;
    const puedeStatusChange = doc.tipo === 'VARIOS' || doc.tipo !== 'VARIOS' && doc.pl_totArticles > 0;

    useEffect( () => {
      debugger
      setReload(false)
      if ( docId === 'new' ) return 
      getAPI('/stock/porders/' + docId)
      .then(data => {
          debugger
          if (data.detail && data.detail.length) {
            const detail = data.detail.sort((a,b) => (a.linea - b.linea))
            data.detail = detail;
          }
          setDoc(data)
          
          if (data.detail) {
            let tot = 0;
            data.detail.forEach( it => {
              tot += it.pedido || 0;
            })
            setSkus(tot)
          }
      })
      .catch(err => {
          debugger
          console.log(err.message)
          enqueueSnackbar(err.message, {variant: "error"})
      });   
    }, [docId, edit, reload, enqueueSnackbar])

    const handleEdit = () => {
      setEdit(!edit)
    }

    const handleCancel = () => {
      setEdit(false)
      if (docId === 'new') {
          if (onReload) onReload(true)
          removeCurrentTab()
      }
    }

    const handleAdjunto = (num) => (event) => {
      // adjunto[num] = !adjunto[num];
      setAnchorEl(null);
      setAdjunto(adjunto => ({[num]: !adjunto[num]}))
      setAnchorEl(event.currentTarget);
    }

    const handleClose = (doc) => {
      debugger
      if (doc) {
        setDoc(doc)

        if (docId === 'new') {
          // fuerza un reload de todo el tab y relee el doc!
          updateCurrentTab("NP")(doc)
        }
      }
      setEdit(false)
      if (onReload) onReload(true)
    }

    const handleDeleteDialog = () => {
      setDialog(true)
    }
    
    const handleDialog = (valor) => {
      setDialog(false)
      if (valor) {
        handleDelete()
      }
    }
    
    const handleLine = (line, recibido) => () => {
      setLine(line)
    }
    const handleChangeStatus = (status, dateFld) => (ev) => {
      const obj = {
        status,
        [dateFld]: moment()
      }
      if (status === 'APROBADO') {
        obj['approvedById'] = currentUser._id;
      }
      if (status === 'PARA PEDIR') {
        obj['approvedById'] = null
        obj['fecha_aprobado'] = null
      }

      postAPI("/stock/porders/" + doc._id, obj)
      .then( data => {

        const msg = `Se cambió el estado de la orden de compra de "${doc.status}" a "${status}"`
        enqueueSnackbar(msg, {variant: "info"})
        activityLog({label: "NP-" + doc.np, app:'compras', docId: doc._id, msg, currentUser})

        setReload()
      })
      .catch( err => {
        enqueueSnackbar(err.message, {variant: "error"})
      })      
    }

    const handleSubmitLine = (line) => {
      debugger
      let esNuevo = false
      if (line._id === 'new') {
        delete line._id 
        esNuevo = true
      }

      if (line.vidrio) {
        line.udm = 'UN';
        line.udm_depo = 'UN';
      }

      postAPI("/stock/porders/" + doc._id + "/postLine", {line})
      .then( doc => {
          debugger
          const art = line.articulo ? `${line.articulo.codigo} ${line.articulo.descripcion} ${line.articulo_color || ""}` : line.descripcion;
          const msg = `Se ${esNuevo ? 'agregó' : 'modificó'} una linea: ${art} pedido=${line.pedido} x ${line.udm}`
          activityLog({label: "NP-" + doc.np, app:'compras', docId: doc._id, msg: msg, currentUser})    
  
          enqueueSnackbar(msg, {variant: "info"})
          setDoc(doc)
          setLine(false)
        })
      .catch( err => {
        enqueueSnackbar(err.message, {variant: "error"})
      })
    }

    const handleDeleteLine = (line) => () => {
      postAPI('/stock/porders/' + doc._id + '/delLine/' + line._id)
      .then(doc => {
          debugger
          const art = line.articulo ? `${line.articulo.codigo} ${line.articulo.descripcion} ${line.articulo_color || ""}` : line.descripcion;
          const msg = `Se borró la linea ${line.linea}: ${art} pedido=${line.pedido} x ${line.udm}`
          activityLog({label: "NP-" + doc.np, app:'compras', docId: doc._id, msg: msg, currentUser})    

          enqueueSnackbar("Se borró la linea del pedido", {variant: "info"})
          setDoc(doc)

          setReload(true)
      })
      .catch(err => {
          debugger
          console.log(err.message)
          enqueueSnackbar(err.message, {variant: "error"})
      });         
    }

    const handleNewReception = () => {
      debugger
      const rec = {_id: 'new', provee: doc.provee };
      if (rec._id === 'new') {
        rec.detail = doc.detail.filter( it => it.tipo === 'NP')
        rec.detail.forEach( it => {

          if (it.articulo && it.articulo.deposito_almacen) {
            it.almacen = {_id: it.articulo.deposito_almacen}
          }

          let sumRec = 0;
          doc.detail.forEach( it2 => {
            console.log(it2.pedidolog_ref, it._id)
            if (it2.pedidolog_ref === it._id && it2.tipo === 'REC') {
              sumRec += it2.recibido_depo;
            }
          })
          it.recibido_sum = sumRec;
        })
      }
      setRecepcion(rec)
    }

    const handleSubmitRecepcion = (rec) => {
      debugger
      postAPI("/stock/porders/" + doc._id + "/reception/" + rec._id, {rec})
      .then( ({result}) => {
        const msg = `Se agregó la recepción REC-${result.rec} a la NP-${doc.np}`
        activityLog({label: "REC-" + result.rec, app:'compras', docId: doc._id, msg, currentUser})    

        enqueueSnackbar("Se agregó recepción", {variant: "info"})
        setReload()
        setRecepcion(false)
      })
      .catch( err => {
        enqueueSnackbar(err.message, {variant: "error"})
      })
    }

    const handleDelete = () => {
      deleteAPI('/stock/porders/' + doc._id)
      .then(data => {
          debugger
          const msg = `Se borró el pedido: ${doc.np}`
          activityLog({label: "NP-" + doc.np, app:'compras', docId: doc._id, msg: msg, currentUser})    
  
          enqueueSnackbar("Se borró el registro", {variant: "info"})
          if (onReload) onReload(true)
          removeCurrentTab();
      })
      .catch(err => {
          debugger
          console.log(err.message)
          enqueueSnackbar(err.message, {variant: "error"})
      });         
    }

    const handleUpload = tipoAdjunto => uploadedFile => {
      debugger
      postAPI('/stock/porders/addToSet/' + doc['_id'], {[tipoAdjunto]: uploadedFile})
      .then(data => {
        debugger
        const msg = `Se guardó el adjunto: ${tipoAdjunto}/${uploadedFile.filename}`
        activityLog({label: "NP-" + doc.np, app:'compras', docId: doc._id, msg: msg, currentUser})    

        enqueueSnackbar(msg, {variant: 'info'})
        setReload(1);
      })
      .catch(err => {
        enqueueSnackbar(err.message, {variant: 'error'})
      });  
  }
  const handleUploadDelete = tipoAdjunto => file => ev => {
      debugger
      const nuevo = doc[tipoAdjunto].filter(it => it.filename !== file.filename);
      postAPI('/stock/porders/' + doc['_id'], {[tipoAdjunto]: nuevo})
      .then(data => {
        debugger
        const msg = `Se borró el adjunto: ${tipoAdjunto}/${file.filename}`
        activityLog({label: "NP-" + doc.np, app:'compras', docId: doc._id, msg: msg, currentUser})    

        enqueueSnackbar(msg, {variant: 'info'})
        setReload(1);
      })
      .catch(err => {
          enqueueSnackbar(err.message, {variant: 'error'})
      });
  }
  const handlePendiente = (ev) => {
    setPendiente(!pendiente)
  }
  const handlePL = (ev) => {
    if (doc.provee) {
      appendTab("PL")({...doc.provee, vigente: doc.fecha_entrega, tipo: doc.tipo})
    }
  }
  const handleOF = (ev) => {
    if (doc.of && doc.of.of) {
      appendTab("WORK-F")({...doc.of})
    }
  }
  const handleImportDialog = (st) => (ev) => {
    setImportDialog(st)
    setReload(true)
  }

  const configReport = useCallback( async () => {
    debugger

    const artKey = (it) => {return it && it.pedidolog_ref}

    if (!doc || !doc.detail) return;

    const sumRows = {};
    doc.detail.forEach( it => {
        const key = artKey(it);
        sumRows[key] = sumRows[key] || {np: 0, rec: 0, open: false}
        if (it.tipo === 'REC') {
          sumRows[key]['rec'] += Number(it.recibido_depo) || 0;
        }
        if (it.tipo === 'NP') {
          sumRows[key]['np'] += Number(it.pedido_depo) || 0;
        }
    })

    const detail = () => {
      if (!doc || !doc.detail) return [];
      return doc.detail.filter(it=>it.tipo === 'NP').sort(it => it.linea);
    }
    const getTotales = (row, tipo) => {
      const key = artKey(row);
      const el = sumRows[key];
      return el ? el[tipo] : ""
    }

    const colsVIDRIO = [
      {
        title: 'Código', field: '_', transform: (v, doc) => {
          if (doc.vidrio && doc.vidrio.codigo ) {
              return doc.vidrio.codigo
          }
        },
        headClassName: 'head1', rowClassName: 'row'
      },
      {
        title: 'Ancho', field: '_', transform: (v, doc) => {
          if (doc.vidrio && doc.vidrio.ancho ) {
                return doc.vidrio.ancho;
          }
        },
        headClassName: 'head1', rowClassName: 'row'
      },
      {
        title: 'Alto', field: '_', transform: (v, doc) => {
          if (doc.vidrio && doc.vidrio.alto ) {
                return doc.vidrio.alto;
          }
        },
        headClassName: 'head1', rowClassName: 'number'
      },
      {title: 'Composicion', field: 'articulo_color', headClassName: 'head1', rowClassName: 'row'},
      {title: 'Cara1', field: '_', transform: (v, doc) => {
        if (doc.vidrio && doc.vidrio.c1) {
          return doc.vidrio.c1
        }
      }},
      {title: 'CaraM', field: '_', transform: (v, doc) => {
        if (doc.vidrio && doc.vidrio.cm) {
          return doc.vidrio.cm
        }
      }},
      {title: 'Cara2', field: '_', transform: (v, doc) => {
        if (doc.vidrio && doc.vidrio.c2) {
          return doc.vidrio.c2
        }
      }},
      {title: 'PED', field: 'pedido',                 headClassName: 'head1', rowClassName: 'number'},
      {title: 'REC', field: '_', transform: (v, doc) => getTotales(v, 'rec'), headClassName: 'head1', rowClassName: 'number'},
      {title: 'UDM', field: 'udm',                    headClassName: 'head1', rowClassName: 'row'},
      {title: 'Precio', field: 'precio',              headClassName: 'head1', rowClassName: 'number'},
      {title: 'Moneda', field: 'moneda',              headClassName: 'head1', rowClassName: 'row'},
    ]
    const colsMATERIAL = [
        {title: 'Código', field: '_', transform: (v, doc) => {
                if (doc.articulo ) {
                    const codigo = doc.articulo.codigo;
                    const nn = Number(codigo);
                    if (nn) {
                        return `'${codigo}`;
                    }
                    return codigo;
                }
            },
            headClassName: 'head1', rowClassName: 'row'
        },
        {title: 'Descripción', field: '_', transform: (v, doc) => {
                if (doc.articulo ) {
                    return doc.articulo.descripcion;
                }
            },
            headClassName: 'head1', rowClassName: 'row'
        },
        {title: 'Color', field: 'articulo_color',   headClassName: 'head1', rowClassName: 'row'},
        {title: 'PED', field: 'pedido',            headClassName: 'head1', rowClassName: 'number'},
        {title: 'REC', field: '_', transform: (v, doc) => getTotales(v, 'rec'), headClassName: 'head1', rowClassName: 'number'},
        {title: 'UDM', field: 'udm',                    headClassName: 'head1', rowClassName: 'row'},
        {title: 'Precio', field: 'precio',              headClassName: 'head1', rowClassName: 'number'},
        {title: 'Moneda', field: 'moneda',              headClassName: 'head1', rowClassName: 'row'},
    ]
    const colsVARIOS = [

    ]
    let cols = colsMATERIAL;
    if (doc.tipo === 'VIDRIOS') {
      cols = colsVIDRIO;
    }
    if (doc.tipo === 'VARIOS') {
      cols = colsVARIOS;
    }

    const reportConfig = {
        title: `Lineas de pedido NP-${doc.np}] / [${doc.provee.codigo_proveedor}] ${doc.provee.fullname}`,
        subTitle: "Entrega=" + moment(doc.fecha_entrega).format('YYYY-MM-DD') + ". Creado por " + currentUser.username + " el " + moment().local().toISOString(),
        cols,
        styles: {
            head1:  {backgroundColor:"#dbe0f0", textAlign: 'center'},
            number: {textAlign:'right'},
            row:    {textAlign:"left"}             
        },
    }
debugger
    // const ret = await getAPI('/stock/porders/' + docId, {limit:EXCEL_LIMIT});
    reportConfig['rows'] = detail();

    return reportConfig;
}, [docId, doc.np, currentUser.username]);

  let title = !doc || docId === 'new' ? "Nueva NP" : (edit ? 
    `Edición de NP  ${doc.tipo} #${doc.np}  (${doc.status})` : 
    `Nota de Pedido ${doc.tipo} #${doc.np}  (${doc.status})`
  )
  if (smallScreen) {
    title = !doc || docId === 'new' ? "Nueva NP" : (edit ? 
      `Edición de NP  ${doc.tipo} #${doc.np}  (${doc.status})` : 
      `NP-${doc.np} (${doc.status})`
    )
  }
  const tratamiento = doc.tratamiento && doc.tratamiento.descripcion ? 'TRATAMIENTO ' + doc.tratamiento.descripcion : "";

  const provee = doc && doc.provee || {};
  let info = [
    <Box sx={{display: 'inline-block'}}>
      a [{provee.codigo_proveedor}] {provee.fullname} &nbsp;
      para [{moment(doc.fecha_entrega).utc().format('YYYY-MM-DD')}]: {doc.descripcion}
      {tratamiento}
    </Box>,
    <Box sx={{float:'right'}}>
      {doc.obra && `Obra=[${doc.obra.carpeta}] ${doc.obra.nombre}`}&nbsp;
      {doc.of && <Link onClick={handleOF} href='#'>{doc.of.tipo == 'fabrica' ? 'OF':'OS'}-{doc.of.of}</Link>}&nbsp;
      Entrega: {doc.lugar_entrega} &nbsp;
      {doc.tipo !== 'VARIOS' && 
        <span>
          Lista de Precios:  <Link onClick={handlePL} href='#'>{doc.pl_totArticles ? doc.pl_totArticles : <span style={{backgroundColor: 'red', color: '#ddd', padding: '4px'}}>No tiene definido</span> }</Link>
        </span>
      }
    </Box>,
  ]
  if (smallScreen) {
    info = [
      <Box sx={{display: 'inline-block', overflow: 'hidden'}}>[{provee.codigo_proveedor}] {provee.fullname} para [{moment(doc.fecha_entrega).utc().format('YYYY-MM-DD')}]: {doc.descripcion}</Box>
    ]
  }

  const reference = {docId, doc};

  return (
      <Paper sx={{width: '100%', height: '100%'}}> 
        <HeaderBar 
          title={[
            title, 
            !disabled && !edit && docId !== 'new' && 
              <StatusChange 
                theme={theme}
                disabled={!puedeStatusChange} 
                puedeAprobar={puedeAprobar} 
                status={doc.status} 
                onStatusChange={handleChangeStatus}
              />,
              // <span>{puedeStatusChange ? "SI" : "NO"}</span>
          ]}
          actions={[
            !disabled && !edit && docId !== 'new' && <Button autoFocus color="inherit" disabled={disabled} onClick={handleEdit}>{!edit ? "Editar" : "Cancelar"}</Button>,
            !disabled && edit && docId !== 'new' && <Button autoFocus variant='outlined' color="inherit" disabled={disabled || !puedeBorrar} onClick={handleDeleteDialog}>{"Borrar"}</Button>,
            !edit && docId !== 'new' && <PrintPreview sx={{float:'right', color: '#ddd'}} component={<POrderPrint doc={doc}/>}/>,
            !edit && docId !== 'new' && <ActivityOpenClose sx={{float: 'right', color: '#ddd'}} app='compras' docId={docId}/>,
          ]}
          info={docId !== 'new' && info}
        />
        <Box sx={{m:2}}/>

        <Box sx={{height: '100%'}}>
          
          {edit && 
            <POrderEdit
              doc={doc} 
              onClose={handleClose} 
              onCancel={handleCancel} 
            /> 
          }

          {!edit && doc && (
            <Box sx={{m:1, overflow: !smallScreen ? 'hidden' : 'auto', height:'100%'}}>

              {
                !disabled && doc && ["PARA PEDIR"].indexOf(doc.status) > -1 && 
                  <Button sx={{m:1, float: 'right'}} size='small' variant='outlined' disabled={!puedePedir} onClick={handleImportDialog(true)}>Importar</Button>
              }
              {
                !disabled && docId !== 'new' && 
                  <ExportButton sx={{m:1, float: 'right'}} configReport={configReport}>EXCEL</ExportButton>
              }

              <Stack direction='row' spacing={2}>
                {!disabled && <Button onClick={handleLine({_id: 'new', provee: doc.provee, tratamiento: doc.tratamiento })}  disabled={!puedePedir} variant='filled'>NUEVA LINEA</Button>}
                {!disabled && <Button onClick={handleNewReception} disabled={!puedeRecibir} variant='filled'>NUEVA RECEPCION</Button>}
                <FormControlLabel checked={pendiente} onChange={handlePendiente} control={<Switch/>} label="Pendiente"/>
              </Stack>

              <POrderDetail 
                sx={{height: !smallScreen ? "calc(100% - 340px)" : "100%"}} 
                doc={doc} 
                pendiente={pendiente}
                disabled={disabled}
                onEditLine={handleLine}
                onDeleteLine={handleDeleteLine}
                appendTab={appendTab}
              />
              <Box sx={{mt: '10px', width:'100%', height: '100%'}}>

                  <Grid container>
                    <Hidden mdDown>
                      <Adjuntos
                          doc={doc}
                          disabled={disabled}
                          anchorEl={anchorEl}
                          adjunto={adjunto}
                          handleAdjunto={handleAdjunto}
                          handleUpload={handleUpload}
                          handleUploadDelete={handleUploadDelete}
                          smallScreen={smallScreen}                 
                      />
                    </Hidden>
                    <Grid item xs={6} md={3}>
                        <AttributesStyled
                          style={{border: 0, boxShadow: "none", padding: "5px"}}
                          list={[
                            {label: 'Lineas', text: doc.detail && doc.detail.length},
                            {label: 'Skus', text: skus || 0},
                            doc.tipo !== 'VARIOS' ? {label: 'KG', text: doc.detail && doc.kg} : {},
                            doc.tipo === 'VIDRIOS' ? {label: 'ML', text: doc.detail && doc.ml} : {},
                            doc.tipo === 'VIDRIOS' ? {label: 'M2', text: doc.detail && doc.m2} : {},
                          ]}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Stack sx={{float:'right'}}>
                        <Typography>Total= {doc.presupuesto} {doc.moneda}</Typography>
                        {doc.tipo_cambio && <Typography>(USD={doc.tipo_cambio && doc.tipo_cambio['USDARG']} EUR={doc.tipo_cambio && doc.tipo_cambio['EURARG']})</Typography>}
                        Aprobado por {doc.approvedById ? doc.approvedById.username : '-'}
                      </Stack>
                    </Grid>

                    <Hidden mdUp>
                      <Adjuntos
                          doc={doc}
                          disabled={disabled}
                          anchorEl={anchorEl}
                          adjunto={adjunto}
                          handleAdjunto={handleAdjunto}
                          handleUpload={handleUpload}
                          handleUploadDelete={handleUploadDelete}
                          smallScreen={smallScreen}                   
                      />
                    </Hidden>

                  </Grid>
                  
              </Box>
            </Box>
          )}

          <POrderLine open={!!line} tipo={doc.tipo} line={line} tratamiento={doc.tratamiento} onCancel={handleLine(false)} onSubmit={handleSubmitLine} />

          <POrderRecepcionDialog open={!!recepcion} tipo={doc.tipo} recepcion={recepcion} pendiente={true} onCancel={()=> setRecepcion(false)} onSubmit={handleSubmitRecepcion}/>

          <MessageBox 
            title="Esta seguro que quiere eliminar este Pedido?"
            open={dialog} 
            onClose={handleDialog}
          />

          { 
            importDialog && 
            <POrderImportDialog 
                reference={reference}
                onClose={handleImportDialog(false)}
            /> 
          } 

        </Box>
      </Paper>
  )
}
