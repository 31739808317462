import React, {forwardRef, useState, useEffect, useCallback} from 'react'; 

import { useSearchParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import {PageBase, PageTitle, SearchBox} from '../../components'; 
import {getAPI, useTabs, checkUserRoles, debounce} from '../../utils' ;
import WorkOrderList from './WorkOrderList';
import RecursosBox from './RecursosBox';
import ActivityOpenClose  from '../Activities/ActivityOpenClose';
import {options_orders_fabrica_status, options_orders_servicios_status} from '../../appConfig';

const IndexPage = forwardRef( ({tipo="WORK-F", isTabSelected}, ref) => {
    let [searchParams] = useSearchParams();
    const { enqueueSnackbar }   = useSnackbar();
    const [filter, setFilter]   = useState(searchParams.get("filter")  || "");
    const [status, setStatus]   = useState(searchParams.get("status")  || "-1");
    const [obras,  setObra]     = useState(searchParams.get("obras")   || "");
    const [recurso, setRecurso] = useState(searchParams.get("recurso") || "-1");
    const [recursos, setRecursos] = useState(searchParams.get("recursos") || []);
    const [reload, setReload]   = useState(false);
    const {appendTab} = useTabs({
        onReload: (reload) => {
            debugger
            setReload(reload)
        }
    });

    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;
    
    const currentUser = useSelector(state => state.currentUser);
    const roles = [
        'local-admin', 'gerencia',
        tipo === 'WORK-F' ? 'fabrica-edit' : 'servicio-edit'
    ]
    const esLocalAdmin = checkUserRoles(currentUser, roles)

    const sort = 'updated_at:-1';

    const options_status0 = tipo === "WORK-F" ? options_orders_fabrica_status : options_orders_servicios_status;
    const options_status = [{label: "TODOS", value: "-1"}].
       concat(options_status0.map(it => {return {label: it, value: it}}));


    // const canOpts = useSelector( state => state.distributors)
    // const optionsDistrib = canOpts ? ([{label: 'TODOS', value:'-1'}].concat(canOpts.map(it => ({label:it.nombre, value:it.codigo}) )  )) : [];
    // const optionsStatus = options_opos_status.map( it => ({label: it, value: it}))

    const fn = useCallback( 
        debounce(
            (page, filter, obras, status, recurso, recursos, tipo, enqueueSnackbar) => {
                setReload(false)
                setLoading(true)
                const rec = tipo === 'WORK-F' ? [recurso] : recursos;
                getAPI('/work/workorders/', {filter, status, obras, tipo, recurso: rec, sort, skip: page*limit, limit})
                .then(data => {
                    debugger
                    setLoading(false);
                    data.list.forEach( it => it.id = it._id);
                    setNum(data.num)
                    setRows(data.list)
                  })
                .catch(err => {
                    console.error(err)
                    enqueueSnackbar(err.message, {variant: 'error'})
                });
            }, 500),
        []
    )        
    useEffect( () => {
        fn(page, filter, obras, status, recurso, recursos, tipo, enqueueSnackbar)
    }, [fn, page, filter, obras, status, recurso, recursos, tipo, reload, isTabSelected, enqueueSnackbar])

    const handleFilter = (ev) => {
        setFilter(ev.target.value)
    }
    const handleStatus = (ev) => {
      setStatus(ev.target.value)
    }
    const handleObra = (ev) => {
        setObra(ev.target.value)
    }
    const handleRecurso = (ev) => {
        setRecurso(ev.target.value)
    }
    const handleRecursos = (ev) => {
        debugger
        let valor = ev.target.value
        // if (valor.indexOf("-1") > -1) {
        //     valor = ["-1"]
        // } else if (valor.indexOf("-2") > -1) {
        //     valor = ["-2"]
        // } else {
        //     valor = valor.filter( it => it !== "-1" || it !== "-2")
        // }
        setRecursos(valor)
    }
    const handleClick= tipo => item => (ev) => {
        debugger
        ev.preventDefault();
        if (item.id) appendTab( tipo )(item)
    }

    return (
        <PageBase
            paperProps={{width:'100%', height: 'calc(100% - 98px)'}} 

            title={
                <>
                    <PageTitle>Ordenes {tipo === 'WORK-F' ? 'Fabrica':'Servicio'} ({num})</PageTitle>
                    <SearchBox sx1={{flexGrow: 1, marginRight: 2}} label="Filtro" value={filter} onChange={handleFilter}/>
                    <SearchBox label="Status" select options={options_status} value={status} onChange={handleStatus}/>
                    <Hidden mdDown>
                        <SearchBox sx={{flexGrow: 0}} label="Obra/PV" value={obras} onChange={handleObra}/>
                        {tipo === 'WORK-F' && <RecursosBox label="Recurso" tipo={tipo} value={recurso} onChange={handleRecurso}/>}
                        {tipo === 'WORK-S' && <RecursosBox label="Recursos" multiple tipo={tipo} value={recursos} onChange={handleRecursos}/>}
                    </Hidden>
                </>
            } 
            actions={
                <Box sx={{display: 'flex'}}>
                    {esLocalAdmin && <Button sx={{m:1, display: 'inline'}} size='small' variant='outlined' onClick={handleClick(tipo)({id: true, _id: 'new'})}>Nuevo</Button>} 
                    <ActivityOpenClose app={tipo === 'WORK-F' ? 'fabrica' : 'servicio'} withLabel/>
                </Box>
            }   
       >           
            <WorkOrderList
                tipo={tipo}
                loading={loading} 
                docs={rows} 
                num={num}
                limit={limit}
                disabled={!esLocalAdmin}

                onClick={handleClick}
                onPageChange={(newPage) => setPage(newPage)}
            />    

        </PageBase>
    )
})

export default IndexPage;