import React, {useState} from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TerminacionColoresAdmin from './Config/TerminacionColoresAdmin';
import UsersIndex from './Users/UserIndex';
import OrgIndex from './Organizations/OrgIndex';
import PlacesIndex from './Places/PlacesIndex';
import CounterIndex from './Config/CounterIndex';
import ExtraDataAdmin from './Config/ExtraDataAdmin';
import UnitsAdmin from './Config/UnitsAdmin';
import DistributorIndex from './Distributor/DistributorIndex';
import WarehouseIndex from './Warehouse/WarehouseIndex';
import JobsIndex from './Jobs/JobsIndex';
import SessionsIndex from './Sessions/SessionsIndex';
import OperationIndex from './Operation/OperationIndex';
import ResourceIndex from './Resource/ResourceIndex';
import ExchangeIndex from './Exchange/ExchangeIndex';
import TransportIndex from './Transport/TransportIndex';
import TaskTopicIndex from './TaskTopic/TaskTopicIndex';

import ListMenu from './ListMenu';

const ExtraCRMData = () => {
    return (
        <Grid container>
            <Grid item xs md={4} >
                <ExtraDataAdmin title="Oportunidad/Tipo Projecto" label='Tipo' table='oposProjectType' width='120'/>
            </Grid>
            <Grid item xs md={4} >
                <ExtraDataAdmin title="Oportunidad/Motivos Perdida" label='Motivo' table='oposMotives' width='120'/>
            </Grid>
            <Grid item xs md={4}>
                <ExtraDataAdmin title="Oportunidad/Recomendado" label='Categoria' table='oposRecommended' width='120'/>
            </Grid>
            <Grid item xs md={4} >
                <ExtraDataAdmin title="Contactos/Tipo de Contacto" label='Tipo' table='contactTypes' width='120'/>
            </Grid>
            <Grid item xs md={4} >
                <ExtraDataAdmin title="Contactos/Tipo de Empresas" label='Tipo' table='firmTypes' width='120'/>
            </Grid>
        </Grid>
    )
}

const ExtraProjectData = () => {
    return (
        <Grid container>
            <Grid item xs md={4} >
                <ExtraDataAdmin title="Tipos de Vidrio" label='Tipo' table='tiposVidrio' width='130'/>
            </Grid>
            <Grid item xs md={4} >
                <ExtraDataAdmin title="Tipos Material" label='Tipo' table='tiposMaterial' width='120'/>
            </Grid>
            <Grid item xs md={4} >
                <ExtraDataAdmin title="Posventa/Tipos" label='Tipo' table='postsaleType' width='120'/>
            </Grid>
        </Grid>
    )
}

const ExtraFabricaData = () => {
    return (
        <Grid container>
            <Grid item xs md={4} >
                <ExtraDataAdmin title="Tipos OF" label='Tipo' table='tipoOF' width='120'/>
            </Grid>
            <Grid item xs md={4} >
                <ExtraDataAdmin title="Tipos OS" label='Tipo' table='tipoOS' width='120'/>
            </Grid>
        </Grid>
    )
}

const ExtraStockData = () => {
    return (
        <Grid container>
            <Grid item xs md={4} >
                <ExtraDataAdmin title="Articulo/Categoria" label='Categorias' table='articleCat' width='120'/>
            </Grid>
            <Grid item xs md={4} >
                <ExtraDataAdmin title="Articulo/SubCategoria" label='SubCategorias' table='articleSubCat' width='120'/>
            </Grid>
        </Grid>
    )
}

const SettingsIndex = () => {
    const [menu, setMenu] = useState("term")
    
    const getMenu = (key, title) => (
        <MenuItem 
            key={key} 
            selected={menu===key} 
            onClick={() => setMenu(key)}
        >
            <ListItemText>{title}</ListItemText>
        </MenuItem>
    )

    const options = [
        ['term', 'Terminaciones/Colores', 'Revisa Terminaciones y Colores'],
        ['units', 'Unidades', 'Maestro de Unidades'],
        ['distributors', 'Distribuidores', 'Distribuidores de Venta'],
        ['warehouses', 'Almacenes', 'Almacenes de Inventario'],
        ['operations', 'Operaciones', 'Operaciones1'],
        ['places', 'Lugares', 'Maestro de Lugares'],
        ['resources', 'Recursos', 'Maestro de Recursos'],
        ['transports', 'Transportes', 'Maestro de Transportes'],
        ['tasktopics', 'Topicos de Tareas', 'Maestro de Topicos de Tareas'],
        ['exchanges', 'Tipos de Cambio', 'Maestro de Tipo de Cambios'],
        ['extraCRM', 'Extras CRM', 'Extras CRM'],
        ['extraProject', 'Extras Projecto', 'Extras Proyecto'],
        ['extraFabrica', 'Extra Fabrica', 'Extra Fabrica'],
        ['extraStock', 'Extras Stock', 'Extras Stock'],

        <Divider />,

        ['users', 'Usuarios', 'Configuracion de Usuarios'],
        ['sessions', 'Sesiones', 'Lista de las sesiones activas'],
        ['counters', 'Contadores', 'Contadores generales del sistema'],
        ['jobs', 'Procesos', 'Procesos automaticos del sistema'],
        ['orgs', 'Organizaciones', 'Configuracion de Organizaciones'],
    ]
    const menuList = options.map(it => (it.length > 1 ? getMenu(it[0], it[1]) : it ))

    const handleSelect = (key) => {
        setMenu( key )
    }
    return (
            <Grid sx={{height:'100%'}} container spacing={0}>

                <Grid sx={{ display: { md: 'none', xs: 'block' }}} item xs={12}>
                    <ListMenu sx={{textAlign: 'center'}} options={options} onClick={handleSelect}/>
                    <Divider></Divider>
                </Grid>

                <Grid sx={{ height: '100%', display: { xs: 'none', md: 'block' }}} item md={2}>

                    <Typography sx={{p:1, fontWeight:500, backgroundColor: '#ccc'}}>
                        Admin GESTION AB
                    </Typography>
    
                    <Box sx={{height: "calc(100% - 100px)", overflow: 'auto'}}>
                        <MenuList 
                            variant='menu'
                            sx={{
                                '& .Mui-selected': {
                                    '& .MuiTypography-root': {
                                        fontWeight: 800
                                    }
                                }
                            }}
                        >
                            {menuList}
                        </MenuList>
                    </Box>
                </Grid>

                <Grid sx={{height: '100%', borderLeft: '1px solid #ccc', paddingLeft: 0}} item xs md={10}>
                        {menu === 'term' && <TerminacionColoresAdmin/>}
                        {menu === 'users' && <UsersIndex/>}
                        {menu === 'places' && <PlacesIndex/>}
                        {menu === 'resources' && <ResourceIndex/>}
                        {menu === 'orgs' && <OrgIndex/>}
                        {menu === 'jobs' && <JobsIndex/>}
                        {menu === 'counters' && <CounterIndex/>}
                        {menu === 'distributors' && <DistributorIndex/>}
                        {menu === 'warehouses' && <WarehouseIndex/>}
                        {menu === 'operations' && <OperationIndex/>}
                        {menu === 'transports' && <TransportIndex/>}
                        {menu === 'exchanges' && <ExchangeIndex/>}
                        {menu === 'units' && <UnitsAdmin/>}
                        {menu === 'tasktopics' && <TaskTopicIndex/>}
                        {menu === 'extraCRM' && <ExtraCRMData/>}
                        {menu === 'extraProject' && <ExtraProjectData/>}
                        {menu === 'extraStock' && <ExtraStockData/>}
                        {menu === 'extraFabrica' && <ExtraFabricaData/>}
                        {menu === 'sessions' && <SessionsIndex/>}
                </Grid>
            </Grid>
    )
}

export default SettingsIndex;