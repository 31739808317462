import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InputDropZone from '../../components/DropZone/InputDropZone';
import {SERVER_ATTACHMENT_PATH} from '../../appConfig.js';


const FileList = ({
    docs=[], 
    editable=false, 
    downloadable=true,
    folder="/comercial/pres", 
    emptyMessage="Sin archivos", 
    onDelete, onUploaded=()=>{}, 
    ...other
}) => {
    const [lista, setLista] = useState(docs);

    const org = useSelector(state => state.org);

    useEffect( () => {
        setLista(docs)
    }, [docs])

    const link = (attachment) => {
        if (!attachment) {
            return ""
        }
        const href = SERVER_ATTACHMENT_PATH + "/" + org + "/" + attachment.path + attachment.filename;
        // return <a href={href} target1='_blank' rel='noreferrer'>{attachment.filename}</a>

        // return <Link to={href} reloadDocument>{attachment.filename}</Link>
        return downloadable ? <a href={href} target="_blanck">{attachment.filename}</a> : attachment.filename
    }
    const border = editable ? {border: '1px solid', padding: '8px'} : {}
    return (
        <ul className='MuiFileList-root' style={border} {...other}>
            { lista.length ? 
                lista.map(it => 
                    (it && it.filename && 
                        <li key={it.filename} style={{marginLeft:10, display: 'block'}}>
                            {link(it)}
                            { editable && 
                                <IconButton onClick={onDelete ? onDelete(it) : undefined}>
                                    <DeleteIcon fontSize='small'/>
                                </IconButton>
                            }
                        </li>
                    )
                ) 
                : 
                emptyMessage
            }
            { editable && <InputDropZone label="Arrastre o seleccione" withList multiple folder={folder} onUploaded={onUploaded}/>}
        </ul>
    )
    
}

export default FileList;