import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import _ from 'underscore'

const useStyles = makeStyles((theme) => ({
  title: {}
}))


const FileList = ( {
    files=[], 
    model, 
    emptyMessage=null,   
    //icon: AttachFileIcon,
    summary=false, 
    summaryList=false, 
    onClick=()=>{}, 
    editable=false} ) => {

  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  const removeFile = (id) => (e) => {
    const item = files.filter( (it) => { return it._id == id});
    if (item.length) {
      model.remove({_id: id}, (res) => {
        enqueueSnackbar('Borró documento ' + item[0].name, {variant: 'success'} );
      });
    }
  }

  const linkFile = it => {
    return (
      <a href={model.link(it) + "?download=true"} download={it.name}>
        {it.name} &nbsp;
      </a>
    )
  }

  if ( files.length === 0 ) {
    return _.isFunction(emptyMessage) ? emptyMessage() : emptyMessage;
  }
  if ( summary ) {
    if (summary == '%d%') {
      if (files.length) {
        return (
          <Typography>{files.length} Archivos</Typography>
        )
      } else {
        return ""
      }
    }
  }
  if ( summaryList ) {
    const out = files.map( it => { return this.linkFile(it) });
    return <span>{out}</span>
  }

  return (
    <List dense>
      { files.map( (it) => {
          return (
            <ListItem key={it._id} onClick={onClick}>
                <ListItemIcon>
                  <AttachFileIcon />
                </ListItemIcon>

                <ListItemText
                  primary={this.linkFile(it)}
                  secondary={it.size}
                />

                <ListItemSecondaryAction>
                  {editable &&
                    <IconButton aria-label="Delete" onClick={this.removeFile(it._id)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                </ListItemSecondaryAction>
            </ListItem>
          )
        })
      }
    </List>
  )
}


/*
const tracker = ({subscription='files.organizationFiles.all', query, model}) => {
  query = query || {};
  Meteor.subscribe(subscription)
  const files = model && _.isFunction(model.find) ? model.find(query).fetch() : [];
  return {
    files,
    model
  }
}
*/
//export default compose( withSnackbar, withStyles( styles ), withTracker( tracker ))(FileList)
export default FileList
