import React, {forwardRef, useState} from 'react'; 
import { useSearchParams } from 'react-router-dom';
import { useSelector } from "react-redux";

import Box from '@mui/material/Box';

import {PageBase, PageTitle, SearchBox} from '../../components'; 
import {WorkOrderScheduleTable} from './WorkOrderScheduleTable';
import RecursosBox from './RecursosBox';
import ActivityOpenClose  from '../Activities/ActivityOpenClose';

const IndexPage = forwardRef( ({tipo="WORK-F", tabKey, isTabSelected}, ref) => {
    let [searchParams] = useSearchParams();
    const resources = useSelector(state => state.resources);
    const options_recursos = tipo === 'WORK-F' ? resources['fabrica'].map(it => it.nombre) : resources['servicio'].map(it => it.nombre);
    const [recurso, setRecurso] = useState(searchParams.get("recurso") || [options_recursos[0]]);
    const [check, setCheck] = useState(false);

    const handleRecurso = (ev) => {
        setRecurso(ev.target.value)
    }

    const handleCheck = (ev) => {
        setCheck(ev.target.checked)
    }

    return (
        <PageBase
            paperProps={{width:'100%', height: 'calc(100% - 98px)'}} 

            title={
                <div>
                    <PageTitle>Seguimiento {tipo === 'WORK-F' ? 'Fabrica':'Servicio'}</PageTitle> 
                    {tipo === 'WORK-F' && <RecursosBox label="Recurso" tipo={tipo} value={recurso} onChange={handleRecurso}/>}
                    {tipo === 'WORK-S' && <RecursosBox label="Recursos" multiple tipo={tipo} value={recurso} onChange={handleRecurso}/>}
                    {tipo === 'WORK-F' && <SearchBox label='incluye Fabricadas' value={check} type='checkbox' onClick={handleCheck}/>}
                </div>
            }
            actions={
                <Box sx={{display: 'flex'}}>
                    {/* <Button sx={{m:1, display: 'inline'}} size='small' variant='outlined' onClick={handleClick(tipo)({id: true, _id: 'new'})}>Nuevo</Button>                         */}
                    <ActivityOpenClose app={tipo === 'WORK-F' ? 'fabrica' : 'servicio'} withLabel/>
                </Box>
            }   
       >           
            <WorkOrderScheduleTable
                tipo={tipo === 'WORK-F' ? 'fabrica' : 'servicio'}
                resource={recurso}
                withFab={!!check}
                reload={isTabSelected}
            />    

        </PageBase>
    )
})

export default IndexPage;