import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import { findDOMNode } from 'react-dom';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

import { getFileTransfer, supportsDragDrop } from './utils';

const isImage = (path) => {
  return (/\.(gif|jpg|jpeg|tiff|png|svg)$/i).test(path);
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
      paddingTop: 16,
      paddingBottom: 16,
      marginTop: "10px",
  },

  previewTile: {

  },
  card: {
    maxWidth: 400,
  },
  inputFile: {
      alignItems: "baseline",
      color: "inherit",
      textAlign: "start",
      "-webkitAppearance": "initial",
      backgroundColor: "initial",
      cursor: "default",
      padding: "initial",
      border: "initial"
  },
  inputDrop: {
      fontSize: "1rem",
      lineHeight: 1.5,
      padding: "11px 23px",
      border: "1px solid rgba(0, 0, 0, 0.15)",
      borderRadius: 4,
      outline: "none",
      backgroundColor: "transparent",
      color: "inherit",
      font: "inherit",
      margin: 0
  },
  control: {
    padding: '10px',
  },
  dragActive: {
    border: "2px solid rgba(123, 122, 241, 0.15)",
    padding: 7
  }
});


class Dropzone extends Component {
  constructor() {
    super();
    this._onClick = this._onClick.bind(this);
    this._onOpen = this._onOpen.bind(this);
    this._onDrop = this._onDrop.bind(this);
    this._onDragOver = this._onDragOver.bind(this);
    this._onDragEnter = this._onDragEnter.bind(this);
    this._onDragLeave = this._onDragLeave.bind(this);
    this._renderPreview = this._renderPreview.bind(this);
    this._onClearFilePreview = this._onClearFilePreview.bind(this);
    this._onCancelFileDrop = this._onCancelFileDrop.bind(this);
    this.state = {
      dragActive: false,
      dragDropSupported: false,
      files: []
    };
  }

  UNSAFE_componentWillMount() {
    const dragDropSupported = supportsDragDrop();
    this.setState({
      dragDropSupported
    });
  }

  componentDidMount() {

    if (false && window) {
      const { fullDropTarget } = this.props;
      if (fullDropTarget) {
        window.addEventListener('drop', this._onDrop);
        window.addEventListener('dragover', this._onDragOver);
        window.addEventListener('dragenter', this._onDragEnter);
        window.addEventListener('dragleave', this._onDragLeave);
      } else {
        window.addEventListener('drop', this._onCancelFileDrop);
        window.addEventListener('dragover', this._onCancelFileDrop);
      }
    }

    const el = findDOMNode(this.refs.container)
    if (el) {
      const { fullDropTarget } = this.props;
      if (fullDropTarget) {
        el.addEventListener('drop', this._onDrop);
        el.addEventListener('dragover', this._onDragOver);
        el.addEventListener('dragenter', this._onDragEnter);
        el.addEventListener('dragleave', this._onDragLeave);
      } else {
        el.addEventListener('drop', this._onCancelFileDrop);
        el.addEventListener('dragover', this._onCancelFileDrop);
      }
    }

  }

  componentWillUnmount() {
    if (false && window) {
      const { fullDropTarget } = this.props;
      if (fullDropTarget) {
        window.removeEventListener('drop', this._onDrop);
        window.removeEventListener('dragover', this._onDragOver);
        window.removeEventListener('dragenter', this._onDragEnter);
        window.removeEventListener('dragleave', this._onDragLeave);
      } else {
        window.removeEventListener('dragover', this._onCancelFileDrop);
        window.removeEventListener('drop', this._onCancelFileDrop);
      }
    }

    const el = findDOMNode(this.refs.container)
    if (el) {
      const { fullDropTarget } = this.props;
      if (fullDropTarget) {
        el.removeEventListener('drop', this._onDrop);
        el.removeEventListener('dragover', this._onDragOver);
        el.removeEventListener('dragenter', this._onDragEnter);
        el.removeEventListener('dragleave', this._onDragLeave);
      } else {
        el.removeEventListener('dragover', this._onCancelFileDrop);
        el.removeEventListener('drop', this._onCancelFileDrop);
      }
    }
  }

  UNSAFE_componentWillReceiveProps({ files }) {
    if (files && files !== this.props.files) {
      this.setState({
        files
      });
    }
  }

  _onClick(e) {
    const { onClick } = this.props;
    e.stopPropagation();
    this._onOpen();
    if (typeof onClick === 'function') {
      onClick();
    }
  }

  _onCancelFileDrop(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  _onClearFilePreview(i) {
    const { files } = this.state;
    const newFiles = [
      ...files.slice(0, i),
      ...files.slice(i + 1)
    ];
    this.setState({
      files: newFiles
    });
    if (typeof onDOMChange === 'function') {
      onDOMChange.call(this, newFiles, e); // eslint-disable-line
    }
  }

  _onOpen() {
    const fileInput = findDOMNode(this.refs.fileInput); // eslint-disable-line
    fileInput.value = null;
    fileInput.click();
  }

  _onDrop(e) {
    e.preventDefault();
    const { multiple, onDOMChange } = this.props;
    debugger
    const files = getFileTransfer(e, multiple);
    if (typeof onDOMChange === 'function' && files.length) {
      onDOMChange.call(this, files, e);
    }
    files.forEach((file) => {
      if (window) {
        file.preview = window.URL.createObjectURL(file); // eslint-disable-line
      }
    });
    const newFiles = multiple
      ? [...this.state.files, ...files]
      : files;
    this.setState({
      dragActive: false,
      files: newFiles
    });
  }

  _onDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';

    // bugfix to make sure box gets highlighted
    if (!this.state.dragActive) {
      this.setState({
        dragActive: true
      });
    }

    return false;
  }

  _onDragEnter(e) {
    e.preventDefault();
  }

  _onDragLeave(e) {
    e.preventDefault();
    this.setState({
      dragActive: false,
    });
  }

  _renderPreview(files) {
    const {classes} = this.props;
    if (!files.length) {
      return null;
    }
    return (
      <Grid container className={classes.preview}>
        {files.map((item, i) =>
          <Grid
            item
            key={i}
            className={classes.previewTile}
          >
            <IconButton onClick={this._onClearFilePreview.bind(this, i)}>
              <DeleteIcon />
            </IconButton>

            <Card className={classes.card}>
              {isImage(item.name) ? 
                <CardMedia
                  className={classes.media}
                  image={item.preview}
                  title="Contemplative Reptile"
                />
                :
                <PhotoCameraIcon />
              }
            </Card>
          </Grid>
        )}
      </Grid>
    );
  }

  render() {
    const { classes, label, preview, children } = this.props
    const { dragActive, files, dragDropSupported } = this.state;

    return (
      <div
        onDrop={this._onDrop}
        onDragEnter={this._onDragEnter}
        onDragOver={this._onDragOver}
        onDragLeave={this._onDragLeave}
        className={classnames(classes.root, dragActive ? classes.dragActive : {} )}
      >

        <div className={classes.inputDrop}>
          {dragDropSupported ?
            <Typography variant='h5'>
              {label || ''}
            </Typography>
            :
            <Button
              label={label}
              onClick={this._onClick}
            />
          }
          <input
            ref="fileInput" // eslint-disable-line
            type='file'
            multiple
            onChange={ this._onDrop }
            className={ classes.inputFile }
          />

        </div>

        { preview && this._renderPreview(files) }

        { children }

      </div>
    );
  }
}

Dropzone.propTypes = {
  label: PropTypes.node,
  preview: PropTypes.bool,
  multiple: PropTypes.bool.isRequired,
  onDOMChange: PropTypes.func,
  fullDropTarget: PropTypes.bool,
  files: PropTypes.array,
};

Dropzone.defaultProps = {
  progress: 0,
  multiple: false,
  label: 'Click or drop a file to upload',
  preview: true,
  fullDropTarget: false
};

export default withStyles( styles )(Dropzone);
