import React from 'react'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {lime} from '@mui/material/colors';

export const TableTitle = ({title, ...other}) => {
    return (
        <Typography sx={{p:1,  backgroundColor: lime[700]}} variant='h6' {...other}>{title}</Typography>
    )
}

export default TableTitle;