import React from 'react';
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

const SelectField = ({options=[], value="", ...other}) => {
    return (
        <TextField select autoWidth value={value} {...other}>
            {
                options.map( (opt) => {
                    const {label, value, ...other} = opt;
                    return (
                        <MenuItem key={value} value={value} data-other={other}>
                            {opt.label}
                        </MenuItem>
                    )
                })
            }
        </TextField>
    )
}

export default SelectField;
