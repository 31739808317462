import { useCallback } from 'react';
import { useSelector } from "react-redux";

export const useUnits = () => {
    const units = useSelector(state => state.units);
    
    const unitOptions = useCallback( (deposito_udm) => {
        const unidades = []
        units.map( it => {
            if (it.baseUnit === deposito_udm) {
                unidades.push( {label: it.unit, value: it.unit} )
            }
        })
        return unidades;
    }, [units])

    const getConversion = useCallback( (proveeUDM, depositoUDM) => {
        const res = units.filter( it => it.baseUnit === depositoUDM && it.unit == proveeUDM );
        if (res.length) {
            return res[0]
        }
    }, [units])

    return {unitOptions, getConversion}
}
