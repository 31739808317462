import React, {forwardRef, useState, useEffect, useCallback} from 'react'; 
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import ExitIcon from '@mui/icons-material/ExitToApp';
import DeleteIcon from '@mui/icons-material/Delete';
import {GridActionsCellItem} from '@mui/x-data-grid';

import {PageBase, PageTitle, DataGrid, SearchBox, ExportButton, MessageBox} from '../../components'; 
import {getAPI, deleteAPI, checkUserRoles, useTabs, debounce} from '../../utils' ;
import {options_tipo_mov, EXCEL_LIMIT} from '../../appConfig'
import StockMaterialDialog from './StockMaterialDialog';

import ActivityOpenClose  from '../Activities/ActivityOpenClose';

import moment from 'moment';

const columns = (onEdit, onClick, onDelete) => ([
    {
        field: 'tipo',
        headerName: 'Tipo',
        type: 'string',
        width: 90,
    },
    {
        field: 'fecha',
        headerName: 'Fecha',
        valueGetter: (params) => {
            return moment(params.value).utc().format('YYYY-MM-DD')
        },
        width: 110,
    },
    {
        field: 'comprobante_int',
        headerName: '#',
        type: 'string',
        renderCell: (params) => {
            const own = params.row;
            return (
                <span>
                    {own.comprobante_int}
                    {['TRAN-LINK'].indexOf(own.tipo) == -1 &&
                        <IconButton size='small' onClick={onClick(own)}>
                            <ExitIcon fontSize='8'/>
                        </IconButton>
                    }
                </span>
            );          
        },
        width: 100,
    },
    {
        field: 'articulo.marca',
        headerName: 'Marca',
        valueGetter: (params) => {
            const art = params.row.articulo
            if (art) return art.marca 
            return ""
        },
        width: 120,
    },
    {
        field: 'articulo.codigo',
        headerName: 'Código',
        valueGetter: (params) => {
            const art = params.row.articulo
            if (art) return art.codigo 
            return ""
        },
        width: 150,
    },
    {
        field: 'articulo.descripcion',
        headerName: 'Descripción',
        valueGetter: (params) => {
            const art = params.row.articulo
            if (art) return art.descripcion 
            return ""
        },
        width: 410,
    },
    {
        field: 'articulo_color',
        headerName: 'Color',
        type: 'string',
        width: 150,
    },
    {
        field: 'ori',
        headerName: 'Ori',
        type: 'number',
        valueGetter: (params) => {
            const row = params.row;
            return row.cantidad_ori + " " + row.udm_ori;
        },
        width: 90,
    },
    {
        field: 'base',
        headerName: 'Base',
        type: 'number',
        valueGetter: (params) => {
            const row = params.row;
            if (row.entra > 0) return row.entra + " " + row.udm;
            if (row.sale > 0) return -row.sale + " " + row.udm;
            return ""
        },
        width: 90,
    },
    {
        field: 'almacen.codigo',
        headerName: 'Almacen',
        valueGetter: (params) => {
            const art = params.row.almacen
            if (art) return art.codigo 
            return ""
        },
        width: 130,
    },
    {
        field: 'ubi1',
        headerName: 'U1',
        type: 'number',
        width: 50,
    },
    {
        field: 'ubi2',
        headerName: 'U2',
        type: 'number',
        width: 50,
    },
    {
        field: 'ubi3',
        headerName: 'U3',
        type: 'number',
        width: 50,
    },
    {
        field: 'owner.username',
        headerName: 'Usuario',
        width: 90,
    },
    {
        field: 'actions',
        headerName: 'Acciones',
        type:'actions',
        getActions: (params) => {
            if (params.row && ['TRAN', 'STK-ENV', 'AJS', ].indexOf(params.row.tipo) > -1) {
                return [
                    <GridActionsCellItem icon={<EditIcon/>} onClick={onEdit(params.row)} label="Edit" />,
                    // <GridActionsCellItem icon={<DeleteIcon/>} onClick={onDelete(params.row)} label="Delete" />,
                ]    
            } else {
                return []
            }
        }
    }
])

const IndexPage = forwardRef( ({isTabSelected}, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    let [searchParams] = useSearchParams();
    const [filter, setFilter]   = useState({tipo: 'TODOS'});
    const [reload, setReload]   = useState(false);
    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [comprobante, setComprobante] = useState({});
    const [num, setNum]   = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;

    const {appendTab} = useTabs({onReload: reload => { setReload(reload); }});

    const dispatch = useDispatch();
    const checkReloading = useSelector(state => state.reloads);

    const currentUser = useSelector(state => state.currentUser);
    const esLocalAdmin = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'inventarios-edit'])

    const delayedFunc = useCallback( 
        debounce( ({filter, page, limit}) => {
            setLoading(true)
            const {articulo, marca, color, tipo, almacen, comprobante} = filter;
            getAPI('/stock/movements', {tipo, comprobante, almacen, articulo, marca, color, skip: page*limit, limit})
            .then(data => {
                debugger
                setLoading(false);
                data.list.forEach( it => it.id = it._id);
                setNum(data.num)
                setRows(data.list)
            })
            .catch(err => {
                setLoading(false);
                console.error(err)
                enqueueSnackbar(err.message, {variant: "error"})
            });
        }, 500), 
    []);

    useEffect( () => {
        debugger
        setReload(false)
        delayedFunc({filter, page, limit})
    }, [filter, page, reload, isTabSelected, delayedFunc])

    useEffect( () => {
        if (checkReloading && 
            checkReloading['stockIndex'] ) {
                debugger
                setReload(true)
                dispatch({type: 'RELOADS'})
        }
    }, [checkReloading])

    const handleFilter = fld => ev => {
        setFilter(ll => ({...ll, [fld]: ev.target.value}))
    }
    const handleEdit = doc => () => {
        appendTab('MOV')(doc)
    }
    const handleDeleteVerification = (mov) => () => {
        setDialog(mov)
    }
    const handleComprobante = doc => ev => {
        debugger
        if (doc.comprobante_int.indexOf('ENV') > -1 || doc.comprobante_int.indexOf('DEV') > -1) {
            const codigo = doc.comprobante_int.substr(3);
            const tipo   = doc.comprobante_int.substr(0,3);
            setComprobante({tipo, codigo})
        }
        if (doc.comprobante_int.indexOf('REC') > -1 ) {
            // setComprobante({tipo: doc.tipo, codigo:doc.comprobante_int})
            const codigo = doc.comprobante_int.substr(3);
            // appendTab('REC')({rec: codigo, _id: doc.comprobante && doc.comprobante.pedido_orig && doc.comprobante.pedido_orig._id})
            appendTab('REC')({rec: codigo, _id: doc.comprobante && doc.comprobante.rec && doc.comprobante.rec._id})
        }
        if (doc.tipo === 'TRAN') {
            appendTab('MOV')(doc)
        }
    }
    const handleDelete = (valor) => {
        setLoading(true);
        if (valor) {
            deleteAPI('/stock/movements/'+dialog._id)
            .then(data => {
                debugger
                setLoading(false);
                setDialog(false)
                setReload(true)
            })
            .catch(err => {
                setLoading(false);
                console.error(err)
                enqueueSnackbar(err.message, {variant: "error"})
            });    
        }
    }

    const configReport = useCallback( async () => {
        const reportConfig = {
            title: `Movimientos de Inventario`,
            subTitle: "Creado por " + currentUser.username + " el " + moment().local().toISOString(),
            cols: [
                    {title: "Tipo", field: 'tipo'},
                    {title: "Fecha", field: 'fecha', transform: (v, doc) => {
                        return moment(v).utc().format('YYYY-MM-DD')
                    }},
                    {title: "Comprobante", field: '_', transform: (v, doc) => {
                        return doc.comprobante_int;
                    }},
                    {title: 'Marca', field: '_', transform: (v, doc) => {
                        if (doc.articulo ) {
                            return doc.articulo.marca;
                        }
                    }},
                    {title: 'Código', field: '_', transform: (v, doc) => {
                        if (doc.articulo ) {
                            return doc.articulo.codigo;
                        }
                    }},
                    {title: 'Descripción', field: '_', transform: (v, doc) => {
                        if (doc.articulo ) {
                            return doc.articulo.descripcion;
                        }
                    }},
                    {title: 'Color', field: 'articulo_color'},
                    {title: 'Ori', field: 'ori', transform: (v, doc) => {
                        return doc.cantidad_ori + " " + doc.udm_ori;
                         
                    }},
                    {title: 'Base', field: 'abse', transform: (v, doc) => {
                        if (doc.entra) return doc.entra + " " + doc.udm_ori; 
                        if (doc.sale) return -doc.sale + " " + doc.udm_ori; 
                    }},
                    {title: 'Almacen', field: '_', transform: (v, doc) => {
                        if (doc.almacen ) {
                            return doc.almacen.codigo;
                        }
                    }},
                    {title: 'U1', field: 'ubi1'},
                    {title: 'U2', field: 'ubi2'},
                    {title: 'U3', field: 'ubi3'},
                ],
            styles: {}
        }

        const {articulo, marca, color, tipo, almacen, comprobante} = filter;
        const res = await getAPI('/stock/movements', {tipo, comprobante, almacen, articulo, marca, color, skip: 0, limit: EXCEL_LIMIT})
        reportConfig['rows'] = res.list;

        return reportConfig;
    }, []);

    return (
      <PageBase 
          paperProps={{
            width:'100%',
            height: 'calc(100% - 96px)' 
            //height: 'calc(100% - 98px)'
          }} 
          title={
            <>
                <PageTitle>Movimientos ({num})</PageTitle> 
                <SearchBox label="Tipo" select options={options_tipo_mov}value={filter['tipo']} onChange={handleFilter('tipo')}/>
                <SearchBox label="Marca" value={filter['marca']} onChange={handleFilter('marca')}/>
                <SearchBox label="Articulo" value={filter['articulo']} onChange={handleFilter('articulo')}/>
                <SearchBox label="Color" value={filter['color']} onChange={handleFilter('color')}/>
                <SearchBox label="Almacen" value={filter['almacen']} onChange={handleFilter('almacen')}/>
                <SearchBox label="Comprobante" value={filter['comprobante']} onChange={handleFilter('comprobante')}/>
            </>
          }
          actions={
            <>
            <Stack direction='row'>
                {esLocalAdmin && <Button variant='oulined' size='small' onClick={handleEdit({_id: 'new', tipo:'ING', cantidad_ori:0})}>ING</Button>}
                {esLocalAdmin && <Button variant='oulined' size='small' onClick={handleEdit({_id: 'new', tipo:'TRAN'})}>TRAN</Button>}
                <ExportButton configReport={configReport}>EXCEL</ExportButton>
                <ActivityOpenClose sx={{float: 'right'}} app='stock' withLabel/>
            </Stack>
            </>
          }      
      >
          <DataGrid
              loading={loading} 
              rows={rows} 
              rowCount={num}
              columns={columns(handleEdit, handleComprobante, handleDeleteVerification)} 

              pagination
              paginationMode="server"
              pageSize={limit}
              onPageChange={(newPage) => setPage(newPage)}
  
              rowHeight={35} 
              hideFooter={false} 
              sx={{
                  'root.border': '2px solid red',
                  'root.height': '100vh'
              }}
          />

        <MessageBox 
            title="Esta seguro que quiere eliminar este Movimiento?"
            open={!!dialog} 
            onClose={handleDelete}
        />

        {comprobante && comprobante.codigo && 
            <StockMaterialDialog 
                tipo={comprobante.tipo} 
                comprobante={comprobante.codigo} 
                open={!!comprobante} 
                onCancel={v => setComprobante("")}
            /> 
        }

      </PageBase>
  )
})

export default IndexPage;