import React from 'react';
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from "@mui/material/ListItemText";
import LinearProgress from '@mui/material/LinearProgress';

import DropZone from './DropZone';
import FileList from './FileList';
import IconPopperPopupState from './IconPopperPopupState';

import _ from 'underscore';

const styles = theme => ({
  root: {
    //flexGrow: 1,
    margin: 5
  },
  toolbar: {
    float: 'right'
  },
})

const useStyles = makeStyles(styles);

const FileListCustom = (props) => (
  <FileList
    subscription='files.organizationFiles.all'
    {...props}
  />
)

const FileUploading = ({uploading=[]}) => {
  const render_item = (it) => {
    if (!it) {
      return null;
    }
    return (
      <ListItem>
        <Paper>
           {it.file.name}
           <LinearProgress color="secondary" variant="determinate" value={ it.progress.get() } />
        </Paper>
      </ListItem>
    )
  }
  return (
    <List>{uploading.map( render_item )}</List>
  )
}

const EditableFileList = ({
    editable, model={}, folder, meta={}, multiple=true,
    dropMessage='Arrastre archivos a este espacio...',
    closeComponent=()=>"", onCancel, onUpload, ...other
  }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [uploading, setUploading] = React.useState([]);
  const [error, setError] = React.useState(null)

  const { enqueueSnackbar } = useSnackbar();

  const fileQuery = { 'meta.folder': folder };
  _.each( meta, (v, k) => {
    fileQuery['meta.' + k] = v
  })

  _.extend( meta, {
    //ownerId: Meteor.userId(),
    folder: folder
  });

  const uploadFile = (file) => {

    const upload = model.insert({
      file: file,
      meta: meta,
      streams: 'dynamic',
      chunkSize: 'dynamic',
      allowWebWorkers: true // If you see issues with uploads, change this to false
    }, false);

    upload.on('start', function() {
      setError(null);
      console.log('started uploading');
    });

    upload.on('uploaded', (error, fileObj) => {
      console.log('uploaded: ', fileObj);
      if (error) {
        setError(error);
        setUploading([]);
        return;
      }
      // Remove the filename from the upload box
      //self.refs['fileinput'].value = '';

      // Reset our state for the next file
      debugger
      const up = Array.from( uploading );
      const inx = up.findIndex( (it) => { return it.config.fileId == fileObj._id } );
      if (inx >= 0 ) {
        up.splice(inx,1);
      }
      setUploading(up);

      if (onUpload) {
        onUpload(fileObj)
      }
    });

    upload.on('end', (error, fileObj) => {
      if (error) {
        enqueueSnackbar("Error al subir el archivo: " + error, {variant: 'error'})
        //alert('Error during upload: ' + error);
      } else {
        enqueueSnackbar("Subió correctamente el archivo " + fileObj.name, {variant: 'success'})
        //alert('File "' + fileObj.name + '" successfully uploaded');
      }
      console.log('termino upload' + fileObj.name);
    });

    upload.on('error', function (error, fileObj) {
      console.log('Error during upload: ' + error);
    });

    upload.on('progress', (progress, fileObj) => {
      console.log('Upload Percentage: ', fileObj.name, progress);
      setProgress(progress); // es necesario para que renderee el uploading de todo!!!
    });

    const up = Array.from( uploading );
    up.push(upload);
    setUploading(up);

    upload.start();
  }

  const handleDOMChange = (files) => {
    _.each( files, uploadFile );
    return
  }

  const handleOpen = (ev) => {
    setOpen(!open)
  }

  if (!multiple) {
      const dz = () => (
        <DropZone
          label={dropMessage}
          fullDropTarget={true}
          multiple={false}
          preview={false}
          onDOMChange={handleDOMChange}
        />
      )
      return (
        <Paper className={classes.root}>

          <FileListCustom
            query={fileQuery}
            model={model}
            editable={editable}
            emptyMessage={dz}
            {...other}
          />

        </Paper>
      )
  }

  return (
    <Paper className={classes.root}>

      <div className={classes.toolbar1}>
        {editable &&
          <Button color='primary' onClick={handleOpen}>
            {!open ? "Editar"  : "Cancelar"}
          </Button>
        }
        {closeComponent(onCancel)}
      </div>

      { open &&
        <DropZone
          label={dropMessage}
          fullDropTarget={true}
          multiple={true}
          preview={false}
          onDOMChange={handleDOMChange}
        >

          <FileUploading uploading={uploading}/>

        </DropZone>
      }

      <FileListCustom
        query={ fileQuery }
        model={model}
        editable={editable && open}
        {...other}
      />

    </Paper>
  )
}

export default EditableFileList;


const EditablePopupFileList = (other) => {
  const closeComponent = (onCancel) => (
    <Button color='primary' onClick={onCancel}>
      Cerrar
    </Button>
  )
  const label = <FileListCustom {...other} summary='%d%'/>
  return (
    <IconPopperPopupState label={label}>
      <EditableFileList closeComponent={closeComponent} {...other}/>
    </IconPopperPopupState>
  )
}
export {EditablePopupFileList}
