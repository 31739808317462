import { useTabContext } from "@mui/lab/TabContext"

const MyTabPanel = (props) => {
  const {
    children,
    className,
    style,
    value: id,
    containerProps,
    ...other
  } = props

  const context = useTabContext()

  if (context === null) {
    throw new TypeError("No TabContext provided")
  }
  const tabId = context.value

  return (
    <div
      role="tabpanel"
      className={className}
      style={{
        width: "100%",
        height: "100%",
        margin: 0,
        //padding: '10px',
        //display: "flex",
        ...style,
        position: "absolute",
        left: 0,
        visibility: id === tabId ? "visible" : "hidden",
      }}
      {...other}
    >
      {children}
    </div>
  )
}

export default MyTabPanel;