import React, {useState} from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';

import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import red from '@mui/material/colors/red';

import Papa from 'papaparse';

import _ from 'underscore';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      marginTop: '10px',
      marginBottom: '10px',
      padding: '10px',
      overflowX: 'auto',
      backgroundColor: '#eee'
    },
    tabular: {
      
    },
    cell: {
      padding: 5
    },
    button: {
      marginLeft: 10
    },
    errorContainer: {
      padding: 10,
      backgroundColor: red[100]
    },
    error: {
      fontSize: 15,
      color: red[500]
    },
    rowError: {
      backgroundColor: red[300]
    },
    errorCol: {
      color: red[500]
    }
  }) 
)

const ParseCSV = ({
  open=false,
  toolbar='pend',
  withTitle= true,
  withHeader= false,
  skipEmptyLines= false,
  escapeChar= '"',
  delimiter= "",
  dynamicTyping= true,
  headerValidation= [],
  transform= null,
  onValidation= () => {},
  onProcess= () => {},
  onClose= () => {},

}) => {

  const [parsed, setParsed] = useState("");
  const [disable, setDisable] = useState(false);
  const [pasted, setPasted] = useState("");
  const [progress, setProgress] = useState(-1);
  const [errors, setErrors] = useState({});
  const [metaCol, setMetaCol] = useState([]);
  const [meta, setMeta] = useState({});
  const classes = useStyles();

  const handleClose = (ev) => {
    if (onClose) {
      onClose(ev)
    }
  }
  const handleChange = (ev) => {
    let text = ev.clipboardData.getData('Text');

    //debugger
    // OBS HACK Para sacarme las , de los numeros
    const numeros = text.match(/(\d+,\d+)\s/);
    if (numeros && numeros.length) {
      const nn = numeros[1].replace(',','.');
      text = text.replace(/(\d+,\d+)/, nn)
    }

    const parsed = parse(text);
    setParsed(parsed);
  }

  const handleClear = (ev) => {
    setParsed("");
    setMeta({});
    setErrors({});
    setProgress(-1)
  }

  const handleProcess = (ev) => {
    setDisable(true)

    const handleProgress = (count, total) => {
      setProgress(count/total*100);
      for (let x=0; x < 1000000; x+=1) {}
    }

    if (onProcess) {
      onProcess( parsed, metaCol, handleProgress, (err, res) => {
        debugger
        if (err) {
          setDisable(false)
          setErrors(err)
          return
        }

        debugger
        const rowsRemaining = parsed.data.filter( (it, inx) => !!metaCol[inx]['error'] )
        if (rowsRemaining.length) {
          setParsed(dd => ({...dd, data: rowsRemaining}))
          setMetaCol(dd => (metaCol.filter( it => !!it['error'])))
          setDisable(false);  
          return false
        } else {
          handleClear()
          setDisable(false);  
          return true;
        }
      } )
    }
  }

  const parse = (text) => {

    setDisable(true);

    const out = Papa.parse(text, {
      trimHeaders: true,
      header: withHeader,
      skipEmptyLines: skipEmptyLines,
      escapeChar: escapeChar,
      delimiter: delimiter,
    //  delimiter: '\t',
      dynamicTyping: dynamicTyping,
      transform: transform
    })

    debugger
    validate(out);

    setDisable(false);
    setMeta(out.meta);

    return out;
  }

  const validate = async (parsed) => {
    const notFound = [];
    if (headerValidation.length) {
      const fields = parsed.meta.fields;
      _.each( headerValidation, fld => {
        if (fields.indexOf(fld) < 0) {
          notFound.push(fld)
        }
      })
    }
    const {metaCol, errors} = await onValidation(parsed);
    if (notFound.length) {
      errors.push( {tipo:'faltan-columnas', msg: 'Faltan las siguientes columnas: ' + notFound.join(',') } )
    }
    setErrors(errors);
    setMetaCol(metaCol)
  }

  const renderErrors = () => {
    if (_.size(errors) == 0) return ""
    return (
      <Paper className={classes.errorContainer}>
        <h3 className={classes.error}>Hubo los siguientes errores (delimitador: "{meta.delimiter}")</h3>
        <List>
          { _.map(errors, err => <ListItemText>{err.msg}</ListItemText> )}
        </List>
      </Paper>
    )
  }

  const renderTabular = (parsed) => {
    if (!parsed) return ""

    const data = parsed.data;
    const fields = parsed.meta.fields;
    if (!data.length) {
      return "NO DATA"
    }
    const cols = fields ? fields.length : data[0].length;
    const header = withHeader ?
      <TableHead>
        <TableRow>
          {fields.map( col =>
            <TableCell>{col}</TableCell>
          )}
        </TableRow>
      </TableHead> : ""

    let rowError = [];
    if (_.size(errors)) {
      _.each( errors || [], it => {
        rowError =  _.union(rowError, it.rows)
      })
    }

    return (
      <div className={classes.tabular}>
        <Table padding='dense' className={classes.table}>
          { header }
          <TableBody>
            { data.map( (row, inx) => {
              return (
                <TableRow key={inx} className={classNames(classes.row, rowError.indexOf(inx+1) > -1 && classes.rowError)}>
                  {withHeader ?
                    fields.map( col =>
                      <TableCell className={classes.cell}>{row[col] ? row[col].trim() : ""}</TableCell>
                    )

                    :

                    row.map( col =>
                      <TableCell className={classes.cell}>{col}</TableCell>
                    )
                  }
                  <TableCell className={classes.errorCol}>{metaCol[inx] && metaCol[inx]['error']}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }


  if (!open) {
    return ""
  }
  return (
    <Paper className={classes.root}>
      { withTitle &&
        <h1>
          Importación CSV
          <Button variant='raised' onClick={handleClose} className={classes.button}>
            Cerrar
          </Button>

          { progress > -1 && <LinearProgress variant="determinate" value={progress} /> }
          { disable &&       <LinearProgress color="secondary" /> }
        </h1>
      }

      { !parsed &&
        <Input
          //ref={(inp) => this.input = inp;}
          fullWidth={true}
          multiline={true}
          placeholder="Pegue el archivo de texto que desea importar"
          value={pasted}
          autoFocus={true}
          onPaste={handleChange}
        />
      }


      { parsed &&
        <Stack direction='row'>
          <Tooltip title="Eliminar contenido">
            <IconButton onClick={handleClear} disabled={disable}><DeleteIcon/></IconButton>
          </Tooltip>
          <Tooltip title="Procesar contenido importado">
            <IconButton color="primary" onClick={handleProcess} disabled={disable || _.size(errors) ? true : false}>
              <ArrowForwardIcon/>
            </IconButton>
          </Tooltip>
          <Box sx={{p:2}}>{parsed.data.length} lineas</Box>
        </Stack>
      }

      { errors && renderErrors()}

      { renderTabular(parsed) }

    </Paper>
  )
}



export default ParseCSV;
