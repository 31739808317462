import { useNavigate } from 'react-router-dom'
import {useState, useEffect} from 'react';
//import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
//import DeleteIcon from '@mui/icons-material/Delete';
import {PageBase, PageTitle, DataGrid, SearchBox} from '../../../components'; 
import {getAPI} from '../../../utils' 

import OrgEdit from './OrgEdit';

const columns = (onEdit, onDelete) => ([
        {
        field: 'name',
        headerName: 'Nombre',
        type: 'string',    
        width: 110,
    },
    {
        field: 'active',
        headerName: 'Active',
        type: 'boolean',    
        width: 110,
    },
    {
        field: 'options.migration',
        headerName: 'Migration',
        renderCell: params => {
            const opt = params.row.options;
            return opt ? opt.migration : ""
        },
        type: 'number',    
        width: 110,
    },
    {
        field: 'actions',
        headerName: 'Acciones',
        type:'actions',
        getActions: (params) => [
          <GridActionsCellItem icon={<CreateIcon/>} onClick={onEdit(params.row)} label="Edit" />,
          //<GridActionsCellItem icon={<DeleteIcon/>} onClick={onDelete(params)} label="Delete" showInMenu />,
        ]
    }

])

const OrgIndex = () => {
    const [rows, setRows] = useState([]);
    const [error, setError] = useState();
    const [filter, setFilter] = useState("");
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState()
    const navigate = useNavigate()

    useEffect( () => {
        setLoading(true)
        getAPI('/admin/orgs/', {s: filter})
        .then(data => {
            setLoading(false);
            // agrego el id obligatorio para DataGrid
            data.orgs.forEach( it => { it.id = it._id; } );
            setRows(data.orgs)
        })
        .catch(err => {
            console.log(err.message)
            setError(err.message)
        });

    }, [filter])
    
    const handleFilter = (ev) => {
        setFilter(ev.target.value)
    }
    const handleEdit = (item) => (ev) => {
        debugger
        setEdit(item._id)
        // navigate("/admin/orgs/" + item.row._id, {replace: true})
    }
    const handleDelete = (item) => (ev) => {

    }
    const handleNew = (ev) => {
        // navigate("/admin/orgs/new", {replace: true})
        setEdit('new')
    }

    return (
        <PageBase 
            title={
                <>
                    <PageTitle>Empresas</PageTitle> 
                    <SearchBox label="Filtro" onChange={handleFilter}/>
                </>
            }
            actions={
                <>
                    <Button sx={{m: 1 }} size='small' variant='outlined' onClick={handleNew}>Nuevo</Button>
                </>
            }
            errorStatus={error}
        >
            <DataGrid
                loading={loading} 
                rows={rows}
                columns={columns(handleEdit, handleDelete)} 
                rowHeight={35} 
                hideFooter={false} 
                sx={{
                    'root': {
                        clear: 'both',
                        border: '2px solid red',
                        //height: '100vh'
                    }
                }}
            />

            { edit && <OrgEdit  docId={edit} onCancel={()=>setEdit(undefined)} /> }    

        </PageBase>
    )
}

export default OrgIndex;