import React from 'react'; 
import {useSelector} from "react-redux";
import {SearchBox} from '../../components'; 

const RecursosBox = ({tipo, multiple=false, ...other}) => { 
  const resources = useSelector(state => state.resources);
  if (resources && Object.keys(resources).length) {
    const options_recursos0 = tipo === 'WORK-F' ? resources['fabrica'].map(it => it.nombre) : resources['servicio'].map(it => it.nombre);
    const options_recursos1 = [{label: 'TODOS', value: "-1"}, {label: 'SIN', value: "-2"}].
                                  concat(options_recursos0.map(it => {return {label:it, value:it}}));
  
    return(
      <SearchBox select SelectProps={{multiple: !!multiple}} options={options_recursos1} {...other} />
    )  
  }
  return "SIN RECURSOS"
}
export default RecursosBox;