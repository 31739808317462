import React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';

import { makeStyles } from '@mui/styles';
import { defaultTheme } from '../../utils';

import ActivityList from './ActivityList';


const useStyles = makeStyles(
  (theme) => ({
    root: {
      //fontSize: "120%"
      width: '100%',
      //maxHeight: 200
    },
    drawerPaper: {
      width: 400,
    },
    title: {
      borderBottom:'1px solid',
      paddingBottom: '9px'
    },
    boton: {
      float: 'right',
    },
    button: {
      //margin: theme.spacing.unit,
      margin: 2
    },
    activityContainer: {
      padding: 15
    },
  })
)

const ActivityMiniDrawer = ({open, onClose, ...more}) => {
  const classes = useStyles(defaultTheme);
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper}}
    >
      <div
        role='button'
        className={classes.activityContainer}
      >

        <h2 className={classes.title}>
          Actividad {more['app'] && more['app'].toUpperCase()}
          <Button size='small' variant='outlined' className={classes.boton} onClick={onClose}>Cerrar</Button>
        </h2>

        <ActivityList {...more}/>

      </div>
    </Drawer>
  )
}
export default ActivityMiniDrawer