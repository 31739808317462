import React from "react"; 

import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import ExitIcon from '@mui/icons-material/ExitToApp';

const NumberCard = ({title, label, value, defaultValue="0", color=grey, onClick}) => {

    return (
        <Box sx={{m:1}}>
            <Box sx={{p:1, width: '100%'}} backgroundColor={color[200]}>
                {title}
                { onClick && 
                    <Box sx={{float: 'right', pointer: 'cursor'}} onClick={onClick}>
                        <ExitIcon size='small'/>
                    </Box>             
                }
            </Box>
            <Box sx={{p:1}} backgroundColor={color[500]} color='inherit'>
                <Typography sx={{width: '100%', textAlign: 'center'}} variant='h2'>{value || defaultValue}</Typography>
                <Box sx={{width: '100%', textAlign: 'center'}}>
                    <Typography sx={{textAlign: 'center'}} variant='caption'>{label}</Typography>
                </Box>
            </Box>
        </Box>    )
}

export default NumberCard;