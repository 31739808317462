import React from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {Form} from '../../../components';
import {postAPI, checkUserRoles} from '../../../utils';
import { useUsersChannel } from '../../Layout/WebSocketProvider';

const CounterForm = ({editDoc, onClose, ...other}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { reloadConfigs } = useUsersChannel();

    const currentUser = useSelector(state => state.currentUser);
    const isAdmin = checkUserRoles(currentUser, ['local-admin'])

    const handleSubmit = (data) => {
        debugger
        let id = editDoc._id;
        if (id === 'new') {
            id = "";
        }
        postAPI('/settings/counters/' + id, data)
        .then(res => {
            console.log(res)
            enqueueSnackbar("Se guardo el cambio correctamente", {variant: 'info'})
            reloadConfigs()
            onClose(res)
        })
        .catch(err => {
            debugger
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: 'error'})
            //setError({title: err.message})
        });    
    }
  
    const fields = [
      {
          label: 'Nombre',
          name: '_id',
          disabled: isAdmin || editDoc._id === 'new' ? false : true
      },
      {
          label: 'Valor',
          name: 'seq',
      }
    ]
  
    return (
      <Form 
        sizes={{xs:6}}
        fields={fields}
        defaultValues={editDoc}
        onSubmit={handleSubmit}   
        //onSubmitDiff={onSubmitDiff}
        actionsComp={ (onSubmit, isValid) => (
            <Box sx={{textAlign: 'center', margin:'10px'}}>
                <Button 
                    sx={{minWidth: '100px', marginRight: '10px'}}
                    color='secondary'
                    variant='outlined' 
                    onClick={onClose}>Cancelar</Button>
                <Button 
                    sx={{minWidth: '100px'}}
                    variant="outlined" 
                    disabled={!isValid} 
                    onClick={onSubmit}>Guardar</Button>
            </Box>
            )                    
        }
        debug={false}
        {...other}
      />
    )
}
  
export default CounterForm;