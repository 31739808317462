import React, {useState, useEffect} from 'react';
import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { BarrioAutocompleteInput, LocalidadAutocompleteInput, ProvinciaAutocompleteInput, PartidoAutocompleteInput, PaisAutocompleteInput} from '../Settings/Places/PlacesAutocomplete';
import { VendedorAutocompleteObjectInput } from '../Settings/Users/UserAutocomplete';
import { ContactAutocompleteObjectInput } from '../Crm/ContactAutocomplete';
import { PageBase, Form, SelectInput } from '../../components';
import { MarcaSelectInput, MarcaTerminacionSelectInput, MarcaTerminacionColorSelectInput } from '../Settings/Config/MarcaSelectInput';
import { getAPI, postAPI, checkUserRoles, activityLog } from '../../utils';

import {options_project_status, options_sino} from '../../appConfig';
 
const defaultDoc = {
    status: 'SP',
    mosquiteros:'NO', 
    premarcos: "NO", 
    cortinas: "NO", 
    contramarcos: "NO", 
    moneda: 'USD', 
    rep: '',
    exito: 25, 
    dto_profesional: 'SIN',
    pais: "ARGENTINA",
    fecha_ingreso:'',
    fecha_medicion:'',
    fecha_fabricacion:'',
    fecha_colocacion:'',
    fecha_c90:'',
    fecha_terminacion:'',
    fecha_cobrado:'',
}

const ProjectEdit = ({
        docId, 
        edit=true, 
        debug=false,
        onAfterSubmit=(data) => {},
        onCancel=() => {},
        ...other
    }) => {
    let params = useParams();
    const [doc, setDoc] = useState(defaultDoc);
    // eslint-disable-next-line
    const [docOrig, setDocOrig] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { enqueueSnackbar } = useSnackbar();

    let id = docId || params.id;
 
    const currentUser = useSelector(state => state.currentUser);
    const canOpts = useSelector( state => state.distributors)
    const optionsDistrib = canOpts ? canOpts.map(it => ({label:it.nombre, value:it._id}) ) : [];

    const optionsAll = useSelector(state => state.options) || {};
    const options_tipo_obra = optionsAll['oposProjectType'] || [];
    const options_material = optionsAll['tiposMaterial'] || [];
    const options_vidrios = optionsAll['tiposVidrio'] || [];

    // eslint-disable-next-line
    const hidden = !checkUserRoles(currentUser, ['local-admin']);
    const onlyAdmin = checkUserRoles(currentUser, ['local-admin']);

    useEffect( () => {
        if (!id || id === "new") return;

        setLoading(true);
        getAPI('/project/' + id)
        .then(data => {
            debugger
            setLoading(false);
            setDoc(data);
            if (!edit) {
                setDocOrig(data);
            }
        });
    }, [id, edit]);

    const onSubmit = (data, diffMgs) => {
        debugger
        if (id === 'new') {
            id = "";
        }
        // ['arquitecto', 'cliente', 'contacto_obra', 'emp_constructora'].forEach(key => {
        //     if (!data[key]) {
        //         data[key] = {}
        //     }
        // })
        postAPI('/project/' + id, data)
        .then(data => {
            debugger
            if (id === '') {
                const doc = data.doc; 
                const msg = "Se creó la nueva obra correctamente";
                activityLog({label: doc.nombre, app:'obras', docId:doc._id, msg, currentUser})
                enqueueSnackbar(msg, {variant: 'info'})
            } else {
                activityLog({label: doc.nombre, app:'obras', docId, diffMgs, currentUser})       
                enqueueSnackbar("Se guardó el cambio correctamente", {variant: 'info'})
            }
            onAfterSubmit(data)
        })
        .catch(err => {
            debugger
            setError({title: err.message})
        });
    }
  
    const watchChanges = (value, name, type, getValues, setValue) => {
        debugger
        if ( type==='change') {
            if (name === 'marca') {
                setValue('terminacion', '')
                setValue('color', '')
            }
            if ( name === 'terminacion') {
                setValue('color', '')
            }
        } 
    }

    const diffFnContacto = (orig) => {
        if (!orig || Object.keys(orig).length === 0) return 'SIN';
        if (orig && orig['fullname']) {
            return orig['fullname'];
        }
    }

    const datosSection = [
        {
            name: 'datos',
            label: 'Datos',
            type: 'divider',
            sizes: {xs:12}
        },
        {
            label: 'Carpeta',
            name: 'carpeta',
            rules: {
                //required: "Debe ingresar valor",
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:4, md:2},
            disabled: !onlyAdmin,
        },
        {
            label: 'Nombre Obra',
            name: 'nombre',
            rules: {
                //required: "Debe ingresar valor",
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Status',
            name: 'status',
            options: options_project_status,
            disabled: !onlyAdmin,
            sizes: {xs:4, md:2}
        },        
        {
            label: 'Vendedor',
            name: 'ownerId',
            Component: VendedorAutocompleteObjectInput,
            diffFn: (orig) => {return orig ? orig['username'] : 'SIN'},
            rules: {
                required: "Debe seleccionar valor",
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Presupuesto',
            name: 'presupuesto',
            rules: {
                required: "Debe ingresar codigo de presupuesto",
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Distribuidor',
            name: 'distributorId',
            Component: ({...other}) => <SelectInput {...other} localField="_id" />,
            diffFn: (orig) => {
                if (orig && orig['_id']) return orig['_id']
                return orig
            },
            options: optionsDistrib,
            rules: {
                required: "Debe ingresar un representante",
            },
            sizes: {xs:4, md:2}
        },
        {
            label: "Cliente",
            name: 'cliente',
            Component: ({...other}) => <ContactAutocompleteObjectInput {...other} defaultValue={{}} withAdd={!!edit}/>,
            diffFn: diffFnContacto,
            rules: {
                required: "Debe ingresar valor",
            },
            sizes: {xs:6, md:3}
        },
        {
            label: "Arquitecto",
            name: 'arquitecto',
            Component: ({...other}) => <ContactAutocompleteObjectInput {...other} defaultValue={{}} withAdd={!!edit}/>,
            diffFn: diffFnContacto,
            rules: {
                required: "Debe ingresar valor",
            },
            sizes: {xs:6, md:3}
        },
        {
            label: 'Emp.Constructora',
            name: 'emp_constructora',
            Component: ({...other}) => <ContactAutocompleteObjectInput {...other} defaultValue={{}} withAdd={!!edit}/>,
            diffFn: diffFnContacto,
            rules: {
                // required: "Debe ingresar valor",
            },
            sizes: {xs:6, md:3}
        },
        {
            label: 'Contacto Obra',
            name: 'contacto_obra',
            Component: ({...other}) => <ContactAutocompleteObjectInput {...other} defaultValue={{}} withAdd={!!edit}/>,
            diffFn: diffFnContacto,
            rules: {
                // required: "Debe ingresar valor",
            },
            sizes: {xs:6, md:3}
        },
    ];
    const direccionSection = [
        {
            name: 'direccion',
            label: 'Dirección',
            type: 'divider',
            sizes: {xs:12}
        },

        {
            label: 'Barrio',
            name: 'barrio',
            Component: BarrioAutocompleteInput,
            sizes: {xs:4}
        },
        {
            label: 'Lote',
            name: 'lote',
            sizes: {xs:2}
        },
        {
            label: 'Direccion',
            name: 'direccion',
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                },
            },
            sizes: {xs:6}
        },
        {
            label: 'Localidad',
            name: 'localidad',
            Component: LocalidadAutocompleteInput,
            rules: {
            required: "Debe ingresar valor",
            // validate: {
                //     exists: (v) => !!v || 'Es obligatorio',
                //     isEmail: v => /.*@.*/ || "Debe ingresar formato kkkk@sss.ddd",
                // },
            },
            sizes: {xs:6, md:3}
        },
        {
            label: 'Partido',
            name: 'partido',
            Component: PartidoAutocompleteInput,
            sizes: {xs:6, md:3}
        },
        {
            label: 'Provincia',
            name: 'provincia',
            Component: ProvinciaAutocompleteInput,
            rules: {
                required: "Debe ingresar valor",
            },
            sizes: {xs:6, md:3}
        },
        {
            label: 'Pais',
            name: 'pais',
            Component: PaisAutocompleteInput,
            rules: {
            },
            sizes: {xs:6, md:3}
        },
    ]
    const aberturasSection = [
        {
            name: "aberturas",
            label: 'Aberturas',
            type: 'divider',
            sizes: {xs:12}
        },
        {
            label: 'Tipo Obra',
            name: 'tipo_obra',
            options: options_tipo_obra,
            sizes: {xs:3}
        },
        {
            label: 'Cantidad',
            name: 'ventanas',
            rules: {
                required: "Debe ingresar valor"
            },
            sizes: {xs:3}
        },
        {
            label: 'ML',
            name: 'ml',
            rules: {
                required: "Debe ingresar valor"
            },
            sizes: {xs:3}
        },
        {
            label: 'M2',
            name: 'm2',
            rules: {
                required: "Debe ingresar valor"
            },
            sizes: {xs:3}
        },
        // {
        //     label: 'Ingreso Por',
        //     name: 'ingreso_por',
        //     options: options_ingreso_por,
        //     sizes: {xs:2}
        // },
        // {
        //     sizes: {xs: 2}
        // },
        {
            label: 'Vidrios',
            name: 'vidrios',
            options: options_vidrios,
            rules: {
            required: "Debe ingresar valor"
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Material',
            name: 'material',
            options: options_material,
            rules: {
            required: "Debe ingresar valor"
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Marca',
            name: 'marca',
            Component: MarcaSelectInput,
            rules: {
            required: "Debe ingresar valor"
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Terminacion',
            name: 'terminacion',
            Component: MarcaTerminacionSelectInput,
            rules: {
            required: "Debe ingresar valor"
            },
            sizes: {xs:6, md:2}
        },
        {
            label: 'Color',
            name: 'color',
            Component: MarcaTerminacionColorSelectInput,
            rules: {
            required: "Debe ingresar valor"
            },
            sizes: {xs:6,md:4}
        },
        {
            label: 'Premarcos',
            name: 'premarcos',
            options: options_sino,
            sizes: {xs:3}
        },
        {
            label: 'Contramarcos',
            name: 'contramarcos',
            options: options_sino,
            sizes: {xs:3}
        },
        {
            label: 'Mosquiteros',
            name: 'mosquiteros',
            options: options_sino,
            sizes: {xs:3}
        },
        {
            label: 'Cortinas',
            name: 'cortinas',
            options: options_sino,
            sizes: {xs:3}
        },
    ]
    const fechasSection = [
        {
            name: 'fechas',
            label: 'Fechas',
            type: 'divider',
            sizes: {xs:12}
        },
        {
            label: 'Fecha Ingreso',
            name: 'fecha_ingreso',
            dateProps: {format:"YYYY-MM-DD"},
            rules: {
                required: "Debe ingresar valor"
            },
            sizes: {sx:3, lg:2}
        },
        {
            label: 'Fecha Medicion',
            name: 'fecha_medicion',
            dateProps: {format:"YYYY-MM-DD"},
            disabled: !onlyAdmin,
            rules: {
            },
            sizes: {sx:3, lg:2}
        },
        {
            label: 'Fecha Fabricacion',
            name: 'fecha_fabricacion',
            dateProps: {format:"YYYY-MM-DD"},
            disabled: !onlyAdmin,
            rules: {
            },
            sizes: {sx:3, lg:2}
        },
        {
            label: 'Fecha Colocación',
            name: 'fecha_colocacion',
            dateProps: {format:"YYYY-MM-DD"},
            disabled: !onlyAdmin,
            rules: {
            },
            sizes: {sx:3, lg:2}
        },
        {
            label: 'Fecha C90',
            name: 'fecha_c90',
            dateProps: {format:"YYYY-MM-DD"},
            disabled: !onlyAdmin,
            rules: {
            },
            sizes: {sx:3, lg:2}
        },
        {
            label: 'Fecha Terminación',
            name: 'fecha_terminacion',
            dateProps: {format:"YYYY-MM-DD"},
            disabled: !onlyAdmin,
            rules: {
            },
            sizes: {sx:3, lg:2}
        },
        {
            label: 'Fecha Cobrado',
            name: 'fecha_cobrado',
            dateProps: {format:"YYYY-MM-DD"},
            disabled: !onlyAdmin,
            rules: {
            },
            sizes: {sx:3, lg:2}
        },
    ]

    const fields = [...datosSection, ...direccionSection, ...aberturasSection, ...fechasSection]

    return (
        <Box {...other}>
            <Box sx={{color:'red'}}>
                {error}
            </Box>
            <Form
                loading={loading}
                disabled={!edit}
                fields={fields}
                defaultValues={doc}
                watchChanges={watchChanges}
                onSubmit={onSubmit}
                actionsComp={ (onSubmit, isValid) => (
                    <Box sx={{textAlign: 'center', margin:'10px'}}>
                        <Button 
                            sx={{minWidth: '100px', marginRight: '10px'}}
                            color='secondary'
                            variant='outlined' 
                            onClick={onCancel}>Cancelar</Button>
                        <Button 
                            sx={{minWidth: '100px'}}
                            variant="outlined" 
                            //disabled={!isValid} 
                            onClick={onSubmit}>Guardar</Button>
                    </Box>
                    )                    
                }
                debug={debug}
            />
        </Box>
    );
};

export default ProjectEdit;


export const ProjectPage = ({...other}) => (
    <PageBase title={"Obra"}>
        <ProjectEdit {...other} />
    </PageBase>
)