import * as React from 'react';
import {AutocompleteBase, AutocompleteInput} from '../../../components';

const PlacesAutocomplete = ({tipo, helperText, ...other}) => {

    const fld = tipo === 'BARRIO' ? 'nombre' : tipo.toLowerCase();
    const getOptionLabel = (it) => {
      return it && it[fld] ? it[fld] : "";
    }
    const renderOption = (props, option) => {
      let out = [];
      if (tipo === 'BARRIO') {
        out = [option.nombre];
        //if (out && it.partido) out.push(it.partido)
        if (out && option.provincia) out.push(option.provincia)
      }
      if (tipo === 'LOCALIDAD') {
        out = [option.localidad];
        //if (out && it.partido) out.push(it.partido)
        if (out && option.provincia) out.push(option.provincia)
      }
      if (tipo === 'PARTIDO') {
        out = [option.partido];
        if (out && option.provincia) out.push(option.provincia)
      }
      if (tipo === 'PROVINCIA') {
        out = [option.provincia];
      }
      if (tipo === 'PAIS') {
        out = [option.pais];
      }
      return <li {...props}>{out.length ? out.join(' / ') : ""}</li> 
    }
    return (
      <AutocompleteBase
        label="Barrio"
        //filterSelectedOptions
        autocomplete_params={{tipo}}
        autocomplete_method='/settings/places/autocomplete'
        defaultValue=""
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        disableClearable={false}
        errorText={helperText}
        {...other}
      />
    )
  }
  
export default PlacesAutocomplete;


export const BarrioAutocompleteInput =  ({setValue, ...other}) => {
  return (
      <AutocompleteInput 
          field='nombre' 
          {...other} 
          Component={({...params}) => {
            const handleChangeSelection = (ev, newValue, reason) => {
              debugger
              if (newValue && newValue['partido']) {
                setValue('direccion', newValue['direccion'])
                setValue('localidad', newValue['localidad'])
                setValue('partido', newValue['partido'])
                setValue('provincia', newValue['provincia'])
                setValue('pais', newValue['pais'])
              }
              if (params['onChangeSelection']) {
                params['onChangeSelection'](ev, newValue, reason)
              }
            }
            return (              
              <PlacesAutocomplete 
                label="Barrio" 
                tipo="BARRIO" 
                {...params}
                onChangeSelection={handleChangeSelection}
              />
            )
          }
          }
      />
  )
}

export const LocalidadAutocompleteInput =  ({setValue, ...other}) => {
  return (
      <AutocompleteInput 
          field='localidad' 
          {...other} 
          Component={({...params}) => {
            const handleChangeSelection = (ev, newValue, reason) => {
              debugger
              if (newValue) {
                setValue('partido', newValue['partido'])
                setValue('provincia', newValue['provincia'])
                setValue('pais', newValue['pais'])
              }
              if (params['onChangeSelection']) {
                params['onChangeSelection'](ev, newValue, reason)
              }
            }
            return <PlacesAutocomplete 
                    label="Localidad" 
                    tipo="LOCALIDAD" 
                    {...params}
                    onChangeSelection={handleChangeSelection}
                />

          }
          }
      />
  )
}
export const PartidoAutocompleteInput =  ({setValue, ...other}) => {
  return (
      <AutocompleteInput 
          field='partido' 
          {...other} 
          Component={({...params}) => {
            const handleChangeSelection = (ev, newValue, reason) => {
              debugger
              if (newValue) {
                setValue('provincia', newValue['provincia'])
                setValue('pais', newValue['pais'])
              }
              if (params['onChangeSelection']) {
                params['onChangeSelection'](ev, newValue, reason)
              }
            }
            return <PlacesAutocomplete 
                    label="Partido" 
                    tipo="PARTIDO" 
                    {...params}
                    onChangeSelection={handleChangeSelection}
                  />
          }

          }
      />
  )
}
export const ProvinciaAutocompleteInput =  ({...other}) => {
  return (
      <AutocompleteInput 
          field='provincia' 
          {...other} 
          Component={({...params}) => 
              <PlacesAutocomplete 
                  label="Provincia" 
                  tipo="PROVINCIA" 
                  //onChangeSelection={handleChangeSelection}
                  {...params}
              />
          }
      />
  )
}

export const PaisAutocompleteInput =  ({...other}) => {
  return (
      <AutocompleteInput 
          field='pais' 
          {...other} 
          Component={({...params}) => 
              <PlacesAutocomplete 
                  label="Pais" 
                  tipo="PAIS" 
                  //onChangeSelection={handleChangeSelection}
                  {...params}
              />
          }
      />
  )
}
