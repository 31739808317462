import React, {forwardRef, useState, useEffect, useCallback} from 'react'; 
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import ExitIcon from '@mui/icons-material/ExitToApp';
import {GridPagination} from '@mui/x-data-grid';
import {PageBase, PageTitle, DataGrid, SearchBox, ExportButton} from '../../components'; 
import {getAPI, useTabs, debounce} from '../../utils' ;
import {EXCEL_LIMIT} from '../../appConfig';

import moment from 'moment';

const optionsStatus = [
    {label: 'Todos', value: -1}, 
    {label: 'Planificado', value: ['SP','F','C']}, 
    {label: 'Finalizado', value: ['C90', 'T', 'CO']
}]

const columns = (onClick) => ([
    {
        field: 'obra.carpeta',
        headerName: 'Obra',
        renderCell: params => {
            const obra = params.row.obra;
            const link = obra && (
                <IconButton size='small' onClick={onClick('OBRA')(obra)}>
                    <ExitIcon fontSize='10'/>
                </IconButton>
              )
              return obra ? <Box sx={{overflow: 'auto'}}>{`[${obra.carpeta}] ${obra.nombre}`} {link}</Box> : ""     
        },
        flex: 0.5,
    },
    {
        field: 'obra.status',
        headerName: 'Estado',
        type: 'string',
        valueGetter: params => {
            const obra = params.row.obra;
            return obra ? obra.status : "";
        },
        width: 70,
    },
    {
        field: 'articulo.marca',
        headerName: 'Marca',
        valueGetter: (params) => {
            const art = params.row.articulo
            if (art) return art.marca 
            return ""
        },
        width: 150,
    },
    {
        field: 'articulo.codigo',
        headerName: 'Código',
        valueGetter: (params) => {
            const art = params.row.articulo
            if (art) return art.codigo 
            return ""
        },
        width: 150,
    },
    {
        field: 'articulo.descripcion',
        headerName: 'Descripción',
        valueGetter: (params) => {
            const art = params.row.articulo
            if (art) return art.descripcion 
            return ""
        },
        flex: 1,
    },
    {
        field: 'articulo_color',
        headerName: 'Color',
        type: 'string',
        // width: 90,
        flex: 0.5
    },
    {
        field: 'planificado',
        headerName: 'PLAN',
        type: 'number',
        width: 50,
    },
    {
        field: 'pedido',
        headerName: 'REQ',
        type: 'number',
        width: 50,
    },
    {
        field: 'recibido',
        headerName: 'REC',
        type: 'number',
        width: 50,
    },
    {
        field: 'udm',
        headerName: 'UDM',
        type: 'number',
        width: 50,
    },
    {
        field: 'updated_pla',
        headerName: 'Ult PLAN',
        //type: 'date',
        valueGetter: (params) => {
            return moment(params.value).utc().format('YYYY-MM-DD')
        },
        width: 110,
    },
])

const IndexPage = forwardRef( ({}, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    let [searchParams] = useSearchParams();
    const [filter, setFilter]= useState({articulo: "", obra: "", status: -1});
    const [check, setCheck]     = useState(false);
    const [tot, setTot]         = useState({plan: 0, req:0, rec:0});
    const [reload, setReload]   = useState(false);
    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;

    const currentUser = useSelector(state => state.currentUser);

    const {appendTab} = useTabs({
        onReload: (reload) => {
            debugger
            setReload(reload)
        }
    });
    const debounceFn = useCallback(
        debounce( ({filter, page, enqueueSnackbar}) => {
            setLoading(true)
            setRows([])
            getAPI('/stock/planning', {...filter, skip: page*limit, limit})
            .then(data => {
                setLoading(false);
                data.list.forEach( it => {it.id = it.articulo._id+it.articulo_color+it.obra._id});
                setNum(data.num)
                setRows(data.list)
                setTot(data.totals)
            })
            .catch(err => {
                setLoading(false);
                console.error(err)
                enqueueSnackbar(err.message, {variant: "error"})
            });    
        }, 500)
    , [])

    useEffect( () => {
        debugger
        setReload(false)
        debounceFn({filter, page, enqueueSnackbar})
    }, [filter, page, reload, enqueueSnackbar])

    const handleCheck = (ev) => {
        setCheck(ev.target.checked)
    }
    const handleClick = (tipo) => (item) => (ev) => {
        ev.preventDefault();
        if (item.id) appendTab( tipo )(item)
    }
    
    const configReport = useCallback( async () => {
        const reportConfig = {
            title: `Materiales Requeridos x Obras`,
            subTitle: "Creado por " + currentUser.username + " el " + moment().local().toISOString(),
            cols: [
                    {title: 'Obra', field: '_', transform: (v, doc) => {
                            if (doc.obra ) {
                                return `[${doc.obra.carpeta}] ${doc.obra.nombre}`;
                            }
                        },
                    },
                    {title: 'Estado', field: '_', transform: (v, doc) => {
                            if (doc.obra) {
                                return doc.obra.status;
                            }
                    }},
                    {title: 'Marca', field: '_', transform: (v, doc) => {
                            if (doc.articulo ) {
                                return doc.articulo.marca;
                            }
                        },
                    },
                    {title: 'Código', field: '_', transform: (v, doc) => {
                            if (doc.articulo ) {
                                return doc.articulo.codigo;
                            }
                        },
                    },
                    {title: 'Descripción', field: '_', transform: (v, doc) => {
                            if (doc.articulo ) {
                                return doc.articulo.descripcion;
                            }
                        },
                    },
                    {title: 'Color', field: 'articulo_color'},
                    {title: 'PLAN', field: 'planificado'},
                    {title: 'REQ', field: 'pedido'},
                    {title: 'REC', field: 'recibido'},
                    {title: 'UDM', field: 'udm'},
            ],
            styles: {}
        }

        const ret = await getAPI('/stock/planning', {...filter, limit:EXCEL_LIMIT})
        reportConfig['rows'] = ret['list'] || []

        return reportConfig;
    }, [filter]);

    const handleFilter = (fld) => (ev) => {
        setFilter(oo => ({...oo, [fld]: ev.target.value}))
    }

    const CustomFooterStatusComponent = ({...other}) => {
        return (
          <Box sx={{ p: 1, display: 'flex' }}>
            Status {other.status}

            <GridPagination sx={{float:'right'}} {...other}/>
          </Box>
        );
    }
      
    return (
      <PageBase 
          paperProps={{
            width:'100%',
            height: (check ? 'calc(100% - 150px)' : 'calc(100% - 96px)' )
            //height: 'calc(100% - 98px)'
          }} 
          title={
            <>
                <Hidden smDown>
                    <PageTitle>Materiales Requeridos x Obras ({num})</PageTitle> 
                </Hidden>
                <SearchBox label="Obra" value={filter["obra"]} onChange={handleFilter("obra")}/>
                <SearchBox sx={{width: "120px", mr:2 }} label="Status" select options={optionsStatus} value={filter["status"]} onChange={handleFilter('status')}/>
                <SearchBox label="Marca" value={filter['marca']} onChange={handleFilter('marca')}/>
                <SearchBox label="Articulo" value={filter['articulo']} onChange={handleFilter('articulo')}/>
                <SearchBox label="Color" value={filter['articulo_color']} onChange={handleFilter('articulo_color')}/>
                <SearchBox type='checkbox' label="Ver Totales" value={check} onChange={handleCheck}/>                
            </>
          }
          actions={
            <>
            <Stack direction='row'>
                <ExportButton configReport={configReport}>EXCEL</ExportButton>
            </Stack>
            </>
          }      
      >
          <DataGrid
              loading={loading} 
              rows={rows} 
              rowCount={num}
              columns={columns(handleClick)} 

              pagination              
              paginationMode="server"
              pageSize={limit}
              onPageChange={(newPage) => setPage(newPage)}
  
            //   extraComponents={{footer: CustomFooterStatusComponent}}
            //   extraComponentsProps={{footer: {status: '123', page, pageSize:limit, onPageChange: (newPage) => setPage(newPage)}}}

              rowHeight={35} 
              hideFooter={false} 
              sx={{
                  'root.border': '2px solid red',
                  'root.height': '100vh'
              }}
          />
          {
            check && 
            <Grid sx={{mt:2}} container>
                <Grid xs={8}></Grid>
                <Grid xs={1}>
                    Totales
                </Grid>
                <Grid xs={1}>
                    Planificado = {tot.plan}
                </Grid>
                <Grid xs={1}>
                    Requerido = {tot.req}
                </Grid>
                <Grid xs={1}>
                    Recibido = {tot.rec}
                </Grid>
            </Grid>
          }

      </PageBase>
  )
})

export default IndexPage;