import React, {forwardRef, useState, useEffect, useCallback} from 'react'; 
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {GridActionsCellItem} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import {PageBase, PageTitle, DataGrid, SearchBox, MessageBox, ExportButton} from '../../../components'; 
import {getAPI, postAPI, deleteAPI, useTabs, activityLog} from '../../../utils' ;
import {EXCEL_LIMIT} from '../../../appConfig';
import ActivityOpenClose  from '../../Activities/ActivityOpenClose';
import PriceListImportDialog from './PriceListImportDialog';
import ValidityDialog from './ValidityDialog';

import moment from 'moment';

const columns = (tipo, onClick, onDelete) => ([
    {
        field: 'articulo.codigo',
        headerName: 'Código',
        valueGetter: (params) => {
            const art = params.row.articulo
            if (art) return art.codigo 
            return ""
        },
        hide: tipo === "2",
        width: 150,
    },
    {
        field: 'articulo.descripcion',
        headerName: 'Descripción',
        valueGetter: (params) => {
            const art = params.row.articulo
            if (art) return art.descripcion 
            return ""
        },
        hide: tipo === "2",
        width: 450,
    },
    {
        field: 'servicio.codigo',
        headerName: 'Código',
        valueGetter: (params) => {
            const ser = params.row.servicio
            if (ser) return ser.codigo 
            return ""
        },
        hide: tipo === "1",
        width: 150,
    },
    {
        field: 'servicio.descripcion',
        headerName: 'Descripción',
        valueGetter: (params) => {
            const ser = params.row.servicio
            if (ser) return ser.descripcion 
            return ""
        },
        hide: tipo === "1",
        width: 450,
    },
    {
        field: 'precio',
        headerName: 'Precio',
        type: 'number',
        width: 90,
    },
    {
        field: 'moneda',
        headerName: 'Moneda',
        type: 'string',
        width: 70,
    },
    {
        field: 'vigencia_desde',
        headerName: 'Desde',
        valueGetter: (params) => {
            return params.value && moment(params.value).utc().format('YYYY-MM-DD')
        },
        width: 120,
    },
    {
        field: 'vigencia_hasta',
        headerName: 'Hasta',
        valueGetter: (params) => {
            return params.value && moment(params.value).utc().format('YYYY-MM-DD')
        },
        width: 120,
    },
    {
        field: 'updated_at',
        headerName: 'Actualizado',
        valueGetter: (params) => {
            return params.value && moment(params.value).utc().format('YYYY-MM-DD')
        },
        width: 120,
    },
    {
        field: 'actions',
        headerName: 'Acciones',
        type:'actions',
        getActions: (params) => [
          //<GridActionsCellItem icon={<CreateIcon/>} onClick={onClick('CONTACT')(params.row)} label="Edit" />,
          <GridActionsCellItem icon={<DeleteIcon/>} onClick={onDelete(params.row)} label="Delete" showInMenu />,
        ]
    }
])

const IndexPage = forwardRef( ({proveeId="", provee={}, vigente, tipo: tipoProp}, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    let [searchParams] = useSearchParams();
    const [filter, setFilter]   = useState(searchParams.get("filter") || "");
    const [vigencia, setVigencia] = useState(searchParams.get("vigencia") || []);
    const [tipo, setTipo] = useState(searchParams.get('tipo') || tipoProp || "1")
    const [opts, setOpts] = useState([]);
    const [importDialog, setImportDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [validDialog, setValidDialog]   = useState(false);
    const [reload, setReload]   = useState(false);
    const [reloadVigencia, setReloadVigencia] = useState(false);
    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;

    const currentUser = useSelector(state => state.currentUser);

    const {appendTab} = useTabs(
        (reload) => {
            setReload(reload)
        }
    );

    useEffect( () => {
        debugger
        if (vigencia.length === 0) {
            return
        }
        setReload(false)
        setLoading(true)
        getAPI('/stock/price_list/' + proveeId, {filter, vigencia, tipo, skip: page*limit, limit})
        .then(data => {
            setLoading(false);
            data.list.forEach( it => it.id = it._id);
            setNum(data.num)
            setRows(data.list)
        })
        .catch(err => {
            setLoading(false);
            console.error(err)
            enqueueSnackbar(err.message, {variant: "error"})
        });
    }, [page, filter, reload, tipo, proveeId, vigencia, enqueueSnackbar])

    useEffect( () => {
        debugger
        setReloadVigencia(false)
        getAPI('/stock/price_list/vigencias/' + proveeId, {tipo})
        .then(data => {
            debugger
            const data1 = data.list.map( it => ({
                label: `${it.desde && moment(it.desde).utc().format('YYYY-MM-DD')} - ${it.hasta && moment(it.hasta).utc().format('YYYY-MM-DD')} (${it.num})`, 
                value: [it.desde, it.hasta] 
            }));
            setOpts(data1);
            if (data1.length) {
                const hoy = vigente ? moment(vigente) : moment();
                const cur = data1.filter( it => it.value[0] && it.value[1] && moment(it.value[0]) <= hoy && moment(it.value[1]) >= hoy)
                if (cur && cur.length) {
                    setVigencia(cur[0].value);
                }
            }
        })
        .catch(err => {
            console.error(err)
        });
    }, [proveeId, tipo, vigente, reloadVigencia])


    const handleDelete = (item) => (ev) => {
      debugger
      setLoading(true)
      deleteAPI('/stock/price_list/lista/' + item._id)
      .then(data => {
          setLoading(false);
          setReload(true);
          const arti = tipo === '1' ? item.articulo.codigo + '-' + item.articulo.descripcion : item.servicio.codigo + '-' + item.servicio.descripcion;
          const msg = "Se borró el precio del articulo " + arti + " correctamente";
          const label = "LP-" + provee.codigo_proveedor;
          activityLog({label, app:'pricelist', docId: provee._id, msg, currentUser})

          enqueueSnackbar(msg, {variant: "info"})
      })
      .catch(err => {
          setLoading(false);
          console.error(err)
          enqueueSnackbar(err.message, {variant: "error"})
      });
    }
    const handleDeleteList = (valor) => {
        setDeleteDialog(false);
        if (valor) {
            setLoading(true)
            debugger
            const data = {
                tipo: tipo === '1' ? 'articulo' : 'servicio',
                proveeId: provee._id, 
                vigencia_desde: vigencia[0], 
                vigencia_hasta: vigencia[1]
            }
            postAPI('/stock/price_list/deleteList', data)
            .then(data => {
                setLoading(false);
                setReload(true);
                setReloadVigencia(true);
                const msg = "Se borró la lista " + vigencia[0] + "-" + vigencia[1] + " correctamente";
                const label = "LP-" + provee.codigo_proveedor;
                activityLog({label, app:'pricelist', docId: provee._id, msg, currentUser})
                enqueueSnackbar(msg, {variant: "info"});
            })
            .catch(err => {
                setLoading(false);
                console.error(err)
                enqueueSnackbar(err.message, {variant: "error"})
            });    
        }
    }

    const handleFilter = (ev) => {
        setFilter(ev.target.value)
    }
    const handleVigencia = (ev)=> {
      setVigencia(ev.target.value)
    }  
    const handleTipo = (ev) => {
        setTipo(ev.target.value)
    }

    const handleClick = tipo => item => (ev) => {
      if (item.id) appendTab(tipo)(item)
    }
    const handleImportDialog = (st) => (ev) => {
      setImportDialog(st)
      if (!st) {
        setReloadVigencia(true);
      }
    }
    const handleDeleteDialog = (st) => (ev) => {
        setDeleteDialog(st)
    }

    const handleValidityDialog = st => (ev) => {
        setValidDialog(st)
    }
    const handleValiditySubmit = (valor) => {
        debugger
        const data = {
            tipo: tipo === '1' ? 'articulo' : 'servicio',
            proveeId: provee._id, 
            vigencia_original: vigencia, 
            vigencia_nueva: valor,
        }
        postAPI('/stock/price_list/changeValidity', data)
        .then(data => {
            setValidDialog(false)
            setReload(true);
            setReloadVigencia(true);
            const msg = "Se cambio la vigencia de la lista " + vigencia[0] + "-" + vigencia[1] + " correctamente";
            const label = "LP-" + provee.codigo_proveedor;
            activityLog({label, app:'pricelist', docId: provee._id, msg, currentUser})
            enqueueSnackbar(msg, {variant: "info"});
        })
        .catch(err => {
            setLoading(false);
            console.error(err)
            enqueueSnackbar(err.message, {variant: "error"})
        });
    }
  
    const configReport = useCallback( async () => {
        const fullname = provee.razon_social || provee.nombre + " " + provee.apellido;
        const reportConfig = {
            title: `Lista de precios [${provee.codigo_proveedor}] ${fullname}`,
            subTitle: "Creado por " + currentUser.username + " el " + moment().local().toISOString(),
            cols: [
                    {title: 'Codigo', field: '_', transform: (v, doc) => {
                            if (doc.tipo === 'articulo' && doc.articulo ) {
                                const code = doc.articulo.codigo;
                                if (Number(code)) {
                                    return "'" + code;
                                }
                                return code;
                            }
                            if (doc.tipo === 'servicio' && doc.servicio ) {
                                return doc.servicio.codigo;
                            }
                        },
                    },
                    {title: 'Descripción', field: '_', transform: (v, doc) => {
                        if (doc.tipo === 'articulo' && doc.articulo ) {
                            return doc.articulo.descripcion;
                        }
                        if (doc.tipo === 'servicio' && doc.servicio ) {
                            return doc.servicio.descripcion;
                        }
                    },
                },
                {title: 'Precio', field: 'precio'},
                {title: 'Moneda', field: 'moneda'},
                {title: 'Vigencia Desde', field: 'vigencia_desde', transform: v => moment(v).utc().format('YYYY-MM-DD')},
                {title: 'Vigencia Hasta', field: 'vigencia_hasta', transform: v => moment(v).utc().format('YYYY-MM-DD')}
            ],
            styles: {}
        }

        const rows = await getAPI('/stock/price_list/' + proveeId, {filter, vigencia, tipo, skip: 0, limit: EXCEL_LIMIT})
        reportConfig['rows'] = rows.list;

        return reportConfig;
    }, [provee, proveeId, filter, vigencia, tipo]);

    const columnVisibilityModel = {
        // Hide columns status and traderName, the other columns will remain visible
        'articulo.codigo': tipo === '1' ? true : false,
        'articulo.descripcion': tipo === '1' ? true : false,
        'servicio.codigo': tipo === '2' ? true : false,
        'servicio.descripcion': tipo === '2' ? true : false,
    }

    const tipoOptions = [{label:'Fabrica', value: "1"}, {label:"Servicio", value: "2"}]
    return (
      <PageBase 
          paperProps={{width:'100%', height: 'calc(100% - 98px)'}} 
          title={
            <>
                <PageTitle>Lista de Precio [{provee.codigo_proveedor}] {provee.fullname} ({num})</PageTitle> 
                <SearchBox label="Filtro" value={filter} onChange={handleFilter}/>
                <SearchBox label="Tipo" select value={tipo} options={tipoOptions} onChange={handleTipo}/>
                <SearchBox label="Vigencias" select value={vigencia} options={opts} onChange={handleVigencia}/>
            </>
          }
          actions={
            <>
            <Stack direction='row'>
                <Button sx={{m:1}} size='small' variant='outlined' onClick={handleValidityDialog(true)}>Vencimiento</Button>
                <Button sx={{m:1}} size='small' variant='outlined' onClick={handleImportDialog(true)}>Importar</Button>
                <Button sx={{m:1}} size='small' variant='outlined' onClick={handleDeleteDialog(true)}>Borrar</Button>
                <ExportButton configReport={configReport}>EXCEL</ExportButton>
                <ActivityOpenClose app='pricelist' withLabel/>
            </Stack>
            </>
          }      
      >
          <DataGrid
              loading={loading} 
              rows={rows} 
              rowCount={num}
              columns={columns(tipo, handleClick, handleDelete)} 
              columnVisibilityModel={columnVisibilityModel}

              pagination
              paginationMode="server"
              pageSize={limit}
              onPageChange={(newPage) => setPage(newPage)}
  
              rowHeight={35} 
              hideFooter={false} 
              sx={{
                  'root.border': '2px solid red',
                  'root.height': '100vh'
              }}
          />

          { importDialog && 
            <PriceListImportDialog 
                tipo={tipo === '1' ? 'articulo' : 'servicio'}
                provee={provee} 
                vigencia={vigencia}
                onClose={handleImportDialog(false)}
            /> 
          } 
          
          { deleteDialog && 
            <MessageBox
                title="Esta seguro que quiere eliminar esta Lista?"
                open={deleteDialog} 
                onClose={handleDeleteList}
            />
          }

          <ValidityDialog
            open={validDialog}
            vigencia={vigencia}
            onCancel={handleValidityDialog(false)}
            onClose={handleValiditySubmit}
          />
      </PageBase>
  )
})

export default IndexPage;