import React from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { styled, useThemeProps } from '@mui/material/styles';

const AttributesRoot = styled('div', {
    name: 'MuiAttributes', // The component name
    slot: 'root', // The slot name
    // shouldForwardProp: () => (true)    
  })(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    padding: theme.spacing(3, 4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    letterSpacing: '-0.025em',
    fontWeight: 600,
  }));

const AttributeTitle = styled('label', {
    name: 'MuiAttributes',
    slot: 'title',
})(({theme}) => ({
    fontSize: '14px',
    fontWeight: 600
}))

const AttributeContainer = styled(Grid, {
    name: 'MuiAttributes',
    slot: 'container',
})(({theme}) => ({
    fontSize: '12px',
    overflow: 'hidden', 
    textOverflow: 'ellipsis'
}))

const AttributeLabel = styled('label', {
    name: 'MuiAttributes',
    slot: 'label',
})(({theme}) => ({
    fontSize: '12px',
}))

const AttributeText = styled(Typography, {
    name: 'MuiAttributes',
    slot: 'text'
})(({theme}) => ({
    fontSize: '13px',
    overflow: 'hidden', 
    textOverflow: 'ellipsis'
}))

const AttributeLabelText = ({title, label, text, children, hidden=false, sizes={}, ...other}) => {
    if (hidden) return ""
    return (
        <AttributeContainer className='MuiAttributes-container' item {...sizes}>
            {title && <AttributeTitle className='MuiAttributes-title' {...other}>{title}</AttributeTitle>}
            {label && <AttributeLabel className='MuiAttributes-label' {...other}>{label}</AttributeLabel>}
            {text !== undefined && <AttributeText className='MuiAttributes-text' {...other}>{text}</AttributeText>}
            {children}
        </AttributeContainer>
    )
}

const Attributes = React.forwardRef(function Attributes(props, ref) {
    const { list=[], sizes={}, ...other } = props;
    return (
        <Box {...other}>
            <AttributesRoot className='MuiAttributes-root' ref={ref}>
                <Grid container spacing={1}>

                    {list.map( (el, inx) => {
                        if (!el) return "";
                        el['sizes'] = el['sizes'] || sizes;
                        return (
                            <AttributeLabelText key={el.title} {...el}/>
                        )
                    })}

                </Grid>
            </AttributesRoot>
        </Box>
    )
})

export default Attributes;