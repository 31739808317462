import React, {forwardRef} from 'react'; 
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import ExitIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import {red} from '@mui/material/colors';
import {DataGrid, EditDate} from '../../components'; 

import moment from 'moment';

const columns = (onFecha, onClick=()=>{}) => ([
    {
        field: 'nombre',
        headerName: 'Nombre',
        type: 'string',
        width: 200,
        renderCell: params => {
            const nombre = params.value;
            if (onClick && onClick('OPPO')) {
                return <Link href="#" onClick={onClick('OPPO')(params.row)}>{nombre}</Link>            
            }
        }
    },
    {
        field: 'contacto',
        headerName: 'Contacto',
        type: 'string',    
        renderCell: (params) => {
            const own = params.row.contacto;
            const out = own ? own.fullname : "";

            const link = (onClick && onClick('CONTACT')) ? (
                <IconButton size='small' onClick={onClick('CONTACT')(own)}>
                    <ExitIcon fontSize='10'/>
                </IconButton>
            ) : ""
            return out && out.trim() ? [<span>{out}</span>, link] : out;          
        },
        width: 240,
    },
    {
        field: 'status',
        headerName: 'Estado',
        type: 'string',    
        width: 110,
    },
    {
        field: 'presupuesto',
        headerName: 'Presupuesto',
        type: 'string',    
        width: 150,
    },
    {
        field: 'ownerId',
        headerName: 'Responsable',
        type: 'string',    
        valueGetter: (params) => {
            const own = params.value && params.value.username;
            return own ? own : "";
        },
        width: 130,
    },
    {
        field: 'distributorId',
        headerName: 'Distribuidor',
        type: 'string',    
        valueGetter: (params) => {
            const own = params.value;
            return own ? own.codigo : "";
        },
        width: 60,
    },
    {
        field: 'exito',
        headerName: 'Exito',
        type: 'number',    
        width: 60,
    },
    {
        field: 'fecha_estimada',
        headerName: 'Fecha Est',
        //type: 'date',
        renderCell: (params) => {
            const id = params.row._id;
            const fecha = params.value;
            return (
                <EditDate title='Cambio fecha Estimada' value={moment(fecha).utc()} onChange={onFecha(id, 'fecha_estimada')}>
                {
                    fecha ? moment(fecha).utc().format("YYYY-MM-DD") : <Box sx={{color:red[600]}}>SIN</Box>
                }
                </EditDate>
            )
             
        },
        width: 130,
    },
    {
        field: 'fecha_revision',
        headerName: 'Rev (d)',
        type: 'number',
        renderCell: (params) => {
            const id = params.row._id;
            const validStatus = ['GANADA','CERRADA','PERDIDA'].indexOf(params.row.status) === -1;
            const fecha = params.value;
            //return moment(fecha).utc().format("YYYY-MM-DD")
            return validStatus && <EditDate title='Cambio fecha Revision' value={moment(fecha).utc()} onChange={onFecha(id, 'fecha_revision')}>
                {!!fecha ? moment().utc().diff(moment(fecha).utc(), 'days') : <Box sx={{color:red[600]}}>SIN</Box>}
            </EditDate>
        },
        width: 80,
    },
    {
        field: 'fecha_ingreso',
        headerName: 'Ing (d)',
        type: 'number',
        valueGetter: (params) => {
            const fecha = params.value;
            const ganada = params.row.fecha_ganada || undefined;
            return fecha && moment(ganada).utc().diff(moment(fecha).utc(), 'days')
        },
        width: 80,
    },
    {
        field: 'material',
        headerName: 'Material',
        type: 'string',    
    },
    {
        field: 'color',
        headerName: 'Color',
        type: 'string',    
    },
    // {
    //     field: 'celular',
    //     headerName: 'Tel/Celular',
    //     type: 'string',    
    //     valueGetter: (params) => {
    //         const own = params.row.contacto;
    //         const out = own && own._id ? [ 
    //             own.celular || "",
    //             own.telefono
    //         ].join(' / ') : ""
    //         return out;
    //     },
    //     width: 110,
    // },
    {
        field: 'direccion',
        headerName: 'Direccion',
        sortable: false,
        width: 160,
        valueGetter: (params) => (
            [ params.value || "", 
              params.value || "",
            ].join(' ')
        )
    },
    // {
    //     field: 'localidad',
    //     headerName: 'Localidad',
    //     sortable: false,
    //     width: 160,
    //     /*valueGetter: (params) => (
    //         [ params.value || "", 
    //           params.value || "",
    //         ].join(' ')
    //     )*/
    // },
    {
        field: 'partido',
        headerName: 'Partido',
        sortable: false,
        width: 160,
    },
    {
        field: 'obra',
        headerName: 'Obra',
        sortable: false,
        width: 160,
        renderCell: (params) => {
            const own = params.row.obra;
            const out = own ? own.carpeta : "";

            const link = (onClick && onClick('OBRA')) ? (
                <IconButton size='small' onClick={onClick('OBRA')(own)}>
                    <ExitIcon fontSize='10'/>
                </IconButton>
            ) : ""
            return out && out.trim() ? [<span>{out}</span>, link] : out;              
        },
    },
    // {
    //     field: 'actions',
    //     headerName: 'Acciones',
    //     type:'actions',
    //     getActions: (params) => [
    //       <GridActionsCellItem icon={<CreateIcon/>} onClick={onClick('OPPO')(params.row)} label="Edit" />,
    //     ]
    // }
])

const ListPage = forwardRef( ({
    docs=[], 
    num=0,
    limit=100, 
    loading=false,
    hideColumns={},
    onClick=()=>{},
    onFecha=()=>()=>{},
    onPageChange=()=>{},
    ...other
}, ref) => {
    return (
        <DataGrid
            loading={loading} 
            rows={docs} 
            rowCount={num}
            columns={columns(onFecha, onClick)} 
            // disableColumnFilter
            sortingOrder='null'

            pagination              
            paginationMode="server"
            pageSize={limit}
            onPageChange={onPageChange}

            rowHeight={35} 
            hideFooter={false}

            {...other}
        />    
    )
})

export default ListPage;