import {postAPI} from './API.js'

export const activityLog = async ({label, app, docId, msg="", diffMgs=[], currentUser}) => {
    if (!msg && diffMgs.length) {  
        const msg0 = "Se modificaron los siguientes atributos: "
        msg = msg0 + diffMgs.join(', ')     
    }
    const created_by = {
        username: currentUser.username,
        _id: currentUser._id
    }

    await postAPI("/activities", {acerca: {_id:docId, label, app}, msg, created_by})
}