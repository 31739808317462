import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Form } from '../../../components'
import { getAPI, postAPI } from '../../../utils';
import { useUsersChannel } from '../../Layout/WebSocketProvider';

const TransportForm = ({editDocId, onClose}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ doc, setDoc ] = useState({});
    const { reloadConfigs } = useUsersChannel();

    useEffect( () => {
        debugger
        // reads data to Edit instead of using datagrid row
        if (editDocId === 'new') return 
        getAPI('/settings/transports/' + editDocId)
        .then(data => {
            setDoc(data)
        })
        .catch(err => {
            debugger
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });   
      }, [editDocId])
      
    const handleSubmit = (data) => {
        debugger
        let id = editDocId;
        if (id === 'new') {
            id = "";
            delete data._id
        }
        postAPI('/settings/transports/' + id, data)
        .then(res => {
            console.log(res)
            enqueueSnackbar("Se guardo el cambio correctamente", {variant: 'info'});
            reloadConfigs();
            onClose(res)
        })
        .catch(err => {
            debugger
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });    
    }
  
    const fields = [
        {
            label: 'Nombre',
            name: 'nombre',
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:6},
        },
        {
            label: 'Patente',
            name: 'patente',
            // rules: {
            //     validate: {
            //         exists: (v) => !!v || 'Es obligatorio',
            //     }
            // },
            sizes: {xs:6},
        },
        {
            label: 'Activo',
              name: 'activo',
              options: [
                  { value: 1, label: "SI" },
                  { value: 0, label: "NO" },
              ],
              sizes: {xs:4}
          },
      // {
        //   label: 'Descripción',
        //   name: 'descripcion',
        //   rules: {
        //       validate: {
        //           exists: (v) => !!v || 'Es obligatorio',
        //       }
        //   },
        //   sizes: {xs:4},
        // },
        // {
        //     label: 'Es Default?',
        //     name: 'default',
        //     type: "boolean",
        //     sizes: {xs:4},
        // },
        // {
        //     label: 'Es Noneteable?',
        //     name: 'noneteable',
        //     type: "boolean",
        //     sizes: {xs:4},
        // },
    ]
  
    return ( 
        <Box>
            <Form 
                fields={fields}
                defaultValues={doc}
                onSubmit={handleSubmit}   
                //onSubmitDiff={onSubmitDiff}
                actionsComp={ (onSubmit, isValid) => (
                    <Box sx={{textAlign: 'center', margin:'10px'}}>
                        <Button 
                            sx={{minWidth: '100px', marginRight: '10px'}}
                            color='secondary'
                            variant='outlined' 
                            onClick={onClose}>Cancelar</Button>
                        <Button 
                            sx={{minWidth: '100px'}}
                            variant="outlined" 
                            disabled={!isValid} 
                            onClick={onSubmit}>Guardar</Button>
                    </Box>
                    )                    
                }
                debug={false}
            />
        </Box>
    )
}
  
export default TransportForm;