import React, {useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from "react-redux";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import Typography from '@mui/material/Typography';
import { HeaderBar, MessageBox, PrintPreview } from '../../components';

import {getAPI, postAPI, deleteAPI, useTabs, checkUserRoles, activityLog} from '../../utils';
//import ProjectShow from './ProjectShow';
import WorkOrderEdit from './WorkOrderEdit';
import WorkOrderShow from './WorkOrderShow';
import WorkOrderPrint from './WorkOrderPrint';
import ActivityOpenClose  from '../Activities/ActivityOpenClose';

import { options_orders_fabrica_status, options_orders_servicios_status } from '../../appConfig';


const defaultDoc = (docId, tipo, relObra, categoria) => {
  return {
    _id: docId,
    status: tipo === 'WORK-F' ? options_orders_fabrica_status[0] : options_orders_servicios_status[0],
    categoria,
    etapa: "1",
    reproceso:'NO', 
    tipo: tipo === 'WORK-F' ? 'fabrica' : 'servicio',
    cantidad: 1,
    ups:1,
    //obra: {_id: ''},
    plan_fecha_inicio: '',
    plan_fecha_finalizacion: '',
    fecha_inicio: '',
    fecha_finalizacion: '',
    obra: relObra
} }


const StatusChanges = ({doc, onClick=()=>{}}) => {
  const statusChanges = doc.tipo === 'fabrica' ? 
  [
    {prev: "SIN PROCESAR", next: [{status: "PROCESADO", color: 'green'}]},
    {prev: "PROCESADO", next: [{status: "EN FABRICACION", color: 'yellow'}]},
    {prev: "EN FABRICACION", next: [{status: "FABRICADO", color: 'yellow'}]},
    {prev: "FABRICADO", next: [{status: "RETIRADO", color: 'yellow'}]},
    //{prev: "RETIRADO",  },
  ] : 
  [
    {prev: "SIN PROGRAMAR", next: [{status: "PROGRAMADO", color: 'green'}]},
    {prev: "PROGRAMADO", next: [{status: "CONFIRMADO", color: 'yellow'}, {status: 'SIN PROGRAMAR', color: 'blue'}]},
    {prev: "CONFIRMADO", next: [{status: "EN PROCESO", color: 'yellow'}, {status: 'SIN PROGRAMAR', color: 'blue'}]},
    {prev: "EN PROCESO", next: [{status: "REALIZADO", color: 'yellow'}]},
  ]  
  return (
    <Box sx={{float: 'right', '@media print': {display: 'none'}}}>
      { 
        statusChanges.map( it => {
          return doc.status === it['prev'] && 
              it['next'].map(it1 => (
                <Button 
                  sx={{mr:1}}
                  color={it1['color']} 
                  size='small'
                  variant='contained'
                  onClick={ onClick(it1['status'])} 
                >
                  {it1['status']}
                </Button>
            ))
        })
      }
    </Box>
  )  
}


export default function WorkOrderShowEdit({docId, tipo='WORK-F', relObra, onReload}) {
    const { enqueueSnackbar } = useSnackbar();
    const [edit, setEdit] = useState(docId === 'new');
    const [dialogDelete, setDialogDelete] = useState(false);
    const [reload, setReload] = useState(false);
    // eslint-disable-next-line
    const {updateCurrentTab, removeCurrentTab} = useTabs({onReload});

    const currentUser = useSelector(state => state.currentUser);
    const roles = [
      'local-admin',
      'gerencia',
      tipo==='WORK-F' ? 'fabrica-edit' : 'servicio-edit'
    ]
    const disabled = !checkUserRoles(currentUser, roles)

    const optionsAll = useSelector(state => state.options) || {article_cat: []};
    const [doc, setDoc] = useState(defaultDoc(docId, tipo, relObra, optionsAll['articleCat'] && optionsAll['articleCat'][0] && optionsAll['articleCat'][0].label));
    
    const dispatch = useDispatch();

    const docLabel = doc && doc.tipo === 'fabrica' ? "OF" : "OS";

    let title = docId === 'new' ? "Nueva Orden " + (tipo === 'WORK-F' ? 'Fabrica' : 'Servicio'):  "";
    if (doc && doc._id && doc.of) {
      title = docLabel;
      title += " " + doc.of + " (" + doc.status + ")";
    }
debugger
    useEffect( () => {
       setReload(false);
       if ( docId === 'new' ) return 
       getAPI('/work/workorders/' + docId)
       .then(data => {
        debugger
         if (data) setDoc(data)
       })
       .catch(err => {
           debugger
           console.log(err.message)
           enqueueSnackbar(err.message, {variant: "error"})
         });   
    }, [docId, edit, reload, enqueueSnackbar])

    const handleEdit = () => {
      setEdit(!edit)
    }
    const handleCancel = () => {
      setEdit(false)
      setReload(true)
      if (doc['_id'] === 'new') {
        removeCurrentTab()
      }
    }

    const handleAfter = (doc) => {
      debugger
      if (doc) setDoc(doc)
      setEdit(false)
      setReload(true)
      updateCurrentTab(doc.tipo === 'fabrica' ? 'WORK-F' : 'WORK-S')(doc);
      if (onReload) onReload()
    }

    const handleDelete = (valor) => {
      setDialogDelete(false)
      if (valor) {
        deleteAPI('/work/workorders/' + doc['_id'])
        .then(data => {
          debugger
          const msg = "Se eliminó el registro correctamente";
          enqueueSnackbar(msg, {variant: 'info'});
          activityLog({label: docLabel + "-" + doc.of, app:doc.tipo, docId: doc._id, msg: msg, currentUser});
          removeCurrentTab()
          // updateCurrentTab(doc.tipo === 'fabrica' ? 'WORK-F' : 'WORK-S')()
          if (onReload) onReload()
        })
        .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
        });  
      }
    }

    const handleChangeStatus = newStatus => ev => {
      ev.preventDefault();
      postAPI('/work/workorders/' + doc['_id'] + '/changeStatus', {status: newStatus})
      .then(data => {
        debugger
        enqueueSnackbar("Se guardó el cambio correctamente", {variant: 'info'})
        setReload(true)
        if (onReload) onReload();

        const msg = `Se cambió de estado de "${doc.status}" a "${newStatus}"`
        activityLog({label: docLabel + "-" + doc.of, app: doc.tipo, docId: doc._id, msg: msg, currentUser})

      })
      .catch(err => {
          enqueueSnackbar(err.message, {variant: 'error'})
      });
    }

    // OBS FALTA
    const handleSplit = () => {
      postAPI('/work/workorders/' + doc['_id'] + '/split', {})
      .then(data => {
        debugger
        if (data['result']) {
          const msg = `Se hizo el split a OF ${data.of.of}.`
          enqueueSnackbar(msg, {variant: 'info'})
          activityLog({label: docLabel + "-" + doc.of, app: doc.tipo, docId: doc._id, msg: msg, currentUser})
  
          setReload(true)
          if (onReload) onReload();  
        }

        dispatch({type: "RELOADS", key: {planning: {of: doc['_id']}}});
      })
      .catch(err => {
          enqueueSnackbar(err.message, {variant: 'error'})
      });
    }

    const toolbar = (
      <Box sx={{float: 'right', display: 'inline'}}>

        <ActivityOpenClose sx={{float: 'right', color: '#ddd'}} app={tipo === 'WORK-F' ? 'fabrica' : 'servicio'} docId={docId}/>

        {!edit && docId !== 'new' && <PrintPreview sx={{float: 'right', color: '#ddd'}} component={<WorkOrderPrint doc={doc}/>}/>}

        {!disabled && !edit && doc['_id'] !== 'new' &&
          <Button 
            sx={{float: "right", '@media print': {display: 'none'}}} 
            disabled={disabled} 
            onClick={handleEdit} 
            autoFocus 
            color="inherit" 
            size="small" 
            variant="outlined"
          >
            Editar
          </Button>
        }

        { !disabled && edit && doc['_id'] !== 'new' && 
          <Button 
            sx={{mr:2, float: "right", '@media print': {display: 'none'}}} 
            disabled={disabled} 
            onClick={() => setDialogDelete(true)} 
            autoFocus 
            color="inherit" 
            size="small" 
            variant="outlined"
          >
            Borrar
          </Button>
        }

        { !disabled && !edit && docId !== 'new' && 
          <StatusChanges doc={doc} onClick={handleChangeStatus}/> 
        }

        { !edit && docId !== 'new' && doc.status === 'EN FABRICACION' && 
          <Button 
            sx={{mr:1, float: 'right'}}
            //color={"blue"} 
            size='small'
            variant='contained'
            onClick={handleSplit}
          >
            SPLIT
          </Button>
        }
      </Box>
    )
    
    return (
        <Paper 
          sx={{
            width: '100%',
            height: '100%',
          }}
        > 
          <HeaderBar 
            title={[
              <Box sx={{mt: 2}}>
                
                {toolbar}

                {title}

                <Hidden smDown>{doc.obra ? " ([OB " + doc.obra.carpeta + "] " + doc.obra.nombre + ")": "" }</Hidden>
                
                { doc['_id'] !== 'new' && 
                  <Typography sx={{ color: 'white', display: 'inline'}} variant='h6'>
                    {doc.nombre} &nbsp;
                  </Typography>
                } 

              </Box>
            ]}

          />

          { edit ?
            <WorkOrderEdit
              sx={{
                margin:'20px',
                height: "calc(100% - 218px)",
                overflow: 'auto'
              }}
              edit={edit}
              doc={doc}
              tipo={tipo} 
              onAfterSubmit={handleAfter}
              onCancel={handleCancel}
            /> 
            :
            <WorkOrderShow
              doc={doc}
              tipo={tipo} 
              onCancel={handleCancel}
              onReload={(ev) => setReload(true)}
            /> 
          }
          
          <MessageBox 
            title="Esta seguro que quiere borrar esta orden?"
            open={dialogDelete} 
            onClose={handleDelete}
          />

        </Paper>
  )
}
