import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const CircularProgressPage = () => {
    return (
        <main style={{ padding: "1rem" }}>
            <Box sx={{ position: 'fixed', left: '50%', top: '50%', display: 'inline-flex' }}>
                <CircularProgress/>
            </Box>
        </main>    
    )
}

export default CircularProgressPage