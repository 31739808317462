import React, {forwardRef, useState, useEffect, useCallback} from 'react'; 
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import {PageBase, PageTitle, SearchBox, MessageBox, ExportButton} from '../../components'; 
import {getAPI, deleteAPI, activityLog, checkUserRoles, debounce} from '../../utils' ;
import {EXCEL_LIMIT} from '../../appConfig';
import PlanningImportDialog from './PlanningImportDialog';
import PlanningEditDialog from './PlanningEditDialog';
import PlanningList from './PlanningList';

import moment from 'moment';

const IndexPage = forwardRef( ({project="", of="", disabled=false}, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    let [searchParams] = useSearchParams();
    const [filter, setFilter]   = useState(searchParams.get("filter") || "");
    const [pendiente, setPendiente] = useState(false);
    const [importDialog, setImportDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [reload, setReload]   = useState(false);
    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;

    const currentUser = useSelector(state => state.currentUser);
    const esLocalAdmin = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'obra-edit'])
    
    debugger
    const checkReloading = useSelector(state => state.reloads);

    const docId = project ? project._id : of._id;
    const baseUrl = project ? '/project/' + docId : '/work/workorders/' + docId;
    const labelMsg = project ? project.nombre : (of && of.tipo === 'fabrica' ? 'OF-' : 'OS-');
    const appMsg = project ? 'obras' : (of && of.tipo === 'fabrica' ? 'fabrica' : 'servicio');

    const delayedFunc = useCallback( 
        debounce( ({page, filter, limit, enqueueSnackbar}) => {
            setLoading(true)
            getAPI(baseUrl + '/materials', {filter, skip: page*limit, limit})
            .then(data => {
                setLoading(false);
                data.list.forEach( it => it.id = it.articulo._id+it.articulo_color);
                setNum(data.num)
                setRows(data.list)
            })
            .catch(err => {
                setLoading(false);
                console.error(err)
                enqueueSnackbar(err.message, {variant: "error"})
            });
        }, 500),
    [])

    useEffect( () => {
        if (checkReloading && 
            checkReloading['planning'] && 
            checkReloading['planning']['of'] && 
            checkReloading['planning']['of'] === of._id) {
                debugger
                setReload(true)
                dispatch({type: 'RELOADS'})
        }
    }, [checkReloading, of._id])

    useEffect( () => {
        debugger
        setReload(false)
        delayedFunc({page, filter, limit, enqueueSnackbar})
    }, [page, filter, limit, reload, enqueueSnackbar])

    const handleDeleteArticulo = (item) => (ev) => {
      setLoading(true)
      deleteAPI( baseUrl + '/materials/' + item.articulo._id+'$'+item.articulo_color)
      .then(data => {
          setLoading(false);
          setReload(true);

          const art = item.articulo.codigo + " " + item.articulo_color;
          const msg = "Se borró el requerimiento " + art + " x " + item.planificado + " " + item.udm + ".";
          activityLog({label: labelMsg, app: appMsg, docId, msg, currentUser})

          enqueueSnackbar(msg, {variant: "info"})
      })
      .catch(err => {
          setLoading(false);
          console.error(err)
          enqueueSnackbar(err.message, {variant: "error"})
      });
    }
    const handleDeleteList = (st) => {
        if (!st) {
            setDeleteDialog(false)
        } else {
            setDeleteDialog(false)
            setLoading(true)
            debugger
            deleteAPI(baseUrl+'/materials/deleteList')
            .then(data => {
                setLoading(false);
                setReload(true);
                debugger
                const num = data && data.deletedCount;
                const msg = "Se borró la lista de "+ num +" requerimientos.";
                activityLog({label: labelMsg, app: appMsg, docId, msg, currentUser})

                enqueueSnackbar(msg, {variant: "info"})
            })
            .catch(err => {
                setLoading(false);
                console.error(err)
                enqueueSnackbar(err.message, {variant: "error"})
            });    
        }
    }

    const handleFilter = (ev) => {
        setFilter(ev.target.value)
    }
    const handleImportDialog = (st) => (ev) => {
      setImportDialog(st)
      setReload(true)
    }
    const handleDeleteDialog = (st) => (ev) => {
        setDeleteDialog(st)
    }
    const handleEditDialog = (doc) => (ev) => {
        setEditDialog(doc)
        if (!doc) {
            setReload(true)
        }
    }
    const handlePendiente = (ev) => {
        setPendiente(ev.target.checked)
    }
    
    const configReport = useCallback( async () => {
        debugger
        const reportConfig = {
            title: project ? `Materiales Obra [${project.carpeta}] ${project.nombre}` : `Materiales OF [${of.of}]`,
            subTitle: "Creado por " + currentUser.username + " el " + moment().local().toISOString(),
            cols: [
                    {title: 'Código', field: '_', transform: (v, doc) => {
                            if (doc.articulo ) {
                                const codigo = doc.articulo.codigo;
                                const nn = Number(codigo);
                                if (nn) {
                                    return `'${codigo}`;
                                    return `=SUBSTITUTE("${codigo}"; "."; ".")`;
                                }
                                return codigo;
                            }
                        },
                        headClassName: 'head1', rowClassName: 'row'
                    },
                    {title: 'Descripción', field: '_', transform: (v, doc) => {
                            if (doc.articulo ) {
                                return doc.articulo.descripcion;
                            }
                        },
                        headClassName: 'head1', rowClassName: 'row'
                    },
                    {title: 'Color', field: 'articulo_color',   headClassName: 'head1', rowClassName: 'row'},
                    {title: 'PLAN', field: 'planificado',       headClassName: 'head1', rowClassName: 'number'},
                    {title: 'REQ', field: ' pedido',            headClassName: 'head1', rowClassName: 'number'},
                    {title: 'REC', field: 'recibido',           headClassName: 'head1', rowClassName: 'number'},
                    {title: 'UDM', field: 'udm',                headClassName: 'head1', rowClassName: 'row'},
                    {title: 'Ult PLA', field: 'updated_pla', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD'), headClassName: 'head1', rowClassName: 'row'},
                    {title: 'Ult REQ', field: 'updated_req', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD'), headClassName: 'head1', rowClassName: 'row'},
                    {title: 'Ult ENV', field: 'updated_env', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD'), headClassName: 'head1', rowClassName: 'row'}
                ],
            styles: {
                head1:  {backgroundColor:"#dbe0f0", textAlign: 'center'},
                number: {textAlign:'right'},
                row:    {textAlign:"left"}             
            },
        }

        const ret = await getAPI(baseUrl + '/materials', {filter, limit:EXCEL_LIMIT})
        reportConfig['rows'] = ret['list'];

        return reportConfig;
    }, [baseUrl, filter, of.of, project]);

    const columnVisibilityModel={
        actions: !disabled && !!esLocalAdmin
    }

    const reference = project ? 
                        {tipo: 'PLAN', obra: project} : 
                        {tipo: 'REQ', of: of, obra: of.obra}
    return (
      <PageBase 
          paperProps={{
            width:'100%',
            height: '100%',
          }} 
          title={
            <>
                <PageTitle>Materiales ({num})</PageTitle> 
                <SearchBox label="Filtro" value={filter} onChange={handleFilter}/>
                <SearchBox label="Pendiente" type='checkbox' value={pendiente} onChange={handlePendiente}/>                
            </>
          }
          actions={
            <>
            {esLocalAdmin && <Stack direction='row'>
                <Button sx={{m:1}} disabled={disabled} size='small' variant='outlined' onClick={handleEditDialog({_id: 'new'})}>Nuevo</Button>
                <Button sx={{m:1}} disabled={disabled} size='small' variant='outlined' onClick={handleImportDialog(true)}>Importar</Button>
                <Button sx={{m:1}} disabled={disabled} size='small' variant='outlined' onClick={handleDeleteDialog(true)}>Borrar</Button>
                <ExportButton configReport={configReport}>EXCEL</ExportButton>
            </Stack>}
            </>
          }      
      >

          <PlanningList
              loading={loading} 
              rows={rows.filter(it => pendiente ? it.pedido > it.recibido : true)} 
              rowCount={num}
              columnVisibilityModel={columnVisibilityModel}
              
              onEdit={handleEditDialog}
              onDelete={handleDeleteArticulo} 
              onPageChange={(newPage) => setPage(newPage)}  
          />

          { importDialog && 
            <PlanningImportDialog 
                reference={reference}
                onClose={handleImportDialog(false)}
            /> 
          } 
          
          { deleteDialog && 
            <MessageBox
                title="Esta seguro que quiere eliminar la lista completa de requerimietos?"
                open={deleteDialog} 
                onClose={handleDeleteList}
            />
          }

          {editDialog && 
            <PlanningEditDialog 
                open={true} 
                reference={reference}
                defaultDoc={editDialog} 
                onCancel={()=>setEditDialog(false)}
                onClose={handleEditDialog(false)}
            />
          }
      </PageBase>
  )
})

export default IndexPage;