import React, {createContext, useState, useContext, useEffect, useCallback} from 'react';

import useWebSocket, { ReadyState } from 'react-use-websocket';

export const WebSocketContext = createContext(false, null, () => {})
//                                            ready, value, send

export const WebSocketProvider = ({ children, ws_url }) => {
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
        ws_url || "ws:example",
        {
            share: false,
            shouldReconnect: () => true,
        },
    )
    
    useEffect(() => {
        console.log("Connection state changed")
        if (readyState === ReadyState.OPEN) {
          sendJsonMessage({
            type: "subscribe",
            data: {
              channel: "general-chatroom",
            },
          })
        }
    }, [readyState])

    // Run when a new WebSocket message is received (lastJsonMessage)
    useEffect(() => {
        //console.log(`Got a new message: ${lastJsonMessage}`)
    }, [lastJsonMessage])
  
    const ret = [readyState, lastJsonMessage, sendJsonMessage]
  
    return (
      <WebSocketContext.Provider value={ret}>
        {children}
      </WebSocketContext.Provider>
    )
  }
  
  export const useChannel = (channelName="", onMessage=()=>{}) => {
    const [last, setLast] = useState(null)
    const [isReady, lastMessage, sendMessage] = useContext(WebSocketContext);
    useEffect( () => {
      if (lastMessage && lastMessage.type === channelName) {
        if (onMessage) {
          onMessage(lastMessage)
          setLast(lastMessage.data)
        }
      }
    }, [lastMessage])

    const sendToChannel = (msg) => {
      sendMessage({
        type: channelName,
        data: msg
      })
    }
    return [isReady, last, sendToChannel]
  }

  export const useUsersChannel = (onMessage=()=>{}) => {
    const [isReady, last, sendMessage] = useChannel('usersChannel', onMessage);
    
    const connectUser = useCallback( (org, token, currentUser) => {
      if (currentUser) {
        sendMessage({
          subscribe: true,
          org, 
          token,
          userId: currentUser._id, 
          username: currentUser.username, 
          initials: currentUser && currentUser.name && currentUser.surname &&(currentUser.name[0] + currentUser.surname[0])});
      }
    }, 
    []);

    const reloadConfigs = useCallback( v => sendMessage({reloadConfigs: true}), []);
    const reloadUser = useCallback( id => sendMessage({reloadUser: id}), []);
    const reportActivity = useCallback( (id, location, stat) => sendMessage({userId:id, location, activity: stat}), []);
    const isUserValid = useCallback( (id) => sendMessage({ping:true, userId:id}), []);

    return {
      isReady, 
      sendUserMessage: sendMessage, 
      reloadConfigs,
      reloadUser,
      connectUser,
      connectedUsers: last ? last.users : [],
      reportActivity,
      isUserValid
    };
  }

  export default WebSocketProvider
  