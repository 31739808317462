import React, {forwardRef, useState, useEffect, useCallback} from 'react'; 
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {PageBase, PageTitle, DataGrid, SearchBox, ExportButton} from '../../../components'; 
import {getAPI, deleteAPI, checkUserRoles, useTabs, debounce} from '../../../utils' ;
import {EXCEL_LIMIT} from '../../../appConfig';

import moment from 'moment'

const optionsActive = [
    {label: 'Todos', value: -1}, 
    {label: 'Activos', value: '1'}, 
    {label: 'No Activos', value: '0'}
]

const columns = (onEdit, onDelete) => ([
    {
        field: 'codigo',
        headerName: 'Código',
        type: 'string',
        width: 150,
    },
    {
        field: 'descripcion',
        headerName: 'Descripción',
        type: 'string',
        width: 350,
    },
    {
        field: 'categoria',
        headerName: 'Categoría',
        sortable: false,
        width: 160,
    },
    {
        field: 'subcategoria',
        headerName: 'Subcategoría',
        sortable: false,
        width: 160,
    },
    {
        field: 'marca',
        headerName: 'Marca',
        sortable: false,
        width: 160,
    },
    {
        field: 'precio',
        headerName: 'Precio',
        sortable: false,
        width: 70,
    },
    {
        field: 'moneda',
        headerName: 'Moneda',
        sortable: false,
        width: 70,
    },
    {
        field: 'activo',
        headerName: 'Activo',
        sortable: false,
        width: 60,
    },
    {
        field: 'deposito_almacen.codigo',
        headerName: 'Almacen Default',
        valueGetter: (params) => {
            return params.row.deposito_almacen && params.row.deposito_almacen.codigo
        },
        sortable: false,
        width: 160,
    },
    {
        field: 'actions',
        headerName: 'Acciones',
        type:'actions',
        getActions: (params) => [
          <GridActionsCellItem icon={<CreateIcon/>} onClick={onEdit(params.row)} label="Edit" />,
          <GridActionsCellItem icon={<DeleteIcon/>} onClick={onDelete(params.row)} label="Delete" showInMenu />,
        ]
    }
])


const IndexPage = forwardRef( (_, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    let [searchParams] = useSearchParams();
    const [search, setSearch] = useState({
        filter: searchParams.get("filter") || "",
        marca: searchParams.get("marca") || "",
        categoria: searchParams.get("categoria") || "",
        activo: searchParams.get("activo") || "1"
    })
    const [reload, setReload] = useState(false);
    const {appendTab} = useTabs({
        onReload: (reload) => {
            setReload(reload)
        }
    });

    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;

    const currentUser = useSelector(state => state.currentUser);
    const esLocalAdmin = checkUserRoles(currentUser, ['local-admin','gerencia','inventarios-edit'])

    const delayedFunc = useCallback( 
        debounce( ({...other}) => {
            setLoading(true)
            getAPI('/stock/parts/', {...other}) //filter, marca, categoria, skip: page*limit, limit
            .then(data => {
                setLoading(false);
                data.list.forEach( it => it.id = it._id);
                setNum(data.num)
                setRows(data.list)
            })
            .catch(err => {
                setLoading(false);
                console.error(err)
                enqueueSnackbar(err.message, {variant: "error"})
            });
        }, 500), 
    [])

    useEffect( () => {
        setReload(false)
        delayedFunc({...search, skip: page*limit, limit})

    }, [search, page, reload, delayedFunc])

    const handleDelete = (item) => (ev) => {
        debugger
        setLoading(true)
        deleteAPI('/settings/distributors/' + item.row._id)
        .then(data => {
            setLoading(false);
            setReload(true);
            enqueueSnackbar("Se borro el registro correctamente", {variant: "info"})
        })
        .catch(err => {
            setLoading(false);
            console.error(err)
            enqueueSnackbar(err.message, {variant: "error"})
        });
    }
    const handleSearch = (fld) => (ev) => {
        const valor = ev.target.value;
        setSearch({...search, [fld]: valor})
    }

    const handleEdit = (item) => (ev) => {
        debugger
        if (item.id) appendTab("PART")(item)
    }
    
    const configReport = useCallback( async () => {
        const reportConfig = {
            title: `Lista de Artīculos`,
            subTitle: "Creado por " + currentUser.username + " el " + moment().local().toISOString(),
            cols: [
                {title: 'Codigo', field: 'codigo'},
                {title: 'Descripción', field: 'descripcion'},
                {title: 'Categoría', field: 'categoria'},
                {title: 'Subcategoría', field: 'subcategoria'},
                {title: 'Marca', field: 'marca'},
                {title: 'Precio', field: 'precio'},
                {title: 'Moneda', field: 'moneda'},
                {title: 'Almacen', field: '_', transform: (v, doc) => {
                    return doc.deposito_almacen && doc.deposito_almacen.codigo
                }},
                {title: 'Activo', field: 'activo'},
            ],
            styles: {}
        }

        // const ss = buildQuery(search);
        const ret = await getAPI('/stock/parts', {...search, skip: 0, limit: 2*EXCEL_LIMIT})
        reportConfig['rows'] = ret.list;

        return reportConfig;
    }, [search]);

    return (
        <PageBase 
            paperProps={{width:'100%', height: 'calc(100% - 98px)'}} 
            title={
                    <>
                        <PageTitle>Artículos ({num})</PageTitle> 
                        <SearchBox label="Codigo/Desc" value={search['filter']} onChange={handleSearch("filter")}/>
                        <SearchBox label="Marca" value={search['marca']} onChange={handleSearch('marca')}/>
                        <SearchBox label="Categoria" value={search['categoria']} onChange={handleSearch('categoria')}/>
                        <SearchBox label="Activo" select options={optionsActive} value={search['activo']} onChange={handleSearch('activo')}/>
                    </>
            }
            actions={
                <Stack direction='row'>
                    {esLocalAdmin && <Button sx={{m:1}} size='small' variant='outlined' onClick={handleEdit({_id: 'new', id: 'new'})}>Nuevo</Button>}
                    <ExportButton configReport={configReport}>EXCEL</ExportButton>
                </Stack>
            }
                    
       >
            <DataGrid
                loading={loading} 
                rows={rows} 
                rowCount={num}
                columns={columns(handleEdit, handleDelete)} 

                pagination
                paginationMode="server"
                pageSize={limit}
                onPageChange={(newPage) => setPage(newPage)}
    
                rowHeight={35} 
                hideFooter={false} 
                sx={{
                    'root.border': '2px solid red',
                    'root.height': '100vh'
                }}
              
            />    
        </PageBase>
    )
})

export default IndexPage;