import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

const DashboardBase = ({title='', items=[], loading=false, sizes={xs:6, sm:3, md:2}}) => {
    return (
        <Box sx={{m:1, height: "100%"}}>
            {   
                loading && 
                <div style={{ position: 'absolute', top: 50, width: '100%' }}>
                    <LinearProgress />
                </div>
            }
            <Typography variant="h4">{title}</Typography>
            <Grid sx={{mt: '5px', height: '100%', overflow:"auto"}} container spacing={2}>

                { items.map(item => (
                    <Grid sx={{overflow:'hidden'}} item  {...item.sizes}>
                        {item.component}
                    </Grid>
                )) }

            </Grid>
        </Box>
    )
}

export default DashboardBase;