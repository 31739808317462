import React, {forwardRef} from 'react'; 
import {GridActionsCellItem} from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import ExitIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import {DataGrid} from '../../components'; 

import moment from 'moment';

const columns = (tipo, onClick=()=>{}) => ([
    {
      field: 'of1',
      headerName: tipo === 'WORK-F' ? 'OF' : 'OS',
      type: 'string',
      renderCell: (params) => {
        const row = params.row;
        if (onClick && onClick(tipo)) {
          return <Link href="#" onClick={onClick(tipo)(row)}>{row.of}</Link>            
        }
      },
      width: 80,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      width: 150,
    },
    
    {
        field: 'obra',
        headerName: 'Obra',
        type: 'string',    
        renderCell: (params) => {
          const own = params.value;
          const out = own ?  own.carpeta + " " + own.nombre || "" : "";

          const link = (onClick && onClick('OBRA')) ? (
              <IconButton size='small' onClick={onClick('OBRA')(own ? own : "")}>
                  <ExitIcon fontSize='10'/>
              </IconButton>
          ) : ""
          return out && out.trim() ? <span>{out} {link}</span> : "";          
        },
        width: 240,
    },

    {
      field: 'pv',
      headerName: 'PV',
      type: 'string',    
      renderCell: (params) => {
        const own = params.value;
        const out = own ?  own.pv + " " + own.nombre || "" : "";

        const link = (onClick && onClick('PVTA')) ? (
            <IconButton size='small' onClick={onClick('PVTA')(own ? own : "")}>
                <ExitIcon fontSize='10'/>
            </IconButton>
        ) : ""
        return out && out.trim() ? <span>{out} {link}</span> : "";          
      },
      width: 240,
  },

    {
        field: 'np',
        headerName: 'NP',
        type: 'string',    
        renderCell: (params) => {
          const own = params.value;
          const out = own ?  own.np : "";

          const link = (onClick && onClick('NP')) ? (
              <IconButton size='small' onClick={onClick('NP')(own ? own : "")}>
                  <ExitIcon fontSize='10'/>
              </IconButton>
          ) : ""
          return out && out.trim() ? <span>{out} {link}</span> : "";          
        },
        width: 140,
    },

    {
        field: 'fecha_inicio1',
        headerName: 'Fecha Inicio',
        //type: 'date',
        valueGetter: (params) => {
          const f1 = params.row.plan_fecha_inicio;
          const f2 = params.row.fecha_inicio;
          const fecha = f2 ? f2 : f1;
          return fecha && moment(fecha).utc().format("YYYY-MM-DD")
        },
        width: 110,
    },
    {
      field: 'fecha_finalizacion1',
      headerName: 'Fecha Fin.',
      //type: 'Date',
      valueGetter: (params) => {
        const f1 = params.row.fecha_inicio;
        const fecha = f1 ? params.row.fecha_finalizacion : params.row.plan_fecha_finalizacion
        return fecha && moment(fecha).utc().format("YYYY-MM-DD")
      },
      width: 110,
    },
    {
      field: tipo === 'WORK-F' ? 'recurso' : 'recursos',
      headerName: 'Recurso',
      type: 'String',
      width: 120,
    },
    {
      field: 'ups',
      headerName: 'UPS',
      type: 'Number',
      width: 60,
    },
    {
      field: 'reproceso',
      headerName: 'Reproceso',
      type: 'String',
      width: 120,
    },
    {
      field: 'categoria',
      headerName: 'Categoria',
      type: 'String',
      width: 120,
    },
    {
      field: 'ofs_rel',
      headerName: 'REL',
      renderCell: (params) => {
        const ofs_rel = params.value;
        if (ofs_rel && ofs_rel.length && onClick && onClick('WORK-F')) {
          return ofs_rel.map(it => (
            <Box sx={{m:1}}>
              <Link onClick={onClick('WORK-F')(it)}>
                  OF {it.of}
              </Link>
            </Box>
          ) )
        }
      },
      type: 'String',
      width: 120,
    },
    {
      field: 'of_rel',
      headerName: 'Relacionado',
      renderCell: (params) => {
        const of_rel = params.value;
        if (of_rel && onClick && onClick('WORK-S')) {
          return (
            <Box sx={{m:1}}>
              <Link onClick={onClick('WORK-S')(of_rel)}>
                  OS {of_rel.of}
              </Link>
            </Box>
          )
        }
      },
      type: 'String',
      width: 120,
    },
    {
      field: 'updated_at',
      headerName: 'Actualizado',
      //type: 'date',
      valueGetter: params => moment(params.value).utc().format('YYYY-MM-DD'),
      width: 110
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      type:'actions',
      getActions: (params) => [
        <GridActionsCellItem icon={<CreateIcon/>} onClick={onClick(tipo)(params.row)} label="Edit" />,
      ]
    }
  ])

const ListPage = forwardRef( ({
                  docs=[], 
                  num=0,
                  limit=100, 
                  loading=false,
                  tipo='WORK-F',
                  onClick=()=>{},
                  onPageChange=()=>{},
                  ...other
                }, ref) => {

    const columnVisibilityModel = {
      ofs_rel: tipo === 'WORK-S',
      of_rel: tipo === 'WORK-F',
    }
    if (other && other['disabled']) {
      columnVisibilityModel['actions'] = false
    }

    
    return (
        <DataGrid
            loading={loading} 
            rows={docs} 
            rowCount={num}
            columns={columns(tipo, onClick)} 
            disableColumnFilter
            sortingOrder='null'
            columnVisibilityModel={columnVisibilityModel}

            pageSize={limit}
            onPageChange={onPageChange}

            rowHeight={35} 
            hideFooter={false}
            
            {...other}
        />    
    )
})

export default ListPage;