import React, {useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {Form} from '../../../components'
import {postAPI, getAPI} from '../../../utils';
import {roles} from '../../../appConfig.js'
// import {getAPI} from '../../utils';

import { useUsersChannel } from '../../Layout/WebSocketProvider.jsx';


const UserForm = ({editPass, notDisabled, edit, editId, onClose}) => {
    const { enqueueSnackbar } = useSnackbar();
    // const [editPass, setEditPass] = useState();
    const [editDoc, setEditDoc] = useState({id: ''});
    const distributors = useSelector( state => state.distributors );
debugger
    const {reloadUser} = useUsersChannel();
    const currentUser = useSelector( state => state.currentUser);
    //const notDisabled = checkUserRoles(currentUser, ['local-admin'])

    useEffect( () => {
      debugger
      // reads data to Edit instead of using datagrid row
      if (!editId || editId.id === '') return;
      
      getAPI('/settings/users/' + editId)
      .then(data => {
          console.log('Leyo un registro a editar: ', data)
          setEditDoc(data)
          // enqueueSnackbar("Se elimino item correctamente", {variant: 'info'})
          // setPerson(data && data.razon_social ? true : false)
        })
      .catch(err => {
          debugger
          console.log(err.message)
          enqueueSnackbar(err.message, {variant: "error"})
      });  
    }, [editId, enqueueSnackbar])

   
    const handleSubmit = (data) => {
      debugger
        var id
        id = editDoc.id === '' ? '' : data._id

        postAPI('/settings/users/' + id, data)
        .then(res => {
            console.log(res)
            enqueueSnackbar("Se guardó el cambio correctamente", {variant: 'info'})

            reloadUser(id)

            onClose(res)
        })
        .catch(err => {
            debugger
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });    
    }
  
    const fields = [
        {
            label: 'Nombre Usuario',
            name: 'username',
            rules: {
                //required: "Debe ingresar valor",
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:4}
        },
        {
            label: 'Nombre',
            name: 'name',
            rules: {
                //required: "Debe ingresar valor",
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:4}
        },
        {
            label: 'Apellido',
            name: 'surname',
            rules: {
                exists: (v) => !!v || 'Es obligatorio',
                required: "Debe ingresar valor",
                pattern: {value: /[A-Za-z]{3}/, message: 'Solo 3 letras'},
            },
            sizes: {xs:4}
        },
        {
            label: 'Email',
            name: 'email',
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                    isEmail: v => /.*@.*/ || "Debe ingresar formato kkkk@sss.ddd",
                },
            },
            sizes: {xs:4}
        },
        {
            label: 'Activo',
            name: 'active',
            type: "boolean",
            options1: [
                { value: true, label: "SI" },
                { value: false, label: "NO" },
            ],
            sizes: {xs:4}
        },
        {
             label: 'Roles',
             name: 'roles',
             multiple: true,
             options: roles().map(it => ({label: it, value: it})),
             rules: {
                 validate: {
                     exists: (v) => !!v || 'Es obligatorio',
                 },
             },
             //disabled: id !== 'new',
             sizes: {xs:4}
        },
        {
            label: 'Distribuidores',
            name: 'distributorIds',
            multiple: true,
            options: distributors.map(it => ({label: it.nombre, value: it._id})),
            rules: {
                // validate: {
                //     exists: (v) => !!v || 'Es obligatorio',
                // },
            },
            disabled: (!edit && editId && editId.id !== '') || !notDisabled,
            sizes: {xs:4}
       },
       {
            label: 'Password',
            name: 'password',
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                },
            },
            hidden: editDoc && editDoc.id !== '' && !editPass || !edit,
            sizes: {xs:4}
        },
         /*{
            label: 'Empresa',
            name: 'orgId._id',
            options: orgs.map(it => ({label: it.name, value: it._id})),
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                },
            },
            //disabled: id !== 'new',
            sizes: {xs:4}
        },*/
    ]

    return (
        <Form 
          fields={fields}
          disabled={!(notDisabled && (edit || editId.id === ''))}
          
          defaultValues={editDoc}
          onSubmit={handleSubmit}   
          //onSubmitDiff={onSubmitDiff}
          actionsComp={ (onSubmit, isValid) => (
              <Box sx={{textAlign: 'center', margin:'10px'}} >
                  <Button 
                      sx={{minWidth: '100px', marginRight: '10px'}}
                      color='secondary'
                      variant='outlined' 
                      onClick={onClose}>Cancelar
                  </Button>
                  <Button 
                      sx={{minWidth: '100px'}}
                      disabled={!isValid} 
                      variant="outlined" 
                      onClick={onSubmit}>Guardar
                  </Button>
              </Box>
              )                    
          }
          debug={false}
        />
  )
}
  
export default UserForm;
