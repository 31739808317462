import React from 'react'; 
import { useSelector } from "react-redux";
import {SearchBox} from '../../components'; 

const TaskTopicBox = ({tipo, boardId, ...other}) => { 
  const lista = useSelector(state => state.topics) || [];
  const lista1 = lista.filter(it => {return boardId ? it.boardId===boardId : true}).map(it => {
    return {label: it.board + ":" + it.name, value: it._id}
  });
  let options = [{label: 'TODOS', value: "-1"}, {label: 'SIN', value: "-2"}];
  if (lista1 && Object.keys(lista1).length) {
    options = options.concat(
      lista1
    );
  
  }
  return(
    <SearchBox select options={options} {...other} />
  )  
  // return <Box sx={{display: 'inline'}}>SIN</Box>

}
export default TaskTopicBox;