import React from 'react';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import TablePagination from '@mui/material/TablePagination';

  
const MyTableRow = ({key, inx, dataRow, cols, rowProps={}, isLastRow=true, odd = false}) => {
    if (!cols) return null;
    let {sx, className, ...other} = rowProps;
    if (!isLastRow) {
        sx['& td'] = {borderBottom: 0}
    }
    if (odd === true) {
        // sx['backgroundColor'] = '#EEE'
    }
    return (
        <TableRow 
            key={key || dataRow.key || dataRow._id} 
            sx={sx}
            {...other}
        >
            {cols.map( col => !col.hide && 
                                <TableCell {...col.td}>
                                    {col.field(dataRow, inx)}
                                </TableCell>
            )}
        </TableRow> 
    )
}


const TableMUI = ({
    sx={}, cols=[], 
    data=[], 
    tableProps={}, 
    headerProps={}, 
    rowProps={}, 
    loading=false, 
    colsSelect,
    colsExtra=false,
    extraPagination='',
    pagination:paginationProp=false,
    page=0,
    onPageChange=()=>{},
    rowCount=0,
    rowsPerPage=25,
    rowsPerPageOptions=[10,25,50,100],
    onRowsPerPageChange=()=>{}
}) => {

    return (
        <Box sx={{height: paginationProp ? 'calc(100% - 100px)' : '100%'}}>

            {loading && <LinearProgress/>}
            <TableContainer sx={{height: '100%', ...sx}} component={Paper}>
                <Table size='small' padding='normal' stickyHeader {...tableProps}>
                    <TableHead>
                        <TableRow {...headerProps}>
                            { cols.map( col => !col.hide && <TableCell {...col.th}>{col.name}</TableCell>) }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {   data.map( (dataRow, inx) => {
                                const cc = colsSelect ? colsSelect(dataRow, inx) : cols;
                                const extra = colsExtra && colsExtra(dataRow, inx);
                                const odd = inx % 2 === 0;
                                let out = [
                                    <MyTableRow 
                                        dataRow={dataRow} 
                                        inx={inx} 
                                        cols={cc} 
                                        rowProps={rowProps}
                                        isLastRow={!extra}
                                        odd={odd}
                                    />                                    
                                ]
                                if (extra) {
                                    out.push(
                                        <MyTableRow 
                                            key={'H'+(dataRow.key||dataRow._id)} 
                                            dataRow={dataRow} 
                                            inx={inx} 
                                            cols={colsExtra(dataRow, inx)} 
                                            rowProps={rowProps}
                                            odd={odd}
                                        />
                                    )
                                }
                                return out;
                            })
                        }
                        { data.length === 0 && 
                            <TableRow key='nodata'>
                                <TableCell colSpan={cols.length} sx={{textAlign:'center'}}>No hay data</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>

            </TableContainer>

            {extraPagination}

            {
                paginationProp && 
                <TablePagination 
                    sx={{float: 'right'}} 
                    count={rowCount} 
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                    labelRowsPerPage="Filas por página"
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                />
            }

        </Box>
    )
}

export default TableMUI;