import React from 'react'; 
import { useSelector } from "react-redux";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const UnitsSelect = ({baseUnit, ...other}) => { 
  const units = useSelector(state => state.units);
  
  let options = []
  if (!units || units.filter(it => it.baseUnit === baseUnit).length == 0) {
    //return "SIN UNIDADES DEFINIDAS"
  } else {
    options = units.filter(it => it.baseUnit === baseUnit).map(it => {return {label: it.unit, value: it.unit}});
  }

  return(    
      <TextField select {...other}>
        { options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
              {option.label}
          </MenuItem>
        ))}
      </TextField>
  )  

}
export default UnitsSelect;