import {useCallback} from 'react';

export const debounce = (func, milis) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, milis);
    };
};

