import React, {useState, useEffect, useCallback} from 'react'
//import { DataGrid } from '../../../components';
import { DataGrid } from '@mui/x-data-grid';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import { makeStyles } from '@mui/styles';
import { defaultTheme } from '../../../utils';

const useStyles = makeStyles(
    (theme) => ({
      root: {
        display: 'flex',
        flexDirection: 'column',
        height: 400,
        width: '100%',
        //border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`
      }
    }),
    { defaultTheme },
)

const ListaValor = ({label="", rowsDef, disabled=false, onChange, onCellClick, debug=false}) => {
    const [error, setError] = useState("");
    const [edit, setEdit] = useState({});
    const [rows, setRows] = useState([]);
    const myClasses = useStyles();

    const fld = label.toLowerCase();

    useEffect( () => {
        debugger
        setRows(rowsDef)
        setEdit({})
    }, [rowsDef])

    const handleEdit = (params) => () => {
        debugger
        const row = params.row;
        setEdit(row)
        setError("")
    }
    const handleDelete = useCallback( (params) => () => {
        const row = params.row;
        const rr = rows.filter(it => it.id !== row.id);
        debugger
        setRows(rr)
        onChange(rr)
    }, [rows, onChange])

    const handleNew = () => {
        debugger
        if (!edit.id) {
            if (rows.map( it => it[fld]).indexOf(edit[fld]) > -1) {
                setError("El registro ya existe")
                return
            }    
        }
        setError("");

        let rr;
        if (!edit.id) {
            let mm = -1;
            rows.forEach( it => {if (it.id > mm) {mm = it.id} })
            edit.id = mm + 1;
            rr = [...rows, edit]
        } else {
            rr = rows.filter(it => it.id !== edit.id)
            rr.push(edit)
        }
        setRows(rr)
        onChange(rr)
        setEdit({})
    }
    const handleChange = (ev) => {
        const valor = ev.target.value;
        // console.log('change', valor)
        setEdit({...edit, [fld]: valor.toUpperCase()})
    }
    const handleCellClick = (params) => {
        if (onCellClick) onCellClick(params)
    }
    return (
        <Box sx={{width: '300px'}}>
            {debug && <Box>{fld}</Box>}
            {debug && <Box>{edit[fld]}</Box>}
            <TextField 
                label={label} 
                size="small" 
                value={edit[fld] || ""}
                onChange={handleChange} 
                error={!!error}
                helperText={error}
            />
            <Button disabled={disabled} onClick={handleNew}>Guardar</Button>
            
            <DataGrid
                className={myClasses.root}

                //loading={loading} 
                rows={rows} 
                rowCount={rows.length || 0}
                columns={[
                    {
                        field: fld,
                        headerName: label,
                        type: 'string',    
                        width: 210,
                    },
                    // {
                    //     field: 'id',
                    //     headerName: "ID",
                    //     type: 'string',    
                    //     width: 110,
                    // },
                    {
                        field: 'actions',
                        headerName: 'Acciones',
                        type:'actions',
                        getActions: (params) => [
                          <GridActionsCellItem icon={<CreateIcon/>} onClick={handleEdit(params)} label="Edit" />,
                          <GridActionsCellItem icon={<DeleteIcon/>} onClick={handleDelete(params)} label="Delete" />,
                        ]
                    }                                  
                ]} 

                //pagination
    
                rowHeight={35} 
                hideFooter={true}

                onCellClick={handleCellClick}
                //getRowClassName={getRowClassName}

                sx={{
                    'root.border': '2px solid red',
                    'root.height': '100vh'
                }}  
            />  
        </Box>
    )
}

export default ListaValor;