import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import Chip from '@mui/material/Chip';
import {checkUserRoles, postAPI, activityLog} from '../../utils';

export const TaskStatusChange = ({doc, disabled=false, onChange, ...other}) => {
    const { enqueueSnackbar } = useSnackbar();

    const currentUser = useSelector(state => state.currentUser);
    const hidden = !checkUserRoles(currentUser, ['local-admin'])

    const taskMap = {
        'PENDIENTE': 'REALIZADO',
        'REALIZADO': 'NO REALIZADO',
        'NO REALIZADO': 'PENDIENTE'
    }
    const color = {
        'PENDIENTE': 'green',
        'REALIZADO': 'blue',
        'NO REALIZADO': 'red'        
    }
    
    const handleClick = (ev) => {
        if (disabled) return;
debugger
        const nextStatus = taskMap[doc.status];
        const oldStatus = doc['status'];
        postAPI('/task/' + doc['_id'], {status: nextStatus})
        .then(({res, doc}) => {
            enqueueSnackbar("Se guardó el cambio correctamente", {variant: 'info'})
    
            let docLabel, docId, docTipo;
            if (doc.obra) {
                docId = doc.obra._id;
                docTipo = 'obras'
                docLabel = doc.obra && doc.obra.nombre;
            }
            if (doc.of) {
                docId = doc.of._id;
                docTipo = doc.tipo;
                docLabel = doc.of && doc.of.tipo === 'servicio' ? 'OS-' + doc.of.of : 'OF-' + doc.of.of;
            }

            const msg = `Se cambió de estado de tarea "${doc.asunto}" "${oldStatus}" a "${nextStatus}"`
            activityLog({label: docLabel, app: docTipo, docId, msg, currentUser})

            if (onChange) {
                onChange(doc)
            }
        })
        .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
        });
    }
    return (
        <Chip size='small' color={color[!disabled && doc.status]} onClick={handleClick} label={doc['status']} {...other} />
    )
}

export default TaskStatusChange;
