import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';


export default function SimpleListMenu({options, sx={}, onClick=()=>{}}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const open = Boolean(anchorEl);

    const handleClickListItem = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuItemClick = (event, index) => {
      setSelectedIndex(index);
      setAnchorEl(null);
      if (onClick) onClick(options[index][0])
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <Box>
        <List
          component="nav"
          sx={{ bgcolor: 'background.paper', ...sx }}
        >
          <ListItem
            button
            onClick={handleClickListItem}
          >
            <ListItemText
              primary={options[selectedIndex][1]}
              secondary={options[selectedIndex][2]}
            />
          </ListItem>
        </List>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
            {options.map((option, index) => {
                return option.length > 1 ? 
                    <MenuItem
                    key={option[0]}
                    //disabled={index === 0}
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                    >
                        {option[1]}
                    </MenuItem>
                    :
                    option
                })
            }
        </Menu>
      </Box>
    );
  }