import React from "react"; 
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '../../components/TabPanel'

import {FormExample} from '../../components/FormExample'
import MenuExample from '../../components/MenuExample'
import AutocompleteExample from '../../components/AutocompleteExample'
import AutocompleteFormExample from  '../../components/AutocompleteFormExample'
import PlacesAutocomplete from '../Settings/Places/PlacesAutocomplete'
import ContactoAutocomplete from '../Crm/ContactAutocomplete';
import UserAutocomplete from '../Settings/Users/UserAutocomplete';
import {EditablePopupFileList} from '../../components/DropZone/EditableFileList'
import DropZone from '../../components/DropZone/DropZone'
import InputDropZone from '../../components/DropZone/InputDropZone'
import UserFormExample from '../../components/AutocompleteFormExample2'
import ContactFormExample from '../../components/AutocompleteFormExample3'
import SelectInputFormExample from '../../components/SelectInputFormExample'
import {DatePickerTest} from '../../components/DatePickerTest'
import UserIndexTest from '../Settings/Users/UserIndexTest'
import OpportunityModal from '../Crm/OpportunityModal'
import { DatePicker } from "@mui/x-date-pickers";

const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AutocompleteTest = () => {
  const [localidad, setLocalidad] = React.useState("")
  const handleChangeSelection = (event, newValue, reason, details) => {
    debugger
    if (reason == 'selectOption') {
      setLocalidad(newValue)
    }
  }
  return (
    <>
      <PlacesAutocomplete debug label="Barrio" tipo="BARRIO" defaultValue="" onChangeSelection={handleChangeSelection}/>

      <PlacesAutocomplete debug label="Localidad" tipo="LOCALIDAD" defaultValue={localidad} />

      <PlacesAutocomplete debug label="Partido" tipo="PARTIDO" defaultValue={localidad} />

      <PlacesAutocomplete debug label="Prov" tipo="PROVINCIA" defaultValue={localidad} />
    </>
  )
}

const OpportunityModalTest = () => {
  const [open, setOpen] = React.useState(true)

  return (
    <>
      <Button onClick={()=>setOpen(true)}>Abrir</Button>
      { open && <OpportunityModal alwaysEdit debug docId="5f5927abe138231544000079" onClose={()=> setOpen(false)}/> }
      <Link to="/crm/oppos/5f5927abe138231544000079"> edit oportunidad</Link>
    </>
  )
}

const TestPage = ({currentUser}) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
         
    return (
    <Box sx={{ width: '100%' }}>
      <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList 
            onChange={handleChange} 
            variant="scrollable"
        >
          <Tab label="Forms" value="0" />
          <Tab label="Autocomplete" value="1"/>
          <Tab label="Menu" value="2" />
          <Tab label="Usuarios :db" value="3" />
          <Tab label="Oportundiad Modal" value="4"/>
          <Tab label="DropZone" value="5" />
          <Tab label="Autocomplete1" value="6" />
          <Tab label="FormAutocomplete" value="7" />
        </TabList>
      </Box>

      <TabPanel value={"0"}>
          <FormExample/> 
      </TabPanel>


      <TabPanel value="1">
        <Box>
          <h1>User</h1>
          <AutocompleteExample debug
            label="Single Users"
            defaultValue={{username: 'msalas', _id: '61a52f549dfdb8b1a6b6c22e'}}
          />

          <AutocompleteExample debug 
            label="Multiple Users" 
            multiple 
            defaultValue={[ {username: 'msalas', _id: '61a52f549dfdb8b1a6b6c22e'} ]}
          />

          <h1>Contactos</h1>
          <div>
            <ContactoAutocomplete debug/>
          </div>

          <h1>Localidad/Partido/Provincia</h1>
          <AutocompleteTest/>

          <h1>Form Autocomplete</h1>
          <AutocompleteFormExample />
        </Box>
      </TabPanel>

      <TabPanel value={"2"}>
        <MenuExample/>
      </TabPanel>
      
      <TabPanel value={"3"}>
        <UserIndexTest/>
      </TabPanel>
      
      <TabPanel value={"4"}>
        <OpportunityModalTest />
      </TabPanel>
      
      <TabPanel value="5">
        <Box>
          <DropZone multiple label="Archivo adjunto"/>
          <Box sx={{m:1}}>
            EditablePopupFileList
            <EditablePopupFileList 
                editable={true}
                //model={OrganizationFiles}
                folder='/app'
                //onUpload={handleUpload}          
            />
          </Box>
          <Box sx={{m:1}}>
            InputDropZone
            <InputDropZone label="Arrastre o haga click" multiple withList/>
          </Box>
        </Box>
      </TabPanel>
      
      <TabPanel value="6">
        <Box>
            <UserAutocomplete debug={true}/>
            <UserAutocomplete debug={true} multiple defaultValue={[]} />
        </Box>
      </TabPanel>

      <TabPanel value="7">
        <Box sx={{overflow: 'scroll', height: 'calc(100% - 120px)'}}>
          <UserFormExample/>

          <ContactFormExample/>
        </Box>
      </TabPanel>
      </TabContext>
    </Box>        
    )
}

const TestPageContactAutocomplete = () => {

  return (
    <Box>
      <ContactFormExample/>
    </Box>
  )

}

const TestPageSelectInput = () => {

  return (
    <Box>
      <SelectInputFormExample/>
    </Box>
  )

}

export default DatePickerTest;