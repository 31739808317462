import React, {useEffect, useState, useContext} from 'react';
import { TabsContext } from '../Layout/TabsProvider'; 
import { useDispatch } from "react-redux";
import { blue } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import {DashboardBase, NumberCard} from '../../components';
import {getAPI} from '../../utils' 
import OpposVendedoresStatusTable  from './OpposVendedoresStatusTable';

const NumberCardGrid = ({sizes={xs:6}, ...other}) => (
    <Grid item {...sizes}>
        <NumberCard {...other}/>
    </Grid>
)

const DashPage = ({tabKey}) => {
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [stat, setStat] = useState({
        oppos:{
            en_tratativa:0,
            a_presupuestar:0,
            ganadas_ult_7:0
        }
    })
    
    const {curTab, dispatchTabs} = useContext(TabsContext);
    const dispatch = useDispatch();

    useEffect( () => {
        debugger
        if (curTab.key === tabKey) {
            console.log(curTab)
            setReload(true)
        }
    }, [curTab, tabKey])

    useEffect( () => {
        setReload(false)
        setLoading(true)
        getAPI('/crm/oppos/stats')
        .then(data => {
            setLoading(false);
            setStat(data)
        })
        .catch(err => {
            console.error(err)
        });

    }, [reload])

    const handleClick = (tipo, filter) => () => {
        dispatch({type: 'FILTERS-MERGE', oppos: filter})
        dispatchTabs({type: 'gotoTabLabel', label: tipo})
    }

    const items = [
        {
            component: <NumberCard title="OPOS" label="En Tratativa" value={stat.oppos.en_tratativa} color={blue} onClick={handleClick('OPORTUNIDADES', {status: 'EN TRATATIVA'})}/>,
            sizes: {xs:6,sm:3,md:2}
        },
        {
            component: <NumberCard title="OPOS" label="A Presupuestar" value={stat.oppos.a_presupuestar} color={blue} onClick={handleClick('OPORTUNIDADES', {status: 'A PRESUPUESTAR'})} />,
            sizes: {xs:6,sm:3,md:2}
        },
        {
            component: <NumberCard title="OPOS" label="Ganadas" value={stat.oppos.ganadas} color={blue} onClick={handleClick('OPORTUNIDADES', {status: 'GANADA'})} />,
            sizes: {xs:6,sm:3,md:2}
        },
        {
            component: <NumberCard title="OPOS" label="Ganadas Ult 7d" value={stat.oppos.ganadas_ult_7} color={blue} onClick={handleClick('OPORTUNIDADES', {status: 'GANADA', ingresada: 'last7d'})} />,
            sizes: {xs:6,sm:3,md:2}
        },
        {
            component: <OpposVendedoresStatusTable reload={reload} />,
            sizes: {xs:6}
        }
    ]
        
    return (
        <DashboardBase 
            items={items}
            loading={loading}
        />
    )
}

export default DashPage;