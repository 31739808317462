import Box from "@mui/material/Box"; 
import Navigation  from './Navigation';

import {StackMenu} from '../../components';

import {menuConfig} from '../../appConfig.js';

import { ConnectedUsers } from "./ConnectedUsers.jsx";

const AppContainer  = ({children={}}) => {
    return (
        <Box sx={{height: "100%", width: '100%'}}>
            
            <Navigation appName={"GestionAB"}>
                <StackMenu 
                    menu={menuConfig()}
                    buttonProps={{fontSize: '0.8rem', color: 'white'}}
                    iconButtonProps={{color: 'white'}}
                />

                <ConnectedUsers/>

            </Navigation>

            <Box sx={{height: "100%", width: "100%"}}>{children}</Box>
            
        </Box>
    )
}
export default AppContainer