import React, {useState, useMemo, useEffect, useCallback} from "react";
import { useForm, useFormState, useController, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from '@mui/material/FormControl';
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Alert from '@mui/material/Alert';
import LinearProgress from "@mui/material/LinearProgress";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import {DatePicker} from '@mui/x-date-pickers/DatePicker';

import _ from 'underscore'
import moment from 'moment'

const componentSX = {
    "& .MuiInputLabel-root.Mui-disabled": {
        color: '#555'
    },
    "& .MuiOutlinedInput-input.Mui-disabled": {
        color: '#003',
        '-webkit-text-fill-color': '#003'                                
    }
}

const getErrorMessage = (error, key) => {
    if (key.indexOf('.') > -1) {
        let keys = key.split('.')
        let kk = isNaN(parseInt( keys[0] )) ? keys[0] : parseInt(keys[0]);  
        let part = error[kk], inx = 1;
        while (part && inx < keys.length) {
            kk = isNaN(parseInt( keys[inx] )) ? keys[inx] : parseInt(keys[inx]);  
            part = part[kk]
            inx += 1;
        }
        if (part && part.message) {
            return part.message
        }
    }
    return error[key] ? error[key].message : "";
}

const DebugWatched = ({ control, name }) => {
    const firstName = useWatch({
      control,
      name: name, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
      defaultValue: "default" // default value before the render
    });
    return <div>Watch: {JSON.stringify(firstName)}</div>; // only re-render at the component level, when firstName changes
}

export const TextInput = ({ control, name, defaultValue='', rules={}, ...other }) => {
    const {
      field: { onChange, onBlur, value, ref },
      //fieldState: { invalid, isTouched, isDirty },
      //formState: { touchedFields, dirtyFields }
    } = useController({
      name,
      control,
      rules,
      defaultValue,
    });

    return (
      <TextField 
        size='small'
        onChange={onChange} // send value to hook form 
        onBlur={onBlur} // notify when input is touched/blur
        value={value} // input value
        name={name} // send down the input name
        inputRef={ref} // send input ref, so we can focus on input when error appear
        {...other}
      />
    );
}

export const SwitchInput = ({ control, name, defaultValue='', rules={}, ...other }) => {
    const {
      field: { onChange, onBlur, value, ref },
      //fieldState: { invalid, isTouched, isDirty },
      //formState: { touchedFields, dirtyFields }
    } = useController({
      name,
      control,
      rules,
      defaultValue,
    });
  
    return (
      <FormControlLabel 
        onChange={onChange} // send value to hook form 
        onBlur={onBlur} // notify when input is touched/blur
        checked={value} // input value
        name={name} // send down the input name
        inputRef={ref} // send input ref, so we can focus on input when error appear
        control={<Switch />}
        {...other}
      />
    );
}

export const SelectInput = ({ control, name, localField, defaultValue='', rules={}, children, debug=false, ...other }) => {
    const {
      field: { onChange, onBlur, value, ref },
      //fieldState: { invalid, isTouched, isDirty },
      //formState: { touchedFields, dirtyFields }
    } = useController({
      name,
      control,
      rules,
      defaultValue,
    });

    const getValue = (doc) => {
        if (localField && doc[localField]) return doc[localField]
        return doc
    }

    return (
        <FormControl size='small' fullWidth>
            <InputLabel id="demo-simple-select-helper-label">{other['label']}</InputLabel>
            <Select 
                labelId="demo-simple-select-helper-label"
                onChange={onChange} // send value to hook form 
                onBlur={onBlur} // notify when input is touched/blur
                value={getValue(value || [])} // input value
                name={name} // send down the input name
                inputRef={ref} // send input ref, so we can focus on input when error appear
                {...other}
            >
                {children}
            </Select>
            {debug && <DebugWatched control={control} name={name}/>}
        </FormControl>
    );
    /*
    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} disabled>
            <InputLabel>Age</InputLabel>
            <Select 
                size='small'
                onChange={onChange} // send value to hook form 
                onBlur={onBlur} // notify when input is touched/blur
                value={value || []} // input value
                name={name} // send down the input name
                inputRef={ref} // send input ref, so we can focus on input when error appear
                {...other}
            />
            {children}
        </FormControl>
    );
    */
}

export const DatePickerInput = ({ control, name, defaultValue='', rules={}, dateProps={format:"YYYY-MM-DD"}, helperText,  ...other }) => {
    const {
        field: { onChange, onBlur, value, ref },
        //fieldState: { invalid, isTouched, isDirty },
        //formState: { touchedFields, dirtyFields }
    } = useController({
        name,
        control,
        rules,
        defaultValue,
    });
    return (   
        <DatePicker
            views={['day']}
            mask=''
            {...dateProps}
            onChange={onChange} // send value to hook form 
            onBlur={onBlur} // notify when input is touched/blur
            value={value && moment(value).utc()} // input value
            name={name} // send down the input name
            inputRef={ref} // send input ref, so we can focus on input when error appear
            slots={{textField: (params) => <TextField sx={componentSX} size='small' {...params} error={!!helperText} helperText={helperText} />}}
            {...other}
        />
    )
}

export const AutocompleteInput1 = ({ control, name, field='', defaultValue='', rules={}, Component, ...other }) => {
    const {
        field: { onChange, onBlur },
        //fieldState: { invalid, isTouched, isDirty },
        //formState: { touchedFields, dirtyFields }
    } = useController({
        name,
        control,
        rules,
        defaultValue,
    });
    const [currentValue, setCurrent] = React.useState(defaultValue);

    const onChangeSelection = (event, newValue, reason, details) => {
        if (reason === 'selectOption') {
            onChange(newValue[field]);
            setCurrent(newValue);
        }
        if (reason === 'clear') {
            onChange("");
            setCurrent("");
        }
    }
    
    return (   
        <Component
            //onChange={onChange} // send value to hook form 
            onBlur={onBlur} // notify when input is touched/blur
            defaultValue={currentValue} // input value
            onChangeSelection={onChangeSelection}
            //name={name} // send down the input name
            //inputRef={ref} // send input ref, so we can focus on input when error appear
            //renderInput={(params) => <TextField {...params} />}
            {...other}
            debug
        />
    )
}

export const AutocompleteInput = ({ control, name, field="", defaultValue="", rules={}, Component, debug=false, ...other }) => {
    const {
        field: { onChange, onBlur, value, ref },
        //fieldState: { invalid, isTouched, isDirty },
        //formState: { touchedFields, dirtyFields }
    } = useController({
        name,
        control,
        rules,
        //defaultValue: defaultValue[field],
        defaultValue
    });
    const onChangeSelection = (event, newValue, reason, details) => {
        if (reason === 'selectOption') {
            onChange(newValue[field])
        }
        if (reason === 'clear') {
            onChange(defaultValue)
            // onChange("")
        }
    }
    return (   
        <Component
            //onChange={onChange} // send value to hook form 
            onBlur={onBlur} // notify when input is touched/blur
            //defaultValue={{[field]: value}}
            value={{[field]:value}}

            onChangeSelection={onChangeSelection}
            //name={name} // send down the input name
            //inputRef={ref} // send input ref, so we can focus on input when error appear
            //renderInput={(params) => <TextField {...params} />}
            ref={ref}
            {...other}
            debug={debug}
        />
    )
}

export const AutocompleteObjectInput = ({ control, name, defaultValue="", rules={}, Component, debug=false, ...other }) => {
    const {
        field: { onChange, onBlur, value, ref },
        //fieldState: { invalid, isTouched, isDirty },
        //formState: { touchedFields, dirtyFields }
    } = useController({
        name,
        control,
        rules,
        defaultValue
    });
    const onChangeSelection = (event, newValue, reason, details) => {
        if (reason === 'selectOption') {
            onChange(newValue)
        }
        if (reason === 'clear') {
            onChange(defaultValue)
        }
    }
    return (   
        <Component
            //onChange={onChange} // send value to hook form 
            onBlur={onBlur} // notify when input is touched/blur
            value={value}

            onChangeSelection={onChangeSelection}
            //name={name} // send down the input name
            //inputRef={ref} // send input ref, so we can focus on input when error appear
            //renderInput={(params) => <TextField {...params} />}
            ref={ref}
            {...other}
            debug={debug}
        />
    )
}


const Form = ({
    fields=[], 
    withSections=[],
    defaultValues={}, 
    disabled=false,
    sizes={xs:6},
    mode='onChange',
    loading=false,
    watchChanges=()=>{},
    onSubmit=()=>{}, 
    actionsComp=()=>{},
    withDivider=true,
    debug=false
}) => {
    //console.log('[Form]', defaultValues)

    const { control, reset, watch, setValue, getValues, setError, clearErrors, handleSubmit, formState:{isValid, errors} } = useForm({
        mode,
        reValidateMode: 'onChange',
        resolver: undefined,
        //defaultValues,
        defaultValues: useMemo(() => {
            return defaultValues;
        }, [defaultValues])     
    });
    const { dirtyFields } = useFormState({
        control
    });
    const [diffArray, setDiffArray] = useState([]);
    let sizesDef = useMemo( () => {
        return sizes;
    }, [sizes])

    // registra mapeos de tipos
    let fieldsMap = useMemo( () => {
        return {}
    }, [])

    const recalcDiff = useCallback(() => {
        // build differences between original and new form
        const diff = []
        debugger
        for (const key in dirtyFields) {
            try {
                let label = fieldsMap[key]['label'];
                const custom = fieldsMap[key] && fieldsMap[key]['diffFn'];
                if (custom) {
                    let viejo = custom(defaultValues[key]);
                    let nuevo = custom(getValues(key));
                    if (viejo !== nuevo) {
                        viejo = viejo || 'SIN';
                        nuevo = nuevo || 'SIN';
                        diff.push( [label, viejo, nuevo] )   
                    }        
                } else {
                    let viejo = defaultValues[key];
                    let nuevo = getValues(key);
                    const fm = fieldsMap[key];
                    if (fm && fm['type'] === 'date') {
                        const fmt = fm['format'] || 'YYYY-MM-DD';
                        if (viejo) viejo = moment(viejo).utc().format(fmt)
                        if (nuevo) nuevo = moment(nuevo).utc().format(fmt)
                        if (!viejo || viejo == null) viejo = 'SIN';
                        if (!viejo || nuevo == null) nuevo = 'SIN';
                    }
                    if (fm && fieldsMap[key]['type'] === 'boolean') {
                        viejo = !!viejo ? 'SI' : 'NO';
                        nuevo = !!nuevo ? 'SI' : 'NO';
                    }
                    if (viejo !== nuevo) {
                        viejo = viejo || 'SIN';
                        nuevo = nuevo || 'SIN';
                        diff.push( [label, viejo, nuevo] )   
                    }    
                }
            } catch(err) {
                console.error(err)
            }
        }
        setDiffArray(diff)

    }, [dirtyFields, defaultValues, getValues, fieldsMap])
    
    useEffect(() => {
        //debugger
        if (_.isEmpty(defaultValues)) return;
        console.log("[Form] reset", defaultValues)
        reset(defaultValues);
    }, [defaultValues, reset]);

    useEffect(() => {
        //debugger
        const subscription = watch((value, { name, type }) => {
            console.log("[Form] subscription ", value, name, type);
            recalcDiff()
            if (watchChanges) {
                watchChanges(value, name, type, getValues, setValue, setError, clearErrors)
            }
        })
        return () => subscription.unsubscribe();
    }, [watch, watchChanges, getValues, setValue, setError, clearErrors, recalcDiff])


    const customSubmit = (data, ev) => {
        console.log('[Form] custumSubmit', data, diffArray)
        const diffMsgs = _.map(diffArray, it => {return it[0] + ": " + it[1]  + " => " + it[2]})
        onSubmit(data, diffMsgs);
    }
    
    const fieldMapper = numSections => ({
            name,
            label,
            diffFn,
            rules={}, 
            options=[], 
            sizes=sizesDef, 
            hidden=false,
            dateProps,
            type='text',
            Component=null,
            ...moreOpts
        }) => {

            if (!name) return  <Grid key={name} item {...sizes}/>;

        // si el name es compuesto que tome la primera parte
        if (name.indexOf('.') > -1) {
            const nameArr = name.split('.');
            fieldsMap[nameArr[0]] = {type: 'string', label, diffFn}
        } else {
            fieldsMap[name] = {type: 'string', label, diffFn}
        }

        // console.log('compo', name, errors[name])
        if (!Component) {
            if (type === 'date' || dateProps) {
                fieldsMap[name] = {type: 'date', label, ...dateProps}
                Component = DatePickerInput;
            }
            if (moreOpts && moreOpts['multiple']) {
                Component = SelectInput;
            }
            if (options.length) {
                Component = SelectInput;
            }
            if (type === 'boolean') {
                fieldsMap[name] = {type: 'boolean', label}
                Component = SwitchInput;
            }
            if (type === 'divider') {
                numSections += 1;
                const num = numSections;
                Component = () => (
                    <Divider sx={{mt:2, p: '5px 0px'}}><Typography variant='h6'>{label}</Typography></Divider>
                )
            }
            if (type === 'label') {
                Component = () => (
                    <Typography>{label}</Typography>
                )
            }
            if (!Component) {
                Component = TextInput
            }
        }

        const helperText = getErrorMessage(errors, name);
        return !hidden &&  
                <Grid key={name} item {...sizes}>
                    <Component
                        sx={componentSX}
                        name={name}
                        label={label}
                        control={control}
                        rules={rules}
                        setValue={setValue}
                        //size='medium'
                        margin='none'
                        disabled={disabled || loading}
                        select={options.length > 0 ? true : undefined}
                        error={!!helperText ? true : undefined}
                        helperText={helperText ? helperText : undefined}
                        fullWidth={true}
                        {...dateProps}
                        {...moreOpts}      
                    >
                        {options.map( (opt) => (
                            <MenuItem key={opt.value} value={opt.value}>
                                {opt.label}
                            </MenuItem>
                        ))}
                    </Component>
                </Grid>
    }

    let numSections = 0;
    return (
        <form onSubmit={handleSubmit(customSubmit)} noValidate autoComplete="off">
            {loading && <LinearProgress /> }
            <Grid container spacing={1}>
                { fields.map( fieldMapper(numSections) ) }
            </Grid>
            
            { withDivider && <Divider sx={{margin: '10px'}}/> }

            { !disabled && actionsComp( handleSubmit(customSubmit), isValid) }

            { debug && 
                <Alert severity="info" style={{ marginBottom: 8 }}>
                    <code>
                        <b>getValues=</b>{JSON.stringify(getValues())} <br/>
                        <b>formState.isValid=</b>{isValid ? 1 : 0} <br/>
                        <b>errors=</b>{JSON.stringify(errors)} <br/>
                        <b>dirty=</b>{JSON.stringify(dirtyFields)} <br/>
                        <b>diffArray=</b>{JSON.stringify(diffArray)} <br/>
                    </code>
                </Alert>                
            }
        </form>
    )    
}

export default Form;
