import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const HeaderBar = ({title, actions=[], info=[], ...other}) => {
    return (
      <AppBar 
        position='static'
        color='secondary'
        {...other}
      >
        <Toolbar variant='dense'>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title} 
            </Typography>
            {actions}
        </Toolbar>
        <Box sx={{
          margin: '8px 50px',
          color: '#ddd'
        }}>
            {info}
        </Box>
      </AppBar>
    )
}

export default HeaderBar;

const HeaderBar1 = ({title, actions, info}) => {
  return (<Box/>
    // <Box sx={{p:3}}>
    //   {title}
    // </Box>
  )
}
// export default HeaderBar1