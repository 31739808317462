export const printDiv = (html, title="Impresion") => {

    // var a = window.open('', '', 'height=800, width=600');
    // document.head.querySelectorAll('link, style').forEach(htmlElement => {
    //   a.document.head.appendChild(htmlElement.cloneNode(true));
    // });
    // a.document.body.innerHTML = html;
    // a.document.close();
    // a.print();
    // a.close();


    // Open a new window with specific dimensions
    const printWindow = window.open('', '', 'height=800,width=600');

    // Copy all stylesheets and inline styles to the new window
    document.head.querySelectorAll('link[rel="stylesheet"], style').forEach(htmlElement => {
      printWindow.document.head.appendChild(htmlElement.cloneNode(true));
    });

    // Add the HTML content to the new window's body
    printWindow.document.body.innerHTML = html;

    // Set the title of the new window
    printWindow.document.title = title;

    // Wait for the content to be fully loaded before printing
    printWindow.document.addEventListener('load', () => {
        printWindow.document.close(); // Close the document for writing
        printWindow.focus(); // Focus the new window
        printWindow.print(); // Trigger the print dialog
        printWindow.close(); // Close the window after printing
    }, true);

    // Optionally focus the new window in case it doesn't focus automatically
    printWindow.focus();

  }
