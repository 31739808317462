import React, {forwardRef, useState, useEffect, useCallback} from 'react'; 

import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from 'notistack';
// import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import {grey} from '@mui/material/colors';

import {PageBase, PageTitle, SearchBox} from '../../components'; 
import {getAPI, useTabs, checkUserRoles, debounce, postAPI, activityLog} from '../../utils' ;
import {UserSearchBox} from '../Settings/Users/UserSearchBox';
import OpportunityList from './OpportunityList';

import {options_opos_status} from "../../appConfig.js"
import ActivityOpenClose  from '../Activities/ActivityOpenClose';

import moment from 'moment'

const optionsRevision = [
    {label: 'Todos', value: '-1'},
    {label: 'Sin Fecha', value: '-2'},
    {label: 'Vencidos', value: '0'},
    {label: 'Prox 7d', value: '1'},
    {label: 'Prox 30d', value: '2'},
    {label: 'Mayor 30d', value: '3'},
]

const optionsExito = [
    {label: 'Todos', value: '-1'},
    {label: 'Mayor a 75%', value: 75},
    {label: 'Mayor a 50%', value: 50},
    {label: 'Mayor a 25%', value: 25},
    {label: 'SIN',         value: '-2'}
]

const optionsIngresada = [
    {label: "Sin limite",   value: '-1'},
    {label: "Ult 7d",       value: 'last7d'},
    {label: "Ult mes",      value: 'lastMonth'},
    {label: "Ult año",      value: 'lastYear'},
]

const IndexPage = forwardRef( ({isTabSelected}, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    let {filter, status, exito, ingresada, distribuidor, responsable, revision} = useSelector(state => state.oppos);
    const [reload, setReload] = useState(false);
    const {appendTab} = useTabs({
        onReload: (reload) => {
            setReload(reload)
        }
    });

    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;

    const currentUser = useSelector(state => state.currentUser);
    const esLocalAdmin = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'crm-edit'])

    const canOpts = useSelector( state => state.distributors)
    const optionsDistrib = canOpts ? ([{label: 'TODOS', value:'-1'}].
                                concat(canOpts.map(it => ({label:it.nombre, value:it._id}) ) ))
                                : [];

    const optionsStatus = options_opos_status.map( it => ({label: it, value: it}));
    
    const fn = useCallback(
        debounce(
            (page, filter, status, exito, ingresada, distribuidor, responsable, revision, enqueueSnackbar) => {
                setReload(false)
                setLoading(true)
                getAPI('/crm/oppos/', {filter, status, exito, ingresada, distribuidor, responsable, revision, skip: page*limit, limit})
                .then(data => {
                    setLoading(false);
                    if (data && data.list) {
                        data.list.forEach( it => it.id = it._id);
                        setNum(data.num)
                        setRows(data.list)
                    }
                })
                .catch(err => {
                    console.error(err)
                    enqueueSnackbar(err.message, {variant: 'error'})
                });
            }, 500) 
        , [])

    useEffect( () => {
        fn(page, filter, status, exito, ingresada, distribuidor, responsable, revision, enqueueSnackbar)
    }, [page, filter, status, exito, ingresada, distribuidor, responsable, revision, reload, isTabSelected, enqueueSnackbar, fn])

    const handleSearch = (tipo) => (ev) => {
        if (tipo === 'filter')      dispatch({type: 'FILTERS', oppos: {filter: ev.target.value, status, exito, ingresada, distribuidor, responsable, revision}}); 
        if (tipo === 'status')      dispatch({type: 'FILTERS', oppos: {status: ev.target.value, filter, exito, ingresada, distribuidor, responsable, revision}});
        if (tipo === 'exito')       dispatch({type: 'FILTERS', oppos: {exito: ev.target.value, filter, status, ingresada, distribuidor, responsable, revision}});;
        if (tipo === 'ingresada')   dispatch({type: 'FILTERS', oppos: {ingresada: ev.target.value, filter, status, exito, distribuidor, responsable, revision}});;
        if (tipo === 'distribuidor')     dispatch({type: 'FILTERS', oppos: {distribuidor: ev.target.value, filter, status, exito, ingresada, responsable, revision}});;
        if (tipo === 'responsable') dispatch({type: 'FILTERS', oppos: {responsable: ev.target.value, filter, status, exito, ingresada, distribuidor, revision}});;
        if (tipo === 'revision')    dispatch({type: 'FILTERS', oppos: {revision: ev.target.value, filter, status, exito, ingresada, responsable, distribuidor}});;
    }

    const handleClick= tipo => item => (ev) => {
        ev.preventDefault();
        if (item.id) appendTab(tipo)(item)
    }

    const handleClear = (ev) => {
        dispatch({type: 'FILTERS-RESET', key: 'oppos'})
    }

    const handleFecha = (_id, tipo) => valor => {
        debugger
        postAPI('/crm/oppos/' + _id, {
            [tipo]: valor
        }).then( ({res, doc}) => {
            debugger
            const msg = `Se cambió la ${tipo} a ${moment(valor).format('YYYY-MM-DD')}"`
            enqueueSnackbar(msg, {variant: 'info'});
            activityLog({label: doc.nombre, app:'comercial', _id, msg, currentUser})
    
            setReload(true)
        }).catch(err => {
            enqueueSnackbar(err.message, {variant: 'err'})
        })
    }

    const columnVisibilityModel={
        actions: !!esLocalAdmin
    }

    return (
        <PageBase
            sx={{width:'100%', height: 'calc(100% - 95px)'}} 

            title={
                <>
                    <Hidden smDown>
                        <PageTitle>Oportunidades ({num})</PageTitle>
                    </Hidden>
                    <SearchBox sx={{ml:1, mr:2, flexGrow:1}} label="Filtro"  value={filter} onChange={handleSearch('filter')}/>
                    <SearchBox label="Status" select multiple options={optionsStatus} value={status} onChange={handleSearch('status')}/>
                    <Hidden mdDown>
                        <UserSearchBox label="Responsable" vendedor value={responsable} onChange={handleSearch('responsable')}/>
                        <SearchBox sx={{minWidth:100, mr:1}} label="Distribuidores"   select options={optionsDistrib} value={distribuidor} onChange={handleSearch('distribuidor')}/>
                        <SearchBox label="Exito"     select options={optionsExito} value={exito} onChange={handleSearch('exito')}/>
                    </Hidden>
                    <Hidden lgDown>
                        <SearchBox label="Revision"  select options={optionsRevision} value={revision} onChange={handleSearch('revision')}/>
                        <SearchBox label="Ingresada" select options={optionsIngresada} value={ingresada} onChange={handleSearch('ingresada')}/>
                        <Box sx={{color: grey[500], position:'relative', bottom:'-23px', fontSize:9, display: 'inline', cursor: 'pointer'}} onClick={handleClear}>Reset</Box>
                    </Hidden>
                </>
            }
            actions={
                <Box sx={{display: 'flex'}}>
                    {esLocalAdmin && <Button sx={{m:1}} size='small' variant='outlined' onClick={handleClick('OPPO')({id: true, _id: 'new'})}>Nuevo</Button>}
                    <ActivityOpenClose app='comercial' withLabel/>
                </Box>
            }
       >           
            <OpportunityList
                loading={loading} 
                docs={rows} 
                num={num}
                limit={limit}
                columnVisibilityModel={columnVisibilityModel}

                onClick={handleClick}
                onFecha={handleFecha}
                onPageChange={(newPage) => setPage(newPage)}
            />    

        </PageBase>
    )
})

export default IndexPage;