import React, {useState, useEffect} from 'react'; 
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {PageBase, PageTitle, DataGrid, SearchBox} from '../../../components'; 
import TaskTopicEditModal from './TaskTopicEditModal';
import {getAPI, deleteAPI} from '../../../utils' ;
import { useUsersChannel } from '../../Layout/WebSocketProvider';

const columns = (onEdit, onDelete) => ([
    {
        field: 'boardlist',
        headerName: 'Board',
        valueGetter: (params) => {
          return params.value && params.value.name
        },  
        width: 200,
    },
    {
        field: 'name',
        headerName: 'Nombre',
        type: 'string',
        width: 200,
    },
    {
        field: 'color',
        headerName: 'Color',
        type: 'string',
        width: 200,
    },
    // {
    //     field: 'activo',
    //     headerName: 'Activo',
    //     type: 'Number',
    //     valueGetter: (params) => {
    //       return params.row.activo === 1 ? 'SI' : 'NO'
    //     },  
    //     width: 120,
    // },
    {
        field: 'actions',
        headerName: 'Acciones',
        type:'actions',
        getActions: (params) => [
          <GridActionsCellItem icon={<CreateIcon/>} onClick={onEdit(params)} label="Edit" />,
          <GridActionsCellItem icon={<DeleteIcon/>} onClick={onDelete(params)} label="Delete" showInMenu />,
        ]
    }
])

const IndexPage = () => {
    debugger
    const { enqueueSnackbar } = useSnackbar();
    let [searchParams] = useSearchParams();
    const [filter, setFilter]   = useState(searchParams.get("filter") || "");
    const [editDocId, setEditDocId] = useState("");
    const [reload, setReload]   = useState(false);
    const { reloadConfigs } = useUsersChannel();

    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;
    
    useEffect( () => {
        setReload(false)
        setLoading(true)
        getAPI('/settings/taskTopic/', {filter, skip: page*limit, limit})
        .then(data => {
            setLoading(false);
            data.list.forEach( it => it.id = it._id);
            setNum(data.num)
            setRows(data.list)
        })
        .catch(err => {
            setLoading(false);
            console.error(err)
            enqueueSnackbar(err.message, {variant: "error"})
        });

    }, [page, filter, reload])

    const handleFilter = (ev) => {
        setFilter(ev.target.value)
    }
    const handleEdit = (item) => (ev) => {
        setEditDocId(item.row._id)
    }
    const handleDelete = (item) => (ev) => {
        debugger
        setLoading(true)
        deleteAPI('/settings/taskTopic/' + item.row._id)
        .then(data => {
            setLoading(false);
            setReload(true);
            enqueueSnackbar("Se borró el registro correctamente", {variant: "info"});
            reloadConfigs()
        })
        .catch(err => {
            setLoading(false);
            console.error(err)
            enqueueSnackbar(err.message, {variant: "error"})
        });
    }
    const handleNew = (ev) => {
        setEditDocId("new")
    }
    const handleClose = (obj) => {
        debugger
        if (obj && obj.res) {
            setReload(true)
        }
        setEditDocId("")
    }

    return (
        <PageBase title={
                    <>
                        <PageTitle>Tópicos de Tareas ({num})</PageTitle> 
                        <SearchBox label="Filtro" value={filter} onChange={handleFilter}/>
                    </>
                }
                actions={
                    <>
                        <Button sx={{m:1}} size='small' variant='outlined' onClick={handleNew}>Nuevo</Button>
                    </>
                }         
       >
            <DataGrid
                loading={loading} 
                rows={rows} 
                rowCount={num}
                columns={columns(handleEdit, handleDelete)} 

                pagination
                paginationMode="server"
                pageSize={limit}
                onPageChange={(newPage) => setPage(newPage)}
    
                rowHeight={35} 
                hideFooter={false} 
                sx={{
                    'root.border': '2px solid red',
                    'root.height': '100vh'
                }}
              
            />    

            { editDocId && <TaskTopicEditModal editDocId={editDocId} onClose={handleClose}/> }    

        </PageBase>
    )
}

export default IndexPage;