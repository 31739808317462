import React from 'react';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {Form} from '../../../components'
import {postAPI} from '../../../utils';
import { useUsersChannel } from '../../Layout/WebSocketProvider';

const PlacesForm = ({tipo, editDoc, onClose}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { reloadConfigs } = useUsersChannel();

    const handleSubmit = (data) => {
        debugger
        let id = editDoc._id;
        if (id === 'new') {
            id = "";
        }
        data['tipo'] = tipo;
        postAPI('/settings/places/' + id, data)
        .then(res => {
            console.log(res)
            enqueueSnackbar("Se guardo el cambio correctamente", {variant: 'info'});
            reloadConfigs();
            onClose(res)
        })
        .catch(err => {
            debugger
            console.log(err.message)
            //setError({title: err.message})
        });    
    }
  
    const fields = [
      {
          label: 'Nombre',
          name: 'nombre',
          rules: {
              validate: {
                  exists: (v) => !!v || 'Es obligatorio',
              }
          },
          sizes: {xs:4},
          hidden: tipo !== 'BARRIO'
      },
      {
          label: 'Direccion',
          name: 'direccion',
          rules: {
              validate: {
                  exists: (v) => !!v || 'Es obligatorio',
              }
          },
          sizes: {xs:4},
          hidden: tipo !== 'BARRIO'
      },
      {
        label: 'Teléfono',
        name: 'telefono',
        rules: {
            validate: {
                exists: (v) => !!v || 'Es obligatorio',
            }
        },
        sizes: {xs:4},
        hidden: tipo !== 'BARRIO'
    },
    {
        label: 'Localidad',
        name: 'localidad',
        rules: {
            validate: {
                exists: (v) => !!v || 'Es obligatorio',
            }
        },
        sizes: {xs:6},
        hidden: tipo === 'PROVINCIA' || tipo === 'PARTIDO' || tipo === 'PAIS'
      },
      {
        label: 'Partido',
        name: 'partido',
        rules: {
            validate: {
                exists: (v) => !!v || 'Es obligatorio',
            }
        },
        sizes: {xs:6},
        hidden: tipo === 'PROVINCIA' || tipo === 'PAIS'
      },
      {
        label: 'Provincia',
        name: 'provincia',
        rules: {
            validate: {
                exists: (v) => !!v || 'Es obligatorio',
            }
        },
        sizes: {xs:6},
        hidden: tipo === 'PAIS'
      },
      {
        label: 'Pais',
        name: 'pais',
        rules: {
            validate: {
                exists: (v) => !!v || 'Es obligatorio',
            }
        },
        sizes: {xs:6},
      },
    ]
  
    return (
      <Form 
        fields={fields}
        defaultValues={editDoc}
        onSubmit={handleSubmit}   
        //onSubmitDiff={onSubmitDiff}
        actionsComp={ (onSubmit, isValid) => (
            <Box sx={{textAlign: 'center', margin:'10px'}}>
                <Button 
                    sx={{minWidth: '100px', marginRight: '10px'}}
                    color='secondary'
                    variant='outlined' 
                    onClick={onClose}>Cancelar</Button>
                <Button 
                    sx={{minWidth: '100px'}}
                    variant="outlined" 
                    disabled={!isValid} 
                    onClick={onSubmit}>Guardar</Button>
            </Box>
            )                    
        }
        debug={false}
      />
    )
}
  
export default PlacesForm;