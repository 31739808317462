import React, {useState, useEffect, useCallback} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Form from '../../components/Form'
import {getAPI, postAPI, activityLog} from '../../utils';
import { ProvinciaAutocompleteInput, PartidoAutocompleteInput} from '../Settings/Places/PlacesAutocomplete';
import { UserAutocompleteObjectInput } from '../Settings/Users/UserAutocomplete';
import { options_moneda } from '../../appConfig';

import moment from 'moment'

const ContactForm = ({ docId, disabled, onClose, onCancel, provee=0, debug=false}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [person, setPerson] = useState(false);
    const [options, setOptions] = useState([]);
    const [editDoc, setEditDoc] = useState({id: '', tipo:"CONTACTO", activo: 'SI'});
    const currentUser = useSelector(state => state.currentUser);

    const optionsAll = useSelector(state => state.options) || {contactTypes: [], firmTypes: []};
  
    // const fetchOpts = useCallback( async (tipo) => {
    //   const lista = await getAPI('/settings/config/' + tipo);
    //   const opts = lista.data.map(it => ({value: it.name, label: it.name}));
    //   return opts;
    // }, [])

    // useEffect( () => {
    //   debugger
    //   const contactTypes = fetchOpts('contactTypes');
    //   const firmTypes = fetchOpts('firmTypes');
    //   if (person) {
    //     setOptions(contactTypes)
    //   } else {
    //     setOptions(firmTypes)
    //   }
    // }, [person])

    useEffect( () => {
      debugger
      // reads data to Edit instead of using datagrid row
      if (docId === 'new') return
      
      getAPI('/crm/contacts/' + docId)
      .then(data => {
          console.log('Leyo un registro a editar: ', data)
          setEditDoc(data)
          // enqueueSnackbar("Se elimino item correctamente", {variant: 'info'})
          setPerson(data && data.razon_social ? true : false)
        })
      .catch(err => {
          debugger
          console.log(err.message)
          enqueueSnackbar(err.message, {variant: "error"})
      });  
 
    }, [docId, enqueueSnackbar])
  
    const handleChange = () => {
      setPerson(!person)
    }

    const handleSubmit = (data, diffMgs) => {
        const id1 = docId === 'new' ? '' : data._id;

        data['es_provee'] = provee === '1';

        postAPI('/crm/contacts/' + id1, data)
        .then(res => {
          debugger
          const doc = res.doc;
          const label = provee === '1' ? "[" + data.codigo_proveedor + "] " + data.fullname : data.fullname;
          
          if (docId === 'new') {
              const msg = 'Se creo nuevo contacto';
              activityLog({label, app:'contactos', docId:doc._id, msg, currentUser})       
              enqueueSnackbar(msg, {variant: 'info'})
          } else {
              activityLog({label, app:'contactos', docId, diffMgs, currentUser})       
              const msg = "Se modificó el contacto correctamente"
              enqueueSnackbar(msg, {variant: 'info'})
          }
          if (onClose) onClose(res.doc)
        })
        .catch(err => {
            debugger
            console.log(err.message)
            //setError({title: err.message})
            enqueueSnackbar(err.message, {variant: "error"})
        });    
    }

    const watchChanges = (value, name, type, getValues, setValue, setError, clearErrors) => {
      debugger
      if (name === 'partido' && value) {
        setValue('provincia', value.provincia)
      }
    }
    
    const fields = [
      {
        label: 'Código Proveedor',
        name: 'codigo_proveedor',
        rules: {
            validate: {
                exists: (v) => !!v || 'Es obligatorio',
            },
            pattern: {value: /[0-9]{3,}/, message: 'Al menos 3 numeros'},
          },
        hidden: provee==='0',
        sizes: {xs:6, md:4}
      },
      {
        label: 'Tipo',
        name: 'tipo',
        options: !person ? optionsAll['contactTypes'] : optionsAll['firmTypes'],
        // options: options || [],
        hidden: provee==='1',
        sizes: {xs:6, md:4}
      },
      {
        label: 'Razon Social',
        name: 'razon_social',
        rules: {
            validate: {
                exists: (v) => !!v || 'Es obligatorio',
            },
        },
        hidden: !person,
        sizes: {xs:6, md:8}
      },
      {
        label: 'Nombre',
        name: 'nombre',
        rules: {
            //required: "Debe ingresar valor",
            validate: {
                exists: (v) => !!v || 'Es obligatorio',
            }
        },
        hidden: person,
        sizes: {xs:6, md:4}
      },
      {
        label: 'Apellido',
        name: 'apellido',
        rules: {
            exists: (v) => !!v || 'Es obligatorio',
            required: "Debe ingresar valor",
            pattern: {value: /[A-Za-z]{3}/, message: 'Solo 3 letras'},
        },
        hidden: person,
        sizes: {xs:6, md:4}
      },
      {
        label: 'Email',
        name: 'email',
        rules: {
            exists: (v) => !!v || 'Es obligatorio',
            pattern: {value: /.*@.*\..*/, message: "Debe ingresar formato kkkk@sss.ddd"},
        },
        sizes: {xs:6, md:4}
      },
      {
        label: 'Teléfono',
        name: 'telefono',
        rules: {
          exists: (v) => !!v || 'Es obligatorio',
          // validate: {
            //     exists: (v) => !!v || 'Es obligatorio',
            //     isEmail: v => /.*@.*/ || "Debe ingresar formato kkkk@sss.ddd",
            // },
        },
        sizes: {xs:6, md:4}
      },
      {
        label: 'Celular',
        name: 'celular',
        rules: {
            // validate: {
            //     exists: (v) => !!v || 'Es obligatorio',
            //     isEmail: v => /.*@.*/ || "Debe ingresar formato kkkk@sss.ddd",
            // },
        },
        sizes: {xs:6, md:4}
      },
      {
        label: 'Dirección',
        name: 'direccion',
        rules: {
            validate: {
                exists: v => !!v || 'Es obligatorio',
                largo: v => v.length > 6 || "La direccion debe tener un largo mayor a 6 caracteres",
            },
            // pattern: {value: /(\w){6,}/, message: "Debe ser de al menos 6 caracteres de largo"},
        },
        sizes: {xs:6, md:4}
      },
      {
        label: 'Partido',
        name: 'partido',
        Component: PartidoAutocompleteInput,
        rules: {
            // validate: {
            //     exists: (v) => !!v || 'Es obligatorio',
            //     isEmail: v => /.*@.*/ || "Debe ingresar formato kkkk@sss.ddd",
            // },
        },
        sizes: {xs:6, md:4}
      },
      {
          label: 'Provincia',
          name: 'provincia',
          Component: ProvinciaAutocompleteInput,
          rules: {
          },
          sizes: {xs:6, md:4}
      },
      {
        label: 'Activo',
        name: 'activo',
        options: [
            { value: 'SI', label: "SI" },
            { value: 'NO', label: "NO" },
        ],
        sizes: {xs:6, md:4}
      },
      {
        label: 'Moneda',
        name: 'moneda',
        options: options_moneda,
        rules: {
            validate: {
                exists: (v) => !!v || 'Es obligatorio',
            },
        },
        hidden: provee==='0',
        sizes: {xs:6, md:4}
      },
      {
        label: 'CUIT',
        name: 'cuit',
        rules: {
            validate: {
                exists: (v) => !!v || 'Es obligatorio',
                patron: (v) => /(\d+){2}-(\d){8}-(\d){1}/ || 'Debe tener este patron xx-xxxxxxxx-x'
            },
        },
        hidden: provee==='0',
        sizes: {xs:6, md:4}
      },
        {
          label: 'Responsable',
          name: 'ownerId',
          Component: UserAutocompleteObjectInput,
          sizes: {xs:6, md:4}
        },
    ]

    return (
      <Box sx={{height: "calc(100% - 140px)", overflow: 'scroll'}}>
         <FormControl component="fieldset" disabled={disabled}>
          <RadioGroup 
            row aria-label="gender"
            defaultValue="persona"
            name="radio-buttons-group"
            value = {person ? "empresa" : "persona"} 
            onChange={handleChange}
          >
            <FormControlLabel 
              sx={{color: person ? "blue" : "red"}}
              labelPlacement= "start"                 
              value="persona" 
              control={<Radio />} 
              label="Persona"  
            />
            <FormControlLabel 
              sx={{color: person ? "red" : "blue"  }} 
              labelPlacement= "start" 
              value="empresa" 
              control={<Radio />}
              label="Empresa" 
            />
          </RadioGroup>
        </FormControl>
        
        <Form 
          fields={fields}
          disabled={disabled}
          // disabled={!notDisabled || !(notDisabled && edit)}
          defaultValues={editDoc}
          onSubmit={handleSubmit}   
          watchChanges={watchChanges}
          actionsComp={ (onSubmit, isValid) => (
              <Box sx={{textAlign: 'center', margin:'10px'}} >
                  <Button 
                      sx={{minWidth: '100px', marginRight: '10px'}}
                      color='secondary'
                      variant='outlined' 
                      onClick={onCancel}>Cancelar
                  </Button>
                  <Button 
                      sx={{minWidth: '100px'}}
                      disabled={!isValid} 
                      variant="outlined" 
                      onClick={onSubmit}>Guardar
                  </Button>
              </Box>
          )}
          debug={debug}
        />

        { docId !== 'new' && 
          <Box sx={{float:'right'}}>
              <Typography sx={{color: 'gray'}} variant='body2'>
                Actualizado {moment(editDoc.updatedAt).utc().format("YYYY-MM-DD")} / 
                Creado {moment(editDoc.createdAt).utc().format("YYYY-MM-DD")}
            </Typography>
          </Box>
        }
      </Box>
    )
}
  
export default ContactForm;