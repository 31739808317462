import React from 'react'
import { useSelector } from "react-redux";
import { AutocompleteBase, AutocompleteInput, AutocompleteObjectInput } from '../../../components'

const OperationAutocomplete = ({helperText, onInputChange, ...props}) => {
    const operations = useSelector(state => state.operations) || [];

    const getOptionLabel = (it) => {
        //console.log("[userAutocomplete] getOptionLabel", it)
        return it && it.codigo ? it.codigo + " " + it.descripcion : ""
    }
    const isOptionEqualToValue = (option, value) => {
        return option && value && option.codigo === value.codigo
    }

    const customFetch = inputValue => {
        return new Promise( (resolve, reject) => {
            // const filtrados = operations.filter( it => it.label.indexOf(inputValue) > -1)
            return resolve(operations)
        })
    }
    return (
        <AutocompleteBase
            sx={{width: '100%'}}
            label="Tratatmiento"
            //filterSelectedOptions
            // autocomplete_method='/settings/users/autocomplete'
            // autocomplete_params={qParams}
            customFetch={customFetch}
            getOptionLabel={getOptionLabel}
            renderOption={(props, option) => <li {...props}>{option.codigo + " " + option.descripcion}</li> }
            isOptionEqualToValue={isOptionEqualToValue}
            disableClearable={false}
            errorText={helperText}
            onInputChange={onInputChange}
            {...props}
        />
    )
}

export default OperationAutocomplete;
    
export const OperationAutocompleteInput = ({...other}) => {
    return (
        <AutocompleteInput 
            field='operation'
            {...other} 
            Component={OperationAutocomplete}
        />
    )
}

export const OperationAutocompleteObjectInput = ({...other}) => {
    return (
        <AutocompleteObjectInput 
            {...other} 
            Component={OperationAutocomplete}
        />
    )
}
