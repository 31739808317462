import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { LocalidadAutocompleteInput, ProvinciaAutocompleteInput, PaisAutocompleteInput} from '../Places/PlacesAutocomplete';
import { Form } from '../../../components'
import { getAPI, postAPI } from '../../../utils';
import { useUsersChannel } from '../../Layout/WebSocketProvider';

const DistributorForm = ({editDocId, onClose}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ doc, setDoc ] = useState({default: false});
    const { reloadConfigs } = useUsersChannel();

    useEffect( () => {
        debugger
        // reads data to Edit instead of using datagrid row
        if (editDocId === 'new') return 
        getAPI('/settings/distributors/' + editDocId)
        .then(data => {
            setDoc(data)
        })
        .catch(err => {
            debugger
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });   
      }, [editDocId, enqueueSnackbar])
      
    const handleSubmit = (data) => {
        debugger
        let id = editDocId;
        if (id === 'new') {
            id = "";
            delete data._id
        }
        postAPI('/settings/distributors/' + id, data)
        .then(res => {
            console.log(res)
            enqueueSnackbar("Se guardo el cambio correctamente", {variant: 'info'})
            reloadConfigs()
            onClose(res)
        })
        .catch(err => {
            debugger
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });    
    }
  
    const fields = [
        {
            label: 'Codigo',
            name: 'codigo',
            rules: {
                // validate: {
                //     exists: (v) => !!v || 'Es obligatorio',
                // }
            },
            sizes: {xs:4},
        },
        {
          label: 'Nombre',
          name: 'nombre',
          rules: {
              validate: {
                  exists: (v) => !!v || 'Es obligatorio',
              }
          },
          sizes: {xs:4},
        },
        {
            label: 'Direccion',
            name: 'direccion',
            rules: {
                // validate: {
                //     exists: (v) => !!v || 'Es obligatorio',
                // }
            },
            sizes: {xs:4},
        },
        {
            label: 'Teléfono',
            name: 'telefono',
            rules: {
                // validate: {
                //     exists: (v) => !!v || 'Es obligatorio',
                // }
            },
            sizes: {xs:4},
        },
        {
            label: 'Localidad',
            name: 'localidad',
            Component: LocalidadAutocompleteInput,
            rules: {
                // validate: {
                //     exists: (v) => !!v || 'Es obligatorio',
                // }
            },
            sizes: {xs:4},
        },
        {
            label: 'Provincia',
            name: 'provincia',
            Component: ProvinciaAutocompleteInput,
            rules: {
                // validate: {
                //     exists: (v) => !!v || 'Es obligatorio',
                // }
            },
            sizes: {xs:4},
        },
        {
            label: 'Pais',
            name: 'pais',
            Component: PaisAutocompleteInput,
            rules: {
                // validate: {
                //     exists: (v) => !!v || 'Es obligatorio',
                // }
            },
            sizes: {xs:4},
        },
        {
            label: 'Activo?',
            name: 'isActive',
            type: "boolean",
            sizes: {xs:4},
        },
        {
            label: 'Es Default?',
            name: 'default',
            type: "boolean",
            sizes: {xs:4},
        },
    ]
  
    return ( 
        <Box>
            <Form 
                fields={fields}
                defaultValues={doc}
                onSubmit={handleSubmit}   
                //onSubmitDiff={onSubmitDiff}
                actionsComp={ (onSubmit, isValid) => (
                    <Box sx={{textAlign: 'center', margin:'10px'}}>
                        <Button 
                            sx={{minWidth: '100px', marginRight: '10px'}}
                            color='secondary'
                            variant='outlined' 
                            onClick={onClose}>Cancelar</Button>
                        <Button 
                            sx={{minWidth: '100px'}}
                            variant="outlined" 
                            disabled={!isValid} 
                            onClick={onSubmit}>Guardar</Button>
                    </Box>
                    )                    
                }
                debug={false}
            />
        </Box>
    )
}
  
export default DistributorForm;