import React, {useState, useEffect, useMemo} from 'react';
import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import { useParams, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { POrderDetail } from './POrderDetail';


import {Attributes, AdjuntoToggle} from '../../../components';
import { defaultTheme } from '../../../utils';
import moment from 'moment'

import { styled } from '@mui/material/styles';

const AttributesStyled = styled(Attributes)(({ theme }) => ({
    // '.MuiAttributes-label': {
    //     display: 'none'
    // },
    "@media print": {
        '.MuiAttributes-label': {
            fontSize: '9px'
        },
        '.MuiAttributes-text': {
            fontSize: '10px'
        },
    }
}));

const POrderDetailStyled = styled(POrderDetail)(({ theme }) => ({
    '.MuiTableHead-root .MuiTableCell-root': {
        // display: 'none',
        fontSize: '9px'
    },
    '.MuiTableBody-root .MuiTableCell-root': {
        padding: 0,
        paddingLeft: 2
    },
    '.MuiTableCell-body': {
        fontSize: '9px'
    },
    '.MuiTableCell-root .MuiIconButton-root': {
        display: 'none'
    },
}))

const POrderPrint = ({doc}) => {

    const provee = doc && doc.provee || {};

    return (
        <Box>
            {/* <span style={{float: 'right', fontSize: 9}}>{moment().format('YYYY-MM-DD')}</span> */}
            <Typography sx={{mb:1}} variant='h5'>Nota de Pedido NP-{doc.np}</Typography>
            <AttributesStyled
                
                sizes={{xs: 3}}
                list={
                    [
                        // {title: 'DETALLE'},
                        {label: 'NP',               text: doc.np},
                        {label: 'Status',           text: doc.status},
                        {label: 'Proveedor',        text: "["+provee.codigo_proveedor + "] " + provee.fullname},
                        {label: 'Fecha Entrega',    text: moment(doc.fecha_entrega).utc().format('YYYY-MM-DD')},
                        {label: 'Obra',             text: doc.obra && `[${doc.obra.carpeta}] ${doc.obra.nombre}` },
                        {},
                        {label: 'Descripción',      text: doc.description},
                        {},
                        {label: 'Entrega',          text: doc.lugar_entrega },
                        {label: 'Direccion',        text: doc.lugar_entrega_dire },
                        {label: 'NP Provee',        text: doc.np_provee },
                        {},
                        // {},
                        {label: 'Fecha Aprobado',   text: doc.fecha_aprobado ? moment(doc.fecha_aprobado).utc().format('YYYY-MM-DD') : 'SIN'},
                        {label: 'Fecha Pedido',     text: doc.fecha_pedido ? moment(doc.fecha_pedido).utc().format('YYYY-MM-DD') : 'SIN'},
                        {label: 'Fecha Confirmado', text: doc.fecha_confirmado ? moment(doc.fecha_confirmado).utc().format('YYYY-MM-DD') : 'SIN'},
                        {label: 'Fecha Recibido',   text: doc.fecha_recibido ? moment(doc.fecha_recibido).utc().format('YYYY-MM-DD') : 'SIN'},

                        {label: 'Total Pedido', text: doc.presupuesto + " " + doc.moneda},
                        {label: 'Tipo Cambio', text: <span>(USD={doc.tipo_cambio && doc.tipo_cambio['USDARG']} EUR={doc.tipo_cambio && doc.tipo_cambio['EURARG']})</span>},
                        doc.tipo !== 'VARIOS' ? {label: 'KG', text: doc.detail && doc.kg, sizes:{xs:2}} : {},
                        doc.tipo === 'VIDRIOS' ? {label: 'ML', text: doc.detail && doc.ml, sizes:{xs:2}} : {},
                        doc.tipo === 'VIDRIOS' ? {label: 'M2', text: doc.detail && doc.m2, sizes:{xs:2}} : {},
                    ]
                }
            />
            
            <POrderDetailStyled 
                doc={doc}
                withActions={false}
                withRecs={false}
            />
            <Typography sx={{float:'right'}} variant="caption">Actualizado el {moment(doc.updated_at).utc().format('YYYY-MM-DD')}</Typography>

        </Box>

    )
}

export default POrderPrint;