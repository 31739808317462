import { useState, useEffect, useCallback, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from "react-redux";
import { reconnectNow, reconnectAPI, useInterval } from '../../utils';
import Login from './Login';
import { useUsersChannel } from './WebSocketProvider';
import CircularProgressPage from './CircularProgressPage';

const reconnect = async (dispatch, navigate) => {
    try {
        console.log('PERSIST USER - reconnecting')
        reconnectNow().catch(err => {
            console.log(err)
            navigate('/login', {replace: true})
        })
    } catch(err) {
        console.log('PERSIST USER - reconnect FAILED')
        debugger
        navigate('/login', {replace: true})
    }
}

const useMouseEventCounter = () => {
    const [mouseEventCount, setMouseEventCount] = useState(0);

    useEffect(() => {
      const handleMouseEvent = () => {
        setMouseEventCount(prevCount => prevCount + 1);
      };
  
      // Attach event listeners when component mounts
      window.addEventListener('mousemove', handleMouseEvent);
      window.addEventListener('click', handleMouseEvent);
  
      // Clean up event listeners when component unmounts
      return () => {
        window.removeEventListener('mousemove', handleMouseEvent);
        window.removeEventListener('click', handleMouseEvent);
      };
    }, []); // Empty dependency array ensures the effect runs only once on mount
  
    return mouseEventCount;
};
  
const PersistUser = ({reconnectionTimeMs=5000, reconnectionCycles=5, children, devel=true}) => {
    const [recompute, setRecompute] = useState(0); 
    const [show, setShow] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const org = Cookies.get('org');
    const token = Cookies.get('token');

    const currentUser = useSelector( state => state.currentUser);
    
    const {isReady, connectUser, reportActivity, isUserValid} = useUsersChannel(msg => {
        if (currentUser && msg && msg['data']) {
            if (msg['data']['logoutUser'] === currentUser._id) {
                navigate('/logout', {replace: true})
            }
            if (msg['data']['reloadUser'] === currentUser._id) {
                reconnectNow()
            }
            if (msg['data']['reloadConfigs']) {
                reconnectNow()
            }
        }
    });

    const mmove = useMouseEventCounter();
    const [prevCount, setPrevCount] = useState(0);
    useInterval( () => {
        if (isReady && currentUser) {
            reportActivity(currentUser._id, location.pathname, mmove - prevCount);
            setPrevCount( v => mmove);    
        }
    }, 5000)

    // conecta el websockets
    useEffect( () => {
        if (isReady && currentUser) {
            connectUser(org, token, currentUser);
        }
    }, [isReady, connectUser, currentUser])

    // checks connected user
    useInterval( () => {
        if (isReady && currentUser) {
            isUserValid(currentUser._id);
        }
    }, 10000)

    // si cambia y falta token o usuario que reconecte
    useEffect( () => {
        setShow(null);

        if (!org || !token || currentUser && !currentUser.active) {
            console.log('no token - login')
            // navigate('/login', {replace: true})
            setShow('login')
        } 
        if (org && token && !currentUser) {
            // reconnect(dispatch, navigate)
            reconnectNow()
            setShow('progress')
        }
    }, [token, org, currentUser, dispatch, navigate])

    // si el reconnectNow no genera un currentUser que mande a login
    useInterval( () => {
        if (org && token && !currentUser) {
            setRecompute( recompute + 1);
            if (recompute === 3) {
                setRecompute(0);
                setShow('login')
            }    
        }
    }, 5000)

    // useInterval( () => {
    //     debugger
    //     // fuerza un render para traer el currenUser
    //     setRecompute( recompute + 1);
    //     const token = Cookies.get('token');
    //     if (!token) {
    //         console.log('no token - login')
    //         navigate('/login', {replace: true})
    //     } 
    //     if (recompute % reconnectionCycles === 0) {
    //         reconnect(dispatch, navigate)
    //     } else if (!currentUser && token) {
    //         reconnect(dispatch, navigate)
    //     }
    //     console.log("[PersistUser] interval", recompute, devel)
    // }, currentUser ? null : (devel ? reconnectionTimeMs : null))

    if (show === 'progress') {
        return <CircularProgressPage/>
    } if (show === 'login') {
        return <Login/>
    } else {
        return children
    }
}

export default PersistUser;