import React, {forwardRef, useState, useEffect, useCallback} from 'react'; 
import { useSelector, useDispatch } from "react-redux";

//import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import {PageBase, PageTitle, SearchBox} from '../../components'; 
import {getAPI, useTabs, checkUserRoles, debounce} from '../../utils' ;
import ProjectList from './ProjectList';
import ActivityOpenClose  from '../Activities/ActivityOpenClose';
import {UserSearchBox} from '../Settings/Users/UserSearchBox';

import {options_project_status} from "../../appConfig.js"

const optionsOrder = [
    {label: 'Obra', value: 'obra'},
    {label: 'Nombre', value: 'nombre'},
    {label: 'Creado', value: 'created_at'},
    {label: 'Actualizado', value: 'updated_at'},
]

const IndexPage = forwardRef( ({isTabSelected}, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const [filter, setFilter] = useState("");
    const [status, setStatus] = useState("-1");
    const [order, setOrder] = useState("obra");
    const [ownerId, setOwnerId] = useState("-1");
    const [reload, setReload] = useState(false);
    const [distribuidor, setDistribuidores] = useState("-1")
    const {appendTab} = useTabs({
        onReload: (reload) => {
            setReload(reload)
        }
    });

    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;

    const currentUser = useSelector(state => state.currentUser);
    const esLocalAdmin = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'obra-edit'])

    const optionsStatus = [{label: 'TODOS', value:'-1'}].concat(options_project_status)
    const canOpts = useSelector( state => state.distributors)
    const optionsDistrib = canOpts ? ([{label: 'TODOS', value:'-1'}].concat(canOpts.map(it => ({label:it.nombre, value:it._id}) )  )) : [];
    
    useEffect( () => {
        if (isTabSelected === true) {
            //setReload(true)
        }
    }, [isTabSelected]);

    const fn = useCallback( 
        debounce(
            (filter, status, order, ownerId, distribuidor, page, reload, enqueueSnackbar) => {
                setReload(false)
                setLoading(true)
                getAPI('/project/', {filter, status, order, ownerId, distribuidor, skip: page*limit, limit, sort: 'updated_at:-1'})
                .then(data => {
                    setLoading(false);
                    debugger
                    if (data && data.list) {
                        data.list.forEach( it => it.id = it._id);
                        setNum(data.num)
                        setRows(data.list)    
                    }
                  })
                .catch(err => {
                    console.error(err)
                    enqueueSnackbar(err.message, {variant: 'error'})
                });        
            }, 500),
        []
    )
    
    useEffect( () => {
        fn(filter, status, order, ownerId, distribuidor, page, reload, enqueueSnackbar)
    }, [fn, filter, status, order, ownerId, distribuidor, page, reload, enqueueSnackbar])

    const handleSearch = fld => (ev) => {
        if (fld === 'filter') setFilter(ev.target.value)
        if (fld === 'status') setStatus(ev.target.value)
        if (fld === 'ownerId') setOwnerId(ev.target.value)
        if (fld === 'distribuidor') setDistribuidores(ev.target.value)
        if (fld === 'order') setOrder(ev.target.value)
    }
    const handleClick= tipo => item => (ev) => {
        ev.preventDefault();
        if (item.id) appendTab(tipo)(item)
    }

    const columnVisibilityModel={
        actions: !!esLocalAdmin
    }

    return (
        <PageBase
            paperProps={{width:'100%', height: 'calc(100% - 98px)'}} 

            title={
                <>
                    <Hidden smDown>
                        <PageTitle>Obras ({num})</PageTitle>
                    </Hidden>
                    <SearchBox sx={{ml:1, mr:2, flexGrow:1}} label="Filtro" value={filter} onChange={handleSearch('filter')}/>
                    <SearchBox label="Status" select multiple options={optionsStatus} value={status} onChange={handleSearch('status')}/>
                    <Hidden lgDown='2'>
                        <SearchBox sx={{minWidth:100, mr:1}} label="Distribuidores" select options={optionsDistrib} value={distribuidor} onChange={handleSearch('distribuidor')}/>
                        <UserSearchBox label="Vendedor" vendedor value={ownerId} onChange={handleSearch('ownerId')}/>
                    </Hidden>
                    <SearchBox label="Ordenado" select options={optionsOrder} value={order} onChange={handleSearch('order')}/>
                </>
            }
            actions={
                <>
                    { esLocalAdmin && <Button sx={{m:1}} size='small' variant='outlined' onClick={handleClick('OBRA')({id: true, _id: 'new'})}>Nuevo</Button> }
                    <ActivityOpenClose app='obras' withLabel/>
                </>
            }   
       >           
            <ProjectList
                loading={loading} 
                docs={rows} 
                num={num}
                limit={limit}
                columnVisibilityModel={columnVisibilityModel}

                onClick={handleClick}
                onPageChange={(newPage) => setPage(newPage)}
            />    

        </PageBase>
    )
})

export default IndexPage;