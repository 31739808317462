import * as React from 'react';
import TextField from '@mui/material/TextField';
import { alpha, styled } from '@mui/material/styles';

export const InputTextField = styled(TextField)(({ theme }) => ({
    // disableUnderline:false, 
    margin:'dense', 
    size:'small', 
    width: 80,
    color: theme.palette.success.main,
    '& .MuiInputBase-input': {
        fontSize: '12px',
        padding: '10px 10px',
    },
  }));
  