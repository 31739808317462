import React, {useEffect, useState} from 'react'
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import {SelectField} from '../../components';
import UserAutocomplete from '../Settings/Users/UserAutocomplete';
import ProjectAutocomplete from '../Project/ProjectAutocomplete';
import PostSaleAutocomplete from '../PostVenta/PostSaleAutocomplete';
import WorkOrderAutocomplete from '../Fabrica/WorkOrderAutocomplete';
import POrderAutocomplete from '../Compras/POrder/POrderAutocomplete';

import { activityLog, getAPI, postAPI } from '../../utils';

import {options_task_status} from '../../appConfig';

const Separator = ({children}) => (
    <Box sx={{mb:1, textAlign: 'center', borderBottom: '1px solid #333'}}>{children}</Box>
) 

const TaskEdit = ({
                    docId='new', 
                    reference={},
                    onAfterSubmit=()=>{},
                    ...other
                }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [progress, setProgress] = useState(false);
    const currentUser = useSelector(state => state.currentUser);
    const [doc, setDoc] = useState({asunto: "", status: 'PENDIENTE', ownerId: currentUser._id});

    const topics0 = useSelector(state => state.topics) || []
    const topicOptions = topics0.map(it => {
        return {label: it.board + ":" + it.name, value: it._id}
    });
    const statusOptions = options_task_status.map( it => ({label: it, value: it}))
  
    useEffect( () => {
        if (!docId || docId === "new") return;
        getAPI('/task/' + docId)
        .then(data => {
            debugger
            setDoc(data);
        });
    }, [docId]);

    const handleChange = (fld) => (ev, doc) => {
        debugger
        if (fld === 'topicId') setDoc( old => ({...old, tasklist: {_id: ev.target.value}}))
        if (fld === 'asunto') setDoc( old => ({...old, asunto: ev.target.value}))
        if (fld === 'status') setDoc( old => ({...old, status: ev.target.value}))
        if (fld === 'obraId') setDoc( old => ({...old, obra: doc}))
        if (fld === 'pvId')   setDoc( old => ({...old, pv: doc}))
        if (fld === 'ofId')   setDoc( old => ({...old, of: doc}))
        if (fld === 'npId')   setDoc( old => ({...old, np: doc}))
    }

    const handleUser = (event, newValue, reason, details) => {
        debugger
        if (reason === 'selectOption') {
            setDoc(old =>  ({...old, ownerId: newValue}));
        }
        if (reason === 'clear') {
            setDoc(old =>  ({...old, ownerId: ""}));
        }
    }

    const handleReset = () => {
        setDoc(old => ({...old, asunto: "", status: options_task_status[0]}))
    }

    const handleSubmit = () => {
        debugger
        let id = docId;
        if (docId === 'new') {
            id = "";
        }

        const {label, app, field, id:refId} = reference;
        const doc1 = {
            ...doc, 
            ownerId: doc.ownerId._id || currentUser._id,
        };
        if (field) {
            doc1[field]= {_id: refId};
        }

        postAPI('/task/' + id, doc1)
        .then(data => {
            debugger
            const doc = data.doc; 
            if (id === '') {
                const msg = "Se guardó la nueva tarea \"" + doc.asunto + "\"";
                activityLog({label, app, docId:refId, msg, currentUser})
                enqueueSnackbar(msg, {variant: 'info'})
            } else {
                const msg = "Se modificó la tarea  \"" + doc.asunto + "\"";
                activityLog({label, app, docId:refId, msg, currentUser})
                enqueueSnackbar("Se guardó el cambio correctamente", {variant: 'info'})
            }
            if (onAfterSubmit) onAfterSubmit(doc);
            handleReset()
        })
        .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
        });
    }
  
    return (
        <Box {...other}>
            { progress &&
                <div>
                <LinearProgress/>
                <Typography style={{textAlign:'center'}}>Espere mientras se termine este proceso</Typography>
                </div>
            }
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <SelectField 
                        sx={{width: '100%'}}
                        size='small'
                        label="Tablero" 
                        options={topicOptions} 
                        value={doc.tasklist && doc.tasklist._id } 
                        onChange={handleChange('topicId')}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        sx={{width: '100%'}}
                        size='small'
                        label="Asunto" 
                        value={doc.asunto}
                        multiline
                        onChange={handleChange('asunto')}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectField 
                        sx={{width: '100%'}}
                        size='small'
                        label="Status" 
                        options={statusOptions} 
                        value={doc.status} 
                        onChange={handleChange('status')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Separator>Referencias</Separator>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <UserAutocomplete 
                        label='Responsable'
                        defaultValue={doc.ownerId} 
                        onChangeSelection={handleUser}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={4}>
                    <ProjectAutocomplete 
                        defaultValue={doc.obra} 
                        onChangeSelection={handleChange('obraId')}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <PostSaleAutocomplete 
                        defaultValue={doc.pv} 
                        onChangeSelection={handleChange('pvId')}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <WorkOrderAutocomplete
                        tipo='fabrica' 
                        defaultValue={doc.of} 
                        onChangeSelection={handleChange('ofId')}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <WorkOrderAutocomplete
                        tipo='servicio' 
                        defaultValue={doc.os} 
                        onChangeSelection={handleChange('osId')}
                    />
                </Grid> */}
                <Grid item xs={12} sm={4}>
                    <POrderAutocomplete
                        defaultValue={doc.os} 
                        onChangeSelection={handleChange('npId')}
                    />
                </Grid>

                <Grid sx={{textAlign: 'center'}} item xs={12}>

                    <Button onClick={handleReset} variant='outlined' color='secondary'>Limpiar</Button>
                    &nbsp;
                    <Button onClick={handleSubmit} variant='outlined'>{docId === 'new' ? 'Nueva Tarea' : 'Guardar Tarea'}</Button>
                </Grid>
                
            </Grid>
        </Box>
    )
}

export default TaskEdit