import React, {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { MessageBox } from "../../../components";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

const ValidityDialog = ({open, vigencia, onCancel, onClose}) => {
    const [desde, setDesde] = useState();
    const [hasta, setHasta] = useState();
    const [error, setError] = useState({});

    useEffect( () => {
        if (vigencia.length > 1) {
            setDesde(moment(vigencia[0]).utc())
            setHasta(moment(vigencia[1]).utc())
        }
    }, [vigencia])

    const handleDesde = (ev) => {
        setDesde(ev)
    }
    const handleHasta = (ev) => {
        setHasta(ev)
    }
    const handleClose = (ev) => {
        if (onClose) {
            onClose([desde, hasta])
        }
    }
    return (
        <MessageBox 
            title="Cambio de Vigencias"
            open={open} 
            onCancel={onCancel}
            onClose={handleClose}
            // onlyCancel={false}
            maxWidth='sm'
            fullWidth={true}
        >
            <Grid container spacing={1}>
                <Grid xs={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker sx={{m:1, width: "100%"}} label='Desde' value={desde && moment(desde)} onChange={handleDesde} format="YYYY-MM-DD" views={['day','year']} error={!!error['desde']} helperText={error['desde']}/>
                    </LocalizationProvider>
                </Grid>
                <Grid xs={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker sx={{m:1, width: "100%"}} label='Hasta' value={hasta && moment(hasta)} onChange={handleHasta} format="YYYY-MM-DD" views={['day','year']} error={!!error['hasta']} helperText={error['hasta']}/>
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </MessageBox>
    )    
}

export default ValidityDialog;