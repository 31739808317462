import React, {useState, useMemo, useCallback, useEffect} from 'react'
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { getAPI, postAPI } from '../../../utils';
import ListaValor from './ListaValor'
import { useUsersChannel } from '../../Layout/WebSocketProvider';

const TerminacionColoresAdmin = ({debug=false}) => {
    const [term, setTerm] = useState([]);
    const [lev1, setLev1] = useState(-1);
    const [lev2, setLev2] = useState(-1);
    const {enqueueSnackbar} = useSnackbar();
    const { reloadConfigs } = useUsersChannel();

    const lev1Def = useMemo( () => {
        return term.map( (it, inx) => ({ ...it, id: inx }) );
    }, [term]);
    const lev2Def = useMemo( () => {
        if (!term || !term[lev1] || !term[lev1].terminaciones) {
            return []
        }
        return term[lev1].terminaciones.map( (it, inx) => ({ ...it, id: inx}))
    }, [term, lev1])
    const lev3Def = useMemo( () => {
        if (!term || 
            !term[lev1] || 
            !term[lev1].terminaciones || 
            !term[lev1].terminaciones[lev2] || 
            !term[lev1].terminaciones[lev2].colores) {
                return []
        }
        return  term[lev1].terminaciones[lev2].colores.map( (it, inx) => ({ ...it, id: inx}) )
    }, [term, lev1, lev2])

    const handleCellClickLev1 = (params, ev, detail) => {
        setLev1(params.id)
        if (!term[params.id] || !term[params.id].terminaciones || term[params.id].terminaciones.length === 0) {
            setLev2(-1)
        }
    }
    const handleCellClickLev2 = (params) => {
        setLev2(params.id)
    }
    const handleChangeLev1 = (lista) => {
        debugger
        setTerm(lista)
        save()

        setLev1(lista.length - 1)
    }
    const handleChangeLev2 = (lista) => {
        debugger
        term[lev1].terminaciones = lista
        setTerm(term);
        save()

        setLev2(lista.length - 1);
    }
    const handleChangeLev3 = (lista) => {
        debugger
        //term[lev1] = term[lev1] || {terminaciones: []};
        //term[lev1].terminaciones[lev2] = term[lev1].terminaciones[lev2] || {colores: []};
        if (!term[lev1].terminaciones[lev2]) {
            term[lev1].terminaciones[lev2].colores = [];
        }
        term[lev1].terminaciones[lev2].colores = lista;
        setTerm(term);
        save()
    }

    useEffect( () => {
        debugger
        getAPI("/settings/config/terminacionColores")
        .then( res => {
            setTerm(res ? res.data : [] )
        })
    }, [])

    const save = () => {
        debugger
        postAPI("/settings/config/terminacionColores", {data: term} )
        .then(res => {
            enqueueSnackbar("Se guardó correctamente", {variant: 'info'});
            reloadConfigs()
        })
        .catch(err => {
            //setError({title: err.message})
            console.log(err)
        });
    };

    return (
        <Box>
            {debug && <div>lev {lev1} {lev2}</div>} 
            {debug && <div> {JSON.stringify(term[lev1])} </div>}
            <Typography variant='h5' sx={{p:1}}>Modifica la lista de terminaciones del sistema</Typography>
            <Stack sx={{mt: '20px'}} direction='row'>
                <ListaValor label="Marca"       rowsDef={lev1Def}                                 onChange={handleChangeLev1} onCellClick={handleCellClickLev1}/>
                <ListaValor label="Terminacion" disabled={lev1 < 0} rowsDef={lev2Def}       onChange={handleChangeLev2} onCellClick={handleCellClickLev2}/>
                <ListaValor label="Color"       disabled={lev2 < 0} rowsDef={lev3Def} onChange={handleChangeLev3} onCellClick={() => {}}/>
            </Stack>
        </Box>
    )
}

export default TerminacionColoresAdmin;