import React, {useState, useEffect} from 'react';
import {useWatch} from 'react-hook-form';
import MenuItem from "@mui/material/MenuItem";

import { SelectInput } from '../../../components'
import { getAPI } from '../../../utils';

export const MarcaSelectInput = ({...other}) => {
    const [options, setOptions] = useState([])
        
    useEffect( () => {
        getAPI("/settings/config/terminacionColores")
        .then( res => {
            debugger
            if (!res) {
              setOptions([])
              return
            }
            const opciones = res.data.map( it => ({label: it.marca, value: it.marca}))
            setOptions(opciones)
        })
    }, [])


    return (
        <SelectInput 
            {...other}
        >
            {options.map( (opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                </MenuItem>
            ))}
        </SelectInput>
    )
}

export const MarcaTerminacionSelectInput = ({control, ...other}) => {
    const [options, setOptions] = useState([])
    const fv = useWatch({control: control}) || {}
    const marca = fv['marca']

    useEffect( () => {
        getAPI("/settings/config/terminacionColores")
        .then( res => {
          if (!res) {
            setOptions([])
            return
          }
          if (res.data.filter(it => it.marca === marca).length) {
                const opciones = res.data.filter(it => it.marca === marca)[0]['terminaciones'].map( it =>  ({label: it.terminacion, value: it.terminacion}))
                setOptions(opciones)    
            }
        })
    }, [marca])

    return (
        <SelectInput 
            control={control}
            {...other}
        >
            {options.map( (opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                </MenuItem>
            ))}
        </SelectInput>
    )
}

export const MarcaTerminacionColorSelectInput = ({control, ...other}) => {
    const [options, setOptions] = useState([])
    const fv = useWatch({control}) || {}
    const marca = fv['marca']
    const terminacion = fv['terminacion']

    useEffect( () => {
        getAPI("/settings/config/terminacionColores")
        .then( res => {
          if (!res) {
            setOptions([])
            return
          }
          const marcas = res.data.filter(it => it.marca === marca);
            if (marcas.length) {
                const colores = marcas[0]['terminaciones'].filter(it => it.terminacion === terminacion);
                if (colores.length) {
                    const opciones = colores[0]['colores'].map( it =>  ({label: it.color, value: it.color}))
                    setOptions(opciones)        
                } else {
                    setOptions([]) 
                }
            }
        })
    }, [marca, terminacion])


    return (
        <SelectInput 
            control={control}
            {...other}
        >
            {options.map( (opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                </MenuItem>
            ))}
        </SelectInput>
    )
}
    
