import React, {forwardRef, useState, useEffect, useCallback} from 'react'; 
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { DatePicker } from '@mui/x-date-pickers'
import {PageBase, PageTitle, Table, SearchBox, ExportButton, SelectField} from '../../components'; 
import ContactAutocomplete from '../Crm/ContactAutocomplete';
import {getAPI, postAPI, checkUserRoles, debounce, activityLog, useTabs} from '../../utils' ;
import {EXCEL_LIMIT} from '../../appConfig';
import StockMaterialDialog from './StockMaterialDialog';
import moment from 'moment';

const optionsOrden = [
    {label: 'Categoria', value: 'categoria'}, 
    {label: 'Marca', value: 'marca'}, 
    {label: 'Articulo', value: 'articulo'}
]

const optionsColor = [
    {label: 'Todos', value: -1},
    {label: 'Sin Color', value: 0},
    {label: 'Con Color', value: 1}
]

const yellow = "#F0E24D";
const naranja = "#F0BD4D";
const celeste1 = "#64CFF5";
const celeste2 = "#11BEFC"
const azul = "#05A2F4";

const columns = (valores, handleValores) => ([
    {
        name: 'Categoria',
        field: v => v.articulo.categoria,
        width: 100,
    },
    {
        name: 'Marca',
        field: v => v.articulo.marca,
    },
    {
        name: 'Código',
        field: v => v.articulo.codigo,
        width: 150,
    },
    {
        name: 'Descripción',
        field: v => v.articulo.descripcion,
    },
    {
        name: 'Color',
        field: v => v.articulo_color,
    },
    {
        name: 'PLAN',
        field: v => v.planificado,
        td: { align:'right'}, 
        th: { style: {textAlign: "center", background: yellow} }
    },
    {
        name: 'DEM',
        field: v => v.demanda,
        td: { align:'right'}, 
        th: { style: {textAlign: "center", background: yellow} }
    },
    {
        name: 'CONS',
        field: v => v.consumido,
        td: { align:'right'}, 
        th: { style: {textAlign: "center", background: yellow} }
    },
    {
        name: 'DEM NETA',
        field: v => v.demanda_neta,
        td: { align:'right'}, 
        th: { style: {textAlign: "center", background: naranja} }
    },
    {
        name: 'STOCK',
        field: v => v.exist,
        td: { align:'right'}, 
        th: { style: {textAlign: "center", background: celeste1} }
    },
    {
        name: 'REC PEND',
        field: v => v.rec_pend,
        td: { align:'right'}, 
        th: { style: {textAlign: "center", background: celeste1} }
    },
    {
        name: 'STO PLAN',
        field: v => v.aba_plan,
        td: { align:'right'}, 
        th: { style: {textAlign: "center", background: celeste2} }
    },
    {
        name: 'COMPRAR',
        field: v => v.compras_ajs,
        td: { align:'right'}, 
        th: { style: {textAlign: "center", background: azul} }
    },
    {
        name: 'SALDO',
        field: v => v.sobra_plan,
        td: { align:'right'}, 
        th: { style: {textAlign: "center", background: azul} }
    },
    {
        name: 'MIN',
        field: v => v.articulo.stock_min,
        td: { align:'right'}, 
        th: { style: {textAlign: "center", background: azul} }
    },
    {
        name: 'UDM',
        field: v => v.articulo.deposito_udm,
        td: { align:'right'}, 
        th: { style: {textAlign: "center", background: azul} }
    },
    {
        name: 'UDP',
        field: v => v.articulo.provee_udm,
        td: { align:'right'}, 
        th: { style: {textAlign: "center", background: azul} }
    },
    {
        name: 'M',
        field: v => v.compras_ajs_m,
        td: { align:'right'}, 
        th: { style: {textAlign: "center", background: azul} }
    },
    {
        name: '$',
        field: v => v.compras_precio + " " + v.compras_moneda,
        td: { align:'right'}, 
        th: { style: {textAlign: "center", background: azul} }
    },
    {
        name: '*',
        field: v =>     v.compras_ajs > 0 ? 
                        <Checkbox
                            checked={valores && valores[v.id] > 0} 
                            onChange={handleValores(v)}
                        /> : "",
        td: { align:'right'}, 
    },

])

const IndexPage = forwardRef( ({pendiente=false, onReload=()=>{}}, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const [search, setSearch] = useState({
        articulo:"", obras:[], marca:"", 
        color:-1, pendiente, sord: 'articulo', check: true, sobra: false, sinprecio: false
    });
    const [rows, setRows] = useState([]);
    const [valores, setValores] = useState({});
    const [errores, setErrores] = useState({});
    const [fenvio, setFenvio]   = useState(moment());
    const [provee, setProvee]   = useState({});
    const [comprobante, setComprobante] = useState({});
    const [reload, setReload]   = useState(false);
    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(100);

    const {appendTab} = useTabs();

    const currentUser = useSelector(state => state.currentUser);
    const esLocalAdmin = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'inventarios-edit'])

    useEffect( () => {
        debugger
        setSearch(ss => ({...ss, pendiente}))
    }, [pendiente])

    const buildQuery = (search) => {
        const query = {...search};
        if (query['color'] === -1) delete query['color'];
        return query;
    }

    const fn = useCallback( 
        debounce(
            (search, page, perPage, enqueueSnackbar) => {
                setReload(false)
                setLoading(true)
                setErrores({})
                setValores({})
                const query = buildQuery(search);
                getAPI('/stock/material_planning', {...query, skip: page*perPage, limit: perPage})
                .then(data => {
                    debugger
                    setLoading(false);
                    data.list.forEach( it => {
                        it.id = [it._id.k1,it._id.k2].join('|');
                    });

                    setNum(data.num)
                    setRows(data.list)

                    const vv = {}
                    data.list.forEach( it => {
                        if (it.compras_ajs>0) {
                            vv[it.id] = it.compras_ajs;
                        }
                    })
                    setValores(vv);
                })
                .catch(err => {
                    setLoading(false);
                    console.error(err)
                    enqueueSnackbar(err.message, {variant: "error"})
                });    
            }, 1500),
            []
    )
    

    useEffect( () => {
        fn(search, page, perPage, enqueueSnackbar)
    }, [fn, search, page, perPage, reload, enqueueSnackbar])
    
    
    const handleSearch = fld => (ev, option) => {
        let valor;
        switch (fld) {
            case 'check':
                valor = ev.target.checked;
                break;
            case 'sobra':
                valor = ev.target.checked;
                break;
            case 'sinprecio':
                valor = ev.target.checked;
                break;
            case 'pendiente':
                valor = ev.target.checked;
                break;
            default:
                valor = ev.target.value;
        }

        setSearch({...search, [fld]: valor})
    }

    const handleValores = (row) => ev => {
        let valor;
        debugger
        valor = ev.target.checked;
        if (valor) {
            setValores(vv => ({...vv, [row.id]: row.compras_ajs}))
        } else {
            setValores(vv => ({...vv, [row.id]: null}))
        }
    }

    
    const handleTransaction = () => {
        debugger
        const data = {
            tipo: 'MATERIALES',
            provee,
            fecha_entrega: fenvio.toDate()
        }
        const detail = rows.filter( it => valores[it.id] > 0 ).map(it => {
                const doc = {
                provee,
                articulo: it.articulo,
                articulo_color: it.articulo_color,
                pedido: valores[it.id],
                udm: it.articulo.deposito_udm,
            }
            return doc;
        })
        setLoading(true);

        postAPI('/stock/porders/', data)
        .then(async ({res, doc}) => {
            debugger
            setLoading(false);
            setReload(true);

            const msg = "Se creó la nueva NP correctamente";
            enqueueSnackbar(msg, {variant: 'info'})

            activityLog({label: "NP-" + doc.np, app:'compras', docId: doc._id, msg, currentUser});

            await Promise.all(
                detail.map( async line => {
                    try{
                        await postAPI('/stock/porders/' + doc._id + "/postLine", {line})
                    } catch (err) {
                        debugger
                        console.error(err)
                        enqueueSnackbar(err.message, {variant: "error"})                        
                    }
                })    
            )

            if (onReload) onReload(true);
            if (appendTab) {
                appendTab("NP")(doc)
            }

        })
        .catch(err => {
            setLoading(false);
            console.error(err)
            enqueueSnackbar(err.message, {variant: "error"})
        });
    }

    const configReport = useCallback( async () => {
        const reportConfig = {
            title: "Planificación Materiales",
            subTitle: "Creado por " + currentUser.username + " el " + moment().local().toISOString(),
            cols: [
                {title: 'Categoría', field: '_', transform: (v, doc) => {
                        if (doc.articulo ) {
                            return doc.articulo.categoria;
                        }
                    },
                },
                {title: 'Marca', field: '_', transform: (v, doc) => {
                        if (doc.articulo ) {
                            return doc.articulo.marca;
                        }
                    },
                },
                {title: 'Código', field: '_', transform: (v, doc) => {
                        if (doc.articulo ) {
                            return doc.articulo.codigo;
                        }
                    },
                },
                {title: 'Descripción', field: '_', transform: (v, doc) => {
                        if (doc.articulo ) {
                            return doc.articulo.descripcion;
                        }
                    },
                },
                {title: 'Color', field: 'articulo_color'},
                {title: 'PLAN', field: 'planificado'},
                {title: 'DEM', field: 'demanda'},
                {title: 'CONS', field: 'consumido'},
                {title: 'DEMO NETA', field: 'demanda_neta'},
                {title: 'EXIST', field: 'exist'},
                {title: 'REC PEND', field: 'rec_pend'},
                {title: 'STO PLAN', field: 'aba_plan'},
                {title: 'COMPRAR', field: 'compras_ajs'},
                {title: 'SALDO', field: 'sobra_plan'},
                {title: 'STO MIN', field: '_', transform: (v, doc) => {
                    if (doc.articulo ) {
                        return doc.articulo.stock_min;
                    }
                }},
                {title: 'UDM', field: '_', transform: (v, doc) => {
                    if (doc.articulo ) {
                        return doc.articulo.deposito_udm;
                    }
                }},
                {title: 'UDP', field: '_', transform: (v, doc) => {
                    if (doc.articulo ) {
                        return doc.articulo.provee_udm;
                    }
                }},
                {title: 'M', field: 'compras_ajs_m'},
                {title: '$', field: '_', transform: (v, doc) => {
                    return doc.compras_precio + " " + doc.compras_moneda
                    
                }},
            ],
            styles: {}
        }

        const query = buildQuery(search);
        const ret = await getAPI('/stock/material_planning', {...query, skip: 0, limit: EXCEL_LIMIT})
        reportConfig['rows'] = ret.list;

        return reportConfig;
    }, [search]);
    
    const extraPagination = () => {
        return esLocalAdmin && search['check'] && 
        <Grid container sx={{float: 'left', width: '50%'}}>
            <Grid item>
                <Typography variant='h6' sx={{mt:1, mr:1}}>Generacion de NP</Typography>
                <Typography variant='caption' sx={{mr:1}}>Seleccionó {Object.keys(valores).length}</Typography>
            </Grid>
            <Grid item>
                <DatePicker 
                    sx={{
                        // flex: 1,
                        width: '180px',
                        "& .MuiOutlinedInput-root": {marginTop: '8px'},
                        "& .MuiOutlinedInput-input": {padding: '8.5px 14px'}
                    }} 
                    variant='standard' 
                    size='small' 
                    value={fenvio} 
                    onChange={v => setFenvio}
                />
            </Grid>
            <Grid item>
                <ContactAutocomplete
                    sx={{m:1, width: '220px'}}
                    value={provee}
                    onChange={(_,v) => {debugger; setProvee(v || {})}}
                    provee='1'
                />
            </Grid>
            <Grid item>
                <Button 
                    sx={{m:1}} 
                    margin='dense' 
                    // size='small' 
                    variant='outlined'
                    disabled={Object.keys(valores).length === 0 || Object.keys(errores).length > 0}
                    onClick={handleTransaction}
                >
                        GENERAR
                </Button>
            </Grid>
        </Grid>            
    }

    return (
      <PageBase 
          paperProps={{
            width:'100%',
            height: 'calc(100% - 100px)',
            overflow:'auto'
          }} 
          title={
            <>
                <PageTitle>Planificación de Materiales ({num})</PageTitle> 
                {/* <ProjectSearchBox multiple value={search['obras']} onChange={handleSearch('obras')} sx={{width: '100px'}}/> */}
                <SearchBox label="Obras" value={search['obras']} onChange={handleSearch('obras')}  sx={{width:'80px', mr:2}}/>
                <SearchBox label="Categoria" value={search['categoria']} onChange={handleSearch('categoria')}  sx={{width:'80px', mr:2}}/>
                <SearchBox label="Marca" value={search['marca']} onChange={handleSearch('marca')}  sx={{width:'80px', mr:2}}/>
                <SearchBox label="Articulo" value={search['articulo']} onChange={handleSearch('articulo')}  sx={{width:'120px', mr:2}}/>
                <SearchBox label="Color" value={search['articulo_color']} onChange={handleSearch('articulo_color')}  sx={{width:'80px', mr:2}}/>
                <SearchBox label="Color" select options={optionsColor} value={search['color']} onChange={handleSearch('color')} sx={{width: "100px", mr:2 }} />
                <SearchBox label="Orden" select options={optionsOrden} value={search['sord']} onChange={handleSearch('sord')} sx={{width: "100px", mr:2 }} />

                <SearchBox type='checkbox' label="Pendiente" checked={search['pendiente']} onChange={handleSearch('pendiente')}/>                
                <SearchBox type='checkbox' label="Sobra" checked={search['sobra']} onChange={handleSearch('sobra')}/>            
                <SearchBox type='checkbox' label="Sin Precio" checked={search['sinprecio']} onChange={handleSearch('sinprecio')}/>            
                {/* <SearchBox type='checkbox' label="Ver Totales" checked={search['check']} onChange={handleSearch('check')}/> */}
            </>
          }
          actions={
            <Stack direction='row'>
                <ExportButton configReport={configReport}>EXCEL</ExportButton>
            </Stack>
          }      
      >
        <>
            <Table 
                stickyHeader
                loading={loading}
                cols={columns(valores, handleValores)}
                data={rows}

                pagination
                page={page}
                rowsPerPage={perPage}
                rowCount={num}
                rowsPerPageOptions={[50,100,300]}
                onPageChange={(ev, newPage) => setPage(newPage)}
                onRowsPerPageChange={ev => setPerPage(ev.target.value)}

                extraPagination={extraPagination()}

                tableProps={{
                    size:'small', padding: 'none', stickyHeader: true
                }}
                headerProps={{
                    sx: { 
                        th: {
                            fontSize: 13, 
                            fontWeight:'bold', 
                            color:'white', 
                            backgroundColor: "#549857", //green[500], 
                            textAlign: 'center', 
                            borderRight: '1px solid #fff',
                            p: '2px 5px'
                        }
                    }
                }}
                rowProps={{
                    sx: {
                        m:1, 'td': {fontSize: '12px', p: '0px 10px'}}
                }}
            />

            { 
              comprobante && comprobante.codigo && 
                <StockMaterialDialog 
                    tipo={comprobante.tipo} 
                    comprobante={comprobante.codigo} 
                    open={!!comprobante} 
                    onCancel={v => setComprobante("")}
                /> 
            }
        </>
      </PageBase>
  )
})

export default IndexPage;