import React, {useEffect} from 'react';
// import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

import { deepOrange, deepPurple, green, red, orange, blue } from '@mui/material/colors';

import { useUsersChannel } from './WebSocketProvider';

const colorPalette = [ deepOrange[200], green[300], red[200], red[600], deepOrange[600], green[600]];

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 21,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));
  
export const ConnectedUsers = (withSessions=true) => {
    const {connectedUsers} = useUsersChannel();
    const location = useLocation();

    const countUsers = {};
    connectedUsers && Object.keys(connectedUsers).map( k => {
        const cur = connectedUsers[k];
        if (cur.initials && location.pathname == cur.location) {
            countUsers[cur.initials] = countUsers[cur.initials] || {sessions:0, activity:0};
            countUsers[cur.initials]['sessions'] += 1;
            countUsers[cur.initials]['activity'] = cur.activity;    
        }
    })
    return (
        <Box> 
            {Object.keys(countUsers).map( (initials, inx) => {
                const {sessions, activity} = countUsers[initials];
                return (
                    <StyledBadge badgeContent={sessions} color={activity > 0 ? "secondary" : 'primary'}>
                        <Avatar sx={{ 
                                        fontSize: '12px',     
                                        marginLeft: '15px',
                                        width:24, height:24, 
                                        bgcolor: colorPalette[inx]
                                    }}
                        >
                            {initials} 
                            {/* {activity} */}
                        </Avatar>
                    </StyledBadge>
                )
            })}

            {/* <ul>
                {lastVal && lastVal.userActivity.map((activity, index) => <li key={`activity-${index}`}>{activity}</li>)}
            </ul> */}
      </Box>
    );
  }

  export default ConnectedUsers;