import React, {forwardRef, useState, useEffect, useReducer, createContext} from 'react'; 
import { useSelector } from "react-redux";

import { useSearchParams } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import Box from '@mui/material/Box';
import {PageBase, PageTitle, SearchBox} from '../../components'; 
import {useTabs} from '../../utils' ;
import TaskList from './TaskList';
import TaskTopicBox from './TaskTopicBox';
import TaskEditModal from './TaskEditModal';
import {UserSearchBox} from '../Settings/Users/UserSearchBox';
import ActivityOpenClose  from '../Activities/ActivityOpenClose';
import {options_task_status} from '../../appConfig';

export const ParamsContext = createContext(null);

function paramsReducer (state, action) {
    switch (action.type) {
        case 'setReload':
            state.reload = action.reload;
            return {...state, reload: action.reload}
            
        case 'setNum':
            state.num = action.num;
            return {...state, num: action.num};

        default:
            return state;
            //throw new Error();
    }
}



const IndexPage = forwardRef( ({ board={}, isTabSelected }, ref) => {
    const currentUser = useSelector(state => state.currentUser);
    let [searchParams] = useSearchParams();
    
    const [query, setQuery]   = useState(searchParams.get("query")  || {board: {}, topic: "-1", users:"-1", status: 'PENDIENTE'});
     const [edit, setEdit] = useState(undefined)
    const [reload, setReload]   = useState(false);
    const [params, dispatchParams] = useReducer(paramsReducer, {list:[], num:0, reload:false});

    const {appendTab} = useTabs({
        onReload: (reload) => {
            debugger
            setReload(reload)
        }
    });

    const limit = 100;
    
    const options_status = [{label: "TODOS", value: "-1"}].
       concat(options_task_status.map(it => {return {label: it, value: it}}));

    useEffect( () => {
        debugger
        if (isTabSelected === true) {
            // setReload(true)
            dispatchParams({type: 'setReload', reload: true})
        }
    }, [isTabSelected]);

    useEffect( () => {
        if (board && Object.keys(board).length) {
            debugger
            setQuery(st => ({...st, board: board._id}))
        }
    }, [board])

    const handleChange = (fld) => ev => {
        const valor = ev.target.value;
        setQuery( o => ({...o, [fld]: valor}) )
    }
    const handleClick= tipo => item => (ev) => {
        debugger
        ev.preventDefault();
        if (item.id) appendTab( tipo )(item)
    }
    const handleEdit = (doc) => ev => {
        setEdit(doc._id)
    }
    const handleClose = (doc) => {
        setEdit(undefined)
        setReload(true)
    }

    return (
        <ParamsContext.Provider value={{params, dispatchParams}}>            
            <PageBase
                paperProps={{width:'100%', height: 'calc(100% - 98px)'}} 

                title={
                    <>
                        <PageTitle>Tareas ({params.num})</PageTitle>
                        <TaskTopicBox label="Topico" boardId={board._id} value={query['topic']} onChange={handleChange('topic')}/>
                        <SearchBox label="Filtro" value={query['filter']} onChange={handleChange('filter')}/>
                        <SearchBox label="Status" select options={options_status} value={query['status']} onChange={handleChange('status')}/>
                        <Hidden mdDown>
                            <SearchBox label="Obra" value={query['obras']} onChange={handleChange('obras')}/>
                            <SearchBox label="PV" value={query['pv']} onChange={handleChange("pv")}/>
                            <UserSearchBox label="Responsable" defaultValue={query['users']} onChange={handleChange('users')}/>              
                        </Hidden>
                    </>
                }
                actions={
                    <Box>
                        {/* <Button sx={{m:1, display: 'inline'}} size='small' variant='outlined' onClick={handleClick(tipo)({id: true, _id: 'new'})}>Nuevo</Button>                         */}
                        <ActivityOpenClose app={'fabrica'} withLabel/>
                    </Box>
                }   
        >           
                <TaskList
                    query={query}
                    limit={limit}

                    onClick={handleClick}
                    onEdit={handleEdit}
                />    

                { edit && <TaskEditModal docId={edit} onClose={handleClose}/>}    


            </PageBase>
        </ParamsContext.Provider>
    )
})

export default IndexPage;