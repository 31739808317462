import React, {useState, useEffect} from 'react'
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom'
//import { useSelector } from "react-redux";
import { useTheme } from '@mui/material/styles';
//import { useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import {PageBase, Form} from '../../../components';
import {getAPI, postAPI} from '../../../utils';
//import {roles} from '../../appConfig';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const OrgEdit = ({match, docId, edit=true, title="Edición de empresa", onCancel, onSubmit}) => {
    //const currentUser = useSelector( state => state.currentUser );
    const [doc, setDoc] = useState();
    const [error, setError] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    let id = docId || (match && match.params.id);
    title = id === 'new' ? "Nueva empresa" : title;

    useEffect( () => {
        debugger
        if (!id || id === 'new') return;
        getAPI('/admin/orgs/' + id)
        .then(data => {
            setDoc(data)
            console.log(data)
        });
    }, [id])

    const handleSubmit = data => {
        debugger
      console.log(data);
      if (id === 'new') {
        id = "";
      }
      postAPI('/admin/orgs/' + id, data)
      .then(data => {
          console.log(data)
          enqueueSnackbar("Se guardo el cambio correctamente", {variant: 'info'})
          //   navigate('/admin/orgs', {replace: true})
          if (onSubmit) onSubmit(data)
      })
      .catch(err => {
          debugger
          setError({title: err.message})
      });
    }

    const onSubmitDiff = (data) => {
        console.log("Cambios", data)
    }
    const fields = [
        {
            label: 'Nombre',
            name: 'name',
            rules: {
                //required: "Debe ingresar valor",
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:4}
        },
        {
            label: 'Activo',
            name: 'active',
            options: [
                { value: true, label: "SI" },
                { value: false, label: "NO" },
            ],
            sizes: {xs:4}
        },
    ]

    return (
        <Dialog 
            fullScreen={fullScreen}
            maxWidth="lg"
            open={true} 
            onClose={onCancel}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }} >
                <Toolbar>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{title}</Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onCancel}
                        aria-label="close" 
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>          
            <Box sx={{color:'red'}}>
                {error}
            </Box>
            <Form
                disabled={!edit}
                fields={fields}
                defaultValues={doc}
                onSubmit={handleSubmit}   
                onCancel={onCancel}
                onSubmitDiff={onSubmitDiff}
                actionsComp={ (onSubmit, isValid) => (
                    <Box sx={{textAlign: 'center', margin:'10px'}} >
                        <Button 
                            sx={{minWidth: '100px', marginRight: '10px'}}
                            color='secondary'
                            variant='outlined' 
                            onClick={onCancel}>Cancelar
                        </Button>
                        <Button 
                            sx={{minWidth: '100px'}}
                            disabled={!isValid} 
                            variant="outlined" 
                            onClick={onSubmit}>Guardar
                        </Button>
                    </Box>
                )}      
            />
        </DialogContent>          

    </Dialog>
    );
};

export default OrgEdit