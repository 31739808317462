import React, {useState, useMemo, useCallback, useEffect} from 'react'
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { getAPI, postAPI } from '../../../utils';
import ListaValor from './ListaValorMulti'
import { useUsersChannel } from '../../Layout/WebSocketProvider';

const UnitsAdmin = ({debug=false}) => {
    const [baseUnits, setBaseUnits] = useState([]);
    const [lev1, setLev1] = useState(-1);
    const [lev2, setLev2] = useState(-1);
    const {enqueueSnackbar} = useSnackbar();
    const { reloadConfigs } = useUsersChannel();

    const rows = useCallback( (lev2) => {
        if (lev2 !== undefined) {
            if (!baseUnits || !baseUnits[lev2] || !baseUnits[lev2].units) {
                return []
            }
            return baseUnits[lev2].units;
        } else {
            return baseUnits;
        }
    }, [baseUnits]);

    const handleCellClick = lev => (params, ev, detail) => {
        debugger
        if (lev === 0) {
            setLev1(params.id)
            if (!baseUnits[params.id] || !baseUnits[params.id].units || baseUnits[params.id].units.length === 0) {
                setLev2(-1)
            }    
        }
        if (lev === 1) {
            setLev2(params.id)
        }
    }

    useEffect( () => {
        debugger
        getAPI("/settings/config/units")
        .then( res => {
            setBaseUnits(res ? res.data : [] )
        })
    }, [])

    const handleChange = (lev) => (lista) => {
        debugger
        if (lev === 0) {
            setBaseUnits(lista)
            save(lista)
    
            setLev1(lista[lista.length-1].id);    
        }
        if (lev === 1) {
            debugger
            baseUnits[lev1].units = lista;
            setBaseUnits(baseUnits);
            save(baseUnits)
    
            setLev2(lista[lista.length-1].id);    
        }
    }

    const save = (baseUnits) => {
        debugger
        postAPI("/settings/config/units", {data: baseUnits} )
        .then(res => {
            enqueueSnackbar("Se guardó correctamente", {variant: 'info'})
            reloadConfigs()
        })
        .catch(err => {
            //setError({title: err.message})
            console.log(err)
        });
    };

    const labelMap = [
        [
            {label: 'Unidad Base', fld: 'baseUnit', width: 120}
        ],
        [
            {label: 'Unidad',     fld: 'unit', width: 110},
            {label: 'Conversión', fld: 'multiply', type: 'number', width: 110 },
            {label: 'Precisión',  fld: 'decimal', type: 'number', width: 110 }
        ]
    ]

    return (
        <Box>
            {debug && <div>lev {lev1} {lev2}</div>} 
            {debug && <div> {JSON.stringify(baseUnits[lev1])} </div>}
            <Typography variant='h5' sx={{p:1}}>Modifica la lista de unidades del sistema</Typography>
            <Stack sx={{mt: '20px'}} direction='row'>
                <ListaValor 
                    labelMap={labelMap[0]}   
                    rowsDefaultValue={rows()}         
                    onChange={handleChange(0)} 
                    onCellClick={handleCellClick(0)}
                />
                <ListaValor 
                    width='600px' 
                    labelMap={labelMap[1]}   
                    rowsDefaultValue={rows(lev1)}
                    disabled={lev1 < 0} 
                    onChange={handleChange(1)} 
                    onCellClick={handleCellClick(1)}
                />
            </Stack>
        </Box>
    )
}

export default UnitsAdmin;