import React, {useState, useEffect, useMemo, useCallback} from 'react'
//import { DataGrid } from '../../../components';
import { DataGrid } from '@mui/x-data-grid';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

import { makeStyles } from '@mui/styles';
import { defaultTheme } from '../../../utils';

const useStyles = makeStyles(
    (theme) => ({
      root: {
        display: 'flex',
        flexDirection: 'column',
        height: 400,
        width: '100%',
        //border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`
      }
    }),
    { defaultTheme },
)

const ListaValorMulti = ({labelMap=[], rowsDefaultValue, disabled=false, onChange, onCellClick, width='300px', sx}) => {
    const [error, setError] = useState("");
    const [edit, setEdit] = useState({});
    const [rows, setRows] = useState([]);
    const myClasses = useStyles();

    useEffect( () => {
        setRows(rowsDefaultValue)
        // setEdit({})
    }, [rowsDefaultValue])

    const handleEdit = useCallback((params) => () => {
        const row = params.row;
        setEdit(row);
        setError("")
    }, [])

    const handleDelete = useCallback( (params) => () => {
        const row = params.row;
        const rr = rows.filter(it => it.id !== row.id);
        debugger
        setRows(rr)
        onChange(rr)
    }, [rows, onChange])

    const handleNew = () => {
        debugger
        if (edit.id === undefined) {
            const fld = labelMap[0].fld;
            if (rows.map( it => it[fld]).indexOf(edit[fld]) > -1) {
                setError(labelMap[0].label + " ya existe")
                return
            }    
        }
        setError("");

        let rr;
        if (edit.id === undefined) {

            let mm = -1;
            rows.forEach( it => {if (it.id > mm) {mm = it.id} })
            edit.id = mm + 1;

            rr = [...rows, edit]
        } else {
            rr = rows.filter(it => it.id !== edit.id)
            rr.push(edit)
        }
        setRows(rr)
        onChange(rr)
        setEdit({})
    }
    const handleChange = (fld) => (ev) => {
        const valor = ev.target.value;
        setEdit({...edit, [fld]: valor.toUpperCase()})
    }
    const handleCellClick = (params) => {
        if (onCellClick) onCellClick(params)
    }
    //const getRowClassName = (params) => {
    //    return `super-app-theme--${params.value}`
    //}
    debugger
    const columns = useMemo( () => {
            const c1 = labelMap.map( it => {
                    return {
                        field: it.fld,
                        headerName: it.label,
                        type: it.type || 'string',
                        width: it.width || 100
                    }
            })
            c1.push(
                {
                    field: 'actions',
                    headerName: 'Acciones',
                    type:'actions',
                    getActions: (params) => [
                      <GridActionsCellItem icon={<CreateIcon/>} onClick={handleEdit(params)} label="Edit" />,
                      <GridActionsCellItem icon={<DeleteIcon/>} onClick={handleDelete(params)} label="Delete" />,
                    ],
                    width: 300
                }                                  
            )
            return c1;
    }, [labelMap, handleDelete, handleEdit])

    return (
        <Box sx={{width: width, ...sx}}>
            {labelMap.map( it => {
                return (
                    <TextField 
                        sx={{width: it.width}}
                        label={it.label} 
                        size="small" 
                        value={edit[it.fld] || ""}
                        onChange={handleChange(it.fld)} 
                        error={!!error}
                        helperText={error}
                    />    
                )
            })}
            <Button disabled={disabled} onClick={handleNew}>Guardar</Button>
            
            <DataGrid
                className={myClasses.root}

                //loading={loading} 
                rows={rows} 
                rowCount={rows.length}
                columns={columns}

                //pagination
    
                rowHeight={35} 
                hideFooter={true}

                onCellClick={handleCellClick}
                //getRowClassName={getRowClassName}

                sx={{
                    'root.border': '2px solid red',
                    'root.height': '100vh'
                }}  
            />  
        </Box>
    )
}

export default ListaValorMulti;