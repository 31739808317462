import React from 'react'
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';

import { AutocompleteBase, AutocompleteObjectInput } from '../../../components'

const PartAutocomplete = ({helperText, onInputChange, onClick=()=>{}, ...props}) => {

    const codigo = (it) => {
        if (!it) return "";
        return `[${it.codigo}] ${it.descripcion} / ${it.marca}`;
    }
    const getOptionLabel = (it) => {
        return codigo(it);
    }
    const isOptionEqualToValue = (option, value) => {
        return codigo(option) === codigo(value)
        return option && value && option._id === value._id
    }
    return (
        <Box>
            <AutocompleteBase
                sx={{width: '100%', display: 'inline-block'}}
                label="Articulo"
                autocomplete_method={'/stock/parts/autocomplete'}
                getOptionLabel={getOptionLabel}
                renderOption={(props, option) => <li {...props}>{codigo(option)}</li> }
                isOptionEqualToValue={isOptionEqualToValue}
                disableClearable={false}
                errorText={helperText}
                onInputChange={onInputChange}
                {...props}
            />
        </Box>
    )
}

export default PartAutocomplete;
    
export const PartAutocompleteObjectInput = ({...other}) => {
    return (
        <AutocompleteObjectInput 
            {...other} 
            Component={PartAutocomplete}
        />
    )
}
