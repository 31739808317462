import React, {useState} from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';

import PrintIcon from '@mui/icons-material/Print';

const DialogStyled = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(3),
      //height: '1050px'
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    "@media print": {
        '& .MuiAppBar-root': {
            display: "none"
        },
        '& .MuiTabs-root': {  // hack para que imprima solo una pagina
            display: 'none'
        },
        '& .MuiBackdrop-root.MuiModal-backdrop': {
            backgroundColor: '#fff'
        },
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const PrintPreview = ({component="", ...other}) => {
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(!open)
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handlePrint = () => {
        window.print()
    }
    
    return (
        <>
            <IconButton onClick={handleOpen} {...other}>
                <PrintIcon />
            </IconButton>

            <DialogStyled
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullScreen
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Preview de Impresión
                        </Typography>
                        <Button autoFocus color="inherit" variant='outline' onClick={handlePrint}>
                            Imprimir
                        </Button>
                    </Toolbar>
                </AppBar>

                <DialogContent dividers>
                    {component}
                </DialogContent>

            </DialogStyled>
        </>
    )
}

export default PrintPreview;