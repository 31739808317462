import React, {useState, useEffect, useRef, forwardRef} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import { styled } from '@mui/material/styles';

import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
//import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import { HeaderBar, MessageBox, PrintPreview } from '../../components';

import OpportunityShow from './OpportunityShow';
import OpportunityEdit from './OpportunityEdit';
import OpportunityPrint from './OpportunityPrint';
import OpportunityNewProject from './OpportunityNewProject';
import ActivityOpenClose  from '../Activities/ActivityOpenClose';
import {getAPI, postAPI, useTabs, checkUserRoles, activityLog, deleteAPI} from '../../utils';
import moment from 'moment'

// eslint-disable-next-line
const StatusChange = ({status, ...other}) => {
  const aprobadoBtn = <Button variant='outlined' sx={{marginLeft: '20px', borderColor: 'white', color:'white'}} {...other}>APROBADO</Button>
  const desaprobadoBtn = <Button variant='outlined' sx={{marginLeft: '20px', borderColor: 'white', color:'white'}} {...other}>DESAPROBADO</Button>
  const pedidoBtn = <Button variant='outlined' sx={{marginLeft: '20px', borderColor: 'white', color:'white'}} {...other}>PEDIDO</Button>
  const confirmadoBtn = <Button variant='outlined' sx={{marginLeft: '20px', borderColor: 'white', color:'white'}} {...other}>CONFIRMADO</Button>
  const paraPedirBtn = <Button variant='outlined' sx={{marginLeft: '20px', borderColor: 'white', color:'white'}} {...other}>PARA PEDIR</Button>
  
  if (status === 'PARA PEDIR') {
    return [aprobadoBtn, desaprobadoBtn]
  }
  if (status === 'APROBADO') {
    return [pedidoBtn, paraPedirBtn]
  }
  if (status === 'DESAPROBADO') {
    return [paraPedirBtn]
  }
  if (status === 'PEDIDO') {
    return [confirmadoBtn, paraPedirBtn]
  }
  if (status === 'CONFIRMADO') {
    return [ paraPedirBtn ]
  }
  return ""
}


const OpportunityShowEdit = ({docId, defContact, onReload, ...other}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [edit, setEdit] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [reload, setReload] = useState(false);
    const [ doc, setDoc ] = useState({
      _id: docId,
    });
    // eslint-disable-next-line
    const {appendTab, updateCurrentTab, removeCurrentTab} = useTabs({onReload});
    const currentUser = useSelector(state => state.currentUser);
    const disabled = !checkUserRoles(currentUser, ['local-admin', 'gerencia', 'crm-edit'])
    // const excludedKeys = ['cantidad', 'descripcion', 'dto_profesional', 'exito', 'fecha_cierre', 'fecha_estimada',
    //    'fecha_ganada', 'fecha_ingreso', 'fecha_revision', 'ingreso_por', 'moneda', 'motivo_perdida' , 'observacion', 
    //    'status', 'valor_rep', 'valor', 'archivo_adjunto',  'archivo_adjunto', 'archivo_presupuesto']
    const title = !doc || docId === 'new' ? "Nueva Oppo" : (edit ? "Edición de Oppo "  : "Oportunidad " );

    useEffect( () => {
      setReload(false);
      // reads data to Edit instead of using datagrid row
      if ( docId === 'new' ) return 
      getAPI('/crm/oppos/' + docId)
      .then(data => {
        if (data) setDoc(data)
      })
      .catch(err => {
          console.log(err.message)
          enqueueSnackbar(err.message, {variant: "error"})
        });   
    }, [docId, edit, reload, enqueueSnackbar])

    const handleEdit = () => {
      debugger
      setEdit(!edit)
      if (edit) {
        if (docId === 'new') {
          removeCurrentTab()
        } else {
          updateCurrentTab('OPPO')(doc);
        }
      }
    }

    const handleClose = (doc) => {
      debugger
      setEdit(false)
      if (onReload) onReload();
      if (docId === 'new') {
        // removeCurrentTab()
        updateCurrentTab('OPPO')(doc);
      }
    }

    const handleReload = (ev) => {
      setReload(true)
    }
    
    const handleAddTab = tipo => item => ev => {
        if (item.id) appendTab(tipo)(item)  
    }

    const handleDelete = () => {
      deleteAPI('/crm/oppos/' + docId)
      .then( data => {
        enqueueSnackbar("Se borró el registro", {variant: "info"})
        if (onReload) onReload(true)
        removeCurrentTab();
      })
      .catch(err => {
        enqueueSnackbar(err.message, {variant: 'error'})
      });
    }

    const projectFromOppo = (doc) => {
      debugger
      return (
        {
          status: 'SP',
          nombre: doc.nombre,
          oportunidad: {_id: doc._id},
          cliente: {_id: doc.contacto._id},
          direccion: doc.direccion,
          ciudad: doc.ciudad,
          lote: doc.lote,
          localidad: doc.localidad,
          partido: doc.partido,
          provincia: doc.provincia,
          pais: doc.pais,
          color: doc.color,
          fecha_c90: null,
          fecha_cobrado: null,
          fecha_colocacion: null,
          fecha_fabricacion: null,
          fecha_ingreso: new Date(),
          fecha_medicion: null,
          fecha_terminacion: null,
          ventanas: doc.cantidad,
          ml: doc.ml,
          m2: doc.m2,
          marca: doc.marca,
          material: doc.material,
          mosquiteros: doc.mosquiteros,
          contramarcos: doc.contramarcos,
          cortinas: doc.cortinas,
          premarcos: doc.premarcos,
          presupuesto: doc.presupuesto,
          distributorId: doc.distributorId,
          terminacion: doc.terminacion,
          tipo_obra: doc.tipo_obra,
          ownerId: doc.ownerId,
          vidrios: doc.vidrios,
          archivo_foto: [],
          archivo_medicion: [],
          archivo_presupuesto: [],
        }
      )        
    } 

    const handleDialog = async (valor, projectId) => {
      setDialog(false)
      debugger
      if (valor) {
        const obra = await createProject()
        if (obra) {
          changeStatus("CERRADA", {fecha_cierre: new Date(), obra: {_id: obra._id}})
        }
      }
      if (projectId) {
        changeStatus("CERRADA", {fecha_cierre: new Date(), obra: {_id: projectId}})
      }
    }

    const createProject = async () => {
      debugger
      let id = "";
      return await postAPI('/project/' + id, projectFromOppo(doc))
                  .then(data => {
                      const {nombre, carpeta} = data.doc;
                      // const nombre = data.razon_social ? "[" + data.codigo_proveedor + "] " + data.doc.razon_social : data.nombre + " " + data.apellido;
                      const msg = "Se creó una nueva Obra"
                      activityLog({label: nombre, app:'obras', id, msg, currentUser})          
                      enqueueSnackbar("Se generó nueva Obra " + carpeta + " correctamente", {variant: 'info'});
                      return data.doc;
                  })
                  .catch(err => {
                      enqueueSnackbar(err.message, {variant: 'error'})
                  });
    }  

    const handleChangeStatus = (newStatus) => ev => {
      ev.preventDefault();

      if(newStatus === "CERRADA") {
        setDialog(true)
        return
      } 
      if (newStatus === 'EN TRATATIVA' && doc.para_aprobar.length === 0) {
        enqueueSnackbar("No se puede cambiar de estado hasta que se cargue un presupuesto Para Aprobar", {variant: 'error'});
        return
      }

      let extra = null;
      if (newStatus === 'GANADA') {
        extra = 'fecha_ganada';
      }
      if (newStatus === 'PERDIDA') {
        extra = 'fecha_ganada';
      }
      if (newStatus === 'CERRADA') {
        extra = 'fecha_cierre';
      }
      if (extra) {
        doc[extra] = new Date()
        setDoc(doc)
        changeStatus(newStatus, {[extra]: new Date()})
      } else {
        changeStatus(newStatus)         
      }                
    }
    
    const changeStatus = (newStatus, extra={}) => {
      postAPI('/crm/oppos/' + docId, {status: newStatus, ...extra})
      .then(data => {
        const msg = `Se cambió el estado de ${doc.status} a ${newStatus}"`
        activityLog({label: doc.nombre, app:'comercial', docId, msg, currentUser})

        enqueueSnackbar("Se guardó el cambio correctamente", {variant: 'info'})
        setReload(true)

        updateCurrentTab("OPPO")({...doc, status: newStatus, ...extra})
        if (onReload) onReload()
      })
      .catch(err => {
          enqueueSnackbar(err.message, {variant: 'error'})
      });
    }

    const statusButtonBar = [
      <Box sx={{display: 'inline'}} >
        { doc.status === 'INGRESADA'      && <Button color='yellow' onClick={ handleChangeStatus("A PRESUPUESTAR")} size='small' variant='contained'>A PRESUPUESTAR</Button>}
        { doc.status === 'A PRESUPUESTAR' && <Button color='green'  onClick={ handleChangeStatus("EN TRATATIVA")} size='small' variant='contained'>EN TRATATIVA</Button>} 
        { doc.status === 'EN TRATATIVA'   && <Button color='green'  onClick={ handleChangeStatus("GANADA")} size='small' variant='contained'>GANADA</Button>} 
        { doc.status === 'EN TRATATIVA'   && <Button color='red'    onClick={ handleChangeStatus("PERDIDA")} size='small' variant='contained'>PERDIDA</Button>} 
        { doc.status === 'EN TRATATIVA'   && <Button color='yellow' onClick={ handleChangeStatus("A PRESUPUESTAR")} size='small' variant='contained'>A PRESUPUESTAR</Button>}
        { doc.status === 'GANADA'         && <Button color='green'  onClick={ handleChangeStatus("CERRADA")} size='small' variant='contained'>CERRADA</Button>}                
        {/* { doc.status === 'GANADA'         && <Button color='red'  onClick={ handleNewProject} size='small' variant='contained'>PRUEBA</Button>}                   */}
      </Box>
    ]
    
    const info = (
      <span>
        <Box sx={{display: 'inline-block'}}>
          Exito ({doc.exito}%) estimado para el {moment(doc.fecha_estimada).utc().format('YYYY-MM-DD')} &nbsp; /
          Revision {doc.fecha_revision ? moment(doc.fecha_revision).utc().format('YYYY-MM-DD') : 'SIN'}
        </Box>, 
        <Box sx={{float:'right', "& a": {color: '#ddd'}}}>
            {doc.status === 'CERRADA' && doc.obra && (
              <Link href="#" onClick={handleAddTab('OBRA')(doc.obra)}>OBRA {doc.obra.nombre} [{doc.obra.carpeta}]</Link> 
            )}
        </Box>
      </span>
    )

    return (
        <Paper 
          className='MuiOppo-root'
          sx={{
              width: '100%',
              height: '100%',
              // padding: 1, 
          }}
          {...other}
        >
          <HeaderBar 
          
            title={[
              // <Box sx={{display: 'inline-block'}}>Partido/Provincia: [{doc.partido} / {doc.provincia}] </Box>,
              <Box key='1'>
                {title} 
                <Box sx={{float:'right', display: 'inline'}}>
                      { !edit && !disabled && docId !== 'new' && statusButtonBar }
                      <Button autoFocus color="inherit" sx={{marginTop:'3px'}} disabled={disabled} onClick={handleEdit} size='small' variant="outlined">{!edit && docId !== 'new'  ? "Editar" : "Cancelar"}</Button>
                      {edit && docId !== 'new' && <Button autoFocus color="inherit" sx={{marginTop:'3px'}} disabled={disabled} onClick={handleDelete} size='small' variant="outlined">Borrar</Button>}
                      {!edit && docId !== 'new' && <PrintPreview sx={{ color: '#ddd'}} component={<OpportunityPrint doc={doc}/>}/>}
                      <ActivityOpenClose sx={{color: '#ddd'}} app='comercial' docId={docId}/>
                </Box>

                { docId !== 'new' && 
                  <Typography sx={{ color: 'white', display: 'inline'}} variant='h6'>
                    {doc.nombre} &nbsp;

                    <Hidden smDown="2">
                      por {doc.valor} {doc.moneda} &nbsp;
                      (
                        {doc.status}
                        {doc.status === 'GANADA' && " el " + moment(doc.fecha_ganada).utc().format('YYYY-MM-DD') }
                        {doc.status === 'CERRADA' && " el " + moment(doc.fecha_cerrada).utc().format('YYYY-MM-DD') }
                        {doc.status === 'PERDIDA' && " el " + moment(doc.fecha_perdida).utc().format('YYYY-MM-DD') }
                      )
                      &nbsp; 
                    </Hidden>
                  </Typography>   
                  
                } 
              </Box>
            ]}

            info={ !edit && <Hidden smDown={2}>{info}</Hidden>}

            // actions={[
            //   <Button autoFocus color="inherit" disabled={disabled} onClick={handleEdit}>{!edit ? "Editar" : ""}</Button>,
            // ]}

          />
          <OpportunityNewProject
            open={dialog} 
            onCancel={() => setDialog(false)}
            onNew={(v) => handleDialog(v)}
            onAsociate={(v) => handleDialog(undefined, v)}
          />

          { !edit && docId !== 'new' && 
            <OpportunityShow 
              className='MuiOppo-detail'
              doc={doc} 
              withHeader={false}
              onReload={handleReload} 
            /> 
          }

          { (edit || docId === 'new') && 
            <OpportunityEdit 
              className='MuiOppo-detail'
              edit={edit || docId === 'new'}
              docId={docId} 
              defContact={defContact}
              onAfterSubmit={handleClose}
              onCancel={handleEdit}
          /> 
          }
        </Paper>
  )
}

const OpportunityShowEditStyled = styled(OpportunityShowEdit)(
  ({ theme }) => ({
    "& .MuiOppo-detail": {
      overflow:'auto',
      height: 'calc(100% - 200px)',
      padding: '10px',
    },
    "& .MuiButton-root": {
      marginLeft: "4px",
      [theme.breakpoints.down('md')]: {
          lineHeight: 1,
          fontSize: "11px",
          padding: '4px 4px',
          fontWeight: 800,
      },
    }
  })
)

export default OpportunityShowEditStyled
