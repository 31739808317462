import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinkIcon from '@mui/icons-material/Link';

import MeasurementTable from './MeasurementTable';
import FileList from '../Attachments/FileList';
import WorkOrderTable from '../Fabrica/WorkOrderTable';
import TasksTable from '../Task/TasksTable';
import { Attributes } from '../../components';

import moment from 'moment'


const AttributesStyled = styled(Attributes)(({ theme }) => ({
    '.MuiAttributes-title': {
        borderBottom: '1px solid'
    },
    "@media print": {
        "@page": {
            "size": "portrait",
            "margin": "10mm 5mm 5mm 5mm",
            "mso-header-margin":"0mm",
            "mso-footer-margin":"0mm",
            "mso-paper-source":0,
        },
        '.MuiAttributes-label': {
            fontSize: '9px'
        },
        '.MuiAttributes-text': {
            fontSize: '10px'
        },
        '.MuiAttributes-title': {
            fontSize: '11px'
        },
        '.MuiChip-label': {
            fontSize: '11px'
        },
        'ul, ul li, table td, table th': {
            fontSize: "10px"
        },
    }
}));

const ProjectPrint = ({doc}) => {

    const getDireccion = () => {
        let out = ""
        if (doc.barrio) {
            out = doc.barrio + " " + doc.lote + ", "
        }
        out += (doc.direccion || "SIN")
        out += "-" + (doc.partido  || "SIN")
        out += "-" + (doc.localidad )
        out += "-" + (doc.provincia  || "SIN")
        out += "-" + (doc.pais  || "SIN")
        return out
    }
    const getContact = (fld) => {
        if (!doc || !doc[fld]) return "SIN";
        return doc[fld].fullname            
    }
    const getVendedor = () => {
        if (doc.ownerId) {
            return doc.ownerId.initials
        }
    }
    const getOppos = (opos) => {
        return opos.map(it => it.presupuesto + " ")
    }

    return (
        <>
            <Typography sx={{mb:1}} variant='h5'>Obra [{doc.carpeta}] {doc.nombre}</Typography>

            <AttributesStyled
                sizes={{xs: 2}}
                list={
                    [
                        // {title: 'DETALLE'},
                        {title: 'DESCRIPCION'},
                        {label: 'Descripción',  text: (doc.descripcion || 'SIN'), sizes: {xs:4}},
                        {label: 'Estado',  text: doc.status},

                        {label: 'Direccion',     text: getDireccion(), sizes: {xs:4}},

                        {},
                        {label: 'Vendedor',     text: getVendedor()},
                        {label: 'Distribuidor',        text: doc.distributorId && doc.distributorId.codigo},
                        {label: 'Presupuesto',  text: doc.presupuesto},
                        {label: 'Oportunidades',  text: getOppos(doc.oppos), sizes: {xs:4}},

                        {title: 'CONTACTOS'},
                        {label: 'Cliente',           text: getContact('cliente')},
                        {label: 'Arquitecto',        text: getContact('arquitecto')},
                        {label: 'Emp. Constructora', text: getContact('emp_constructora')},
                        {label: 'Contacto Obra',     text: getContact('contacto_obra')},
                        {},

                        {title: 'CARACTERISTICAS'},
                        {label: 'Tipo Obra',    text: doc.tipo_obra,    },
                        {label: 'Cantidad',     text: doc.ventanas,     },
                        {label: 'ML',           text: doc.ml,           },
                        {label: 'M2',           text: doc.m2,           },
                        {label: 'Ups',           text: doc.ups ? doc.ups.toString() : ""},
                        {},
                        {label: 'Vidrios',      text: doc.vidrios || "NO", },
                        {label: 'Material',     text: doc.material,     },
                        {label: 'Marca',        text: doc.marca,        },
                        {label: 'Terminacion',  text: doc.terminacion,  },
                        {label: 'Color',        text: doc.color,        },
                        {},
                        {label: "Premarcos",    text: doc.premarcos || "NO",    sizes: {xs:2}},
                        {label: "Contramarcos", text: doc.contramarcos || "NO", sizes: {xs:2}},
                        {label: "Mosquiteros",  text: doc.mosquiteros || "NO",  sizes: {xs:2}},
                        {label: "Cortinas",     text: doc.cortinas || "NO",     sizes: {xs:2}},
                        {},
                        {},
                        {label: 'Observación',  text: doc.observacion || 'SIN', sizes: {xs: 8}},
                        {},
                        {title: 'FECHAS'},
                        {label: 'Fecha Ingreso',   text: doc.fecha_ingreso ? moment(doc.fecha_ingreso).utc().format('YYYY-MM-DD') : "SIN"},
                        {label: 'Fecha Medicion',   text: doc.fecha_medicion ? moment(doc.fecha_medicion).utc().format('YYYY-MM-DD') : "SIN"},
                        {label: 'Fecha Fabricacion',   text: doc.fecha_fabricacion ? moment(doc.fecha_fabricacion).utc().format('YYYY-MM-DD') : "SIN"},
                        {label: 'Fecha Colocacion',    text: doc.fecha_colocacion ? moment(doc.fecha_colocacion).utc().format('YYYY-MM-DD') : "SIN"},
                        {label: 'Fecha Terminacon',   text: doc.fecha_terminacion ? moment(doc.fecha_terminacion).utc().format('YYYY-MM-DD') : "SIN"},
                        {},
                        {label: 'Fecha Cobrado',   text: doc.fecha_cobrado ? moment(doc.fecha_cobrado).utc().format('YYYY-MM-DD') : "SIN"},
                        {label: 'Fecha C90',   text: doc.fecha_c90 ? moment(doc.fecha_c90).utc().format('YYYY-MM-DD') : "SIN"},
                        {},
                        {},
                        {},

                        {title: 'ARCHIVOS'},        
                        {   label: 'Presupuesto',
                            children: <FileList 
                                        folder="/comercial/pres"
                                        docs={doc.archivo_presupuesto} 
                                    />,
                            sizes: {xs:3}
                        },
                        // {   label: 'Medicion',
                        //     children: <FileList 
                        //                 folder="/medicion"
                        //                 docs={doc.archivo_medicion} 
                        //             />,
                        //     sizes: {xs:3}
                        // },
                        {   label: 'Foto',          
                            children: <FileList 
                                        folder="/foto"
                                        docs={doc.archivo_foto} 
                                      />, 
                            sizes: {xs:3}
                        },

                        {title: 'PLANILLAS', sx:{"page-break-before": "always"}, sizes:{xs: 12}},
                        {label: "", text: <MeasurementTable editable={false} obraId={doc._id} docs={doc.measurements}/>, sizes:{xs: 12}}, 

                        {title: 'FABRICACION', sx:{"page-break-before": "always"}, sizes:{xs: 12}},
                        {label: '', text: <WorkOrderTable tipo='fabrica' obraId={doc._id}/>, sizes:{xs: 12}},
                        {title: 'SERVICIOS', sizes:{xs: 12}},
                        {label: '', text: <WorkOrderTable tipo='servicio' obraId={doc._id}/>, sizes:{xs: 12}},

                        {title: 'TAREAS', pageBreakBefore: true, sizes:{xs: 12}},
                        {label: '', text: <TasksTable hideEdit reference={{field:'obra', id:doc._id}}/>, sizes:{xs: 12}}
                        

                    ]
                }
            />
            <Typography sx={{float:'right'}} variant="caption">Actualizado el {moment(doc.updated_at).utc().format('YYYY-MM-DD')}</Typography>

        </>


    )
}

export default ProjectPrint;