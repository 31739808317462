import React from 'react'
import Box from '@mui/material/Box';

const TabPanel = (props) => {
    const { children, value, index, sx, ...other } = props;
  
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        sx={{
            overflow: 'auto', height: '100%', ...sx
        }}
        {...other}
      >
        {value === index && (
          <Box sx={theme => ({ 
                    p: 2, 
                    height: '100%',
                    [theme.breakpoints.down('md')]: {
                        p:0
                    }}) 
                }
            >
            {children}
          </Box>
        )}
      </Box>
    );
}

export default TabPanel