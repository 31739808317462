import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Form } from '../../../components'
import { getAPI, postAPI } from '../../../utils';
import { useUsersChannel } from '../../Layout/WebSocketProvider';

const OperationForm = ({editDocId, onClose}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ doc, setDoc ] = useState({});
    const [ optAlm, setOptAlm] = useState([]);
    const [ optCod, setOptCod] = useState([]);
    const [ currCodigo, setCurrCodigo] = useState([])
    const { reloadConfigs } = useUsersChannel();

    const limit = 10000;

    useEffect( () => {
      getAPI('/settings/warehouses/', {filter:'', skip: 0, limit})
      .then(data => {
          setOptAlm(data.list)
      })
      .catch(err => {
          console.log(err.message)
          enqueueSnackbar(err.message, {variant: "error"})
      });   
    },[enqueueSnackbar]);  

    useEffect( () => {
      getAPI('/settings/operations/', {filter:'', skip: 0, limit})
      .then(data => {
          var optCod = data.list.map( it => it.codigo).sort();
          setOptCod(optCod);
      })
      .catch(err => {
          debugger
          console.log(err.message)
          enqueueSnackbar(err.message, {variant: "error"})
      });   
    },[enqueueSnackbar]);  

    useEffect( () => {
        debugger
        if (editDocId === 'new') return 
        getAPI('/settings/operations/' + editDocId)
        .then(data => {
            setDoc(data)
            setCurrCodigo(data.codigo)
        })
        .catch(err => {
            debugger
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });   
      }, [editDocId, enqueueSnackbar])
      
    const handleSubmit = (data) => {
        debugger
        let id = editDocId;
        if (id === 'new') {
            id = "";
            delete data._id
        }
        data.almacen = typeof(data.almacen) === 'string' ?  {_id: data.almacen} : data.almacen
        postAPI('/settings/operations/' + id, data)
        .then(res => {
            console.log(res)
            enqueueSnackbar("Se guardo el cambio correctamente", {variant: 'info'});
            reloadConfigs();
            onClose(res)
        })
        .catch(err => {
            debugger
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });    
    }
    debugger
    var optionsAlmacenes = optAlm.map(it => ({label:it.codigo, value: it._id }));

    const fields =  [
        {
            label: 'Almacen',
            name: 'almacen._id',
            options: optionsAlmacenes,   
            rules: {
              validate: {
                  exists: (v) => !!v || 'Es obligatorio',
              }
            },
            sizes: {xs:4},
        },
        {
            label: 'Codigo',
            name: 'codigo',
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                    inlist: (v) => editDocId !== 'new' || !optCod.includes(v) || 'Codigo ya existe',
                    edit: (v) => editDocId === 'new' || v === currCodigo || !optCod.includes(v) || 'Codigo ya existe'
                  }
            },
            sizes: {xs:4},
        },
        {
          label: 'Descripción',
          name: 'descripcion',
          rules: {
              validate: {
                  exists: (v) => !!v || 'Es obligatorio',
              }
          },
          sizes: {xs:4},
        },
        {
            label: 'Es Servicio?',
            name: 'es_servicio',
            type: "boolean",
            sizes: {xs:4},
        },
    ]
  
    return ( 
        <Box>
            <Form 
                fields={fields}
                defaultValues={doc}
                onSubmit={handleSubmit}   
                //onSubmitDiff={onSubmitDiff}
                actionsComp={ (onSubmit, isValid) => (
                    <Box sx={{textAlign: 'center', margin:'10px'}}>
                        <Button 
                            sx={{minWidth: '100px', marginRight: '10px'}}
                            color='secondary'
                            variant='outlined' 
                            onClick={onClose}>Cancelar</Button>
                        <Button 
                            sx={{minWidth: '100px'}}
                            variant="outlined" 
                            disabled={!isValid} 
                            onClick={onSubmit}>Guardar</Button>
                    </Box>
                    )                    
                }
                debug={false}
            />
        </Box>
    )
}
  
export default OperationForm;