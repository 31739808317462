import React from 'react';
//import { useNavigate } from 'react-router-dom';
//import {useSelector} from "react-redux";

import StackMenu from './StackMenu'

import InboxIcon from "@mui/icons-material/Inbox";
import ExtensionIcon from "@mui/icons-material/Extension";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ContentCut from "@mui/icons-material/ContentCut"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

const menuConfig = [
    { button: "dashboard", icon: <InboxIcon/>, menu: [
            { text: 'Users', icon: <AccountBalanceIcon/> , link: '/users',  roles: ['admin'] },
            { text: 'Contactos', icon: <AssessmentIcon/> , link: '/contacts',  roles: ['admin'] },
            { text: 'Test', icon: <AccountBoxIcon/> , link: '/test',  roles: [] },
      ]
    },
    { button: "config", menu: [
        { text: 'Users', icon: <ContentCut/> , link: '/users',  roles: ['admin'] },
        { text: 'Contactos', icon: <AssessmentIcon/> , link: '/contacts',  roles: ['admin'] },
        { text: 'Test', icon: <AccountBoxIcon/> , link: '/test' },
      ]
    },
    { button: "account", icon: <ExtensionIcon/>, roles: ['admin'], menu: [
        { text: 'Users', icon: <AccountBoxIcon/> , shortcut: "⌘X", link: '/users',  roles: ['admin'] },
        { text: 'Settings', icon: <SettingsIcon/> , shortcut: "⌘X", link: '/contacts',  roles: ['admin'] },
        { text: 'Logout', icon: <LogoutIcon/> , shortcut: "⌘X", link: '/test',  roles: ['admin'] },
      ]
    }
]

const MenuExample = () => {
    return (
        <StackMenu menu={menuConfig}/>
    )
}
export default MenuExample;