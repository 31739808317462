import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { appReduxStore, getAPI } from '../../utils'
import { useChannel } from './WebSocketProvider'

const Logout = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [isReady, lastVal, sendMessage] = useChannel('usersChannel');

    debugger
    useEffect( () => {
        getAPI('/logout' )
        .then(res => {
            appReduxStore.dispatch({
                type: "LOGOUT",
            })
            debugger 
            sendMessage({disconnect: 1})
            enqueueSnackbar("Se desloggeo correctamente", {variant: 'info'})

            navigate('/login')
        })
        .catch(err => {
            console.log(err)
        });
    }, [enqueueSnackbar, navigate])
    
    return (
        <div> Salio de la app </div>
    )
}

export default Logout