import React, {useState, useMemo, useCallback, useEffect} from 'react'
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { getAPI, postAPI } from '../../../utils';
import ListaValor from './ListaValor'
import ListaValorMulti from './ListaValorMulti'
import { useUsersChannel } from '../../Layout/WebSocketProvider';

const ExtraDataAdmin = ({title='', label='Tipo', table='', width=100, debug=false, ...other}) => {
    const [lista, setLista] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const { reloadConfigs } = useUsersChannel();

    useEffect( () => {
        debugger
        getAPI("/settings/config/" + table)
        .then( res => {
            setLista(res ? res.data : [] )
        })
    }, [])

    const handleChange = (lista) => {
        debugger
        setLista(lista)
        save(lista)
    }

    const save = (lista) => {
        debugger
        postAPI("/settings/config/" + table, {data: lista} )
        .then(res => {
            enqueueSnackbar("Se guardó correctamente", {variant: 'info'})
            reloadConfigs()
        })
        .catch(err => {
            //setError({title: err.message})
            console.log(err)
        });
    };

    const labelMap = [
        {label, fld: 'name', width},
    ]

    return (
        <Box {...other}>
            <Typography variant='h5' sx={{p:1}}>{title}</Typography>
            <Stack sx={{mt: '20px'}} direction='row'>
                <ListaValorMulti
                    sx={{mr:1, ml:1, width: '100%'}}
                    label={label}
                    labelMap={labelMap}   
                    rowsDefaultValue={lista}         
                    onChange={handleChange} 
                    // onCellClick={handleCellClick}
                />
            </Stack>
        </Box>
    )
}

export default ExtraDataAdmin;