Array.prototype.sortBy = function(fld) {  
    debugger
    let flds = fld;
    if (typeof fld !== 'object') {
        flds = [fld]
    }
    const getKey = (a) => {
        const out = flds.map(it => a[it]);
        const out1 = out.join('|')
        return !!out1 ? out1.toLowerCase() : "";
    }
    this.sort((a,b) => {
        const x = getKey(a);
        const y = getKey(b);
        if (x<y) return -1;
        if (x>y) return 1;
        return 0;    
    });


    return this;
}