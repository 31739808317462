import React, {useState} from 'react';
import { useTheme } from '@mui/material/styles';
//import { useSelector } from "react-redux";
//import { useParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ContactForm from './ContactForm';
//import {checkUserRoles} from "../../utils";
//import { FaEdit } from 'react-icons/fa';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDialog({tipo, editDoc, onClose, provee, disabled})  {
    debugger
    const [edit, setEdit] = useState(editDoc && editDoc._id && editDoc._id === 'new');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    //const currentUser = useSelector(state => state.currentUser);
    const tipo_registro = provee === '1' ? "Proveedor" : "Contacto";
    const title = editDoc && editDoc._id === 'new' ? 'Nuevo ' + tipo_registro : 
      ((edit) ? 'Edición de '+tipo_registro : tipo_registro);

    const handleEdit = () => {
      setEdit(!edit)
    }
    const handleClose = (res) => {
      setEdit(false)
      if (onClose) { onClose(res) }
    }
    const handleCancel = () => {
      setEdit(false)
      if (editDoc._id === 'new' && onClose) {onClose()}
    }

    return (
      <Dialog 
        fullScreen={fullScreen}
        maxWidth="lg"
        open={true} 
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }} >
            <Toolbar>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">{title}</Typography>
                <Button autoFocus color="inherit" onClick={handleEdit} disabled={disabled}>
                    {!disabled &&  (!edit ? "Editar" : "Cancelar") }
                </Button>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close" 
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>

        <DialogContent>          
          <ContactForm tipo={tipo} disabled={!edit || disabled} provee={provee} 
              docId='new' onClose={handleClose} onCancel={handleCancel}/>
        </DialogContent>
      </Dialog>
  )
}

