import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { postAPI, reconnected } from '../../utils'
import Cookies from 'js-cookie';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import Box from '@mui/material/Box';


const NotAccesible = ({match, title, defaultLocation="/"}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar()

  const cook = Cookies.get('org');

  return (
    <Box sx={{
        overflow: 'auto',
        //minWidth: 320,
        maxWidth: 500,
        bgcolor: 'background.paper',
        height: 'auto',
        position: 'absolute',
        top: '20%',
        left: 0,
        right: 0,
        margin: 'auto',
        borderRadius: '12px',
        boxShadow: 1,
    }}>
        <Card sx={{
            textAlign:'center',
        }}>
            <CardHeader 
                title={ "Sección no accesible" }
            />

            <CardContent>
                Lo siento pero no tiene permisos suficientes para acceder a este módulo ! 
                Consulte con su administrador local.
            </CardContent>

            <CardActions sx={{display: 'block'}}>
    
            </CardActions>

        </Card>
    </Box>
  )
}

export default NotAccesible

export const NotAccesiblePage = ({...other}) => {
    return (
            <NotAccesible
                withRemember withForgot
                defaultLocation={'/dashboard'}
                title={"Ingreso a APP"}
                {...other}
            />
    )
}

