import React, {useState, useEffect} from 'react'; 
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import {PageBase, DataGrid, SearchBox, PageTitle} from '../../../components'; 
import {getAPI, deleteAPI} from '../../../utils' ;
import { useUsersChannel } from '../../Layout/WebSocketProvider';

const columns = (onEdit, onDelete) => ([
    {
        field: '_id',
        headerName: 'Id',
        sortable: false,
        width: 170,
    },
    {
        field: 'name',
        headerName: 'Nombre',
        sortable: false,
        width: 200,
    },
    {
        field: 'data',
        headerName: 'Data',
        valueGetter: (params) => {
            const art = params.row.data
            if (art) return JSON.stringify(art) 
            return ""
        },
        sortable: false,
        width: 220,
    },
    {
        field: 'repeatInterval',
        headerName: 'Intervalo',
        type: 'string',
        width: 200,
    },
    {
        field: 'lastRunAt',
        headerName: 'Ultimo',
        type: 'string',

        sortable: false,
        width: 220,
    },
    {
        field: 'nextRunAt',
        headerName: 'Siguiente',
        type: 'string',
        sortable: false,
        width: 220,
    },
    {
        field: 'actions',
        headerName: 'Acciones',
        type:'actions',
        getActions: (params) => [
        //   <GridActionsCellItem icon={<CreateIcon/>} onClick={onEdit(params)} label="Edit" />,
        //   <GridActionsCellItem icon={<DeleteIcon/>} onClick={onDelete(params)} label="Delete" showInMenu />,
        ]
    }
])

const IndexPage = () => {
    debugger
    const { enqueueSnackbar } = useSnackbar();
    let [searchParams] = useSearchParams();
    const [filter, setFilter]   = useState(searchParams.get("filter") || "");
    const [editDocId, setEditDocId] = useState("");
    const [reload, setReload]   = useState(false);
    const {reloadConfigs} = useUsersChannel();

    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;
    
    useEffect( () => {
        setReload(false)
        setLoading(true)
        getAPI('/settings/sessions/', {filter, skip: page*limit, limit})
        .then(data => {
            setLoading(false);
            data.list.forEach( it => it.id = it._id);
            setNum(data.num)
            setRows(data.list)
        })
        .catch(err => {
            setLoading(false);
            console.error(err)
            enqueueSnackbar(err.message, {variant: "error"})
        });

    }, [page, filter, reload, enqueueSnackbar])

    const handleFilter = (ev) => {
        setFilter(ev.target.value)
    }

    return (
        <PageBase title={
                    <>
                        <PageTitle>Sesiones ({num})</PageTitle> 
                        <SearchBox label="Filtro" value={filter} onChange={handleFilter}/>
                    </>
                }
                actions={
                    <>
                        {/* <Button sx={{m:1}} size='small' variant='outlined' onClick={handleNew}>Nuevo</Button> */}
                    </>
                }
                    
       >
            <DataGrid
                loading={loading} 
                rows={rows} 
                rowCount={num}
                columns={columns()} 

                pagination
                paginationMode="server"
                pageSize={limit}
                onPageChange={(newPage) => setPage(newPage)}
    
                rowHeight={35} 
                hideFooter={false} 
                sx={{
                    'root.border': '2px solid red',
                    'root.height': '100vh'
                }}
              
            />    

        </PageBase>
    )
}

export default IndexPage;