// ONLY CLIENT
import { createElement } from 'react';

import _ from 'underscore'

// convierte a array un hash con un mapa
// mapa = ["fld1 LABEL1", "fld2 LABEL2"]
// export const object_to_array = (its, mapa) => {
//   var arr=[], flds = [], line = [];
//   _.each( mapa, function(inx, it) {
//     var el= it.split(' ');
//     if (el[1])
//       line.push( el[1] );
//     if (el[0])
//       flds.push( el[0] );
//   })
//   arr.push(line);

//   _.each( its, function(inx, it) {
//     var line = [];
//     _.each(flds, function(inx, el) {
//       var b = $.String.getObject(el, it) || "";
//       line.push( b )
//     })
//     arr.push(line)
//   })
//   return arr;
// }

// convierte un string csv a array
//t1 = exporta.csv_to_array("uno||dos||323\ncautro||123||3313",{sep:"||"})
export const csv_to_array= (csv, opts) => {
  opts = opts || {};
  var sep = opts['sep'] || ",";
  var table = [];
  var fline = true;
  csv.split("\n").map( function(cols) {
    var row = [];
    fline = false;
    cols.split(sep).map( function(ele) {
      row.push(ele);
    })
    table.push(row)
  })
  return table;
}

const get_opt = (opts, inx, isHeader=false) => {
  let opt;
  if (isHeader) {
    if (opts.header && opts.header[inx]) {
      opt = opts.header[inx]
    }
  } else {
    if (opts.fields && opts.fields[inx]) {
      opt = opts.fields[inx]
    }
  }
  return opt;
}

// convierte un array csv a table con opts
// opts = { wheader: true|false }
export const array_to_html_table = (arr, opts={}) => {
  var wheader = opts['wheader'] || false;
  var table = createElement("<table>");

  var fline = true;
  arr.map( cols => {
    if (! cols.length == 0)
      cols = [cols];
    var row = (fline && wheader) ? createElement("<tr>") : createElement("<tr>");

    cols.map( function(ele, inx) {
      let e;

      if ( wheader && fline ) {
        e = createElement("<th>", {styles: {height: '30px'}}, ele);
        e.css( {
          'font-size':'10.0pt',
          'font-weight':'700',
          'font-family':'Calibri',
          'mso-generic-font-family':'auto',
          'mso-font-charset':'0',
          'text-align':'center',
          'background': '#D8E4BC',
          'mso-pattern':'black none',
          'white-space':'normal'
        })
        const opt = get_opt(opts, inx, true);
        if (opt) {
          if (opt.attr) {
            e.css(opt.attr[0], opt.attr[1])
          }
          if (opt.css) {
            if (_.isFunction(opt.css)) {
              const xx = opt.css(ele, inx)
              if (xx && xx.length) {
                e.css(xx[0], xx[1])
              }
              if (xx) {
                e.css(xx)
              }
            } else {
              e.css(opt.css[0], opt.css[1])
            }
          }
          if (opt.fun && _.isFunction(opt.fun)) {
            ele = opt.fun(ele);
          }
        }

      } else {
        // OBS es un hack para las columnas que empiezan con ''
        e = createElement("<td>");
        const opt = get_opt(opts, inx, false);
        if (opt) {
          if (opt.attr) {
            e.css(opt.attr[0], opt.attr[1])
          }
          if (opt.css) {
            if (_.isFunction(opt.css)) {
              const xx = opt.css(ele, inx)
              if (xx && xx.length) {
                e.css(xx[0], xx[1])
              }
              if (xx) { // si es object
                e.css(xx)
              }
            } else {
              e.css(opt.css[0], opt.css[1])
            }
          }
          if (opt.fun && _.isFunction(opt.fun)) {
            ele = opt.fun(ele);
          }
        }
        e.html(ele)
      }
      createElement(row).append(e);
    })
    table.append(row)

    fline = false;
  })
  return createElement(table);
}

export const array_to_html = (arr, opts={}) => {
  opts = _.extend({
    th: {
      css: {
        'height': '30px',
        'font-size':'10.0pt',
        'font-weight':'700',
        'font-family':'Calibri',
        'mso-generic-font-family':'auto',
        'mso-font-charset':'0',
        'text-align':'center',
        'background': '#D8E4BC',
        'mso-pattern':'black none',
        'white-space':'normal'
      }
    },
    td: {},
    tr: {},
    table: {}
  }, opts);

  const tag = function(name, localCss, child) {
    let out =  "<" + name;
    let css = opts[name] && opts[name].css ? opts[name].css : {};
    if (css) {
      _.extend(css, localCss);
      console.log('css', name, localCss, css)
      css = _.map(css, (v,k) => {
        return [k,v].join('=')
      }).join('; ')
      out += " style='" + css + "'"
    }
    out += ">"
    out += _.isArray(child) ? child.join(' ') : child;
    out += "</" + name + ">"
    return out;
  }
  const rows = []
  _.each(arr, function(row, inx) {
    if (!_.isArray(row))
      row = [row];
    var cols = [];
    _.each(row,  function(col, inx2) {
      if ( inx == 0 && opts['firstLine'] ) {
        let css = opts['th']
        if (_.isArray( css )) {
          css = css[inx2]
        }
        cols.push( tag('th', css || {}, col) );
      } else {
        let css = opts['td']
        if (_.isArray( css )) {
          css = css[inx2]
        }
        cols.push( tag('td', css || {}, col) );
      }
    })
    rows.push( tag('tr', opts['tr'] || {}, cols));
  })
  return tag('table', opts['table'] || {}, rows)
}

// convierte un string csv a table
// no se usa
export const csv_to_table = (csv, opts) => {
  opts = opts || {};
  var sep = opts['sep'] || ",";
  var wheader = opts['wheader'] || false;
  var table = createElement("<table>");
  var fline = true;
  csv.split("\n").map( function(cols) {
    var row = (fline && wheader) ? createElement("<tr>") : createElement("<tr>");
    fline = false;
    cols.split(sep).map( function(ele) {
      var e = createElement("<td>").html(ele);
      row.append(e);
    })
    table.append(row)
  })
  return createElement("<div>").append(table);
}

export const table_to_excel_fn = () => {
  var uri = 'data:application/vnd.ms-excel;base64,'
    , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
    , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
    , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
    return function(table, sheetName) {
    //var ctx = {worksheet: name || 'Worksheet', table: table.children().html()}
    //window.location.href = uri + base64(format(template, ctx))
    }
}
//table_to_excel = table_to_excel_fn();
//table_to_excel( csv_to_table(res), "asd" )

export const to_mime = (mime, data, filename) => {
  filename = filename || 'download';
  if (mime=='excel') {
    var w = window.open('data:application/vnd.ms-excel,' + encodeURIComponent(data), "_self");
  }
  if (mime=='excelFile') {
    filename = filename + '.xls';
    var csvData = new Blob([data], {type: 'application/vnd.ms-excel;charset=utf-8;'});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(csvData);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  if (mime=='csvFile') {
    filename = filename + '.csv';
    var csvData = new Blob([data], {type: 'text/csv;charset=utf-8;'});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(csvData);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  if (mime=='csv') {
    var w = window.open('data:text/csv,' + encodeURIComponent(data), "_self");
  }
  if (mime=='png') {
    // data es un CANVAS
    var dt = data.toDataURL('image/png');
    /* Change MIME type to trick the browser to downlaod the file instead of displaying it */
    dt = dt.replace(/^data:image\/[^;]*/, 'data:application/octet-stream');

    /* In addition to <a>'s "download" attribute, you can define HTTP-style headers */
    dt = dt.replace(/^data:application\/octet-stream/, 'data:application/octet-stream;headers=Content-Disposition%3A%20attachment%3B%20filename=Canvas.png');

    var link = document.createElement('a');
    link.href = dt;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
