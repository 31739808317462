import React, {useState} from 'react'
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import { AutocompleteBase, AutocompleteObjectInput } from '../../components'

const ProjectAutocomplete = ({withAdd=false, helperText, onInputChange, ...props}) => {
    // eslint-disable-next-line
    const [add, setAdd] = useState(false);

    const obra = (it) => {
        if (!it) return "";
        return "[" + it.carpeta + "] " + it.nombre
    }
    const getOptionLabel = (it) => {
        return obra(it);
    }
    const isOptionEqualToValue = (option, value) => {
        return obra(option) === obra(value)
        //return option && value && option._id === value._id
    }
    return (
        <Box>
            <AutocompleteBase
                sx={{width: (withAdd ? '85%' : '100%'), display: 'inline-block'}}
                label="Obra"

                autocomplete_method='/project/autocomplete'
                //autocomplete_params={{provee}}
                getOptionLabel={getOptionLabel}
                renderOption={(props, option) => <li {...props}>{obra(option)}</li> }
                isOptionEqualToValue={isOptionEqualToValue}
                disableClearable={false}
                errorText={helperText}
                onInputChange={onInputChange}
                {...props}
            />
            {withAdd && <IconButton sx={{verticalAlign: 'bottom'}} onClick={() => setAdd(true)}><PersonAddIcon/></IconButton>}
        </Box>
    )
}

export default ProjectAutocomplete;
    
export const ProjectAutocompleteObjectInput = ({...other}) => {
    return (
        <AutocompleteObjectInput 
            {...other} 
            Component={ProjectAutocomplete}
        />
    )
}

export const ProjectSearchBox = ({...props}) => {
    const obra = (it) => {
        if (!it) return "";
        return "[" + it.carpeta + "] " + it.nombre
    }
    const getOptionLabel = (it) => {
        //return obra(it)
        return it.carpeta !== undefined ? it.carpeta : ""
    }
    const isOptionEqualToValue = (option, value) => {
        return obra(option) === obra(value)
        //return option && value && option._id === value._id
    }
    
    const sx = props['sx'];
    delete props['sx'];

    return (
        <AutocompleteBase
            sx={{
                display: 'inline-block', 
                mr:2, 
                '.MuiInput-root': {
                    paddingBottom:"4px"
                },
                ...sx
            }}
            label="Obra"

            autocomplete_method='/project/autocomplete'
            //autocomplete_params={{provee}}
            getOptionLabel={getOptionLabel}
            renderOption={(props, option) => <li {...props}>{obra(option)}</li> }
            isOptionEqualToValue={isOptionEqualToValue}
            disableClearable={false}
            //errorText={helperText}
            //onInputChange={onInputChange}
            //freeSolo
            variant='standard'
            {...props}
        />
    )
}
