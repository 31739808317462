import React, { useState, useEffect } from 'react';
import {getAPI} from '../../utils';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CircularProgress from '@mui/material/CircularProgress';

import moment from 'moment';

const ItemText = ({doc, withLabel=false}) => {
    const label = withLabel && `[${doc.acerca.label}]`
    return ( 
        <Box>
            <Typography>{label} {doc.msg}</Typography>
            <Typography variant='caption'>Creado por {doc.created_by && doc.created_by.username} el {moment(doc.created_at).format("YYYY-MM-DD HH:mm:SS Z")}</Typography>
        </Box>
    )
}

const ActivityList = ({app, docId, withLabel=false, limit=20}) => {
    const [num, setNum] = useState(0);
    const [docs, setDocs] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect( () => {
        setLoading(true)
        getAPI("/activities", {app, docId, skip: page*limit, limit})
        .then( data => {
            setDocs(data.list)
            setNum(data.num)
            setLoading(false)
        })
    }, [app, docId, page, limit])

    const handleMore = () => {
        setPage(page + 1)
    }
    return (
        <List sx={{opacity: loading ? '50%' : '100%'}}>
            {loading && <ListItem sx={{marginLeft:18}}><CircularProgress/></ListItem>}
            {docs.map( doc => (
                <ListItem>
                    <ItemText doc={doc} withLabel={withLabel}/>
                </ListItem>
            ))}
            {num > docs.list && <Button onClick={handleMore}>Mas</Button>}
        </List>
    )
}

export default ActivityList;