import React, {useEffect, useState} from 'react'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Form from './Form'
import moment from 'moment'

import { TabsTest } from './TabsTest'

export const DatePickerTest = () => {
    const [dd, setDD] = useState()
    // const [doc, setDoc] = useState()
    // useEffect( () => {
    //     setDoc(doc)
    // }, [])
    const handleChange = (vv) => {
        setDD(vv)
    }
    
    // es importante que sea un objecto Moment para que funcione.
    const doc = {fecha_estimada: moment(new Date())}

    const formConfig = [
        {   
            title: 'Test',
            list: [
                {
                    label: 'Fecha Estimada',
                    name: 'fecha_estimada',
                    dateProps: {format:"YYYY-MM-DD"},
                    sizes: {xs:4, md:2}
                },    
            ]
        }    
    ]

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker value={dd} onChange={handleChange}/>

            <Form
                withSections={formConfig}
                defaultValues={doc}
            />

            <TabsTest orientation={'horizontal'}/>

        </LocalizationProvider>
    )
  }
  