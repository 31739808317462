import React, {useState} from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { MessageBox } from "../../components";
import {ProjectSearchBox} from "../Project/ProjectAutocomplete";

const OpportunityNewProject = ({open, onCancel, onNew, onAsociate}) => {
    const [obra, setObra] = useState("")
    const handleChange = (ev, obra) => {
        if (obra && obra._id) setObra(obra._id)
    }
    const handleAsociate = (ev) => {
        if (obra && onAsociate) onAsociate(obra)
    }
    return (
        <MessageBox 
            title="Cierre de Oportunidad"
            open={open} 
            onClose={onCancel}
            onlyCancel={true}
            maxWidth='sm'
            fullWidth={true}
        >
            <Grid container>
                <Grid xs={6}>
                    <Paper>
                        <ProjectSearchBox
                            sx={{width: '100%'}}
                            onChange={handleChange}
                        />
                        <Button sx={{float: 'right'}} onClick={handleAsociate}>Asociar</Button>
                    </Paper>
                </Grid>
                <Grid xs={6}>
                    <Paper>
                        <Button sx={{float: 'right'}} variant='outlined' onClick={onNew}>Obra Nueva</Button>
                    </Paper>
                </Grid>
            </Grid>
        </MessageBox>
    )    
}

export default OpportunityNewProject;