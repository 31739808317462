import React, {useEffect, useState} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import {SelectField} from '../../components'
import PartAutocomplete from '../Stock/Part/PartAutocomplete';

import {postAPI, activityLog} from '../../utils/';


const PlanningEditDialog = ({defaultDoc={}, reference={}, open=true, onCancel, onClose}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [doc, setDoc] = useState({});
    const [progress, setProgress] = useState(false);
    const terminacionColores = useSelector( state => state.terminacionColores || [] );

    const currentUser = useSelector(state => state.currentUser);

    useEffect( () => {
        setDoc(defaultDoc)
    }, [defaultDoc])

    const handleChange = fld => (ev, valor) => {
        debugger
        let vv = ev.target.value;
        if (fld === 'articulo') {
            vv = valor; 
            if (valor) {
                setDoc( dd => ({...dd, udm: vv.deposito_udm}))
            }
        }
        setDoc( dd => ({...dd, [fld]: vv}))
    }

    const handleSubmit = () => {
        setProgress(true)

        debugger
        let baseUrl;
        if (reference['tipo'] === 'PLAN') {
          baseUrl = '/project/' + reference['obra']['_id']; 
          doc['obra'] = reference['obra']
          delete doc.pedido
          delete doc.reservado
          delete doc.recibido
        } else {
          baseUrl = '/work/workorders/' + reference['of']['_id'];
          doc['of'] = reference['of'];
          doc['obra'] = reference['obra'];
          delete doc.planificado;
          delete doc.recibido;
          delete doc.reservado;
        }

        if (doc._id === 'new') {
            delete doc._id;
        }
        if (!doc.articulo_color) {
          doc.articulo_color = ""
        }
        postAPI(baseUrl + '/materials', {...doc})
        .then(res => {
            setProgress(false)

            const action = doc['_id'] ? "editó" : "agregó";
            const art = doc.articulo.codigo + " " + doc.articulo_color;
            const msg = "Se " + action + " el requerimiento " + art + " x " + doc.planificado + " " + doc.udm + ".";
            if (doc['obra'] && !doc['of']) {
              activityLog({label: doc['obra'].nombre, app:'obras', docId: doc['obra']._id, msg: msg, currentUser})
            }

            enqueueSnackbar(msg, {variant: 'success'});
            if (onClose) {
                onClose()
            }
        })
        .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
        })
    }

    const colores = terminacionColores.map(it => ({label:it, value: it}))

    return (
        <Dialog
          disableBackdropClick
          //disableEscapeKeyDown
          open={open}
          maxWidth='md'
          fullWidth={true}
          onClose={onCancel}
        >
          <DialogTitle>Edición de linea {doc.articulo && doc.articulo.codigo} {doc.articulo && doc.articulo.descripcion}</DialogTitle>
          <DialogContent>
            <DialogContentText>

            </DialogContentText>
            { progress &&
              <div>
                <LinearProgress/>
                <Typography style={{textAlign:'center'}}>Espere mientras se termine este proceso</Typography>
              </div>
            }
            <Stack sx={{m:1}} spacing={2}>
                <PartAutocomplete value={doc.articulo} onChange={handleChange('articulo')}/>
                <SelectField label="Color" options={colores} value={doc.articulo_color} onChange={handleChange('articulo_color')}/>
                { reference['tipo'] === 'PLAN' && 
                  <TextField label="Planificado" value={doc.planificado} onChange={handleChange('planificado')}/>
                }
                { reference['tipo'] === 'REQ' && 
                  <TextField label="Requerido" value={doc.pedido} onChange={handleChange('pedido')}/>
                }
                {doc.udm}
            </Stack>


          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} disable={!progress} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleSubmit} disable={!progress} color="primary">
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
    )
}


export default PlanningEditDialog;
