import React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import {grey} from '@mui/material/colors';


const SearchBox = ({type='textfield', options=[], labelProps={}, ...other}) => {
    if (type === 'checkbox') {
        const props = {color: grey[500], position:'relative', bottom:'-23px', fontSize:9, display: 'inline', cursor: 'pointer', ...labelProps}
        return (
            <>
                <Box sx={props}>{other['label']}</Box>
                <Checkbox sx={props} size='small' {...other}/>            
            </>
        )
    }
    return (
        <TextField 
            sx={{mr:2}}
            type='search'           
            variant="standard"
            label='Buscar'
            size="medium"
            {...other}
        >
            { options.map( (option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
            ))}
       </TextField>
    )
}

export default SearchBox;

export const SearchBoxSmall = styled(SearchBox)(({ theme }) => ({
    '& label.Mui-focused': {
      color: '#A0AAB4',
    },
    '& .MuiFormLabel-root.MuiInputLabel-root':{
            fontSize: '14px'
    },  
    '& .MuiInput-underline:after': {
      borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E0E3E7',
      },
      '&:hover fieldset': {
        borderColor: '#B2BAC2',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#6F7E8C',
      },
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        // backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
        // border: '1px solid',
        // borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
        fontSize: 14,
        width: 'auto',
        // padding: '10px 12px',
        transition: theme.transitions.create([
          'border-color',
          'background-color',
          'box-shadow',
        ]),
    }    
}));
