import React, {createContext, useEffect, useReducer, useState} from 'react';
import { useSelector } from "react-redux";

import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';

import { makeStyles } from '@mui/styles';

import TabPanel from '../../components/TabPanel';

import {checkUserRoles} from "../../utils"


const useStyles = makeStyles((theme) => ({
    tabFixed: {
        //width: 200,
        // fontSize: 12
        color: '#9542e4'
    },
}))

export const TabsContext = createContext(null);

function tabsReducer (state, action) {
    let cur;
    switch (action.type) {
        case 'addTab':
            const key = action.label;
            // revisa si existe el tab y no es unica
            const next_cur = state.list.map( it => it.searchable && it.label ).indexOf(key)
            if (next_cur > -1) {

                // actualiza componente
                const ele = state.list[next_cur];
                ele.view = true;
                if (action.label)
                    ele.label = action.label
                if (action.component)
                    ele.component = action.component
                
                return {
                    ...state, 
                    current: state.list[next_cur].inx, 
                    prev: state.current
                }
            }

            // agrega uno nuevo
            const count = state.count + 1;
            const aa = {
                canClose: true,
                inx: count,
                view: true,
                default: false,
                searchable: true, 
                ...action,
                key: key
            }
            delete aa.type; // es clave hacer esto en objeto distinto de action!!!!

            return {
                ...state, 
                list: [...state.list, aa], 
                count: count,
                current: aa.inx,
                prev: state.current
            };

        // case 'updateTab':
        //     state.list.forEach( it => {
        //         if (it.searchable && it.label === action.label ) {
        //             cur = it.inx;
        //             if (action.label)
        //                 it.label = action.label
        //             if (action.component)
        //                 it.component = action.component
        //         }
        //     })
        //     if (cur) {
        //         return  {
        //             ...state,
        //             list: state.list,
        //             current: cur,
        //             prev: state.current,
        //         };    
        //     }
        //     return state;
    
        case 'updateCurrentTab':
            state.list.forEach( it => {
                if (it.inx === state.current ) {
                    if (action.label)
                        it.label = action.label
                    if (action.component)
                        it.component = action.component
                }
            })
            return {
                ...state,
                list: state.list,
            }

        case 'removeCurrentTab':
            const nuevo1 = state.list.filter( it => (it.inx !== state.current ));
            return {
                ...state,
                list: nuevo1,
                current: state.prev,
            }

        case 'removeTab':
            const nList = state.list.filter( it => (it.inx !== action.tab ));
            let next = state.current !== action.tab ? state.current : state.prev;
            const existe = nList.filter( it => it.inx === next);
            if (existe.length === 0) {
                const defTab = nList.filter( it => it.default);
                next = defTab[0].inx;
            }
            return {
                ...state,
                list: nList,
                current: next
            }

        case 'removeAll':
            const defTab = state.list.filter( it => it.default)

            const orig = state.list.filter( it => !it.canClose)
            return {
                ...state,
                list: orig,
                current: defTab[0] && defTab[0].inx,
                prev: null
            }
    
        case 'selectTabByInx':
            return {
                ...state, 
                current: action.inx, 
                prev: state.current
            }

        case 'gotoTabLabel':
            debugger
            state.list.forEach( it => {
                if (it.searchable && it.label === action.label ) {
                    cur = it.inx;
                }
            })
            if (cur) {
                return  {
                    ...state,
                    list: state.list,
                    current: cur,
                    prev: state.current,
                };    
            }
            return state;

        case 'gotoPrevious':
            debugger
            // if (state.prev === state.current) {
            //     const el = state.list.filter( it => it.default)
            //     if (el.length) {
            //         return {
            //             ...state, 
            //             current: el[0].inx
            //         }
            //     }
            // } else {
                return {
                    ...state, 
                    current: state.prev
                }
            // }
    
        case 'gotoDefault':
            debugger
            const el = state.list.filter( it => it.default)
            if (el.length) {
                return {
                    ...state, 
                    current: el[0].inx
                }
            } else {
                return {
                    ...state, 
                    current: state.prev
                }
            }


        default:
            return state;
            //throw new Error();
    }
}


export const TabsProvider = ({defaultTabs=[], debug=false}) => {
    const classes = useStyles();
    const [tabs, dispatchTabs] = useReducer(tabsReducer, {list:[], count: 0, current: -1, prev: -1});
    const [curTab, setCurTab] = useState({});

    const currentUser = useSelector(state => state.currentUser);

    const tabHasRole = it => (!it.roles || it.roles && checkUserRoles(currentUser, it.roles));

    const validTabs = tabs.list.filter( tabHasRole );

    const handleChangeTab = (event, newValue) => {
        const cur = tabs.list.filter(it => it.inx == newValue);
        if (cur && cur.length) {
            setCurTab(cur[0])
        }
        dispatchTabs({type: 'selectTabByInx', inx: newValue})
    };
    
    const removeTab = (tab) => {
        debugger
        dispatchTabs({type: 'removeTab', tab})
        // dispatchTabs({type: 'gotoPrevious'})
    }

    const handleCloseTab = (tab) => (event) => {
        event.preventDefault()
        event.stopPropagation()

        removeTab(tab)
    }

    useEffect( () => {      
        if (!defaultTabs) return;
        debugger
        defaultTabs.filter(tabHasRole).map( newTab => 
            dispatchTabs({
                type: 'addTab', 
                canClose: false,
                default: validTabs.length === 0,
                ...newTab
            })
        )
        dispatchTabs({type: 'gotoDefault'})
        // eslint-disable-next-line
    }, [defaultTabs]) 

    const handleClear = () => {
        dispatchTabs({ type: 'removeAll'})
    }

    const handleTabClick = () => {

    }

    return (
        <TabsContext.Provider value={{curTab, dispatchTabs}}>            
            <Box sx={{width: '100%', height:'100%'}}>

                {debug && <span>len={tabs.list.length} count={tabs.count} current: {tabs.current} prev: {tabs.prev}</span>}
                
                <TabContext value={tabs.current}>

                    <Box sx={{color: grey[500], float: 'right', position:'relative', bottom:'-13px', fontSize:8, display: 'inline', cursor: 'pointer'}} onClick={handleClear}>Reset</Box>

                    <Box sx={{ 
                        borderBottom: 1, borderColor: 'divider', '@media print': {display: 'none'}, 
                        // bgcolor: 'background.paper', flexGrow:1,  height: 224
                        // flexGrow: 1, display: 'flex', height: 224
                    }}>
                        <TabList 
                            onChange={handleChangeTab} 
                            variant="scrollable"
                            scrollButtons="auto"

                            // orientation="vertical"
                            // sx={{ borderRight: 1, borderColor: 'divider', width: 100 }}
                        >
                            { 
                                validTabs.map( it => {
                                    if (!it.view) return "";

                                    return  (<Tab 
                                                key={it.inx} 
                                                label={
                                                    <div>
                                                        { it.label } { debug && it.inx }
                                                        { it.canClose && 
                                                            <IconButton sx={{
                                                                    paddingLeft: "20px", 
                                                                    width: '20px', 
                                                                    height: '20px'
                                                                }} 
                                                                onClick={handleCloseTab(it.inx)}
                                                            >
                                                                <CloseIcon fontSize='small'/>
                                                            </IconButton>
                                                        }
                                                    </div>
                                                }
                                                value={it.inx}
                                                className={!it.canClose ? classes.tabFixed : ''}
                                            />)
                                }) 
                            }
                        </TabList>                         

                        {   validTabs.map( it => {
                                if (!it.view) return "";
                                return (
                                    <TabPanel sx={{height:"100%"}} key={it.inx} value={it.inx} onClick={handleTabClick}>
                                        {  
                                            React.cloneElement(it.component, {
                                                onCloseTab: () => removeTab(it.inx),
                                                tabKey: it.key,
                                                isTabSelected: it.key === curTab.key
                                            })
                                        }
                                        {/*it.component*/}
                                    </TabPanel>
                                )
                            })
                        }

                    </Box>

                </TabContext>
            </Box>
        </TabsContext.Provider>
    )
}
