import React, {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import DehazeIcon from '@mui/icons-material/Dehaze';
import ActivityMiniDrawer from './ActivityMiniDrawer';

const ActivityOpenClose = ({sx, ...other}) => {
    const [open, setOpen] = useState(false)

    const handleChange = () => {
        setOpen(!open)
    }

    return (
        <>
            <IconButton sx={sx} onClick={handleChange}>
                <DehazeIcon />
            </IconButton>

            { open && <ActivityMiniDrawer open={open} onClose={handleChange} {...other}/> }
        </>
    )
}

export default ActivityOpenClose;