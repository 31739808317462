import React from 'react';
import {Link} from 'react-router-dom';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
import classnames from 'classnames';

//import {red, blue} from '@material-ui/core/colors';

const useStyles = makeStyles({
  card: {
    width: "100%",
    margin: "16px 0px",
    display: "inline-block",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    borderRadius: 3,
    position: 'relative',
    overflow: 'visible'
  },
  header: {
    margin: "-17px 15px 0",
    float:'left',
    padding: 15,
    textAlign: "center",
    borderRadius: 3,
//    [theme.breakpoints.down('lg')]: {
//      padding: 0,
//      margin: "5px 5px 0"
//    }
  },
  avatar: {
    margin: 8,
  },
  headerIcon: {
    fill: "#fff",
    width: 40,
    height: 36
  },
  content: {
    padding: "15px 20px",
    textAlign: "right",
    paddingTop: 10,
    paddingBottom: '10px !important'
  },
  contentText: {
    margin: "0 0 10px",
    color: "#999999",
    //fontSize: 16,
    fontWeight: 300,
    lineHeight: "1.5em",
    marginBottom: 0
  },
  descriptionText: {
    margin: 0,
    fontSize: 28,
    fontWeight: 300,
    lineHeight: "1.5em",
  },
  smallText: {
    color: "#777",
    fontSize: "65%",
    fontWeight: 400,
    lineHeight: 1,
  },
  actions: {
    margin: "0 20px 10px",
    height: "auto",
    padding: "0!important",
    borderTop: "1px solid #eeeeee",
    paddingTop: 10,
  },
  footer: {
    color: "#999999",
    margin: "0!important",
    display: "inline-block",
    fontSize: 12,
    lineHeight: "22px"
  },
  footerText: {
    fontSize: 14,
    fontWeight: 300
  },
  statIcon: {
    width: 16,
    height: 16,
    top: 4,
    marginRight: 3
  },
  colorPurple: {
    color:"#fff",
    background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
    boxShadow: "0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)",
  },
  colorRed: {
    color:"#fff",
    background: "linear-gradient(60deg, #ef5350, #e53935)",
    boxShadow: "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)",
  },
  colorGreen: {
    color:"#fff",
    background: "linear-gradient(60deg, #66bb6a, #43a047)",
    boxShadow: "0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)",
  },
  colorOrange: {
    color:"#fff",
    background: "linear-gradient(60deg, #ffa726, #fb8c00)",
    boxShadow: "0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)",
  },
  colorBlue: {
    color:"#fff",
    background: "linear-gradient(60deg, #26c6da, #00acc1)",
    boxShadow: "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)",
  },
  colorYellow: {
    color:"#fff",
    background: "linear-gradient(60deg, #d7da26, #dade07)",
    boxShadow: "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)",
  },
});

const withColor = (classes={}, col) => {
  if (col === 'purple') {
    return  classes.colorPurple
  }
  if (col === 'red') {
    return  classes.colorRed
  }
  if (col === 'green') {
    return  classes.colorGreen
  }
  if (col === 'orange') {
    return  classes.colorOrange
  }
  if (col === 'blue') {
    return  classes.colorBlue
  }
  if (col === 'yellow') {
    return  classes.colorYellow
  }
}


const StatsCard = ({className,
    action,
    title, description, small,
    icon:Icon, iconColor='purple', statIcon:StatIcon, statIconColor='gray', statText}) => {
  
  const classes = useStyles();
  const _statIcon = StatIcon ? <StatIcon color={statIconColor} className={classes.statIcon} /> : "";
  const _statText = statText ? <span className={ classes.footerText }>
                                  {statText}
                               </span> : ""

return (
    <Card className={classes.card}>
      <CardHeader
        action={action}
        //avatar={<Icon className={classes.headerIcon}/>}
        className={classnames(classes.header, withColor(classes, iconColor))}
        classes={{ avatar: classes.avatar }}
      />
      <CardContent className={classes.content}>
        <Typography variant="subtitle2" className={ classes.contentText }>
          {title}
        </Typography>
        <Typography variant="h2" className={ classes.descriptionText }>
          {description}
          <small className={ classes.smallText}>
            {small}
          </small>
        </Typography>
      </CardContent>

      { _statText &&

        <CardActions className={classes.actions}>
          <div className={classes.footer}>

            { _statIcon }
            { _statText }

          </div>
        </CardActions>

        }

    </Card>
  )
}

export default StatsCard;
