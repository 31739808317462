import React, {useState, useEffect} from 'react';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { MessageBox } from '../../../components';
import TextField from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

const ChangeBox = ({open, doc, title,  onSubmit, onClose}) => {
    const [fields, setFields] = useState({fecha: moment()});
    const [error, setError] = useState({});
    useEffect(() => {
        setFields(doc || {})
    }, [doc, open])

    useEffect(() => {
        validate()
    }, [fields])

    const validate = () => {
        const err = {}
        if (!fields['fecha']) {
            err["fecha"]= "Debe completar un valor";
        }
        if (!fields['codigo']) {
            err["codigo"]= "Debe completar un valor";
        }
        if (!fields['tipo_comprobante']) {
            err["tipo_comprobante"]= "Valor";
        }
        if (!fields['total']) {
            err["total"]= "Debe completar un valor";
        }
        setError(err)

        return Object.keys(err).length === 0
    }

    const handleChange = (fld) => (ev) => {
        debugger
        let valor;
        if (fld === 'fecha') {
            valor = ev;
        } else {
            valor = ev.target.value;
        }
        setFields(ff => ({ ...fields, [fld]:valor}))
    }
    const handleClose = (tipo) => {
        if (tipo && onSubmit) {
            if (!validate()) {
                return
            }
            onSubmit(fields)
        }
        setFields({})
        onClose()
    }
    return (
        <MessageBox open={open} title={title} onClose={handleClose}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker sx={{m:1, width: "30%"}} label='Fecha' value={fields['fecha'] && moment(fields['fecha'])} onChange={handleChange('fecha')} format="YYYY-MM-DD" views={['day']} error={!!error['fecha']} helperText={error['fecha']}/>
            </LocalizationProvider>
            <TextField sx={{m:1, width: "12%"}} label='Letra comprobante' value={fields['tipo_comprobante']} onChange={handleChange('tipo_comprobante')} error={!!error['tipo_comprobante']} helperText={error['tipo_comprobante']}/>
            <TextField sx={{m:1, width: "45%"}} label='Codigo' value={fields['codigo']} onChange={handleChange('codigo')} error={!!error['codigo']} helperText={error['codigo']}/>
            <TextField sx={{m:1, width: "45%"}} label='Total' value={fields['total']} onChange={handleChange('total')} error={!!error['total']} helperText={error['total']}/>
            <TextField sx={{m:1, width: "45%"}} label='Moneda' value={fields['moneda']} onChange={handleChange('moneda')}/>
        </MessageBox>    
    )
}

const POrderInvoice = ({doc, onSubmit=()=>{}}) => {
    const [dialog, setDialog] = useState(false);

    return (
        <Box sx={{display: 'inline'}}>
            {
                doc && doc._id ? (
                    <>
                        <Box sx={{display: 'inline'}}>FACTURA {doc.tipo_comprobante} {doc.codigo} {doc.total} {doc.moneda}</Box>
                        &nbsp;
                        <Button size='small' onClick={ev => setDialog(true)}>Cambiar</Button>
                    </>
                )
                :
                <Button size='small' onClick={ev => setDialog(true)}>Agregar Factura</Button>
            }
            <ChangeBox 
                open={dialog} 
                title='Datos de factura' 
                doc={doc}
                onClose={ev => setDialog(false)} 
                onSubmit={onSubmit}
            />
        </Box>
    )
}

export default POrderInvoice;