import React, {useEffect, useState, useMemo, useCallback} from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InfoIcon from '@mui/icons-material/Info';

import { Table } from '../../../components';
import {useUnits} from "../../../utils";
import {AlmacenField} from './AlmacenField';

export const POrderRecepcionTable = ({open, tipo, recepcion={}, onCancel=()=>{}, onSubmit=()=>{}}) => {
  const [doc, setDoc] = useState({})
  const [recibido, setRecibido] = useState([])
  const [almacen, setAlmacen] = useState([])
  const [udmOpt, setUdmOpt] = useState(true)
  const [error, setError] = useState([])
  const {getConversion} = useUnits();

  // asegura copiar los almacenes a almacen
  const warehouses = useSelector( state => state.warehouses || [] );
  useEffect( () => {
    if (!recepcion || !recepcion.detail) return;
    const defWare = warehouses.filter(it => !!it.default)[0]
    const alm = recepcion.detail.map(it => it.almacen ? it.almacen._id : (defWare && defWare['_id']) )
    setAlmacen(alm)
  }, [recepcion, warehouses])

  const validate = useCallback( (doc, recibido, recepcion) => {
    let err = {}
    if (!doc['remito']) {
      err['remito'] = 'Debe ingresar remito';
    }
    if (recibido.length === 0) {
      err['recibido0'] = 'Debe ingresar una cantidad'
    } else {
      for (let inx in recibido) {
        debugger
        const {udm, udm_depo, pedido_depo, recibido_sum} = recepcion.detail[inx];
        const conv = getConversion(udm, udm_depo);
        if (!conv) {
          err['recbido' + inx] = 'No se pudo encontrar la conversion de ' + udm + ' a ' + udm_depo;
        }
        const recibido_depo = udmOpt ? recibido[inx]*conv.multiply : recibido[inx];
        const pendiente = pedido_depo - recibido_sum;
        if (recibido_depo > pendiente) {
          err['recibido'+inx] = 'La cantidad recibida debe ser menor al pendiente de entrega: ' + pendiente + ' ' + udm_depo;
        }
      }
    }
    setError( e => err)
    return err
  }, [udmOpt, getConversion])

  const handleChange = fld => ev => {
    doc[fld] = ev.target.value;
    setDoc(doc)
    validate(doc, recibido, recepcion)
  }
  const handleChangeRecibido = inx => ev => {
    recibido[inx] = ev.target.value;
    setRecibido(recibido)
    validate(doc, recibido, recepcion)
  }
  const handleChangeAlmacen = inx => ev => {
    debugger
    almacen[inx] = ev.target.value;
    setAlmacen(ll => [...almacen])
    //validate(doc, recibido, recepcion)
  }

  const handleUDM = useCallback(() => {
    setUdmOpt(udmOpt ? false : true)
  }, [udmOpt])

  const handleSubmit = () => {
    const err1 = validate(doc, recibido, recepcion)

    if (Object.keys(err1).length) {
      return
    }
    
    debugger
    const detalle = [];
    recibido.forEach( (valor, inx) => {
      const linea = recepcion.detail[inx];
      const recLinea = {
        vidrio: linea.vidrio,
        descripcion: linea.descripcion,
        articulo: linea.articulo,
        articulo_color: linea.articulo_color,
        pedidolog_ref: linea.pedidolog_ref,
        almacen: {_id: almacen[inx]}
      }
      recLinea['recibido'] = valor;
      recLinea['udm'] = udmOpt ? linea.udm : linea.udm_depo;
      detalle.push(recLinea);
    })
    doc.detalle = detalle;
    onSubmit(doc)
  }

  const cols = [
    {   name: "Linea", field: v=> v['linea'],           
      th: { align:'center'},
      td: { align:'left'}, 
    },
    {   name: "Codigo", field: v=> tipo !== 'VARIOS' ? (v['vidrio'] ? v['vidrio']['codigo'] : v['articulo']['codigo']) : "",           
        th: { align:'center'},
        td: { align:'left'}, 
    },
    {   name: 'Descripción', field: v=> tipo === 'MATERIALES' ? v['articulo']['descripcion'] : v['descripcion'],    
        th: { align:'center'},
        td: { align:'left'}, 
    }, 
    {   name: 'Color', field: v=> v['articulo_color'],    
        th: { align:'center'},
        td: { align:'left'}, 
    }, 
    {   name: 'Pedido Ori', field: v=> v['pedido'],    
        th: { align:'center'},
        td: { align:'left'}, 
    }, 
    {   name: 'UDM', field: v=> v['udm'],    
        th: { align:'center'},
        td: { align:'left'}, 
    }, 
    {   name: 'Pedi Depo', field: v=> v['pedido_depo'],    
        th: { align:'center'},
        td: { align:'left'}, 
    }, 
    {   name: 'Rec Depo', field: v=> v['recibido_sum'],    
        th: { align:'center'},
        td: { align:'left'}, 
    }, 
    {   name: 'UDM Depo', field: v=> v['udm_depo'],    
        th: { align:'center'},
        td: { align:'left'}, 
    }, 
    {   name: 'Recibido', field: (v,inx) => <TextField 
                                              size="small" 
                                              number 
                                              value={recibido[inx]} 
                                              onChange={handleChangeRecibido(inx)}
                                              error={!!error['recibido' + inx]}
                                              // helperText={error['recibido' + inx]}
                                            />,    
        th: { align:'center'},
        td: { align:'left'}, 
    }, 
    {   name: <Box>
                UDM 
                <Button onClick={handleUDM}>
                  <Tooltip title='Haga click para cambiar unidad de medida: deposito/proveedor'><InfoIcon/></Tooltip>
                </Button>
              </Box>, 
        field: v=> v[udmOpt ? 'udm' : 'udm_depo'],    
        th: { align:'center'},
        td: { align:'left'}, 
    }, 
    {
      name: "Almacén",
      field: (v, inx) => <AlmacenField 
                            size="small" 
                            value={almacen[inx] || ""} 
                            onChange={handleChangeAlmacen(inx)}
                            sx={{
                              '& .MuiSelect-select': {
                                fontSize: 10
                              },
                            }}
                            //error={!!error['recibido' + inx]}
                            //helperText={error['recibido' + inx]}
                          />,    
        th: { align:'center'},
        td: { align:'left'}, 
    }
  ]
  
  const cols2 = (data, inx) => {
    if (!!error['recibido' + inx]) {
      return ([
        {
          name: 'Error', 
          field: v => error['recibido' + inx],    
          th: { align:'center'},
          td: { align:'right', colSpan: 11, sx: {color: '#f00'}}, 
        }
      ])
    }
  }

  debugger
  const detail = recepcion['detail'] ? recepcion['detail'].filter(it => it.pedido_depo !== it.recibido_sum) : [];

  return (
    <Box>
      <Stack direction='horizontal'>
        <TextField 
          size='small' 
          label="Remito" 
          value={doc['remito']} 
          onChange={handleChange('remito')} 
          helperText={error['remito']}
          error={!!error['remito']}
        />
      </Stack>

      <Table 
        //sx={{width: "800px", p:1, m:1}}
        cols={cols}
        colsExtra={cols2}
        data={detail}
        tableProps={{size:'small', padding: 'normal', stickyHeader: true}}
        headerProps={{ sx: { 
                  '& th': {
                    backgroundColor: '#aaa',
                    fontWeight: 600  
                  },
                } 
        }}
        rowProps={{
          sx: {
            m:1, 
            '& td': {
              fontSize: 10
            },
            fontSize: '14px'
          }
        }}
      />
      <Box sx={{textAlign: 'center', margin:'10px'}}>
        <Button 
            sx={{minWidth: '100px', marginRight: '10px'}}
            color='secondary'
            variant='outlined' 
            onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button 
            sx={{minWidth: '100px'}}
            variant="outlined" 
            disabled={Object.keys(error).length>0} 
            onClick={handleSubmit}
        >
          Guardar
        </Button>

      </Box>
    </Box>
  )
}
