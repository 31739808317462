import { createStore } from "redux";

const opposDefault = {filter:"", status:"EN TRATATIVA", exito:"-1", ingresada:"-1", distribuidor:"-1", responsable:"-1", revision: '-1'};

const appReduxStore = createStore(
  (state = {}, action) => {
    switch (action.type) {
        case "LOGIN":
          return {
            ...state,
            org: action.org,
            authenticated: !!action.user ? true : false,
            currentUser: action.user,
            expires: action.expires
          };
        case "LOGOUT":
          return {
              ...state,
              authenticated: false,
              currentUser: {},
              expires: undefined,
              org: ''
          };
        case "CONFIG":
          return {
              ...state,
              terminacionColores: action.terminacionColores,
              distributors: action.distributors,
              resources: action.resources,
              operations: action.operations,
              units: action.units,
              warehouses: action.warehouses,
              transports: action.transports,
              topics: action.topics,
              boards: action.boards,
              options: action.options
          };
        case "FILTERS":
          return {
            ...state,
            oppos: action.oppos
          };
        case "FILTERS-MERGE":
            return {
              ...state,
              oppos: {...opposDefault, ...action.oppos}
            };
        case "FILTERS-RESET":
            const key = action.key
            if (key === 'oppos') {
              return {...state, oppos: opposDefault};
            }
            return state;
        case "RELOADS":
            return {
              ...state, 
              reloads: action.key ? action.key : undefined
            };
            
        case "ADD_TO_CART":
          return {
            ...state,
            cart: [...state.cart, action.item],
          };
        case "REMOVE_FROM_CART":
          return {
            ...state,
            cart: [...state.cart].filter(({ _id }) => {
              return _id !== action.itemId;
            }),
          };
        case "CLEAR_CART":
          return {
            ...state,
            cart: [],
          };
        default:
          return {
            ...state,
          };
    }      
  }, 
  {
    cart: [],
    oppos: opposDefault,
    reloads: undefined
});

export default appReduxStore;

export const useUser = () => {

}
