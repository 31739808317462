import React, {useEffect, useState, useMemo} from 'react'

import {getAPI} from '../../utils' ;

import { useSnackbar } from 'notistack';

import {Table} from '../../components';

const PlanningTable = ({ofId, projectId, ...other}) => {
    const [rows, setRows] = useState([]);
    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const limit = 1000;

    const { enqueueSnackbar } = useSnackbar();

    const baseUrl = projectId ? '/project/' + projectId : '/work/workorders/' + ofId;

    useEffect( () => {
        debugger
        setLoading(true)
        getAPI(baseUrl + '/materials', {limit})
        .then(data => {
            debugger
            setLoading(false);
            data.list.forEach( it => it.id = it.articulo._id+it.articulo_color);
            setNum(data.num)
            setRows(data.list)
        })
        .catch(err => {
            setLoading(false);
            console.error(err)
            enqueueSnackbar(err.message, {variant: "error"})
        });
    }, [baseUrl, enqueueSnackbar])

    const cols = useMemo(() => [
        // {   name: "Titulo", 
        //     field: v=> v['titulo'],           
        //     th: { align:'center'},
        //     td: { align:'left'}, 
        // },
        {   name: "Marca", 
            field: v=> v['articulo']['marca'],           
            th: { align:'center'},
            td: { align:'left'}, 
        },
        {   name: 'Articulo',    
            field: v=> v['articulo']['codigo'],       
            th: { align: 'center'},
            td: { align: 'left'}, 
        },
        {   name: 'Descripcion',    
            field: v=> v['articulo']['descripcion'],       
            th: { align: 'center'},
            td: { align: 'left'}, 
        },
        {   name: 'Color',    
            field: v=> v['articulo_color'],       
            th: { align: 'center'},
            td: { align: 'left'}, 
        },
        {   name: 'Pedido',    
            field: v=> v['pedido'],       
            th: { align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'Recibido',    
            field: v=> v['recibido'],       
            th: { align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'Reservado',    
            field: v=> v['reservado'],       
            th: { align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'UDM',    
            field: v=> v['udm'],       
            th: { align: 'center'},
            td: { align:'center'}, 
        },        /*
        {   name: 'Inicio',    
            field: v=> moment(v['fecha_inicio']).utc().format('YYYY-MM-DD'), 
            th: {align: 'center'},
            td: { align:'center'}, 
        },
        {   name: 'Finalizacion',    
            field: v=> moment(v['fecha_finalizacion']).utc().format('YYYY-MM-DD'), 
            th: {align: 'center'},
            td: { align:'center'}, 
        }*/
    ], [])

    return (
        <Table
            cols={cols}
            data={rows}
            tableProps={{size:'small', padding: 'normal', stickyHeader: true}}
            headerProps={{sx: {fontWeight:'bold'}}}
            rowProps={{sx: {m:1}}}
            {...other}
        />
    )
}

export default PlanningTable;