import {reconnectAPI} from "./API.js"
import appReduxStore from "./appReduxStore.js"

export const reconnected = res => {  
    debugger
    appReduxStore.dispatch({
        type: "LOGIN",
        user: res.user,
        org: res.org,
        // expires: res.expires
    })
    appReduxStore.dispatch({
        type: 'CONFIG',
        terminacionColores: res.terminacionColores,
        distributors: res.distributors,
        resources: res.resources,
        units: res.units,
        warehouses: res.warehouses,
        transports: res.transports,
        operations: res.operations,
        topics: res.topics,
        boards: res.boards,
        options: res.options
    })
}

export const reconnectNow = () => {
    return reconnectAPI()
    .then( reconnected )
    .catch( err => {
        console.log(err)
    })
}