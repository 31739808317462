import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import moment from 'moment'

const StockMaterialPrint = ({doc}) => {

    return (
        <Box>
            {/* <span style={{float: 'right', fontSize: 9}}>{moment().format('YYYY-MM-DD')}</span> */}
            <Typography sx={{mb:1}} variant='h5'>Movimiento de Material {doc.tipo}/{doc.comprobante}</Typography>

            {doc.listComponent}
            
            <Typography sx={{float:'right'}} variant="caption">Actualizado el {moment(doc.updated_at).utc().format('YYYY-MM-DD')}</Typography>
        </Box>
    )
}

export default StockMaterialPrint;