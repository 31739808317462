import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinkIcon from '@mui/icons-material/Link';

import FileList from '../Attachments/FileList';
import WorkOrderTable from '../Fabrica/WorkOrderTable';
import TasksTable from '../Task/TasksTable';
import { Attributes } from '../../components';

import moment from 'moment'


const AttributesStyled = styled(Attributes)(({ theme }) => ({
    '.MuiAttributes-title': {
        borderBottom: '1px solid'
    },
    "@media print": {
        "@page": {
            "size": "portrait",
            "margin": "10mm 5mm 5mm 5mm",
            "mso-header-margin":"0mm",
            "mso-footer-margin":"0mm",
            "mso-paper-source":0,
        },
        '.MuiAttributes-label': {
            fontSize: '9px'
        },
        '.MuiAttributes-text': {
            fontSize: '10px'
        },
        '.MuiAttributes-title': {
            fontSize: '11px'
        },
        'ul, ul li, table td, table th': {
            fontSize: "10px"
        },
    }
}));

const PostSalePrint = ({doc}) => {

    const getDireccion = () => {
        let out = ""
        if (doc.barrio) {
            out = doc.barrio + " " + doc.lote + ", "
        }
        out += (doc.direccion || "SIN")
        out += "-" + (doc.partido  || "SIN")
        out += "-" + (doc.localidad  || "SIN")
        out += "-" + (doc.provincia  || "SIN")
        out += "-" + (doc.pais  || "SIN")
        return out
    }

    // const contacto = doc.contacto ? doc.contacto._id : {}; 
    const getNameCont = () => {
      if (!doc.contacto) return "";
        return doc.contacto.fullname            
    }

    // const obra = doc.obra ? doc.obra._id : {}; 
    const getNameObra = () => {
      if (!doc.obra) return "";
        return doc.obra.nombre            
    }

    return (
        <>
            <Typography sx={{mb:1}} variant='h5'>PosVenta [{doc.pv}] {doc.nombre}</Typography>

            <AttributesStyled
                sizes={{xs: 2}}
                list={
                    [
                        {title: 'TRABAJO'},
                        {label: 'Tipo',         text: doc.tipo},
                        {label: 'Descripción',  text: doc.descripcion ? doc.descripcion : "SIN", sizes: {xs:8}},

                        {title: 'DATOS'},
                        {label: 'Nombre',       text: doc.nombre},
                        {label: 'Status',       text: doc.status},
                        {label: 'En Garantía',  text: doc.en_garantia},
                        {label: 'Presupuesto',  text: doc.presupuesto},
                        {label: 'Valor',        text: doc.valor},

                        {title: 'CONTACTOS'},
                        // {label: 'Contacto Obra',text:  Object.keys(doc.contacto).length !== 0 ? doc.contacto && doc.contacto.fullname : "" , sizes: {xs:6}},
                        {label: 'Email',           text: doc.email},
                        {label: 'Teléfono',        text: doc.telefono},
                        {label: 'Zona',            text: doc.zona},
                        {label: 'Contacto',        text: doc.contacto ? getNameCont() : 'SIN'},
                        {},
                        {},
                        {label: 'Dirección',       text: getDireccion(), sizes: {xs:10}},

                        {title: 'REFERENCIAS'},
                        {label: 'Referencia',      text: doc.referencia, sizes: {xs:4, sm:2}},
                        {label: 'Obra',            text: doc.obra ? getNameObra() : 'SIN'},
                        {label: 'Distribuidor',           text: doc.distributorId && doc.distributorId.nombre, sizes: {xs:4, sm:2}},
                        {},

                        {title: 'FECHAS'},
                        {label: 'Fecha Recepción',  text: doc.fecha_recepcion ? moment(doc.fecha_recepcion).utc().format('YYYY-MM-DD') : "SIN"},
                        {label: 'Fecha Enviada',    text: doc.fecha_enviada ? moment(doc.fecha_enviada).utc().format('YYYY-MM-DD') : "SIN"},
                        {label: 'Fecha Aceptada',   text: doc.fecha_aceptada ? moment(doc.fecha_aceptada).utc().format('YYYY-MM-DD') : "SIN"},
                        {label: 'Fecha Aprobada',   text: doc.fecha_aprobada ? moment(doc.fecha_aprobada).utc().format('YYYY-MM-DD') : "SIN"},
                        {label: 'Fecha Cerrada',    text: doc.fecha_cerrada ? moment(doc.fecha_cerrada).utc().format('YYYY-MM-DD') : "SIN"},
                        {},

                        {title: 'ARCHIVOS'},        
                        {   label: 'Ficha',
                            children: <FileList 
                                        folder="/ficha"
                                        docs={doc.archivo_ficha} 
                                    />,
                            sizes: {xs:3}
                        },
                        {   label: 'Presupuesto',
                            children: <FileList 
                                        folder="/presupuesto"
                                        docs={doc.archivo_presupuesto} 
                                    />,
                            sizes: {xs:3}
                        },
                        {   label: 'Foto',          
                            children: <FileList 
                                        folder="/foto"
                                        docs={doc.archivo_foto} 
                                      />, 
                            sizes: {xs:3}
                        },

                        {title: 'FABRICACION', sizes:{xs: 12}},
                        {label: '', text: <WorkOrderTable tipo='fabrica' pvId={doc._id}/>, sizes:{xs: 12}},
                        {title: 'SERVICIOS', sizes:{xs: 12}},
                        {label: '', text: <WorkOrderTable tipo='servicio' pvId={doc._id}/>, sizes:{xs: 12}},

                        {title: 'TAREAS', sizes:{xs: 12}},
                        {label: '', text: <TasksTable hideEdit reference={{field:'pv', id:doc._id}}/>, sizes:{xs: 12}}
                        

                    ]
                }
            />
            <Typography sx={{float:'right'}} variant="caption">Actualizado el {moment(doc.updated_at).utc().format('YYYY-MM-DD')}</Typography>

        </>


    )
}

export default PostSalePrint;