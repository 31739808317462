import React, {useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import {postAPI, activityLog} from '../../../utils/';
import {ParseCSV} from '../../../components';

import _ from 'underscore';
import moment from 'moment';


const PriceListImportDialog = ({tipo='articulo', provee={}, vigencia, open=true, onClose}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [progress, setProgress] = useState(false);
    const [validity, setValidity] = useState({})

    const currentUser = useSelector(state => state.currentUser);
debugger
    useEffect( () => {
        if (vigencia.length > 1) {
            setValidity({
                vigencia_desde: (moment(vigencia[0]) || moment()), 
                vigencia_hasta: (moment(vigencia[1]) || moment('2030-12-31'))
            })    
        }
    }, [vigencia])

    const handleValidity = (fld) => (m) => {
        setValidity({...validity, [fld]: m})
    }

    const opts = {
        numColumns: 4,
        colsWithValues: [0,2,3]
    }

    const handleValidation = async (parsed) => {
        const data = parsed.data;

        const checkCodigo = (codigo) => {
            if (codigo[0] === "'") {
              return codigo.slice(1)
            }
            return codigo;
        }
  
        const metaCol = [], errors = [], unicos = {}, falta = {};
        const checkRow = (row, inx) => {

            if (row.length === 1 && !row[0]) return

            if (row.length !== opts.numColumns) {
                falta['col-num'] = falta['col-num'] || []
                falta['col-num'].push(inx + 1);
            }

            if ( !row[0] || !row[2] || !row[3] ) {
                falta['vacio'] = falta['vacio'] || [];
                falta['vacio'].push(inx + 1);
            }
            if (row[0] == '500964.005'){
                debugger
            }
            if (row[2] && isNaN(Number(row[2]))) {
                //falta['no-es-precio'] = falta['no-es-precio'] || [];
                //falta['no-es-precio'].push(inx + 1);
                metaCol[inx] = {error: 'La columna precio no es un número'}
            }
            /*if ( row[6] && row[6].match(',') ) {
                falta['comma'] = falta['comma'] || [];
                falta['comma'].push(inx+1);
            }*/

            unicos[checkCodigo(row[0])] = unicos[checkCodigo(row[0])] || 0;
            unicos[checkCodigo(row[0])] += 1;

            if (unicos[checkCodigo(row[0])] > 1) {
                metaCol[inx] = {error: 'El código ya esta siendo subido en una linea anterior de la importación!'}
            }
    
        }
        if (data.length > 0) {

            data.forEach(checkRow);

            const artCodigos = data.map( it => checkCodigo(it[0]) );
            const articulos = await postAPI('/stock/parts/validate', {query: {activo:'SI', codigo: {$in: artCodigos}}, select: ['_id', 'codigo', 'deposito_udm']});
            const faltan = artCodigos.filter( it => !articulos.map(it1 => it1.codigo).includes(it) )
            
            data.forEach( (row, inx) => {
                const uno = articulos.filter(it1 => it1.codigo === checkCodigo(row[0]))
                if (uno && uno.length) {
                    row.push(uno[0]['deposito_udm'])
                    metaCol[inx] = {...metaCol[inx], artId: uno[0]['_id']};
                } else {
                    row.push("");
                    metaCol[inx] = {...metaCol[inx], error: "Articulo inactivo/inexistente!"}
                }
            })

            if (falta['col-num']) {
                errors.push( {
                    tipo: 'col-num',
                    rows: falta['col-num'],
                    msg: "Las filas " + falta['col-num'].join(', ') + " deben tener 4 columnas: CODIGO, DESCRIPCION, PRECIO, MONEDA"
                });
            }
            if (falta['vacio']) {
                errors.push({
                    tipo: 'vacio',
                    rows: falta['vacio'],
                    msg: "Las filas " + falta['vacio'].join(', ') + " deben tener las columnas: CODIGO, DESCRIPCION, PRECIO, MONEDA con valores"
                });
            }
            if (!falta['col-num']) {
                if (falta['col-4']) {
                    errors.push({
                        tipo:'col-4',
                        rows: falta['col-4'],
                        msg: "Los valores de la columna 5 deben ser números o vacio. Valores erróneos: " + falta['col-4'].join(', ')
                    });
                }
                if (falta['comma']) {
                    errors.push({
                    tipo: 'comma',
                    rows: falta['comma'],
                    msg: "Los valores de la columna 6 NO DEBEN CONTENER COMAS: " + falta['comma'].join(', ')
                    });
                }
            }
            if (falta['no-es-precio']) {
                errors.push({
                    tipo: 'no-es-precio',
                    rows: falta['no-es-precio'],
                    msg: "Las filas " + falta['no-es-precio'].join(', ') + " no tienen un formato de precio välido."
                });
            }
        }
        return {metaCol, errors};
    }

    const handleProcessCSV = (parsed, metaCol, onProgress, cb) => {
        const data = parsed.data;
        if (!data) {
            enqueueSnackbar('No se parsea datos', {variant: 'error'});
            return
        }

        setProgress(true)

        // convierte array a hash
        const desde = moment(validity['vigencia_desde']).toDate();
        const hasta = moment(validity['vigencia_hasta']).toDate()
        const list = data.map( (row, inx) => {
            const linea = {
                tipo:           tipo,
                articulo:       {_id: metaCol[inx]['artId']},
                provee:         {_id: provee._id},
                vigencia_desde: desde,
                vigencia_hasta: hasta,
                precio:         row[2],
                moneda:         row[3],
            }
            return linea
        }).filter((row, inx) => !metaCol[inx]['error'])
        debugger
        postAPI('/stock/price_list/newList', {list, proveeId: provee._id, tipo, vigencia_desde: desde, vigencia_hasta: hasta})
        .then(res => {
            setProgress(false)
            
            const msg = 'Se agregó una nueva lista de precios de ' + (tipo === 'articulo' ? 'ARTICULOS':'SERVICIOS') + ' con ' + list.length + ' registros y vigencia '+ 
                moment(validity['vigencia_desde']).utc().format('YYYY-MM-DD') + ' al ' + moment(validity['vigencia_hasta']).utc().format('YYYY-MM-DD') + '.';
            const label = "LP-" + provee.codigo_proveedor;
            activityLog({label, app:'pricelist', docId: provee._id, msg, currentUser})
  
            enqueueSnackbar(msg, {variant: 'success'});

            if (cb(null, res) && onClose) {
                onClose()
            }
        })
        .catch(err => {
            enqueueSnackbar(err.message, {variant: 'error'})
        })
    }

    const transformCSV = (value, colNum) => {
      //debugger
      let out = value;
      if (_.isString(value)) {
        out = value.trim();
        if ( [2,5].indexOf(colNum) > -1 ) {
          out = out.replace(',','.')
        }
      }
      return out
    }

    return (
        <Dialog
          disableBackdropClick
          //disableEscapeKeyDown
          open={open}
          maxWidth='md'
          fullWidth={true}
          onClose={onClose}
        >
          <DialogTitle>Importación de Lista de Precios {tipo === 'articulo' ? 'ARTICULOS':'SERVICIOS'} proveedor [{provee.codigo_proveedor} {provee.full_name}]</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Puede preparar un archivo de texto en una planilla de cálculo con 4 columnas. {moment(validity['vigencia_desde']).utc().format('YYYY-MM-DD')}
            </DialogContentText>
            { progress &&
              <div>
                <LinearProgress/>
                <Typography style={{textAlign:'center'}}>Espere mientras se termine este proceso</Typography>
              </div>
            }
            <Box sx={{m:1}}>
                 <DatePicker
                    views={['day']}
                    format="YYYY-MM-DD"
                    onChange={handleValidity('vigencia_desde')} // send value to hook form 
                    value={moment(validity['vigencia_desde']).utc()} // input value
                    label={"Desde"} // send down the input name
                    renderInput={(params) => <TextField {...params} label="Desde" />}
                />
                &nbsp;
                <DatePicker
                    views={['day']}
                    format="YYYY-MM-DD"
                    onChange={handleValidity('vigencia_hasta')} // send value to hook form 
                    value={moment(validity['vigencia_hasta']).utc() || moment('2030-12-31')} // input value
                    label={"Hasta"} // send down the input name
                    renderInput={(params) => <TextField {...params} label='Hasta' />}
                />
            </Box>

            <ParseCSV
              open={true}
              withTitle={false}
              //withHeader={true}
              skipEmptyLines
              delimiter={"\t"} // es importante los curly!!!
              dynamicTyping={false}
              transform={transformCSV}
              onValidation={handleValidation}
              onProcess={handleProcessCSV}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} disable={!progress} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
    )
}


export default PriceListImportDialog;
