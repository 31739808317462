import React, {forwardRef, useState, useEffect, useCallback, useImperativeHandle} from 'react'; 
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import ExitIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import {PageBase, PageTitle, DataGrid, SearchBox, ExportButton} from '../../../components'; 
import {getAPI, deleteAPI, debounce, useTabs} from '../../../utils' ;
import {EXCEL_LIMIT, options_porder_status} from '../../../appConfig';
import ActivityOpenClose  from '../../Activities/ActivityOpenClose';
import moment from 'moment';

const columns = (tipo, onAddTab) => ([
    {
        field: 'np',
        headerName: 'NP',
        type: 'string',
        renderCell: (params) => {
            const own = params.row;
            return own && own.pedido_orig && own.pedido_orig._id &&   
                <Link href="#" onClick={onAddTab('NP')(own.pedido_orig._id)}>NP-{own.pedido_orig._id.np}</Link>
        },
        width: 80,
    },
    {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        valueGetter: (params) => {
            const own = params.row;
            return own.pedido_orig._id.status
        },
        hide: tipo === 'REC',
        width: 120,
    },
    {
        field: 'fecha_entrega',
        headerName: 'Fecha Entrega',
        //type: 'date',
        valueGetter: (params) => {
            const own = params.row.pedido_orig._id;
            return own.fecha_entrega && moment(own.fecha_entrega).utc().format("YYYY-MM-DD")
        },
        hide: tipo === 'REC',
        width: 120,
    },
    {
        field: 'provee1',
        headerName: 'Proveedor',
        type: 'string',
        renderCell: (params) => {
            const own = params.row && params.row.pedido_orig && params.row.pedido_orig._id && params.row.pedido_orig._id.provee;
            const out = own ?  "[" + own.codigo_proveedor + "] " + own.fullname + "]" || "" : "";
            const link = onAddTab ? (
                <IconButton size='small' onClick={onAddTab('PROVEE')(own ? own : "")}>
                    <ExitIcon fontSize='10'/>
                </IconButton>
            ) : ""
            return out && out.trim() ? <span>{out} {link}</span> : "";          
        },
        width: 280,
    },
    {
        field: 'refe',
        headerName: 'Referencias',
        type: 'string',    
        renderCell: (params) => {
            const out = []
            const row = params.row && params.row.pedido_orig && params.row.pedido_orig._id;
            if (row.obra) {
              const tag = <Chip size='small' onClick={onAddTab('OBRA')(row.obra)} label={"OB-" + row.obra.carpeta + " " + row.obra.nombre}/>
              out.push( tag )
            }
            if (row.pv) {
              const tag = <Chip size='small' onClick={onAddTab('PVTA')(row.pv)} label={"PV-" + row.pv.pv + " " + row.pv.nombre}/>
              out.push( tag )
            }
            if (row.of) {
              const tipo = row.of.tipo === 'fabrica' ? "OF-" : "OS-";
              const tag = <Chip size='small' onClick={onAddTab('WORK-F')(row.of)} label={tipo + row.of.of}/>
              out.push( tag )
            }
            // if (row.np) {
            //   const tag = <Chip size='small' onClick={onAddTab('NP')(row.np)} label={"NP-" + row.np.np}/>
            //   out.push( tag )
            // }
            return <Box sx={{overflow: 'hidden'}}>{out}</Box>
              
        },
        width: '240',
    },

    {
        field: 'codigo',
        headerName: 'Codigo',
        type: 'string',
        valueGetter: (params) => {
            const own = params.row;
            return own.articulo && own.articulo.codigo
        },
        width: 120,
    },
    {
        field: 'desc',
        headerName: 'Descripcion',
        type: 'string',
        valueGetter: (params) => {
            const own = params.row;
            return own.articulo && own.articulo.descripcion
        },
        width: 180,
    },
    {
        field: 'articulo_color',
        headerName: 'Color',
        type: 'string',
        width: 120,
    },

    {
        field: 'pedido',
        headerName: 'Pedido',
        type: 'number',
        width: 80,
    },
    {
        field: 'recibido',
        headerName: 'Recibido',
        type: 'number',
        width: 80,
    },
    {
        field: 'udm',
        headerName: 'UDM',
        type: 'string',
        width: 80,
    },
])


const IndexPage = forwardRef( ({tipo='NP', addTab}, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    let [searchParams] = useSearchParams();
    const [filter, setFilter] = useState({status:"-2", obra: "", np: "", provee: "", articulo:"", articulo_color: ""});
    const [reload, setReload]   = useState(false);
    const {appendTab} = useTabs({
        onReload: (reload) => {
            setReload(reload)
        }
    });

    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;
    
    const currentUser = useSelector(state => state.currentUser);

    const options_status = [{label: "TODOS", value: "-1"}, {label: 'Pendiente', value: "-2"}].concat(options_porder_status);

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        getAlert() {
            alert("getAlert from Child");
        }
    }));  
    
    const fn = useCallback( 
        debounce(
            (filter, page, limit, enqueueSnackbar) => {
                setReload(false)
                setLoading(true)
                debugger
                getAPI('/stock/porders/detail/', {...filter, tipo, skip: page*limit, limit})
                .then(data => {
                    setLoading(false);
                    data.list.forEach( it => {
                        it.id = it._id.k0 + "|" + it._id.k1 + "|" + it._id.k2 + "|" + it._id.k3;
                    });
                    setNum(data.num)
                    setRows(data.list)
                })
                .catch(err => {
                    setLoading(false);
                    console.error(err)
                    enqueueSnackbar(err.message, {variant: "error"})
                });                    
            }, 1000),
    [])

    useEffect( () => {
        fn(filter, page, limit, enqueueSnackbar)
    }, [page, filter, tipo, reload, enqueueSnackbar])

    const handleFilter = fld => (ev) => {
      setFilter(oo => ({...oo, [fld]: ev.target.value}))
    }    
  
    const handleAddTab = (tipo) => item => ev => {
        if (tipo && item) appendTab(tipo)(item)
    }

    const configReport = useCallback( async () => {
        const colsNP = [
            {title: 'NP', field: 'np', transform: (v, doc) => {
                const own = doc.pedido_orig && doc.pedido_orig._id;
                if (own) {
                    return own.np;
                }
            }},
            {title: 'Fecha Entrega', field: 'fecha_entrega', transform: (v, doc) => {
                const own = doc.pedido_orig && doc.pedido_orig._id;
                if (own) {
                    return moment(own.fecha_entrega).utc().format('YYYY-MM-DD')
                }
            }},
            {title: 'Status', field: 'status', transform: (v, doc) => {
                const own = doc.pedido_orig && doc.pedido_orig._id;
                if (own) {
                    return own.status;
                }
            }},
            {title: 'Proveedor', field: '_', transform: (v, doc) => {
                    const own = doc.pedido_orig && doc.pedido_orig._id && doc.pedido_orig._id.provee;
                    if (own) {
                        return "[" + own.codigo_proveedor + "] " + own.fullname + "]";
                    }
                },
            },
            {title: 'Obra', field: '_', transform: (v, doc) => {
                    const own = doc.pedido_orig && doc.pedido_orig._id && doc.pedido_orig._id.obra;
                    if (own) {
                        return "[" + own.carpeta + "] " + own.nombre + "]";
                    }
                },
            },
            {title: 'PV', field: '_', transform: (v, doc) => {
                    const own = doc.pedido_orig && doc.pedido_orig._id && doc.pedido_orig._id.pv;
                    if (own) {
                        return "[" + own.pv + "] " + own.nombre + "]";
                    }
                },
            },
            {title: 'OF/OS', field: '_', transform: (v, doc) => {
                    const own = doc.pedido_orig && doc.pedido_orig._id && doc.pedido_orig._id.of;
                    if (own) {
                        return (own.tipo === 'fabrica' ? "OF-" : "OS-") + own.of ;
                    }
                },
            },
            {title: 'Codigo', field: '_', transform: (v, doc) => {
                    const own = doc.articulo ;
                    if (own) {
                        return own.codigo;
                    }
                },
            },
            {title: 'Descripcion', field: '_', transform: (v, doc) => {
                    const own = doc.articulo ;
                    if (own) {
                        return own.descripcion;
                    }
                },
            },
            {title: 'Color', field: 'articulo_color'},
            {title: 'Pedido', field: 'pedido'},
            {title: 'Recibido', field: 'recibido'},
            {title: 'UDM', field: 'udm'},
        ]

        const reportConfig = {
            title: `Compras Detalle`,
            subTitle: "Creado por " + currentUser.username + " el " + moment().local().toISOString(),
            cols: colsNP,
            styles: {
                head1:  {backgroundColor:"#dbe0f0", textAlign: 'center'},
                number: {textAlign:'right'},
                row:    {textAlign:"left"}             
            },
        }

        const ret = await getAPI('/stock/porders/detail/', {...filter, tipo, skip: page*limit, limit:EXCEL_LIMIT})

        reportConfig['rows'] = ret['list'];

        return reportConfig;
    }, [filter, tipo, currentUser]);

    const columnVisibilityModel = {
        // Hide columns status and traderName, the other columns will remain visible
        np: tipo === 'NP' ? true : false,
        status: tipo === 'NP' ? true : false,
        tipo: tipo === 'NP' ? true : false,
        descripcion: tipo === 'NP' ? true : false,
        rec: tipo === 'NP' ? false : true,
        remito: tipo === 'NP' ? false : true,
    };

    return (
        <PageBase 
            paperProps={{width:'100%', height: 'calc(100% - 98px)'}} 
            title={
                <>
                    <PageTitle>{tipo === 'NP' ? "Pedidos Detalle" : "Recepciones Detalle"} ({num})</PageTitle> 
                    {tipo === 'NP' && <SearchBox label={"Status"} select options={options_status} value={filter['status']} onChange={handleFilter('status')}/>}
                    <SearchBox label={tipo==='NP' ? "NP" : "Rec/Rem"} value={filter['np']} onChange={handleFilter('np')}/>
                    <SearchBox label={"Articulo"} value={filter['articulo']} onChange={handleFilter('articulo')}/>
                    <SearchBox label={"Color"} value={filter['articulo_color']} onChange={handleFilter('articulo_color')}/>
                    <SearchBox label={"Provee"} value={filter['provee']} onChange={handleFilter('provee')}/>
                    <SearchBox label={"Obra"} value={filter['obra']} onChange={handleFilter('obra')}/>
                </>
            }
            actions={
                <Box sx={{display: 'flex'}}>
                    <ExportButton configReport={configReport}>EXCEL</ExportButton>
                    <ActivityOpenClose app='compras' withLabel/>
                </Box>
            }      
       >
            <DataGrid
                loading={loading} 
                rows={rows} 
                rowCount={num}
                columns={columns(tipo, handleAddTab)} 

                // pagination
                // paginationMode="server"
                // pageSize={limit}
                // onPageChange={(newPage) => setPage(newPage)}
    
                rowHeight={35} 
                hideFooter={false} 
                sx={{
                    'root.border': '2px solid red',
                    'root.height': '100vh'
                }}

                columnVisibilityModel={columnVisibilityModel}
              
            />    

        </PageBase>
    )
})

export default IndexPage;