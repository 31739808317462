import { createTheme } from '@mui/material/styles';

// ver https://mui.com/material-ui/customization/default-theme/

export const defaultTheme = createTheme({
    typography: {
        button: {
          fontSize: '1rem',
        },
    },
    status: {
      danger: '#e53e3e',
    },
    palette: {
      primary: {
        main: '#0971f1',
        darker: '#053e85',
      },
      red: {
        main: '#f20b56ed',
        contrastText: '#fff',
      },
      green: {
        main: '#8bc34a',
        contrastText: '#fff',
      },
      yellow: {
        main: '#ffeb3b',
        contrastText: '#333',
      },
      blue: {
        main: '#4646dd',
        contrastText: '#eee6e6',
        '&:hover': {
          backgroundColor: '#5646dd',
          borderColor: '#0062cc',
        }
      }
    },
    components: {
      MuiTableCell1: {
        styleOverrides: {
          root: {
            paddingTop: 14,
            paddingBottom: 4,
            '&:lastChild': { paddingRight: '5px' },
          },
        },
        defaultProps: {
          padding: '40px 12px 40px 16px',
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            //transform: 'translate(21px, -2px) scale(0.75)'
          }
        }
      },
      // MuiButton: {
      //   styleOverrides: {
      //     root: {
      //       lineHeight: 1,
      //       fontSize: "11px",
      //       padding: '4px 4px',
      //       fontWeight: 800,
      //     },
      //   }
      // },
    }
  });
  