import React, {useState, useRef} from 'react';
import {makeStyles} from "@mui/styles";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const useStyles = makeStyles( (theme) => ({
    root: {
        border: '1px solid inherit'
    },
    dragActive: {
        border: '1px solid blue'
    },
    inputDrop: {

    },
    inputFile: {}
}))

const getFileTransfer = (event, allowMultiple) => {
    let items = [];
    if (event.dataTransfer) {
      const dataTransfer = event.dataTransfer;
      if (dataTransfer.files && dataTransfer.files.length) {
        items = dataTransfer.files;
      } else if (dataTransfer.items && dataTransfer.items.length) {
        items = dataTransfer.items;
      }
    } else if (event.target && event.target.files) {
      items = event.target.files;
    }
  
    if (items.length > 0) {
      if (allowMultiple) {
        return Array.prototype.slice.call(items);
      }
      return [items[0]];
    }
    return [];
}
  
const InputDropZone = ({
    label="", 
    uri="/api/attachments/upload", 
    folder="/comercial/pres", 
    disabled=false,
    multiple=false, 
    withList=true,
    children, 
    dragDropSupported=true, 
    onUploaded=()=>{}
}) => {
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [dragActive, setDragActive] = useState(false);
    // eslint-disable-next-line
    const [reload, setReload] = useState(0);
    const [show, setShow] = useState(withList);
    const [error, setError] = useState("");
    const [errorArr, setErrorArr] = useState([]);
    const fileInput = useRef();

    const sendFile = (file, onSuccess, onError) => {
        
        debugger
        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        // Handle network-level errors (like the request never reaching the server)
        xhr.onerror = function () {
            console.error('Network Error: Could not reach the server.');
            setError('Network Error: Could not reach the server.')
        };
        xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
            // Request was successful
            console.log('Success:', xhr.responseText);
            } else {
            // Handle HTTP errors (non-2xx status codes)
            console.error('HTTP Error:', xhr.status, xhr.statusText);
            }
        };
        xhr.upload.addEventListener("progress", function(e) {
            if (e.lengthComputable) {
                const percentage = Math.round((e.loaded * 100) / e.total);
                file.progress = percentage;
                setReload(percentage)
            }
        }, false);

        xhr.upload.addEventListener("load", function(e) {
            //if (onUploaded) onUploaded(file)
            setReload(0)
        }, false);
        xhr.open("POST", uri, true);
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    console.log(xhr.responseText)
                    if (xhr.responseText) {
                        const res = JSON.parse(xhr.responseText)
                        if (onSuccess) onSuccess(res.doc)
                    }
                }
                if (xhr.status === 500 || xhr.status === 400) {
                    try {
                        var errorResponse = JSON.parse(xhr.responseText); // Parse the JSON error response
                        console.error('Error from server:', errorResponse.error.message);
                        if (onError) {
                            onError(errorResponse.error.message)
                        }
                    } catch (e) {
                        // If response is not valid JSON, handle it differently
                        console.error('Server returned a non-JSON response:', xhr.responseText);
                        if (onError) {
                            onError(xhr.statusText)
                        }
                    }
                }
            }
        };
        fd.append('folder', folder);
        fd.append('myFile', file);
        // Initiate a multipart/form-data upload
        xhr.send(fd);
    }

    const handleDragEnter = (ev) => {
        ev.preventDefault();
    }
    const handleDragLeave = (ev) => {setDragActive(false)}
    const handleDragOver = (ev) => {
        ev.preventDefault()
        ev.stopPropagation()
        if (!dragActive) setDragActive(true)
    }
    const handleDrop = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        debugger
        const filesArray = getFileTransfer(ev, multiple);
        for (let i=0; i < filesArray.length; i++) {
            sendFile(filesArray[i], handleUploaded, handleError(i));
        }
        const newFiles = multiple
            ? [...files, ...filesArray]
            : filesArray;
        setFiles(newFiles)  
        setDragActive(true)
    }
    const handleShow = (ev) => {
        setShow(!show)
    }
    const handleClick = (ev) => {}
    const handleDelete = (inx) => (ev) => {
        files.splice(inx, 1);
        errorArr.splice(inx, 1);
        setFiles([...files])
        setErrorArr([...errorArr])
    }
    const handleUploaded = (doc) => {
        if (onUploaded) {
            onUploaded(doc)
        }
    }
    const handleError = inx => err => {
        console.log('HAY ERROR', err)
        errorArr[inx] = err;
        setErrorArr([...errorArr])
    }

    let globalProgress = 0;
    // eslint-disable-next-line
    files.map( it => {
        globalProgress += it.progress;
    });
    globalProgress = files.length ? Number(globalProgress/files.length).toFixed(1) : 0;

    return (

        <Box
            sx={{p:1}}
            onDrop={handleDrop}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            className={classes.root}
            style={{border: dragActive && "1px solid blue"}}
        >

            <div className={classes.inputDrop}>
                {dragDropSupported ?
                    <Typography variant='h5'>
                        {label}
                    </Typography>
                    :
                    <Button
                        label={label}
                        onClick={handleClick}
                    />
                }
                <input
                    ref={fileInput} // eslint-disable-line
                    type='file'
                    name='myFile'
                    disabled={disabled}
                    multiple={multiple}
                    onChange={ handleDrop }
                    className={ classes.inputFile }
                />
                
                {globalProgress > 0 ? (globalProgress < 100 ? globalProgress + "%" : <CheckIcon style={{color:'green'}}/>) : ""}
                {files.length > 0 && <IconButton onClick={handleShow}>{!show ? <ExpandMoreIcon/> : <ExpandLessIcon/>}</IconButton> }

                {   
                    show && 
                    files.map( 
                        (it, inx) => {
                            return (
                                <Box sx={{border: errorArr[inx] ? '1px solid red' : ''}}>
                                    <Box sx={{m:1}}>{it.name}/{Number(it.size/1000).toFixed(1)}kb</Box>
                                    { it.progress < 100 && <LinearProgress variant="determinate" value={it.progress}/>} 
                                    <span style={{color:'red'}}>{ errorArr[inx] }</span>
                                    { errorArr[inx] ? <IconButton onClick={handleDelete(inx)}><DeleteIcon/></IconButton> : <CheckIcon style={{color: 'green'}}/>}
                                </Box>
                            )
                        }
                    )
                }
            </div>

            { children }

        </Box>
    )
}
export default InputDropZone;