import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import PopupState from 'material-ui-popup-state';
//import PopupState, { bindToggle, bindPopper, bindHover } from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

import {
  usePopupState,
  bindTrigger,
  bindMenu,
  bindHover,
  bindPopper
} from 'material-ui-popup-state/hooks'

import AttachFileIcon from '@mui/icons-material/AttachFile';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: 10,
  },
}));

const IconPopperPopupState1 = ({label, children, placement="top-start", timeout=350}) => {
  const classes = useStyles();

  return (
    <PopupState variant="popper" popupId="demo-popup-popper">
      {(popupState) => (
        <div>
          {label}
          <IconButton variant="contained" color="primary" {...bindHover(popupState)}>
            <AttachFileIcon/>
          </IconButton>
          <Popper {...bindPopper(popupState)} placement={placement} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={timeout}>
                <Paper>
                  {children}
                </Paper>
              </Fade>
            )}
          </Popper>
        </div>
      )}
    </PopupState>
  );
}

const IconPopperPopupState = ({label, children, placement="top-start", timeout=350}) => {
  const classes = useStyles();
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })
  return (
    <div>
      <IconButton variant="contained" color="primary" {...bindHover(popupState)}>
        <AttachFileIcon/> {label}
      </IconButton>
      <Popper {...bindPopper(popupState)} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={timeout}>
            <Paper>
              {React.cloneElement(children, {onCancel: popupState.close})}
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  )
}

export default IconPopperPopupState