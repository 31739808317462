import React, {useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack'; 
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {lime} from '@mui/material/colors';
import { HeaderBar, Attributes, SearchBox, PageTitle } from '../../components';

import ContactForm from './ContactForm';
import {getAPI, deleteAPI, useTabs} from '../../utils' ;
import {checkUserRoles} from '../../utils/checkUserRoles';
import OpportunityList from './OpportunityList'
import ActivityOpenClose  from '../Activities/ActivityOpenClose';

export default function ContactShowEdit({provee, docId, onReload, ...other}) {
    const { enqueueSnackbar } = useSnackbar();
    const [edit, setEdit] = useState(docId === 'new');
    const [ doc, setDoc ] = useState({
      oppos: [],
      nombre: '',
      apellido: '',
      razon_social: '',
      email: '', 
      es_provee: false
    });
    const [material, setMaterial] = useState("");
    const [color, setColor] = useState("");
    const [reload, setReload] = useState(false);
    const {appendTab, updateCurrentTab, removeCurrentTab} = useTabs({onReload: (reload) => {setReload(true)}});

    // const [ docOppos, setDocOppos ] = useState({})
    // const [rows, setRows ] = useState({});
    const currentUser = useSelector(state => state.currentUser);
    const disabled = !checkUserRoles(currentUser, ['local-admin', 'gerencia', 'compras-edit'])

    const nombre = provee === '1' ? "[" + doc.codigo_proveedor + "] " + doc.fullname : (doc.tipo ? doc.tipo : "") + " " + doc.fullname;
    const tipo = provee === '0' ? 'Contacto' : 'Proveedor';
    let title;
    if (docId === 'new') {
      title = "Nuevo " + tipo;
    } else {
      if (edit) {
        title = "Edición de " + nombre;
      } else {
        title = tipo + " " + nombre;
      }  
    }

    // const title = !doc || docId === 'new' ? "Nueva NP" : (edit ? "Edición de NP " + doc.tipo : "Nota de Pedido " + doc.tipo);
    useEffect( () => {
      debugger
      setReload(false)
      // reads data to Edit instead of using datagrid row
      if ( docId === 'new' ) return 
      getAPI('/crm/contacts/' + docId)
      .then(data => {
        debugger
        // Agregando campo 'contacto' a regisro opportunity con dato de Contant 
        data.oppos.forEach( it => {it.id = it._id; it.contacto = data.razon_social ? data.razon_social : data.nombre + ' ' + data.apellido });
        setDoc(data);
        // setDocOppos(data);

        if (onReload) onReload(true)

      })
      .catch(err => {
          debugger
          console.log(err.message)
          enqueueSnackbar(err.message, {variant: "error"})
      });   
    }, [docId, edit, reload, enqueueSnackbar])

    const handleEdit = () => {
      setEdit(!edit)
    }

    const handleCancel = () => {
      setEdit(false)

      if (docId === 'new') {
        removeCurrentTab()
      }
    }

    const handleClose = (doc) => {
      debugger
      if (doc) {

        if (!doc.oppos) doc.oppos = [];         // importante agregar porque sino falla

        if (docId === 'new') {
          updateCurrentTab('CONTACT')(doc)
        }
      }
      setEdit(false)
      if (onReload) onReload(true)
    }
    
    const handleClick= tipo => item => (ev) => {
      debugger
      ev.preventDefault();
      if (item.id) appendTab(tipo)(item)
    }

    const handleNewOppo = () => {
      appendTab('OPPO')({_id: 'new', contacto: doc})
    }

    const handleDelete = () => {
      deleteAPI('/crm/contacts/' + docId)
      .then(data => {
          enqueueSnackbar("Se borró el registro", {variant: "info"})
          if (onReload) onReload(true)
          removeCurrentTab()
      })
      .catch(err => {
          console.log(err.message)
          enqueueSnackbar(err.message, {variant: "error"})
      });         
    }

    const attributesList = [
      {
        label: 'Email',
        text: doc.email,
      },
      {
        label: 'Teléfono',
        text: doc.telefono
      },
      {
        label: 'Celular',
        text: doc.celular
      },
      {
        label: 'Dirección',
        text: [doc.direccion, doc.partido, doc.provincia].join(' - ')
      },
      {
        label: 'Activo',
        text: doc.activo
      },
      {
        label: 'Responsable',
        text: doc.ownerId && doc.ownerId.username
      },
      provee === '0' && {
        label: 'Oportunidades del Contacto',
        text: doc.oppos.length
      },
      provee === '1' && {
        label: 'Ordenes de compra',
        text: (doc.numPO || "0"),
      },
      provee === '1' && {
        label: 'Lista de precios',
        text: <span onClick={handleClick('PL')(doc)}><a href="#">Ver LP</a></span>
      },
    ]
    const r1 = new RegExp(".*" + material, 'i');
    const r2 = new RegExp(".*" + color, 'i');
    const opposList = doc.oppos
                      .filter(it => (material && it.material && it.material.match(r1) || !material))
                      .filter(it => (color && it.color && it.color.match(r2) || !color))
    return (
      <Paper sx={{p:1, width: '100%', height: '100%'}} {...other}> 
        <HeaderBar
          sx={{backgroundColor: lime[700]}} 
          // color={lime[500]}
          title={[
            <div>
              { title }
              { false && docId !== 'new' && doc &&
                <Typography sx={{ color: 'white', display: 'inline', float: 'right', mr:2}} variant='h6'>
                  {doc.email} &nbsp;
                  Teléfono/Celular:  [{doc.telefono + ' / ' + doc.celular}]
                </Typography>   
              } 
            </div>
          ]}
          actions={[
            !edit && docId !== 'new' && <Button autoFocus color="inherit" disabled={disabled} onClick={handleEdit} size='small' variant="outlined">{!edit ? "Editar" : "Cancelar"}</Button>,
            // edit && docId !== 'new'  && <Button autoFocus variant='outlined' color="inherit" disabled={disabled} onClick={handleDeleteDialog}>{"Borrar"}</Button>
            // edit && docId !== 'new' && doc.detail.length == 0 && <Button autoFocus variant='outlined' color="inherit" disabled={disabled} onClick={handleDeleteDialog}>{"Borrar"}</Button>
            docId !== 'new' && <ActivityOpenClose sx={{color: 'inherit'}} app='contactos' docId={docId}/>
          ]}
          info={[
            false && !edit && 
              [
                <Typography sx={{ color: 'white', display: 'inline'}}>
                  <span>Dirección: {doc.direccion} </span> &nbsp; 
                  <span>Partido/Provincia: {doc.partido} / {doc.provincia}</span> &nbsp;

                </Typography> ,
                <Typography sx={{ color: 'white', display: 'inline', float: 'right'}}>
                  {provee === '0' && <span>Oportunidades del Contacto= ({doc.oppos.length}) </span>}
                  {provee === '1' && <span>Ordenes de compra= {doc.numPO || "0"} </span>}
                  {provee === '1' && <span onClick={handleClick('PL')(doc)}><a href="#" style={{color:'#ddd'}}>Ver LP</a></span>}               
                </Typography>
              ]       
            // <Box sx={{display: 'inline-block'}}>a [{provee && provee.codigo_proveedor}] {proveeName} para [{moment(doc.fecha_entrega).utc().format('YYYY-MM-DD')}]: {doc.descripcion}</Box>,
            // <Box sx={{float:'right'}}>Entrega: {doc.lugar_entrega}</Box>,
          ]}
        />
          { docId !== 'new' && doc &&
            <Attributes
              list={attributesList}
              sizes={{xs:4}}
            />
          }

          { !edit && provee === '0' && 
            <>
                <Box sx={{m:1, textAlign:'center'}}>
                  {!disabled && <Button onClick={handleNewOppo} variant='contained'>Crear nueva Oportunidad</Button>}
                </Box>
            
                  <PageTitle sx={{display: 'inline-block'}}><Typography variant='h5'>Oportunidades</Typography></PageTitle>
                  <SearchBox sx={{ml:1}} label="Material" value={material} onChange={ev=>setMaterial(ev.target.value)}/>
                  <SearchBox sx={{ml:1}} label="Color" value={color} onChange={ev => setColor(ev.target.value)}/>
                  <Box sx={{height: 'calc(100% - 400px)'}}>
                    <OpportunityList 
                      docs={opposList}
                      columnVisibilityModel={{contacto: false}}
                      onClick={handleClick}
                    />         
                  </Box>
            </>
          }
        
        <Box sx={{m:2}}/>

        <Box sx={{height: '100%'}}>
          
          { (edit || provee === '1') && 
            <ContactForm 
              disabled={!edit}
              provee={provee}
              docId={docId} 
              doc={doc} 
              onClose={handleClose} 
              onCancel={handleCancel} 
            /> 
          }

        </Box>
      </Paper>
  )
}
