import { useContext } from 'react';
import { TabsContext } from '../ui/Layout/TabsProvider'; 
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";

import OpportunityShowEdit from '../ui/Crm/OpportunityShowEdit';
import ProjectShowEdit from '../ui/Project/ProjectShowEdit';
import PostSaleShowEdit from '../ui/PostVenta/PostSaleShowEdit';
import ContactShowEdit from '../ui/Crm/ContactShowEdit';
import WorkOrderShowEdit from '../ui/Fabrica/WorkOrderShowEdit';
import POrderShowEdit from '../ui/Compras/POrder/POrderShowEdit';
import ROrderShowEdit from '../ui/Compras/POrder/ROrderShowEdit';
import PriceListIndex from '../ui/Compras/PriceList/PriceListIndex';
import PartEditForm from '../ui/Stock/Part/PartEditForm';
import StockMoveEdit from '../ui/Stock/StockMoveEdit';
import StockMaterialsIndex from '../ui/Stock/StockMaterialsIndex';
import TaskIndex from '../ui/Task/TaskIndex';

import {checkUserRoles} from './checkUserRoles';

export const useTabs = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const currentUser = useSelector(state => state.currentUser);
    const {dispatchTabs} = useContext(TabsContext);
    const {onReload=()=>{}} = props || {};

    const opTab = (item={}) => ({    
        label: item._id === 'new' ? "Nueva OPPO" : "OPPO " + item.nombre,
        component: <OpportunityShowEdit docId={item._id === 'new' ? 'new' : item._id} defContact={item.contacto} onReload={onReload}/>, 
        searchable: item._id !== 'new',
        minRoles: ['crm','crm-edit']
    })
    const obTab = (item={}) => ({    
      label: item._id === 'new' ? "Nueva OBRA" : "OBRA " + item.nombre,
      component: <ProjectShowEdit docId={item._id === 'new' ? 'new' : item._id} onReload={onReload}/>, 
      searchable: item._id !== 'new',
      minRoles: ['obra','obra-edit']
    })
    const poTab = (item={}) => ({    
      label: item._id === 'new' ? "Nuevo POSTVENTA" : "PV " + item.pv,
      component: <PostSaleShowEdit docId={item._id === 'new' ? 'new' : item._id} onReload={onReload}/>, 
      searchable: item._id !== 'new',
      minRoles: ['posventa','posventa-edit']
    })
    const orsTab = (item={}) => ({    
      label: item._id === 'new' ? "Nueva ORDEN-S" : "OS " + item.of,
      component: <WorkOrderShowEdit tipo='WORK-S' docId={item._id === 'new' ? 'new' : item._id} relObra={item.obra} onReload={onReload}/>, 
      searchable: item._id !== 'new',
      minRoles: ['servicio','servicio-edit']
    })
    const orfTab = (item={}) => ({    
      label: item._id === 'new' ? "Nueva ORDEN-F" : "OF " + item.of,
      component: <WorkOrderShowEdit tipo='WORK-F' docId={item._id === 'new' ? 'new' : item._id} relObra={item.obra} onReload={onReload}/>, 
      searchable: item._id !== 'new',
      minRoles: ['fabrica', 'fabrica-edit']
    })
    const ctTab = (item={}) => {
        let label = 'Nuevo CONTACTO';
        if (item._id !== 'new') {
            if (item.razon_social) {
                label = item.razon_social;
            } else {
                label = item.nombre + " " + item.apellido;
            }
        }
        return {
            label: label,
            component: <ContactShowEdit provee='0' docId={item._id} onReload={onReload}/>, 
            searchable: item._id !== 'new',
            minRoles: ['crm','crm-edit']
        }
    
    }
    const prTab = (item={}) => {
        let label = 'Nuevo PROVEEDOR';
        if (item._id !== 'new') {
            if (item.razon_social) {
                label = item.razon_social;
            } else {
                label = item.nombre + " " + item.apellido;
            }
        }
        return {    
            label: label,
            component: <ContactShowEdit provee='1' docId={item._id} onReload={onReload}/>, 
            searchable: item._id !== 'new',
            minRoles: ['compras','compras-edit']
        }
    }

    const  npTab = (item={}) => ({    
        label: item._id === 'new' ? "Nueva NP" : "NP #" + item.np,
        component: <POrderShowEdit docId={item._id} onReload={onReload}/>, 
        searchable: item._id !== 'new',
        minRoles: ['compras','compras-edit']
    })

    const  recTab = (item={}) => ({    
        label: item._id === 'new' ? "Nueva REC" : "REC #" + item.rec,
        component: <ROrderShowEdit docId={item._id} onReload={onReload} />, 
        searchable: item._id !== 'new',
        minRoles: ['compras','compras-edit']
    })

    const  plTab = (item={}) => ({    
        label: item._id === 'new' ? "Nueva Lista" : "LP #" + item.codigo_proveedor,
        component: <PriceListIndex proveeId={item._id} provee={item} vigente={item.vigente} tipo='1' onReload={onReload} />, 
        searchable: item._id !== 'new',
        minRoles: ['compras','compras-edit']
    })

    const  partTab = (item={}) => ({    
        label: item._id === 'new' ? "Nuevo Articulo" : "Part #" + item.codigo,
        component: <PartEditForm docId={item._id} onReload={onReload} />, 
        searchable: item._id !== 'new',
        minRoles: ['inventarios']
    })

    const  stockMovTab = (doc) => ({    
        label: doc._id === 'new' ? 'Nuevo ' + doc.tipo : doc.tipo[0] + doc.comprobante_int ,
        component: <StockMoveEdit docId={doc._id} tipo={doc.tipo} onReload={onReload}/>, 
        searchable: true,
        minRoles: ['inventarios','inventarios-edit']
    })

    const  stockMatTab = (doc) => ({    
        label: "MATERIALES" ,
        component: <StockMaterialsIndex ofs={doc['ofs']} pendiente={doc['pendiente']} onReload={onReload}/>, 
        searchable: true,
        minRoles: ['inventarios','inventarios-edit']
    })

    const  taskTab = (doc) => ({    
        label: doc['board'] && doc['board']['name'] || "123" ,
        component: <TaskIndex board={doc['board']} onReload={onReload}/>, 
        searchable: true,
    })

    const getTab = (tipo, item) => {
        let ttab
        if (tipo === "OPPO") {
            ttab = opTab(item)
        }
        if (tipo === "OBRA") {
          ttab = obTab(item)
        }
        if (tipo === "PVTA") {
          ttab = poTab(item)
        }
        if (tipo === "WORK-S") {
          ttab = orsTab(item)
        }
        if (tipo === "WORK-F") {
          ttab = orfTab(item)
        }
        if (tipo === 'CONTACT') {
            ttab = ctTab(item)
        }
        if (tipo === 'PROVEE') {
            ttab = prTab(item)
        }
        if (tipo === 'NP') {
            ttab = npTab(item)
        }
        if (tipo === 'REC') {
            ttab = recTab(item)
        }
        if (tipo === 'PL') {
            ttab = plTab(item)
        }
        if (tipo === 'PART') {
            ttab = partTab(item)
        }
        if (tipo === 'MOV') {
            ttab = stockMovTab(item)
        }
        if (tipo === 'MAT') {
            ttab = stockMatTab(item)
        }
        if (tipo === 'TASK') {
            ttab = taskTab(item)
        }
        ttab['tipo'] = tipo;
        return ttab;        
    }

    const appendTab = tipo => item => {
        debugger

        const ttab = getTab(tipo, item);
        if (ttab && dispatchTabs) {

            // chequea permisos
            if (currentUser && ttab.minRoles) {
                const canAdd = checkUserRoles(currentUser, ['local-admin', 'gerencia', ...ttab.minRoles || []]);
                if (!canAdd) {
                    enqueueSnackbar('Lo siento! No tiene permisos para navegar sobre esta entidad', {variant: 'error'})
                    return
                }
            }
            dispatchTabs({
                type: 'addTab',
                ...ttab
            })    
        } else {
            console.log("ERROR no hay ttab")
        }
    }

    const updateCurrentTab = tipo => item => {
        debugger
        const ttab = getTab(tipo, item);
        if (ttab && dispatchTabs) {
            dispatchTabs({
                type: 'updateCurrentTab', 
                ...ttab
            })
        }
    }

    const removeCurrentTab = () => {
        if (dispatchTabs)
            dispatchTabs({type: 'removeCurrentTab'})
    }

    return {appendTab, updateCurrentTab, removeCurrentTab, dispatchTabs}
}
