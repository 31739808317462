import React, {useState, useEffect} from 'react';
import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { BarrioAutocompleteInput, LocalidadAutocompleteInput, ProvinciaAutocompleteInput, PartidoAutocompleteInput, PaisAutocompleteInput} from '../Settings/Places/PlacesAutocomplete';
import { VendedorAutocompleteObjectInput } from '../Settings/Users/UserAutocomplete';
import { ContactAutocompleteObjectInput } from './ContactAutocomplete';
import { PageBase, Form, SelectInput } from '../../components';
import { MarcaSelectInput, MarcaTerminacionSelectInput, MarcaTerminacionColorSelectInput } from '../Settings/Config/MarcaSelectInput';
import { getAPI, postAPI, checkUserRoles, activityLog } from '../../utils';

import {options_opos_status, options_moneda, options_exito, options_sino, options_dto_profesional} from '../../appConfig';
 
const OpportunityEdit = ({
        docId, 
        defContact={},
        edit=true, 
        debug=false,
        onAfterSubmit=(data) => {},
        onCancel=() => {},
        ...other
    }) => {
debugger
    let params = useParams();
    const currentUser = useSelector(state => state.currentUser);
    const disOpts = useSelector( state => state.distributors);
    const disOptDefault = disOpts.filter(it => !!it.default);
    const [doc, setDoc] = useState({
        mosquiteros:'NO', 
        premarcos:"NO", 
        cortinas: "NO", 
        contramarcos: "NO", 
        moneda: 'USD', 
        exito: 25, 
        dto_profesional: 'SIN',
        pais: "ARGENTINA",
        contacto: defContact,
        distributorId: (disOptDefault && disOptDefault.length>0 ? disOptDefault[0]._id : ""),
        ownerId: checkUserRoles(currentUser, ['esVendedor']) ? currentUser : {}
    });
    const [docOrig, setDocOrig] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { enqueueSnackbar } = useSnackbar();

    let id = docId || params.id;
 
    const optionsDistrib = disOpts ? disOpts.map(it => ({label:it.nombre, value:it._id}) ) : [];

    const optionsAll = useSelector(state => state.options) || {};
    const options_ingreso_por = optionsAll['oposRecommended'] || [];
    const options_tipo_obra = optionsAll['oposProjectType'] || [];
    const options_motivo_perdida = optionsAll['oposMotives'] || [];
    const options_material = optionsAll['tiposMaterial'] || [];
    const options_vidrios = optionsAll['tiposVidrio'] || [];

    const hidden = !checkUserRoles(currentUser, ['local-admin'])

    useEffect( () => {
        if (!id || id === "new") return;

        setLoading(true);
        getAPI('/crm/oppos/' + id)
        .then(data => {
            setLoading(false);
            setDoc(data);
            if (!edit) {
                setDocOrig(data);
            }
        });
    }, [id, edit]);

    const onSubmit = (data, diffMgs) => {
        debugger
        if (id === 'new') {
            id = "";
        }
        postAPI('/crm/oppos/' + id, data)
        .then(({res,doc}) => {
            if(id === '') {
                const msg = "Se creó nueva oportunidad";
                activityLog({label: doc.nombre, app:'comercial', docId:doc._id, msg, currentUser})
                enqueueSnackbar(msg, {variant: 'info'})
            } else {
                activityLog({label: doc.nombre, app:'comercial', docId, diffMgs, currentUser})
                enqueueSnackbar("Se guardo el cambio correctamente", {variant: 'info'})
            }

            onAfterSubmit(doc)
        })
        .catch(err => {
            debugger
            setError({title: err.message})
        });
    }
  
    const watchChanges = (value, name, type, getValues, setValue) => {
        debugger
        if ( type==='change') {
            if (name === 'marca') {
                setValue('terminacion', '')
                setValue('color', '')
            }
            if ( name === 'terminacion') {
                setValue('color', '')
            }
        } 
    }

    const diffFnContacto = (orig) => {
        if (!orig || Object.keys(orig).length === 0) return 'SIN';
        if (orig && orig['fullname']) {
            return orig['fullname'];
        }
    }

    const datosSection = [
        {
            name: 'datos',
            label: 'Datos',
            type: 'divider',
            sizes: {xs:12}
        },
        {
            label: 'Nombre Oportunidad',
            name: 'nombre',
            rules: {
                //required: "Debe ingresar valor",
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:6}
        },
        {
            label: "Contacto",
            name: 'contacto',
            Component: ({...other}) => ContactAutocompleteObjectInput({...other, withAdd: !!edit}),
            diffFn: diffFnContacto,            
            rules: {
                required: "Debe ingresar valor",
            },
            sizes: {xs:6}
        },
        {
            label: 'Descripción',
            name: 'descripcion',
            rules: {
                // required: "Debe ingresar valor",
            },
            sizes: {xs:12, md:6}
        },
        {
            label: 'Distribuidor',
            name: 'distributorId',
            Component: ({...other}) => <SelectInput {...other} localField="_id" />,
            diffFn: (orig) => {
                if (orig && orig['_id']) return orig['_id']
                return orig
            },
            options: optionsDistrib,
            rules: {
                required: "Debe ingresar valor",
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Vendedor',
            name: 'ownerId',
            Component: VendedorAutocompleteObjectInput,
            diffFn: (orig) => {return orig ? orig['username'] : 'SIN'},
            rules: {
                required: "Debe seleccionar valor",
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Presupuesto',
            name: 'presupuesto',
            rules: {
                required: "Debe ingresar codigo de presupuesto",
            },
            sizes: {xs:4, md:2}
        },
        {   label: "Observación",
            name: "observacion",
            sizes: {xs: 10}
        },
        {
            label: 'Dto. Profe.',
            name: 'dto_profesional',
            options: options_dto_profesional,
            sizes: {xs:4, md:2}
        },
    ]
    const propuestaSection = [
        {
            name: 'propuesta',
            label: 'Propuesta',
            type: 'divider',
            sizes: {xs:12}
        },
        {
            label: 'Valor',
            name: 'valor',
            rules: {
                required: "Debe ingresar valor"
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Valor Fabrica',
            name: 'valor_rep',
            rules: {
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Moneda',
            name: 'moneda',
            options: options_moneda,
            sizes: {xs:4, md:2}
        },
        {
            label: 'Exito',
            name: 'exito',
            options: options_exito,
            sizes: {xs:4, md:2}
        },
        {
            label: 'Fecha Estimada',
            name: 'fecha_estimada',
            dateProps: {format:"YYYY-MM-DD"},
            rules: {
                required: "Debe ingresar valor"
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Fecha Revision',
            name: 'fecha_revision',
            dateProps: {format:"YYYY-MM-DD"},
            rules: {
                required: "Debe ingresar valor"
            },
            sizes: {xs:4, md:2}
        },
    ];
    const direccionSection = [
        {
            name: 'direccion',
            label: 'Dirección',
            type: 'divider',
            sizes: {xs:12}
        },
        {
            label: 'Barrio',
            name: 'barrio',
            Component: BarrioAutocompleteInput,
            rules: {
                //required: "Debe ingresar valor",
            },
            sizes: {xs:8, md:4}
        },
        {
            label: 'Lote',
            name: 'lote',
            rules: {
                //required: "Debe ingresar valor",
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Direccion',
            name: 'direccion',
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                },
            },
            sizes: {xs:8, md:6}
        },
        {
            label: 'Localidad',
            name: 'localidad',
            Component: LocalidadAutocompleteInput,
            rules: {
                // validate: {
                //     exists: (v) => !!v || 'Es obligatorio',
                //     isEmail: v => /.*@.*/ || "Debe ingresar formato kkkk@sss.ddd",
                // },
            },
            sizes: {xs:4, md:3}
        },
        {
            label: 'Partido',
            name: 'partido',
            Component: PartidoAutocompleteInput,
            rules: {
                required: "Debe ingresar valor",
                // validate: {
                //     exists: (v) => !!v || 'Es obligatorio',
                //     isEmail: v => /.*@.*/ || "Debe ingresar formato kkkk@sss.ddd",
                // },
            },
            sizes: {xs:4, md:3}
        },
        {
            label: 'Provincia',
            name: 'provincia',
            Component: ProvinciaAutocompleteInput,
            rules: {
                required: "Debe ingresar valor",
            },
            sizes: {xs:4, md:3}
        },
        {
            label: 'Pais',
            name: 'pais',
            Component: PaisAutocompleteInput,
            rules: {
            },
            sizes: {xs:4, md:3}
        },
    ]
    const aberturasSection = [
        {
            name: 'aberturas',
            label: 'Aberturas',
            type: 'divider',
            sizes: {xs:12}
        },
        {
            label: 'Cantidad',
            name: 'cantidad',
            rules: {
                required: "Debe ingresar valor"
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'ML',
            name: 'ml',
            rules: {
                required: "Debe ingresar valor"
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'M2',
            name: 'm2',
            rules: {
                required: "Debe ingresar valor"
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Tipo Obra',
            name: 'tipo_obra',
            options: options_tipo_obra,
            rules: {
                required: "Debe ingresar valor",
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Ingreso Por',
            name: 'ingreso_por',
            options: options_ingreso_por,
            rules: {
                required: "Debe ingresar valor",
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Ups',
            // name: 'ups',
            // rules: {
            //     required: "Debe ingresar valor",
            // },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Vidrio',
            name: 'vidrios',
            options: options_vidrios,
            rules: {
                required: "Debe ingresar valor",
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Material',
            name: 'material',
            options: options_material,
            rules: {
                required: "Debe ingresar valor",
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Marca',
            name: 'marca',
            Component: MarcaSelectInput,
            rules: {
                required: "Debe ingresar valor",
            },
            sizes: {xs:4, md:2}
        },
        {
            label: 'Terminacion',
            name: 'terminacion',
            Component: MarcaTerminacionSelectInput,
            rules: {
                required: "Debe ingresar valor",
            },
            sizes: {xs:6, md:2}
        },
        {
            label: 'Color',
            name: 'color',
            Component: MarcaTerminacionColorSelectInput,
            rules: {
                required: "Debe ingresar valor",
            },
            sizes: {xs:6, md:4}
        },
        {
            label: 'Premarcos',
            name: 'premarcos',
            options: options_sino,
            sizes: {xs:3}
        },
        {
            label: 'Contramarcos',
            name: 'contramarcos',
            options: options_sino,
            sizes: {xs:3}
        },
        {
            label: 'Mosquiteros',
            name: 'mosquiteros',
            options: options_sino,
            sizes: {xs:3}
        },
        {
            label: 'Cortinas',
            name: 'cortinas',
            options: options_sino,
            sizes: {xs:3}
        },
    ]
    const adminSection = [
        {
            name: 'admin',
            label: 'Admin',
            type: 'divider',
            sizes: {xs:12}
        },
        {
            label: 'Status',
            name: 'status',
            options: options_opos_status.filter(it => it !=='TODOS').map(it => ({label: it, value: it})),
            sizes: {xs:4, md:2}
        },
        {
            label: 'Fecha Ingreso',
            name: 'fecha_ingreso',
            dateProps: {format:"YYYY-MM-DD"},
            sizes: {xs:4, md:2}
        },        
        {
            label: 'Fecha Ganada',
            name: 'fecha_ganada',
            dateProps: {format:"YYYY-MM-DD"},
            sizes: {xs:4, md:2}
        },        
        {
            label: 'Fecha Cierre',
            name: 'fecha_cierre',
            dateProps: {format:"YYYY-MM-DD"},
            sizes: {xs:4, md:2}
        },
        {
            label: "Motivo Perdida",
            name: "motivo_perdida",
            options: options_motivo_perdida,
            sizes: {xs:4, md:2}
        }
    ]

    let fieldsArr = [...datosSection, ...propuestaSection, ...direccionSection, ...aberturasSection]
    if (!hidden && docId !== 'new') fieldsArr = [...fieldsArr, ...adminSection];

    return (
        <Box {...other}>
            <Box sx={{color:'red'}}>
                {error}
            </Box>
            <Form
                loading={loading}
                disabled={!edit}
                fields={fieldsArr}
                defaultValues={doc}
                watchChanges={watchChanges}
                onSubmit={onSubmit}
                actionsComp={ (onSubmit, isValid) => (
                    <Box sx={{textAlign: 'center', margin:'10px'}}>
                        <Button 
                            sx={{minWidth: '100px', marginRight: '10px'}}
                            color='secondary'
                            variant='outlined' 
                            onClick={onCancel}>Cancelar</Button>
                        <Button 
                            sx={{minWidth: '100px'}}
                            variant="outlined" 
                            //disabled={!isValid} 
                            onClick={onSubmit}>Guardar</Button>
                    </Box>
                    )                    
                }
                debug={debug}
            />
        </Box>
    );
};

export default OpportunityEdit;


export const OpportunityPage = ({...other}) => (
    <PageBase title={"Oportunidad"}>
        <OpportunityEdit {...other} />
    </PageBase>
)