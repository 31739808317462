import React, {forwardRef} from 'react'; 
import {GridActionsCellItem} from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
//import DeleteIcon from '@mui/icons-material/Delete';
import ExitIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import {DataGrid} from '../../components'; 

import moment from 'moment';

const columns = (onClick=()=>{}) => ([
    {
        field: 'pv',
        headerName: 'PV',
        type: 'string',
        renderCell: params => {
            const pv = params.value;
            if (onClick && onClick('PVTA')) {
                return <Link href="#" onClick={onClick('PVTA')(params.row)}>{pv}</Link>            
            }
        }
    },
    {
        field: 'nombre',
        headerName: 'NOMBRE',
        type: 'string',
        width: 300,
        renderCell: params => {
            const nombre = params.value;
            if (onClick && onClick('PVTA')) {
                return <Link href="#" onClick={onClick('PVTA')(params.row)}>{nombre}</Link>            
            }
        }
    },
    {
      field: 'contacto',
      headerName: 'Contacto',
      type: 'string',    
      renderCell: (params) => {
          const own = params.value; 
          const out = own ? own.fullname: ""; 
          const link = (onClick && onClick('CONTACT')) ? (
              <IconButton size='small' onClick={onClick('CONTACT')(own )}>
                  <ExitIcon fontSize='10'/>
              </IconButton>
          ) : ""
          return out && out.trim() ? [<span>{out}</span>, link] : own ? own : "";          
      },
      width: 240,
    },
    {
      field: 'obra',
      headerName: 'Obra',
      type: 'string',    
      renderCell: (params) => {
            const own = params.value;
            const out = own ?  own.carpeta + " " + own.nombre || "" : "";

            const link = (onClick && onClick('OBRA')) ? (
                <IconButton size='small' onClick={onClick('OBRA')(own ? own : "")}>
                    <ExitIcon fontSize='10'/>
                </IconButton>
            ) : ""
            return out && out.trim() ? <span>{out} {link}</span> : "";          
      },
      width: 240,
    },
    {
        field: 'status',
        headerName: 'Status',
        sortable: false,
        width: 130,
        renderCell: params => {
            const row = params.row
            return row.status
        }
    },
    {
        field: 'tipo',
        headerName: 'Tipo',
        sortable: false,
        width: 200,
    },
    // {
    //     field: 'cliente',
    //     headerName: 'Cliente',
    //     type: 'string',    
    //     renderCell: (params) => {
    //         const own = params.value;
    //         const out = own ? [ own.nombre || "", 
    //                             own.apellido || "",
    //                             own.razon_social || ""
    //                         ].join(' ') : "";
    //         console.log('client', own)
    //         const link = (onClick && onClick('CONTACT') && own && own._id) ? (
    //             <IconButton onClick={onClick('CONTACT')(own._id)}>
    //                 <ExitIcon fontSize='10'/>
    //             </IconButton>
    //         ) : ""
    //         //return out && out.trim() ? [<span>{out}</span>] : own;          
    //         return out && out.trim() ? [<span>{out}</span>, link] : out;          
    //     },
    //     width: 240,
    // },
    {
        field: 'direccion',
        headerName: 'Direccion',
        type: 'string',
        width: 180,
    },
    {
        field: 'email',
        headerName: 'Email',
        type: 'string',
        width: 250,
    },
    {
        field: 'telefono',
        headerName: 'Telefono',
        type: 'string',
        width: 250,
    },
    {
        field: 'ownerId',
        headerName: 'Responsable',
        valueGetter: (params) => {
            const own = params.value;
            return own && own.username
        },
        sortable: false,
        width: 150,
    },
    {
        field: 'updated_at',
        headerName: 'Actualizado',
        //type: "date",
        valueGetter: (params) => {
            const fecha = params.value;
            return fecha && moment(fecha).utc().format("YYYY-MM-DD")
        },
        sortable: false,
        width: 120,
    },
    {
        field: 'actions',
        headerName: 'Acciones',
        type:'actions',
        getActions: (params) => [
            <GridActionsCellItem icon={<CreateIcon/>} onClick={onClick('PVTA')(params.row)} label="Edit" />,
        ]
    }
    ])

const ListPage = forwardRef( ({
    docs=[], 
    num=0,
    limit=100, 
    loading=false,
    hideColumns= [],
    onClick=()=>{},
    onPageChange=()=>{},
    ...other 
}, ref) => {
    
    return (
        <DataGrid
            loading={loading} 
            rows={docs} 
            rowCount={num}
            columns={columns(onClick)} 
            disableColumnFilter
            sortingOrder='null'

            pagination              
            paginationMode="server"
            pageSize={limit}
            onPageChange={onPageChange}

            rowHeight={35} 
            hideFooter={false}

            {...other}      
        />    
    )
})

export default ListPage;